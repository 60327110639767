import { FormControl, Icon } from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import CloseIcon from '@material-ui/icons/HighlightOff';

const Selector = ({ value, valueKey, unit, onChange, onSubmit, disabled, isSelecting, onSubmitKeyPressed, toggleSelection, toggleSelector, cardLeftPosition, meta }) => {
  let selectionCard;
  let link;
  const unitString = unit ? L20NContext.getSync(unit) : L20NContext.getSync('minutes');
  const label = `${value} ${unitString}`;
  return (
    <span style={{ position: 'relative' }}>
      <span
        style={{ color: '#1b97c2', opacity: disabled ? 0.5 : 1, fontWeight: 'bold', fontSize: 18, cursor: disabled ? 'not-allowd' : 'pointer', textDecoration: 'underline' }}
        onClick={disabled ? null: () => toggleSelection(valueKey, link.getBoundingClientRect(), selectionCard.getBoundingClientRect())}
        ref={(c) => { link = c; }}
      >
        {label}
      </span>
      <div
        className="card"
        style={{
          padding: 0,
          position: 'absolute',
          backgroundColor: 'white',
          bottom: -15,
          left: -cardLeftPosition,
          zIndex: 10000,
          minWidth: 200,
          visibility: isSelecting === valueKey ? 'visible' : 'hidden',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          cursor: 'normal',
        }}
        ref={c => (selectionCard = c)}
      >
        {meta.invalid && meta.error &&
          <p style={{ color: 'darkred', marginLeft: 5, fontSize: 13, marginBottom: 0 }}>
            <Entity entity={meta.error} />
          </p>
        }
        <div style={{ position: 'absolute', right: 0 }} onClick={() => toggleSelector()}>
          <CloseIcon style={{ fontSize: 20, color: 'gray', marginTop: 3, marginRight: 7 }} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', padding: 5, paddingTop: 20 }}>
          <FormControl
            type="number"
            className="sentence-input"
            value={value}
            onChange={disabled ? null : e => onChange(e.target.value)}
            onFocus={disabled ? null: e => e.target.select()}
            onKeyPress={disabled ? null: e => onSubmitKeyPressed(e)}
          />
          <h5 style={{ color: '#1b97c2', fontWeight: 'bold', margin: 0, marginTop: 3, marginLeft: 10, fontSize: 15 }}>{unitString}</h5>
        </div>
        <div onClick={() => meta.invalid ? null : onSubmit()} style={{ color: 'white', cursor: 'pointer', backgroundColor: meta.invalid ? '#cacccd' : '#1b97c2', fontWeight: 'bold', textAlign: 'center', lineHeight: '220%' }}>
          <Entity entity="confirm" />
        </div>
      </div>
    </span>
  );
};

class SentenceSelectorInputField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSelecting: false,
      cardLeftPosition: 200,
      currentValue: props.input && props.input.value,
    };
    this.selectionCard = null;
    this.link = null;
    this.selectorBox = null;
  }

  onInputChange(value) {
    const { input } = this.props;
    this.setState({ currentValue: value });
    input.onChange(value);
  }

  onSubmit() {
    const { input: { value: selectedValue }, input, minValue } = this.props;
    const { currentValue } = this.state;
    let valueToSubmit = selectedValue;
    if (!currentValue || (_.isString(currentValue) && !currentValue.length)) valueToSubmit = 1;
    input.onChange(valueToSubmit);
    this.toggleSelection();
  }

  onSubmitKeyPressed(e) {
    if (e) {
      const charCode = e.which || e.keyCode;
      if (charCode === 13) {
        this.onSubmit();
      }
    }
  }
  
  toggleSelection(key, link, card) {
    const { input } = this.props;
    const { isSelecting } = this.state;
    const cardPosition = card && !_.isNull(key) && (card.right > window.innerWidth ? -(window.innerWidth - (card.right + 50)) : (card.width - link.width) / 2);
    this.setState({ isSelecting: ((isSelecting === key || key === null) ? null : key) }, () => {
      if (!_.isNull(isSelecting)) this.setState({ cardLeftPosition: cardPosition });
    });
    input.onFocus();
  }

  toggleSelector() {
    const { isSelecting } = this.state;
    this.setState({ isSelecting: !isSelecting });
  }

  render() {
    const { sentence, containerStyle, disabled, textStyle, unit, input: { value: selectedValue }, meta } = this.props;
    const { isSelecting, cardLeftPosition } = this.state;
    const chunks = sentence.split(/\$0/);
    return (
      <div style={containerStyle} ref={(g) => { this.selectorBox = g; }}>
        <div style={textStyle}>
          {_.map(chunks, (chunk, index) =>
            <span>
              <span style={{ fontSize: 18, color: '#3f3f3f' }}>{chunk}</span>
              {index !== chunks.length - 1 &&
                <span>
                  <Selector
                    key={index}
                    meta={meta}
                    disabled={disabled}
                    value={selectedValue?selectedValue:"0"}
                    isSelecting={isSelecting}
                    cardLeftPosition={cardLeftPosition}
                    unit={unit}
                    toggleSelection={(key, link, card) => this.toggleSelection(key, link, card)}
                    toggleSelector={() => this.toggleSelector()}
                    onChange={value => this.onInputChange(value)}
                    onSubmitKeyPressed={e => this.onSubmitKeyPressed(e)}
                    onSubmit={() => this.onSubmit()}
                  />
                </span>
            }
            </span>,
          )}
        </div>
      </div>
    );
  }
}

export default SentenceSelectorInputField;


// @ts-nocheck
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import { createTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BLUE, GREEN, LOCKER_LOCK_STATUS, RED } from '../../_config/consts';
import { getColorFromThemeName } from '../../_config/utils';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';

import * as LockerActions from '../../redux/actions/locker.actions';
import LockersIconCustom from '../CustomIcons/LockersIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  tableHeader: {
    padding: 5,
    paddingLeft: 25,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: '#E53935',
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lockerRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lockerLocksListContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: -5,
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 45,
    height: 45,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  lockContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid grey',
    padding: 3,
    paddingRight: 6,
    borderRadius: 10,
    backgroundColor: 'white',
    marginRight: 5,
    marginLeft: 5,
    marginTop: 3,
    marginBottom: 3,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect((state) => ({ lockers: state.lockers,
  viewLoading: state.utils.viewLoading, companyConfigurations: state.user.companyConfigurations, themeName: state.settings.items.theme.data.themeName }))

class LockerTableView extends React.Component {
  
  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { lockers: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(LockerActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(LockerActions.setOrder(undefined, undefined));
    onOrderChanged()
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(LockerActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      lockers: { data: { content: lockerData, pagination: { number: currentPage, size, totalPages, totalElements }, filters, sorting: {orderBy, orderDir} } },
      lockers: { selectedLocker },
      lockers: { locks: { content: locks }},
      onFetchLockerOnPage,
      onSelectLocker,
      onRefreshLockers,
      onNewLocker,
      onDeleteLocker,
      viewLoading,
      themeName,
    } = this.props;
    const headerColor = getColorFromThemeName(themeName);
    const canEditCreateLockers = true // TODO AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.LOCKER);
    return (
      <div className="list-view details-section" style={{ top: 150, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(lockerData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell className={classes.tableHeader} colSpan={2}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="lockersList" /></h3>
                        <IconButton onClick={() => onRefreshLockers()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                        {/* {orderBy && 
                          <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                            <UnsortIconCustom style={{ color: 'white', width: 25 }} />
                          </IconButton>
                        } */}
                      </div>
                    </TableCell>
                    <TableCell />
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="lockerTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchLockerOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchLockerOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ minWidth:220 }}>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="lockerName" /></h4>
                      </div>
                    </TableCell>
                    <TableCell style={{ minWidth:200, maxWidth:250 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="lockerStatus" /></h4></TableCell>
                    <TableCell style={{ minWidth:300 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="lockerLocks" /></h4></TableCell>
                    <TableCell style={{ minWidth:300 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="lockerEntranceLocks" /></h4></TableCell>
                    {canEditCreateLockers ? <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="actions" /></h4></TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(lockerData, (locker) => {
                    const lockerLocks = _.chain(locker.locks)
                      .map(lock => _.find(locks, { id: lock.lockId }))
                      .compact()
                      .sortBy(lock => lock.name.toLowerCase())
                      .value();
                    const lockerLocksEntrance = _.chain(locker.entranceLocks)
                      .map(lock => _.find(locks, { id: lock.id }))
                      .compact()
                      .sortBy(lock => lock.name.toLowerCase())
                      .value();
                    
                    const allLocks = locker.locks.length
                    const freeLocks = locker.locks.filter(e=>e.status===LOCKER_LOCK_STATUS.FREE).length
                    const reservedLocks = locker.locks.filter(e=>e.status===LOCKER_LOCK_STATUS.RESERVED).length

                    return (
                      <TableRow
                        key={locker.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={() => onSelectLocker(locker)}
                        selected={selectedLocker && selectedLocker.id === locker.id}
                      >
                        <TableCell style={{maxWidth:0, margin: 25 }}>
                          <div className={classes.lockerRowContainer} style={{ margin: 15, marginLeft: 5 }}>
                            <LockersIconCustom style={{ width: 22, marginRight: 10, color: '#3f3f3f' }} />
                            <h5 style={{ fontSize: 17, fontWeight: 'bold', margin: 15, marginLeft: 0, marginRight: 0, lineBreak:'anywhere' }}>{`${locker.name}`}</h5>
                          </div>
                        </TableCell>
                        <TableCell style={{}}>
                          <div className={classes.lockerRowContainer}>
                            <div style={{display:'flex', flexDirection:'column'}}>
                              <div style={{display:'flex', flexDirection:'row', alignItems:'baseline', marginLeft:0, marginTop:2, marginBottom: 5 }}>
                                <h5 style={{ fontSize: 14, fontWeight: 'bold', margin:0, lineBreak:'anywhere', color: GREEN }}>
                                  {L20NContext.getSync('lockerSlots_FREE')}:
                                </h5>
                                <h5 style={{ fontSize: 14, fontWeight: 'bold', margin: 0,  marginLeft: 5, lineBreak:'anywhere', color: GREEN, minWidth:14 }}>
                                  {`${freeLocks} / ${allLocks} `}
                                </h5>
                              </div>
                              <div style={{display:'flex', flexDirection:'row', alignItems:'baseline', marginLeft: 0, marginTop: 5, marginBottom: 5 }}>
                                <h5 style={{ fontSize: 14, fontWeight: 'bold', margin:0, lineBreak:'anywhere', color: RED }}>
                                  {L20NContext.getSync('lockerSlots_RESERVED')}:
                                </h5>
                                <h5 style={{ fontSize: 14, fontWeight: 'bold', margin:0, marginLeft: 5, lineBreak:'anywhere', color: RED, minWidth:14 }}>
                                  {`${reservedLocks} / ${allLocks} `}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={{}}>
                          <div className={classes.lockerRowContainer} >
                            <div className={classes.lockerLocksListContainer} >
                              {_.map(lockerLocksEntrance, (lock) => {
                                return (
                                  <Tag
                                    tag={lock.lockTag}
                                    style={{ padding: 5 }}
                                    textStyle={{ fontSize: 14 }}
                                  />
                                )
                              })}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={{}}>
                          <div className={classes.lockerRowContainer} >
                            <div className={classes.lockerLocksListContainer} >
                              {_.map(lockerLocks, (lock) => {
                                return (
                                  <Tag
                                    tag={lock.lockTag}
                                    style={{ padding: 5 }}
                                    textStyle={{ fontSize: 14 }}
                                  />
                                )
                              })}
                            </div>
                          </div>
                        </TableCell>
                        {canEditCreateLockers ? (
                          <TableCell>
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                onDeleteLocker(locker.id);
                              }}
                              className={classes.button}
                            >
                              <Entity entity="delete" />
                              <DeleteIcon style={{ fontSize: 15, marginLeft: 5 }} />
                            </Button>
                          </TableCell>
                        ) : null}
                        <ReactTooltip delayShow={200} effect="float" />
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="lockerTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchLockerOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchLockerOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title={filters && !filters.search ? 'noLockers' : 'noLockersFound'}
                    subtitle={filters && !filters.search ? 'noLockersDescription' : 'noLockersFoundSearchDescription'}
                    onNewEntity={filters && !filters.search && onNewLocker ? () => onNewLocker() : null}
                    newEntityTitle="addLocker"
                  /> ) : (
                    <ListLoadingView />
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LockerTableView);

import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  Form
} from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES, RED } from '../../_config/consts.js';
import { hasFormWritePermission } from '../../_config/utils.js';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import CustomField from './Fields/CustomField.jsx';
import ReactTooltip from 'react-tooltip';

const validate = values => {
  const errors = {};
  if (!values.firstname) {
    errors.firstname = 'required';
  }

  if (!values.lastname) {
    errors.lastname = 'required';
  }

  if (!values.username) {
    errors.username = 'required';
  }
  if (!values.userRole || (values.userRole && _.isEmpty(values.userRole))) {
    errors.userRole = 'required';
  }

  if (!values.email) {
    errors.email = 'required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'invalidEmailAddress';
  }
  return errors;
};


const PlatformUserInfoSection = ({ title, body, bodyColor, actionText, onClick, toolTipText }) =>
  (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{title}</h5>
        {toolTipText &&
        <p style={{ margin: 0, marginTop: 5, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync(toolTipText)}>
          <InfoIcon style={{ fontSize: 18, marginLeft: 10, cursor: 'pointer', color: '#3f3f3f' }} />
        </p>
        }
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <div>
          <h5 style={{color: bodyColor }}>{body}</h5>
        </div>
        <h5 onClick={() => onClick()} className="link-label">{actionText}</h5>
      </div>
      <ReactTooltip delayShow={200} effect="float" />
    </div>
  );
@reduxForm({ form: 'InstallerForm', validate })
@connect(state => ({ form: state.form.InstallerForm, user: state.user, companyConfigurations: state.user.companyConfigurations }))
class InstallerForm extends React.Component {


  componentWillReceiveProps(nextProps) {
    const { form, dispatch } = nextProps;
    const { form: oldForm } = this.props;
    if (form && oldForm && form.values && oldForm.values) {
      if (!form.values.username && form.values.email !== oldForm.values.email) {
        if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(form.values.email)) {
          const splittedEmail = form.values.email.split('@');
          if (splittedEmail && splittedEmail.length) {
            dispatch(change('InstallerForm', 'username', splittedEmail[0]));
          }
        }
      }
    }
  }

  render() {
    const { user, installer, onDeleteUser, onConfirmResetPassword, form, onDisableUser, onEnableUser, isDisabledRoleSection } = this.props;
    const isEditing = form && form.values && form.values.id;
    const canDeleteUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.USER);
    const canUpdateUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.USER);
    const isSelfEditing = form && form.values && form.values.id && user && user.data && user.data.id && (form.values.id === user.data.id);
    return (
      <Form>
        <input style={{ display: 'none' }} type="username" name="username" />
        <FormCardContainer
          title={isEditing ? 'userData' : 'createNewInstaller'}
          subtitle={isEditing ? '' : 'createNewInstallerSubtitle'}
          headerStyle={{ marginBottom: 15 }}
        >
          <Entity
            componentClass={Field}
            name="firstname"
            componentAttribute="placeholder"
            entity="insertFirstName"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing)}
            title={<Entity entity="firstName" />}
            component={CustomField}
            mandatory
          />
          <Entity
            componentClass={Field}
            name="lastname"
            componentAttribute="placeholder"
            entity="insertSurname"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing)}
            title={<Entity entity="surname" />}
            component={CustomField}
            mandatory
          />
          <Entity
            componentClass={Field}
            name="email"
            componentAttribute="placeholder"
            entity="insertEmail"
            title="E-mail"
            disabled={isEditing}
            component={CustomField}
            mandatory
          />
          {installer && installer.id && canUpdateUser && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <h5
                onClick={() => onConfirmResetPassword()}
                className="link-label"
                style={{ margin: 0 }}
              >
                <Entity entity="resetPassword" />
              </h5>
            </div>
          )}
          <Entity
            componentClass={Field}
            name="username"
            componentAttribute="placeholder"
            entity="insertUsername"
            title={<Entity entity="username" />}
            disabled={isEditing}
            component={CustomField}
            mandatory
          />
          {installer && installer.id && canUpdateUser && !isSelfEditing && (
            <PlatformUserInfoSection
              title={<Entity entity="userState" />}
              toolTipText={!installer.enabled && 'guestInactiveStatusExplanation'}
              body={installer.enabled ? <Entity key="userEnabled" entity="userEnabled" /> : <Entity key="userDisabled" entity="userDisabled" />}
              bodyColor={installer.enabled ? 'green' : 'red'}
              actionText={installer.enabled ? <Entity key="deactivateUser" entity="deactivateUser" /> : <Entity key="activateUser" entity="activateUser" />}
              onClick={installer.enabled ? () => onDisableUser() : () => onEnableUser()}
            />
          )}
        </FormCardContainer>
        {onDeleteUser && installer.id && canDeleteUser && !isSelfEditing && (
          <MDButton
            title={<Entity entity="deleteUserManagerFromSystem" />}
            backgroundColor={RED}
            onClick={() => onDeleteUser()}
          />
        )}
      </Form>
    );
  }
} 

export default InstallerForm;

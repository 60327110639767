// @ts-nocheck
import { CircularProgress, createTheme, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { BLUE, GREEN } from '../../_config/consts';
import MessageAlertIconCustom from '../CustomIcons/MessageAlertIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import AreaRuleIconCustom from '../CustomIcons/AreaRuleIconCustom.jsx';
import Tag from '../Tag.jsx';

const styles = theme => ({
  container: {
    overflowY: 'auto',
    height: '80vh',
    paddingLeft: 15,
    paddingBottom: 220,
    paddingRight: 20,
    paddingTop: 0,
  },
  rowContainer: {
    padding: 20,
    borderRadius: 10,
    margin: 'auto',
    marginBottom: 15,
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
  },
  rowDescription: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 16,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAreaRulesView = ({ onCreateNew, canManageAreaRules }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 20, marginTop: 50, marginRight: 50 }}>
    <AreaRuleIconCustom style={{ color: '#bdbdbd', width: 50, alignSelf: 'center' }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', textAlign: 'center', marginTop: 0 }}><Entity entity="noAreaRulesFound" /></h3>
    {canManageAreaRules && <h4 style={{ color: '#bdbdbd', fontWeight: 'normal',lineHeight: '1.3em', textAlign: 'center', marginTop: 0 }}><Entity entity="noAreaRulesFoundDescription" /></h4>}
    {canManageAreaRules && 
      <MDButton
        title={<Entity entity="newAreaRule" />}
        titleStyle={{ marginTop: 0, fontSize: 13 }}
        backgroundColor={GREEN}
        containerStyle={{ margin: 0, marginTop: 10 }}
        onClick={() => onCreateNew()}
      />
    }
  </div>
);

const AreaRuleRow = ({ areaRule, allLocksCompatibleWithRules, classes, onRowClick, canManageAreaRules }) => {
  const lock = allLocksCompatibleWithRules.find(e=>e.id===areaRule.lockId)
  return (
    <Card className={`card card-rounded ${classes.rowContainer}`} 
      style={{cursor:canManageAreaRules?'pointer':'default', pointerEvents:canManageAreaRules?'unset':'none', display: 'flex', flexDirection: 'row'}} 
      onClick={onRowClick}>
      <AreaRuleIconCustom style={{ color: BLUE, width: 30, alignSelf: 'center', marginRight:10 }} />
      <div>
        <div style={{display:'flex', flexDirection:'column', marginLeft:10}}>
          <span>
            <span>
              {`${L20NContext.getSync('areaRuleRowDescription')}:`}
            </span>
            <span style={{fontWeight:'bold', marginLeft:4}}>
              {`${areaRule.occupancyLessOrEqualTo} ${L20NContext.getSync('guests')}`}
            </span>
          </span>
          <span>
            <span>
              {`${L20NContext.getSync('areaRuleRowDescription2')}:`}
            </span>
            <span style={{fontWeight:'bold', marginLeft:4}}>
              {`${Math.round(areaRule.checkIntervalMillis/(60*1000))} ${L20NContext.getSync('minutes')}`}
            </span>
          </span>
        </div>
        {lock && lock.id ? (
          <div style={{ margin: 5 }}>
            <Tag
              tag={lock.lockTag}
            />
          </div>
        ) : null}
      </div>
    </Card>
  )
  
}

class AreaRulesListView extends React.Component {
  render() {
    const { areaRulesData, onRowClick, onCreateNew, classes, allLocksCompatibleWithRules, canManageAreaRules } = this.props;
    return (
      <div className={classes.container}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => {}}
          hasMore={false}
          useWindow={false}
          loader={
            <div style={{ marginTop: 0, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingVertical: 5, }}>
              <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loadingLogs" /></h4>
              <CircularProgress className={classes.circularProgress} />
            </div>
          }
        >
          <div>
            {!_.isEmpty(areaRulesData) ?
              _.map(areaRulesData, (areaRule) =>
                <AreaRuleRow
                  key={areaRule.id}
                  areaRule={areaRule}
                  allLocksCompatibleWithRules={allLocksCompatibleWithRules}
                  classes={classes}
                  onRowClick={canManageAreaRules ? () => onRowClick(areaRule) : null}
                  canManageAreaRules={canManageAreaRules}
                />)
                : (
                  <div>
                    <EmptyAreaRulesView
                      onCreateNew={() => onCreateNew()}
                      canManageAreaRules={canManageAreaRules}
                    />
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }

}
export default withStyles(styles)(AreaRulesListView);
// @ts-nocheck
import { Card, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/HighlightOff';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Warning } from '@material-ui/icons';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { Divider } from '@material-ui/core';
import TextIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Field, change, reduxForm } from 'redux-form';
import { BLUE, LANGUAGES, MATCH_TAG_MODE, ORANGE, PERMISSION_ENTITIES, PERMISSIONS } from '../../_config/consts.js';
import * as LockerActions from '../../redux/actions/locker.actions';
import * as LocksActions from '../../redux/actions/lock.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import BasicModal from '../Modals/BasicModal.jsx';
import LockerLocksSelectorField from '../forms/Elements/LockerLocksSelectorField.jsx';
import FormCardContainer from '../forms/Elements/FormCardContainer.jsx';
import CustomField from '../forms/Fields/CustomField.jsx';
import SelectableField from '../forms/Fields/SelectableInput/SelectableField.jsx';
import MDCheckBoxGroupField from '../forms/Fields/MDCheckBox/MDCheckBoxGroupField.js';
import TranslatableOption from '../forms/Fields/TranslatableOption.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import MDRadioButtonView from '../forms/Elements/MDRadioButtonsGroup/MDRadioButtonView.js';
import { elaborateForbiddenDaysString, isLockSupportingLockerCompartmentFunctionality, isLockSupportingPIN } from '../../_config/utils.js';

const timeOptions = [
  {
    value: 1,
    label: <Entity entity="twentifourhours" />,
  },
  {
    value: 2,
    label: <Entity entity="moringTime" />,
  },
  {
    value: 3,
    label: <Entity entity="afternoonTime" />,
  },
  {
    value: 4,
    label: <Entity entity="moringAndAfternoon" />,
  },
];

const styles = theme => ({
  attachment: {
    backgroundColor: '#BBBBBB',
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: BLUE,
    }
  },
});

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'required';
  }

  if (!values.pickupDays) {
    errors.pickupDays = 'required';
  }
  if (values.pickupDays!=null&&values.pickupDays<=0) {
    errors.pickupDays = 'lockerGreaterThanZero';
  }

  if (!values.operationTimeout) {
    errors.operationTimeout = 'required';
  }
  if (values.operationTimeout!=null&&values.operationTimeout<=0) {
    errors.operationTimeout = 'lockerGreaterThanZero';
  }

  if (!values.timeIntervalFrom) {
    errors.timeIntervalFrom = 'required';
  }
  if (!values.timeIntervalTo) {
    errors.timeIntervalTo = 'required';
  }
  if (values.timeIntervalTo && values.timeIntervalFrom) {
    const toTime = moment(values.timeIntervalTo);
    const fromTime = moment(values.timeIntervalFrom);
    const toDate = moment(0).hour(toTime.hour()).minute(toTime.minute()).second(0);
    const fromDate = moment(0).hour(fromTime.hour()).minute(fromTime.minute()).second(0);
    if (toDate.isSameOrBefore(fromDate)) {
      errors.timeIntervalTo = 'Interval not valid';
    }
  }
  /*
  if (!values.timeZone) {
    errors.timeZone = 'required';
  }
  */
  return errors;
};

@reduxForm({ form: 'LockerDetailsViewForm', validate })
@connect(state => ({ lockers: state.lockers, form: state.form.LockerDetailsViewForm, language: state.settings.language, companyConfigurations: state.user.companyConfigurations }))
class LockerDetailsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      timeOptionSelected: undefined,
      timezones: momentTz.tz.names(),
      guessedTimezone: momentTz.tz.guess(),
    }
  }

  async componentDidMount() {
    const { locker } = this.props;
    this.init(locker)
  }

  componentWillReceiveProps(nextProps) {
    const { locker: lockerOld } = this.props;
    const { locker } = nextProps;
    if (locker.id!==lockerOld.id)
      this.init(locker)
  }

  async init(locker) {
    const { dispatch } = this.props;

    dispatch(change('LockerDetailsViewForm', 'smartLocksIds', locker.locks?locker.locks.map(e=>{return e.lockId}):[]));
    dispatch(change('LockerDetailsViewForm', 'entranceLockIds', locker.entranceLocks?locker.entranceLocks.map(e=>{return e.id}):[]));
    dispatch(change('LockerDetailsViewForm', 'lockTagMatchingMode', MATCH_TAG_MODE.AT_LEAST_ONE_TAG));
    dispatch(change('LockerDetailsViewForm', 'lockTagMatchingModeEntrance', MATCH_TAG_MODE.AT_LEAST_ONE_TAG));
    dispatch(change('LockerDetailsViewForm', 'pickupDays', locker.pickupDays || 3));
    dispatch(change('LockerDetailsViewForm', 'operationTimeout', locker.operationTimeout || 60));
    dispatch(change('LockerDetailsViewForm', 'daysOfTheWeek', locker.daysOfTheWeek || [1, 2, 3, 4, 5, 6, 7]));

    const intervalSecondsFrom = locker.intervalSecondsFrom || 0;
    const intervalSecondsTo = locker.intervalSecondsTo || 86340;
    const fromHours = Math.floor(intervalSecondsFrom / 3600);
    const fromMinutes = Math.floor((intervalSecondsFrom % 3600) / 60);
    const toHours = Math.floor(intervalSecondsTo / 3600);
    const toMinutes = Math.ceil((intervalSecondsTo % 3600) / 60);
    dispatch(change('LockerDetailsViewForm', 'timeIntervalFrom',moment().startOf('day').set({ hour: fromHours, minute: fromMinutes }).valueOf()));
    dispatch(change('LockerDetailsViewForm', 'timeIntervalTo',moment().startOf('day').set({ hour: toHours, minute: toMinutes }).valueOf()));
    /*
    const { guessedTimezone } = this.state;
    let timezone_initial = locker.timeZone?locker.timeZone:guessedTimezone
    dispatch(change('LockerDetailsViewForm', 'timeZone', timezone_initial));
    */
  }
  
  onFilterTimezones(value) {
    const timezones = momentTz.tz.names().filter(timezone => timezone.toLowerCase().includes(value.toLowerCase()));
    this.setState({ timezones });
  }

  onSelectTimezone(selectedOption) {
    const { dispatch } = this.props;
    const selectedTimezone = selectedOption && selectedOption.value ? selectedOption.value : momentTz.tz.guess();
    dispatch(change('LockerDetailsViewForm', 'timeZone', selectedTimezone));
    this.setState({ guessedTimezone: selectedTimezone });
  }
  
  onDateCheckboxChange(value) {
    const { dispatch } = this.props;
    this.setState({ timeOptionSelected: value });
    switch (value) {
      case 1: {
        dispatch(change('LockerDetailsViewForm', 'timeIntervalFrom', moment().startOf('day').valueOf()));
        dispatch(change('LockerDetailsViewForm', 'timeIntervalTo', moment().endOf('day').valueOf()));
        break;
      }
      case 2: {
        dispatch(change('LockerDetailsViewForm', 'timeIntervalFrom', moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change('LockerDetailsViewForm', 'timeIntervalTo', moment().startOf('day').set('hours', 13).valueOf()));
        break;
      }
      case 3: {
        dispatch(change('LockerDetailsViewForm', 'timeIntervalFrom', moment().startOf('day').set('hours', 14).valueOf()));
        dispatch(change('LockerDetailsViewForm', 'timeIntervalTo', moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      case 4: {
        dispatch(change('LockerDetailsViewForm', 'timeIntervalFrom', moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change('LockerDetailsViewForm', 'timeIntervalTo', moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      default: {
        dispatch(change('LockerDetailsViewForm', 'timeIntervalFrom', moment().startOf('day').valueOf()));
        dispatch(change('LockerDetailsViewForm', 'timeIntervalTo', moment().endOf('day').valueOf()));
        break;
      }
    }
  }

  updateLockIds(locks) {
    const { dispatch } = this.props;
    const smartLocksIds_new = _.map(locks,e=>{return e.id})
    dispatch(change('LockerDetailsViewForm', 'smartLocksIds', smartLocksIds_new));
  }

  updateLockIdsEntrance(locks) {
    const { dispatch } = this.props;
    const entranceLockIds_new = _.map(locks,e=>{return e.id})
    dispatch(change('LockerDetailsViewForm', 'entranceLockIds', entranceLockIds_new));
  }

  async onAddLockIdRequest(lock) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="assignLockerLockDataMessage" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onAddLockIdConfirm(lock),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onAddLockIdConfirm(lock) {  
    const { dispatch, form } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const lockerId = form.values.id
      const response = await dispatch(LockerActions.addLockToLocker(lockerId,lock.id));
      if (response)
        dispatch(change('LockerDetailsViewForm', 'smartLocksIds', response.locks?response.locks.map(e=>{return e.lockId}):[]));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerLockAssigned' }} /></h6>),
        },
      }));
    } catch (error) {
      let errorMessage = 'lockerLockAssignError';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(LockerActions.setOperationalMode(false));
      dispatch(LockerActions.selectLocker({}));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }

  async onRemoveLockIdRequest(lock) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deleteLockerLockDataMessage" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onRemoveLockIdConfirm(lock),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onRemoveLockIdConfirm(lock) {  
    const { dispatch, form } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const lockerId = form.values.id
      const response = await dispatch(LockerActions.removeLockFromLocker(lockerId,lock.id));
      if (response)
        dispatch(change('LockerDetailsViewForm', 'smartLocksIds', response.locks?response.locks.map(e=>{return e.lockId}):[]));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerLockDeleted' }} /></h6>),
        },
      }));
    } catch (error) {
      let errorMessage = 'lockerLockDeleteError';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(LockerActions.setOperationalMode(false));
      dispatch(LockerActions.selectLocker({}));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }

  render() {
    const { classes, dispatch, lockers, form, formStyle, timeUnitsOptions, language,
      locksFilteredByTags, availableLockTags, onLockTagsChange, 
      onLockInputChange, onFetchMoreLocks, onLockFilterModeChange, 
      locksFilteredByTagsEntrance, availableLockTagsEntrance, onLockTagsChangeEntrance, 
      onLockInputChangeEntrance, onFetchMoreLocksEntrance, onLockFilterModeChangeEntrance, 
    } = this.props;
    const { timeOptionSelected, timezones } = this.state;

    const isEdit = form && form.values && form.values.id
    const locks = lockers?.locks?.content?lockers.locks.content:[];
    
    const smartLocksIds_current = form && form.values && form.values.smartLocksIds ? form.values.smartLocksIds : [];
    const selectedLockData = _.filter(locks, e=>{return _.some(smartLocksIds_current,f=>f===e.id)});
    
    const entranceLockIds_current = form && form.values && form.values.entranceLockIds ? form.values.entranceLockIds : [];
    const selectedLockDataEntrance = _.filter(locks, e=>{return _.some(entranceLockIds_current,f=>f===e.id)});

    const timeFrom = form && form.values && form.values.timeIntervalFrom;
    const timeTo = form && form.values && form.values.timeIntervalTo;
    const daysString = form && form.values && form.values.daysOfTheWeek && elaborateForbiddenDaysString(form.values.daysOfTheWeek) ? elaborateForbiddenDaysString(form.values.daysOfTheWeek) : '';
    
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    const dayOptions = _.map(moment.weekdays(true), (weekday) => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));

    const compartmentsLocksHaveError = selectedLockData ? _.find(selectedLockData, smartLock => !isLockSupportingLockerCompartmentFunctionality(smartLock)) : false;
    const entranceLocksHaveError = selectedLockDataEntrance ? _.find(selectedLockDataEntrance, smartLock => !isLockSupportingPIN(smartLock)) : false;

    const canEditLocker = true // TODO AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.LOCKER);

    return (
      <Form style={formStyle} autoComplete="off" style={{padding:20,paddingBottom:120}}>
        <FormCardContainer title="lockerDataTitle" subtitle="lockerDataSubtitle">
          <Entity
            componentClass={Field}
            name="name"
            componentAttribute="placeholder"
            entity="insertName"
            disabled={!canEditLocker}
            title={<Entity entity="name" />}
            component={CustomField}
            mandatory
          />
          {/* 
          <Field
            name="timeZone"
            title={<Entity entity="selectTimezone" />}
            component={SelectableField}
            options={_.map(timezones, timezone => ({ value: timezone, label: timezone }))}
            titleStyle={{ fontSize: 15, margin:0, marginBottom:6, marginTop:4 }}
            placeholder={<Entity entity="search" />}
            containerstyle={{ widht: '100%' }}
            onInputChange={value => this.onFilterTimezones(value)}
            onSelect={value => this.onSelectTimezone(value)}
            required
            disabled={false}
          />
          */}
        </FormCardContainer>
        {isEdit && 
          <FormCardContainer title="lockerSmartLocks" subtitle="lockerSmartLocksSubtitle">
            <LockerLocksSelectorField
              selectorFieldName="lockTagMatchingMode"
              name="resourceSmartLockTags"
              tags={availableLockTags}
              selectedTags={locksFilteredByTags}
              form={form}
              title="searchForLocks"
              placeholder="insertLockTagsForLocker"
              listTitle="lockerLocksSearchResult"
              selectionSectionTitle="lockerLocksSelectionTitle"
              selectionSectionSubtitle="lockerLocksSelectionSubtitle"
              selectionSectionEmptySubtitle="lockerLocksSelectionEmpty"
              isOptional={true}
              disabled={!canEditLocker}
              initialValues={selectedLockData ? selectedLockData : []}
              onFetchMoreItems={page => onFetchMoreLocks(page)}
              onTagsChange={values => onLockTagsChange(values)}
              onInputChange={value => onLockInputChange(value)}
              onFilterModeChange={values => onLockFilterModeChange(values)}
              onToggleLockId={locks => this.updateLockIds(locks)}
              onAddLockIdRequest={isEdit ? (lock => this.onAddLockIdRequest(lock)) : undefined}
              onRemoveLockIdRequest={isEdit ? (lock => this.onRemoveLockIdRequest(lock)) : undefined}
              lockHasErrorCondition={lock => !isLockSupportingLockerCompartmentFunctionality(lock)}
            />
            {compartmentsLocksHaveError ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Warning style={{ color: ORANGE, marginRight: 5, fontSize: 20 }} />
                <h5 style={{ color: ORANGE, fontWeight: 'bold' }}><Entity entity="selectedLocksLockerCompartmentIncompatibleError" /></h5>
              </div>
            ) : null}
          </FormCardContainer>
        }
        <FormCardContainer title="lockerReservationParameters" subtitle="lockerReservationParametersSubtitle">
          <div style={{fontSize:16,color:'rgb(63, 63, 63)',fontWeight:'bold',marginTop:0}}>
            {L20NContext.getSync('lockerPickupDays')}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row',paddingLeft:0 }}>
            <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="lockerPickupDaysDescription"/>}</h4>
            <Entity
              componentClass={Field}
              name="pickupDays"
              type="number"
              textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
              componentAttribute="placeholder"
              component={CustomField}
              disabled={!canEditLocker}
              containerStyle={{ width: 100 }}
              className="form-control-custom"
            />
            <h4 style={{ color: '#3f3f3f', marginLeft: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="lockerPickupDaysDescription2"/>}</h4>
          </div>
          <div style={{fontSize:16,color:'rgb(63, 63, 63)',fontWeight:'bold',marginTop:0}}>
            {L20NContext.getSync('lockerOperationTimeout')}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row',paddingLeft:0 }}>
            <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="lockerOperationTimeoutDescription"/>}</h4>
            <Entity
              componentClass={Field}
              name="operationTimeout"
              type="number"
              textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
              componentAttribute="placeholder"
              component={CustomField}
              disabled={!canEditLocker}
              containerStyle={{ width: 100 }}
              className="form-control-custom"
            />
            <h4 style={{ color: '#3f3f3f', marginLeft: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="seconds"/>}</h4>
          </div>
        </FormCardContainer>
        <FormCardContainer title="lockerCourier">
          <Divider style={{ marginBottom: 20, marginTop: 10, marginRight: 0, marginLeft: 0 }} />
          <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 15, fontWeight: 'bold' }}><Entity entity="lockerEntranceLocks"/></h4>
          <h4 style={{fontSize:17, lineHeight:'1.4em', color:'rgb(63, 63, 63)', fontWeight:200, marginTop:0}}><Entity entity="lockerEntranceLocksSubtitle"/></h4>
          <LockerLocksSelectorField
            selectorFieldName="lockTagMatchingModeEntrance"
            name="resourceSmartLockTagsEntrance"
            tags={availableLockTagsEntrance}
            selectedTags={locksFilteredByTagsEntrance}
            form={form}
            title="searchForLocks"
            placeholder="insertLockTagsForLocker"
            listTitle="lockerLocksSearchResult"
            selectionSectionTitle="lockerEntranceLocksSelectionTitle"
            selectionSectionSubtitle="lockerEntranceLocksSelectionSubtitle"
            selectionSectionEmptySubtitle="lockerEntranceLocksSelectionEmpty"
            isOptional={true}
            disabled={!canEditLocker}
            initialValues={selectedLockDataEntrance ? selectedLockDataEntrance : []}
            onFetchMoreItems={page => onFetchMoreLocksEntrance(page)}
            onTagsChange={values => onLockTagsChangeEntrance(values)}
            onInputChange={value => onLockInputChangeEntrance(value)}
            onFilterModeChange={values => onLockFilterModeChangeEntrance(values)}
            onToggleLockId={locks => this.updateLockIdsEntrance(locks)}
            onAddLockIdRequest={undefined}
            onRemoveLockIdRequest={undefined}
            lockHasErrorCondition={lock => !isLockSupportingPIN(lock)}
          />
          {entranceLocksHaveError ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Warning style={{ color: ORANGE, marginRight: 5, fontSize: 20 }} />
              <h5 style={{ color: ORANGE, fontWeight: 'bold' }}><Entity entity="selectedLocksPINIncompatibleError" /></h5>
            </div>
          ) : null}
          <Divider style={{ marginBottom: 20, marginTop: 20, marginRight: 0, marginLeft: 0 }} />
          <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 15, fontWeight: 'bold' }}><Entity entity="lockerAccessTime"/></h4>
          <h4 style={{fontSize:17, lineHeight:'1.4em', color:'rgb(63, 63, 63)', fontWeight:200, marginTop:0}}><Entity entity="lockerAccessTimeSubtitle"/></h4>
          <div style={{ paddingLeft: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginLeft: 0, marginRight: 60 }}>
              <Entity
                componentClass={Field}
                title={<Entity entity="timeIntervalFrom" />}
                name="timeIntervalFrom"
                component={CustomField}
                type="antdTimePicker"
                is12HoursFormat={is12HoursFormat}
                placeholder="Select a time"
                disabled={!canEditLocker}
              />
            </div>
            <Entity
              componentClass={Field}
              title={<Entity entity="timeIntervalTo" />}
              name="timeIntervalTo"
              component={CustomField}
              is12HoursFormat={is12HoursFormat}
              type="antdTimePicker"
              placeholder="Select a time"
              disabled={!canEditLocker}
            />
            <div style={{ marginLeft: 30, marginTop: 20 }}>
              {_.map(timeOptions, timeOption => (
                <MDRadioButtonView
                  key={timeOption.value}
                  checked={timeOptionSelected === timeOption.value}
                  value={(timeOption.value).toString()}
                  label={timeOption.label}
                  disabled={!canEditLocker}
                  handleChange={value => this.onDateCheckboxChange(timeOption.value)}
                />
              ))}
            </div>
          </div>
          <Field
            name="daysOfTheWeek"
            title={<Entity entity="daysOfTheWeek" />}
            component={MDCheckBoxGroupField}
            containerstyle={{ paddingLeft: 5, marginTop: 10 }}
            horizontal
            options={dayOptions}
            disabled={!canEditLocker}
            checkboxesContainerStyle={{justifyContent:'start'}}
            titleColor={'rgb(63, 63, 63)'}
          />
          {timeFrom!==undefined && timeTo!==undefined ? (
            <div style={{ paddingTop: 10, marginLeft: 5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <TextIcon style={{ color: '#3f3f3f', marginRight: 12, fontSize: 20 }} />
              <h5 style={{ fontSize: 15, color: '#3f3f3f' }}>
                <Entity
                  entity="lockerAccessTimeHelper"
                  key={`${timeFrom}-${timeTo}`}
                  data={{
                    timeFrom: moment(timeFrom).format('LT'),
                    timeTo: moment(timeTo).format('LT'),
                  }}
                />
                {!daysString ? '' : <span style={{ marginLeft: 5 }}><Entity entity="except" />{daysString}</span>}
              </h5>
            </div>
          ) : null}
        </FormCardContainer>
      </Form>
    );
  }
} 

export default withStyles(styles)(LockerDetailsView);

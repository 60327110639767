import { reservationActions as ReservationActions, resourceActions as ResourceActions } from '@bottega52/bookey-redux-module';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import { change, initialize, reset, submit } from 'redux-form';
import CalendarViewIcon from '@material-ui/icons/Event';
import ListViewIcon from '@material-ui/icons/ViewList';
import ReservationSearchBar from '../../../components/forms/Bookey/ReservationSearchBar.jsx';
import OperationalView from '../../../components/OperationalView/OperationalView.jsx';
import * as CredentialActions from '../../../redux/actions/credential.actions';
import * as GuestActions from '../../../redux/actions/guest.actions';
import * as LocksActions from '../../../redux/actions/lock.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as MetricsActions from '../../../redux/actions/metrics.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as ReservationsActions from '../../../redux/actions/reservations.actions';
import * as formatter from '../../../_config/formatter';
import * as RestService from '../../../_config/rest';
import { LICENSE_TYPES, MATCH_TAG_MODE, ORANGE, RESERVATION_CONFIRMATION_STATUSES, reservationSearchModeValues } from './../../../_config/consts';
import NewReservationView from './NewReservationView.jsx';
import ReservationDetailsView from './ReservationDetailsView.jsx';
import UnavailabilityDetailsView from './UnavailabilityDetailsView.jsx';
import TranslatableOption from '../../../components/forms/Fields/TranslatableOption.jsx';
import { Tab, Tabs } from '@material-ui/core';
import DateRangeView from '../../../components/forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import BookeyReservationsList from '../../../components/MetricCharts/BookeyReservationsList.jsx';
import { getColorFromThemeName } from '../../../_config/utils.js';
import ReservationTableView from '../../../components/forms/Bookey/ReservationTableView.jsx';
import SimpleExportMenu from '../../../components/ExportMenus/SimpleExportMenu.jsx';
import NewReservationViewSlots from './NewReservationViewSlots.jsx';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';


@connect(state => ({ user: state.user, routing: state.router, resources: state.resources, reservations: state.reservations, metrics: state.metrics.bookeyAnalytics, themeName: state.settings.items.theme.data.themeName, guests: state.guests, form: state.form.ReservationForm }))
class ReservationsAgenda extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('reservationAgendaViewMode');
    this.state = {
      startingDate: moment().toDate(),
      showOperativeSection: false,
      showOperativeSection2: false,
      showReservationDetails: false,
      showUnavailabilityDetails: false,
      reservationEvents: [],
      pendingReservationEvents: [],
      unavailabilitiesEvents: [],
      reservationUserId: null,
      defaultCalendarView: cachedViewMode || 'month',
      reservationAgendaViewMode: cachedViewMode || 'month',
      currentMonth: moment().month(),
      activeTab: 0,
    };
  }

  async componentWillMount() {
    const currentDate = moment();
    const { routing, dispatch } = this.props;
    try {
      dispatch(ResourceActions.fetchResourcesWorkspaces(0, 50));
      await this.fetchReservationsAndGuests(currentDate);
      const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
      const { resourceId, startTime, endTime, dateFrom } = parsed;
      if (resourceId) {
        this.onNewReservation({ startTime: _.toNumber(startTime), endTime: _.toNumber(endTime), dateFrom: _.toNumber(dateFrom), dateTo: _.toNumber(dateFrom) }, null, _.toNumber(resourceId), true);

      }
    } catch (error) {
      
    }
  }

  async fetchReservationWhenMonthChange(date) {
    const { dispatch } = this.props;
    const { currentMonth } = this.state;
    if (moment(date).month() !== currentMonth) {
      try {
        await this.fetchReservationsAndGuests(date);
      } finally {
        this.setState({currentMonth: moment(date).month(),});
      }
    }
  }

  async fetchReservationsAndGuests(forcedNewFilterDate) {
    const { dispatch, reservations: { filters: reservationFilter } } = this.props;

    await dispatch(ReservationActions.setReservationFilter('onlyPersonal', false));

    if (forcedNewFilterDate || !reservationFilter.fromDate || !reservationFilter.toDate) {
      const newFilterDate = forcedNewFilterDate?forcedNewFilterDate:moment();
      const beginDate = moment(newFilterDate).startOf('month').subtract(7, 'days').valueOf();
      const endDate = moment(newFilterDate).endOf('month').add(7, 'days').valueOf();
      await dispatch(ReservationActions.setReservationFilter('fromDate', beginDate));
      await dispatch(ReservationActions.setReservationFilter('toDate', endDate));
    }
    
    const { reservations: { filters: reservationFilterNew } } = this.props;
    const reservations = await dispatch(ReservationActions.fetchAllReservations({ ...reservationFilterNew }));

    // _.each(reservations, (reservation) => { dispatch(GuestActions.cacheGuestDetailsIfNeeded(reservation.userId)); });
    
    const uniqueUserIds = _.uniq(_.map(reservations, 'userId'));
    await dispatch(MetricsActions.cancelFetchBookeyUsers());
    await dispatch(MetricsActions.resetBookeyUsers());
    await dispatch(MetricsActions.setBookeyUsersLoading(true));
    await dispatch(MetricsActions.fetchBookeyUsers(uniqueUserIds))

    _.each(reservations, (reservation) => { reservation.resource.checkInSmartLockId && dispatch(LocksActions.cacheLockDetailsIfNeeded(reservation.resource.checkInSmartLockId)); });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { reservations: { data: { content: currentReservations } }, resources: { resourceUnavailabilities: { content: currentUnavailabilities } } } = this.props;
    const { reservations: { data: { content: previousReservations } }, resources: { resourceUnavailabilities: { content: previousUnavailabilities } } } = prevProps;
    if (currentReservations && previousReservations && currentReservations !== previousReservations) {
      const reservationEvents = this.formatReservations(_.filter(currentReservations, reservation => reservation.state !== RESERVATION_CONFIRMATION_STATUSES.PENDING_CONFIRMATION));
      const pendingReservationEvents = this.formatPendingReservations(_.filter(currentReservations, reservation => reservation.state === RESERVATION_CONFIRMATION_STATUSES.PENDING_CONFIRMATION));
      this.setState({ reservationEvents, pendingReservationEvents });
    }
    if (currentUnavailabilities && previousUnavailabilities && currentUnavailabilities !== previousUnavailabilities) {
      const unavailabilitiesEvents = this.formatUnavailabilities(currentUnavailabilities);
      this.setState({ unavailabilitiesEvents });
    }
  }

  formatReservations(reservations) {
    const { guests, user: { data: userData } } = this.props;
    let formattedReservations = [];
    if (reservations) {
      formattedReservations = _.map(reservations, (reservation, key) => {
        const isPersonalReservation = userData && userData.id && reservation.userId && reservation.userId === userData.id;
        let title = reservation.title && isPersonalReservation ? reservation.title : reservation.resource.name;
        title = !reservation.title && guests.cachedGuestsMap[reservation.userId] ? `${reservation.resource.name} - ${guests.cachedGuestsMap[reservation.userId].username}` : title;
        return ({
          id: reservation.id,
          hexColor: '#2a7394',
          title,
          start: moment(reservation.fromDate).toDate(),
          end: moment(reservation.toDate).toDate(),
          type: 'RESERVATION',
          reservation,
        });
      });
    }
    return formattedReservations;
  }

  formatPendingReservations(pendingReservations) {
    const { guests } = this.props;
    let formattedReservations = [];
    if (pendingReservations) {
      formattedReservations = _.map(pendingReservations, (reservation, key) => {
        return ({
          id: reservation.id,
          hexColor: ORANGE,
          title: guests.cachedGuestsMap[reservation.userId] ? `${reservation.resource.name} - ${guests.cachedGuestsMap[reservation.userId].username}` : reservation.resource.name,
          start: moment(reservation.fromDate).toDate(),
          end: moment(reservation.toDate).toDate(),
          type: 'RESERVATION',
          reservation,
        });
      });
    }
    return formattedReservations;
  }

  formatUnavailabilities(unavailabilities) {
    let formattedUnavailabilities = [];
    if (unavailabilities) {
      formattedUnavailabilities = _.map(unavailabilities, (unavailability, key) => {
        return ({
          hexColor: 'red',
          reason: unavailability.reason,
          resource: unavailability.resource,
          title: unavailability.resource && unavailability.resource.name ? `${unavailability.resource.name}` : '',
          start: moment(unavailability.fromDate).toDate(),
          end: moment(unavailability.toDate).toDate(),
          type: 'UNAVAILABILITY',
          unavailability,
        });
      });
    }
    return formattedUnavailabilities;
  }

  // TODO: refactor
  async fetchAvailableResourcesForReservation(page = 0, pageSize = 20, availableResourcesData, append = false) {
    const { dispatch } = this.props;
    const userTagId = availableResourcesData.userTagId;
    const dateFrom = availableResourcesData.dateFrom;
    const dateTo = availableResourcesData.dateTo;
    const canFetchAvailableResources = dateFrom < dateTo;
    if (userTagId && canFetchAvailableResources) {
      const userResponse = await RestService.fetchUsersByTags({ tagIds: [userTagId], userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG });
      if (userResponse.data.content && !_.isEmpty(userResponse.data.content)) {
        this.setState({ reservationUserId: userResponse.data.content[0].id });
      }
      const filters = {
        fromDate: dateFrom,
        toDate: dateTo,
        userId: userResponse.data.content[0].id,
        numberOfPeople: availableResourcesData.numberOfPeople || 1,
      };
      await dispatch(ResourceActions.fetchAvailableResourcesWithMedia(page, pageSize, filters, append));
    } else {
      await dispatch(ResourceActions.setReduxAvailableResources([]));
    }
  }

  async onNewReservation(dateInterval, userTagId = null, resourceId = null, avoidFetchResourcesOnInit = false) {
    const { dispatch } = this.props;
    const formattedDates = formatter.formatOutputData(formatter.RESERVATION_DATES, dateInterval);
    let { dateFrom, dateTo } = formattedDates;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      dispatch(change('ReservationForm', 'dateFrom', dateFrom));
      dispatch(change('ReservationForm', 'dateTo', dateTo));
      dispatch(change('ReservationForm', 'timeTo', dateTo));
      dispatch(change('ReservationForm', 'timeFrom', dateFrom));
      if (resourceId) {
        dispatch(change('ReservationForm', 'resourceId', resourceId));
      }
      if (!avoidFetchResourcesOnInit) {
        await this.fetchAvailableResourcesForReservation(0, 20, { userTagId, dateFrom, dateTo });
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.openOperationalSection();
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onSelectCalendarInterval(calendarInterval) {
    const { dispatch } = this.props;
    const { reservationAgendaViewMode } = this.state;
    const dateFrom = moment(calendarInterval.start).valueOf();
    const dateTo = moment(calendarInterval.end).valueOf();
    const startTime = reservationAgendaViewMode === 'month' ? moment().valueOf() : moment(calendarInterval.start).valueOf();
    const endTime = reservationAgendaViewMode === 'month' ? moment().add(1, 'hour').valueOf() : moment(calendarInterval.end).valueOf();
    if (moment(dateFrom).diff(moment(), 'days') < 0) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="selectedDayBeforeToday" /></h6>),
        },
      }));
      return;
    }
    this.onNewReservation({ dateFrom, dateTo, startTime, endTime });
  }

  openOperationalSection() {
    this.setState({ showOperativeSection: true });
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    this.setState({ showOperativeSection: false });
    this.setState({ showReservationDetails: false });
  }

  closeUnavailabilityOperationalSection() {
    const { dispatch } = this.props;
    this.setState({ showOperativeSection: false });
    this.setState({ showUnavailabilityDetails: false });
  }

  newReservationFromButton() {
    const { dispatch } = this.props;
    dispatch(reset('ReservationForm'));
    const dateFrom = moment().valueOf();
    const dateTo = dateFrom;
    const startTime = dateFrom;
    const endTime = moment().add(1, 'hours').valueOf();
    this.onNewReservation({ dateFrom, dateTo, startTime, endTime });
  }

  async saveReservation(values) {
    const { dispatch } = this.props;
    const { reservationUserId, currentMonth } = this.state;
    const reservationData = formatter.formatOutputData(formatter.RESERVATION, { ...values, reservationUserId });
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(ReservationActions.createReservation(reservationData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.closeOperationalSection();
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reservationCreatedSuccess" /></h6>),
        },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      await this.fetchReservationsAndGuests(moment().month(currentMonth));
    }
  }

  onSelectCalendarEvent(calendarEvent) {
    if (calendarEvent.type === 'UNAVAILABILITY') {
      this.onSelectUnavailability(calendarEvent.unavailability);
    } else {
      this.onSelectReservation(calendarEvent);
    }
  }

  async onSelectReservation(calendarReservation) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      let reservationResource = calendarReservation && calendarReservation.reservation && calendarReservation.reservation.resource;
      const resourceHasCustomMedia = reservationResource && reservationResource.customMedia;
      if (resourceHasCustomMedia) {
        const newMedia = await dispatch(ResourceActions.fetchResourceMedia(calendarReservation.reservation.resource.id));
        reservationResource = {
          ...reservationResource,
          media: newMedia,
        }
      }
      await dispatch(ReservationActions.setSelectedReservation({...calendarReservation.reservation, resource: reservationResource }));
      try {
        await dispatch(GuestActions.cacheGuestDetailsIfNeeded(calendarReservation.reservation.userId));
      } catch (error) {
      }
      await dispatch(CredentialActions.cancelFetchLocksByTags());
      await dispatch(CredentialActions.fetchLocksByTags(calendarReservation.reservation.resource.resourceSmartLockTags, calendarReservation.reservation.resource.lockTagMatchingMode));
      this.setState({ showReservationDetails: true, showOperativeSection: false, showUnavailabilityDetails: false });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onSelectUnavailability(calendarUnavailability) {
    const { dispatch } = this.props;
    const isSharedUnavailability = calendarUnavailability && calendarUnavailability.reason && calendarUnavailability.reason.includes('.jago.cloud');
    try {
      if (!isSharedUnavailability) {
        try {
          const reservation = await dispatch(ReservationActions.fetchReservation(calendarUnavailability.dependencyReservation));
          dispatch(ReservationActions.setSelectedReservation(reservation));
        } catch (error) {}
      }
      dispatch(ResourceActions.setSelectedResourceUnavailability({ ...calendarUnavailability, isSharedUnavailability }));
      this.setState({ showUnavailabilityDetails: true, showOperativeSection: false });
    } finally {  
    }
  }

  onDeleteReservation(reservationId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteReservationConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteReservationConfirm(reservationId),
        onCancelText: <Entity entity="no" />,
        // TODO: use redux-modal module 
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    // TODO: use redux-modal module 
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteReservationConfirm(reservationId) {
    const { dispatch } = this.props;
    const { currentMonth } = this.state;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(ReservationActions.deleteReservation(reservationId));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reservationDeletedSuccess" /></h6>),
        },
      }));
      await this.fetchReservationsAndGuests(moment().month(currentMonth));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorDeletingReservation" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } finally {
      this.closeOperationalSection();
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onCheckInReservation(reservationId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'checkInReservationConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onCheckInReservationConfirm(reservationId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onCheckInReservationConfirm(reservationId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(ReservationActions.createCheckInReservation(reservationId));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reservationCheckInSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorCheckingInReservation" /> },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } finally {
      this.closeOperationalSection();
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onDeleteCheckIn(reservationId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteCheckInReservationConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteCheckInReservationConfirm(reservationId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }


  async onSendCheckinReminder(reservationId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(ReservationActions.generateReservationCheckInOTP(reservationId));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reminderSendSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorSendReminder" /> },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } finally {
      this.closeOperationalSection();
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onDeleteCheckInReservationConfirm(reservationId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(ReservationActions.deleteCheckInReservation(reservationId));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reservationCheckInDeleteSuccess" /></h6>),
        },
      }));
    } catch (error) {
      // TODO: use redux-modal module 
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorCheckingInReservation" /> },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } finally {
      this.closeOperationalSection();
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  eventStyleGetter(event, start, end, isSelected) {
    const style = {
      backgroundColor: event.hexColor,
      borderRadius: '0px',
      opacity: 1,
      color: 'white',
      border: '0.5px solid white',
      display: 'block',
    };
    return {
      style,
    };
  }

  async onSearchReset() {
    const { dispatch } = this.props;
    try {
      await dispatch(ReservationActions.setReservationFilter('resourceId', null));
      await dispatch(ReservationActions.setReservationFilter('userId', null));
      await dispatch(ReservationActions.setReservationFilter('onlyPersonal', false));
      await dispatch(ReservationActions.setReservationFilter('workspace', undefined));
      await this.fetchReservationsAndGuests(undefined);
    } catch (error) { }
  }

  async onSelectResourceFilter(resourceId) {
    const { dispatch } = this.props;
    await dispatch(ReservationActions.setReservationFilter('resourceId', resourceId));
    await dispatch(ReservationActions.setReservationFilter('onlyPersonal', false));
    await this.fetchReservationsAndGuests(undefined);
  }

  async onSelectGuestFilter(guestId) {
    const { dispatch } = this.props;
    await dispatch(ReservationActions.setReservationFilter('userId', guestId));
    await dispatch(ReservationActions.setReservationFilter('onlyPersonal', false));
    await this.fetchReservationsAndGuests(undefined);
  }

  async onSelectWorkspaceFilter(workspace) {
    const { dispatch } = this.props;
    await dispatch(ReservationActions.setReservationFilter('workspace', workspace?workspace:undefined));
    await dispatch(ReservationActions.setReservationFilter('onlyPersonal', false));
    await this.fetchReservationsAndGuests(undefined);
  }

  onSelectCalendarViewMode(viewMode) {
    localStorage.setItem('reservationAgendaViewMode', viewMode);
    this.setState({ reservationAgendaViewMode: viewMode });
  }

  async onViewModeChange(activeTab) {
    const { dispatch } = this.props;
    const { currentMonth } = this.state;
    if (activeTab === this.state.activeTab)
      return;
    if (activeTab===0) {
      try {
        dispatch(UtilsActions.setViewLoading(true));
        this.fetchReservationWhenMonthChange(moment())
        dispatch(UtilsActions.setViewLoading(false));
      } catch (error) {
        dispatch(UtilsActions.setViewLoading(false));
      }
    }
    this.setState({ activeTab });
  }

  async handleDatesChanged(fromDate, toDate, refreshReservations) {
    const { dispatch } = this.props;
    if (fromDate && toDate && moment(fromDate) && moment(toDate)) {
      const maxToDate = moment(fromDate).add(365, 'days').endOf('day').valueOf();
      if (moment(toDate).isAfter(maxToDate))
        toDate = maxToDate;
    }
    
    const startDate = fromDate ? moment(fromDate).startOf('day').valueOf() : moment().startOf('month').valueOf();
    const endDate = toDate ? moment(toDate).endOf('day').valueOf() : moment().endOf('month').valueOf();
    await dispatch(ReservationActions.setReservationFilter('fromDate', startDate));
    await dispatch(ReservationActions.setReservationFilter('toDate', endDate));
    if (!refreshReservations) return;

    const { reservations: { filters } } = this.props;
    //await dispatch(ReservationActions.fetchReservations(0, 100, { ...filters }));
    await this.fetchReservationsAndGuests(undefined);
  }

  handleDatesFocusChange(focusedInput) {
    if (!Boolean(focusedInput)) {
      setTimeout(async () => {
        const { reservations: { filters } } = this.props;
        this.handleDatesChanged(filters.fromDate,filters.toDate,true);
      }, 100);
    }
  };

  async onExportReservations(format,parsedReservations) {
    const { dispatch } = this.props;
    dispatch(ReservationsActions.exportReservations(`Reservations-export-${moment().toLocaleString()}`, format, parsedReservations));
  }

  async onDateHasBeenChanged(dateInterval, userTagId = null, numberOfPeople=1) {
    const { dispatch } = this.props;
    const formattedDates = formatter.formatOutputData(formatter.RESERVATION_DATES, dateInterval);
    let { dateFrom, dateTo } = formattedDates;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await this.fetchAvailableResourcesForReservation(0, 20, { userTagId, dateFrom, dateTo, numberOfPeople });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onChangeReservationUser(userTag) {
    if (userTag && userTag.id) {
      const userResponse = await RestService.fetchUsersByTags({ tagIds: [userTag.id], userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG });
      if (userResponse.data.content && !_.isEmpty(userResponse.data.content))
        this.setState({ reservationUserId: userResponse.data.content[0].id });
      else 
        this.setState({ reservationUserId: undefined })
    }
    else
      this.setState({ reservationUserId: undefined })
  }

  onSelectCalendarInterval2(calendarInterval) {
    const { dispatch } = this.props;
    const { reservationAgendaViewMode } = this.state;
    const dateFrom = moment(calendarInterval.start).valueOf();
    const startTime = reservationAgendaViewMode === 'month' ? moment().valueOf() : moment(calendarInterval.start).valueOf();
    const endTime = reservationAgendaViewMode === 'month' ? moment().add(1, 'hour').valueOf() : moment(calendarInterval.end).valueOf();
    if (moment(dateFrom).diff(moment(), 'days') < 0) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="selectedDayBeforeToday" /></h6>),
        },
      }));
      return;
    }
    this.onNewReservation2(dateFrom, startTime, endTime);
  }

  async onNewReservation2(dateFrom, timeFrom, timeTo) {
    const { dispatch } = this.props;
    const currentDate = moment().startOf('day').valueOf()
    await dispatch(ResourceActions.setReduxAvailableResources([]));
    await dispatch(reset('ReservationFormSlots'));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    this.setState({ showOperativeSection2: false });
    setTimeout(async () => {
      await dispatch(initialize('ReservationFormSlots', { 
        dateFrom: dateFrom ? dateFrom : currentDate,
        searchMode: reservationSearchModeValues.SEARCH_RESOURCES,
        timeFromSearch: timeFrom ? timeFrom : moment().valueOf(),
        timeToSearch: timeTo ? timeTo : moment().add(1, 'hours').valueOf(),
        numberOfGuests: 0,
      }));
      this.setState({ showOperativeSection2: true });
    }, 500);
  }

  async saveReservation2(values) {
    const { dispatch } = this.props;
    const { reservationUserId, currentMonth } = this.state;
    dispatch(UtilsActions.setSpinnerVisibile(true));

    const fromDate = moment(values.dateFrom).set('hour', moment(values.timeFrom).hour()).set('minutes', moment(values.timeFrom).minute()).valueOf();
    const toDate = moment(values.dateFrom).set('hour', moment(values.timeTo).hour()).set('minutes', moment(values.timeTo).minute()).valueOf();
    try {
      const parsedValues = {
        dateFrom: fromDate,
        dateTo: toDate,
        checkInNotNecessary: values.checkInNotNecessary,
        resourceId: values.searchMode===reservationSearchModeValues.SELECT_RESOURCE ? values.resourceSelected.value : values.resourceId,
        title: values.title,
        description: values.description,
        numberOfPeople: parseInt(values.numberOfGuests || 0, 10) + 1,
        invitedEmails: values.invitedEmails ? values.invitedEmails.filter(e=>e.guest && e.guest.name).map(e => e.guest.name) : [],
      }
      const reservationData = formatter.formatOutputData(formatter.RESERVATION, { ...parsedValues, reservationUserId });
      await dispatch(ReservationActions.createReservation(reservationData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showOperativeSection2: false })
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reservationCreatedSuccess" /></h6>),
        },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      await this.fetchReservationsAndGuests(moment().month(currentMonth));
    }
  }

  render() {
    const { dispatch, metrics, reservations: { data: { content: reservationData }, selectedReservation, filters }, resources: { selectedUnavailability, workspaces },  themeName } = this.props;
    const { activeTab, showOperativeSection, showOperativeSection2, defaultCalendarView, showUnavailabilityDetails, showReservationDetails, pendingReservationEvents, unavailabilitiesEvents, reservationEvents } = this.state;

    const localizer = momentLocalizer(moment);

    const workspaceTitleLabel = dispatch(UtilsActions.getResourcesWorkspaceLabel());
    const workspaceOptions = [{ label: workspaceTitleLabel, value: '' }, ..._.map(_.compact(workspaces), workspace => ({ label: workspace, value: workspace }))];
    
    const usersDetails = metrics.usersDetails?metrics.usersDetails:[]
    const parsedReservations = reservationData.map(reservation=>{
      const userSearch = usersDetails.filter(e=>e.id===reservation.userId)
      return {
        ...reservation,
        user:userSearch.length?userSearch[0]:undefined
      }
    })
    
    const textColor = getColorFromThemeName(themeName);

    const canCreateReservation = !AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);

    return (
      <div style={{ marginTop: 40, height: 820 }}>
        <ReservationSearchBar
          themeName={themeName}
          onSearchReset={() => this.onSearchReset()}
          onSelectResource={resourceId => this.onSelectResourceFilter(resourceId)}
          onSelectGuest={guestId => this.onSelectGuestFilter(guestId)}
          onSelectWorkspace={value => this.onSelectWorkspaceFilter(value)}
          workspaceOptions={_.map(workspaceOptions, workspace =>
            <Entity
              key={workspace.value}
              componentClass={TranslatableOption}
              value={workspace.value}
              componentAttribute="text"
              entity={workspace.label}
            />)
          }
          onNewReservation={canCreateReservation ? () => this.onNewReservation2() : null}
        />
        {activeTab===1?(
          <div style={{ marginTop:80, width:'fit-content', marginLeft:350, display:'flex', flexDirection:'row', alignItems:'center' }}>
            <DateRangeView
              id="bookey-date-selector"
              startDateId="bookey-date-selector-start"
              endDateId="bookey-date-selector-end"
              startDate={filters && filters.fromDate ? moment(filters && filters.fromDate).startOf('day') : moment().startOf('month')}
              endDate={filters && filters.toDate ? moment(filters && filters.toDate).endOf('day') : moment().endOf('month')}
              noBorder={false}
              endDatePlaceholderText="--/--/--"
              startDatePlaceholderText="--/--/--"
              minimumNight={0}
              numberOfMonths={3}
              style={{ marginTop: 50, marginLeft: 20 }}
              containerStyle={{ display:'flex', flexDirection:'column', alignItems:'center', marginBottom: 4, marginTop: 4 }}
              isOutsideRange={(day) => false}
              onDatesChange={({ startDate, endDate }) => {this.handleDatesChanged(startDate, endDate, false)}}
              onChange={({ startDate, endDate }) => {this.handleDatesChanged(startDate, endDate, true)}}
              onFocus={(focusedInput) => this.handleDatesFocusChange(focusedInput)}
              onResetOptionSelection={() => this.onSearchReset()}
            />
            <SimpleExportMenu
              titleContainerStyle={{ marginLeft: 50, marginTop: 0 }}
              iconStyle={{ color: textColor }}
              exportTitleStyle={{ color: textColor }}
              onExportLogs={format => this.onExportReservations(format, parsedReservations)}
              popperMarginTop={-20}
            />
          </div>
        ):null}
        <div style={{ top: 145, position: 'fixed', backgroundColor: 'white', display:'flex', flexDirection:'row' }}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, index) => this.onViewModeChange(index)}
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
          >
            <Tab icon={<CalendarViewIcon style={{ fontSize: 30 }} />} />
            <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
          </Tabs>
        </div>
        {activeTab === 0 ?(
          <BigCalendar
            events={[...reservationEvents, ...unavailabilitiesEvents, ...pendingReservationEvents]}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            selectable
            showMultiDayTimes
            culture={moment.locale()}
            style={{ height: 900, padding: 15, paddingTop: 90 }}
            className="calendar-view"
            defaultView={defaultCalendarView}
            defaultDate={this.state.startingDate}
            onSelectEvent={event => this.onSelectCalendarEvent(event)}
            onSelectSlot={dateInterval => {this.onSelectCalendarInterval2(dateInterval) /*this.onSelectCalendarInterval(dateInterval)*/}}
            onView={(view) => this.onSelectCalendarViewMode(view)}
            eventPropGetter={(event, start, end, isSelected) => this.eventStyleGetter(event, start, end, isSelected)}
            onNavigate={(date) => this.fetchReservationWhenMonthChange(date)}
            messages={{
              today: <Entity entity="calendarLabels" data={{ type: 'today' }} />,
              previous: '<',
              next: '>',
              month: <Entity entity="calendarLabels" data={{ type: 'month' }} />,
              week: <Entity entity="calendarLabels" data={{ type: 'week' }} />,
              day: <Entity entity="calendarLabels" data={{ type: 'day' }} />,
              agenda: <Entity entity="calendarLabels" data={{ type: 'agenda' }} />,
              noEventsInRange: <Entity entity="emptyReservationsAgenda" />,
            }}
          />
        ):null}
        {activeTab === 1?(
          <div style={{ marginTop: 100 }}>
            <ReservationTableView
              parsedReservations={parsedReservations}
              onNewReservation={() => this.newReservationFromButton()}
              onSelectReservation={reservation => this.onSelectReservation({reservation:reservation})}
            />
          </div>
        ):null}
        
        <OperationalView
          themeName={themeName}
          isVisible={showOperativeSection}
          onClose={() => this.closeOperationalSection()}
          title={<Entity entity="newReservation" />}
        >
          <NewReservationView
            canCreateReservation
            onSubmitReservationForm={() => dispatch(submit('ReservationForm'))}
            onSaveReservation={(values) => this.saveReservation(values)}
            dateHasBeenChanged={(dateInterval, userTagId) => this.onNewReservation(dateInterval, userTagId)}
            themeName={themeName}
            onFetchMoreAvailableResources={(page, pageSize, availableResourcesData, append) => this.fetchAvailableResourcesForReservation(page, pageSize, availableResourcesData, append)}
          />
        </OperationalView>

        <OperationalView
          themeName={themeName}
          isVisible={showReservationDetails}
          onClose={() => this.closeOperationalSection()}
          title={<Entity entity="reservationDetails" />}
        >
          <ReservationDetailsView
            reservation={selectedReservation}
            onDeleteReservation={(reservationId) => this.onDeleteReservation(reservationId)}
            onCheckInReservation={(reservationId) => this.onCheckInReservation(reservationId)}
            onDeleteCheckInReservation={(reservationId) => this.onDeleteCheckIn(reservationId)}
            onSendCheckinReminder={reservationId => this.onSendCheckinReminder(reservationId)}
          />
        </OperationalView>
        
        <OperationalView
          themeName={themeName}
          isVisible={showOperativeSection2}
          onClose={() => this.setState({ showOperativeSection2: false })}
          title={<Entity entity="newReservation" />}
        >
          <NewReservationViewSlots
            onSubmitReservationForm={() => dispatch(submit('ReservationFormSlots'))}
            onSaveReservation={(values) => this.saveReservation2(values)}
            themeName={themeName}
            dateHasBeenChanged={(dateInterval, userTagId, numberOfPeople=1) => this.onDateHasBeenChanged(dateInterval, userTagId, numberOfPeople)}
            onFetchMoreAvailableResources={(page, pageSize, availableResourcesData, append) => this.fetchAvailableResourcesForReservation(page, pageSize, availableResourcesData, append)}
            onChangeReservationUser={(userTag) => {this.onChangeReservationUser(userTag)}}
          />
        </OperationalView>

        <OperationalView
          themeName={themeName}
          isVisible={showUnavailabilityDetails}
          onClose={() => this.closeUnavailabilityOperationalSection()}
          title={<Entity entity="unavailabilityDetails" />}
        >
          <UnavailabilityDetailsView
            unavailability={selectedUnavailability}
            dependencyReservation={selectedReservation}
            onShowDependencyReservation={() => this.onSelectCalendarEvent({ reservation: selectedReservation })}
          />
        </OperationalView>
        
      </div>
    );
  }
}

export default ReservationsAgenda;

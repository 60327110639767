// @ts-nocheck
import { Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import MDRadioButtonView from '../Elements/MDRadioButtonsGroup/MDRadioButtonView.js';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxGroupField from '../Fields/MDCheckBox/MDCheckBoxGroupField.js';

@connect((state) => ({}))
export default class AdditionalTimeIntervalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { form, timeIntervalName, is12HoursFormat } = this.props;

    return (
      <div style={{ paddingLeft: 15 }}>
        <Row>
          <input style={{ display: 'none' }} type="username" name="username" />
          <div style={{ paddingLeft: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginLeft: 8, marginRight: 40 }}>
              <Entity
                componentClass={Field}
                title={<Entity entity="resourceIntervalFrom" />}
                name={`${timeIntervalName}.timeIntervalFrom`}
                component={CustomField}
                type="antdTimePicker"
                is12HoursFormat={is12HoursFormat}
                placeholder="Select a time"
              />
            </div>
            <Entity
              componentClass={Field}
              title={<Entity entity="resourceIntervalTo" />}
              name={`${timeIntervalName}.timeIntervalTo`}
              component={CustomField}
              is12HoursFormat={is12HoursFormat}
              type="antdTimePicker"
              placeholder="Select a time"
            />
          </div>
        </Row>
      </div>
    );
  }
}

import _ from 'lodash';
import { Divider, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import GuestGroupIcon from '@material-ui/icons/SupervisorAccount';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { COMPANY_NOTIFICATIONS_ATLAS_STATES, COMPANY_NOTIFICATIONS_BATTERY_LEVELS, COMPANY_NOTIFICATIONS_BATTERY_STATES, COMPANY_NOTIFICATIONS_GATEWAY_STATES, COMPANY_NOTIFICATIONS_REPEAT_STATES, RED } from '../../_config/consts.js';
import { isUserWithoutEmail } from '../../_config/utils.js';
import * as GuestActions from '../../redux/actions/guest.actions';
import * as PlatformUserActions from '../../redux/actions/platformUsers.actions';
import CompanyReportIconCustom from '../CustomIcons/CompanyReportIconCustom.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import MDRadioButtonsGroupField from './Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import CustomField from './Fields/CustomField.jsx';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';


const validate = (values) => {
  const errors = {};
  
  if (!values.gatewayMode) {
    errors.gatewayMode = 'required';
  }
  
  if (!values.batteryMode) {
    errors.batteryMode = 'required';
  }
  
  if (!values.atlasMode) {
    errors.atlasMode = 'required';
  }
  
  if (!values.repeatMode) {
    errors.repeatMode = 'required';
  }
 
  if (values.gatewayMode===COMPANY_NOTIFICATIONS_GATEWAY_STATES.ON && (!values.gatewayOfflineForMinutes || values.gatewayOfflineForMinutes<=0)) {
    errors.gatewayOfflineForMinutes = 'required';
  }
 
  if (values.repeatMode===COMPANY_NOTIFICATIONS_REPEAT_STATES.ON && (!values.sameNotificationAfterHours || values.sameNotificationAfterHours<=0)) {
    errors.sameNotificationAfterHours = 'required';
  }

  return errors;
};

@reduxForm({ form: 'HardwareStatusReportForm', validate })
@connect(state => ({ form: state.form.HardwareStatusReportForm, user: state.user, platformUsers: state.platformUsers, installers: state.installers}))
class HardwareStatusReportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersOptions: [],
      userSelected: null,
      loading: false,
    };
  }

  async componentDidMount() {
    this.onFilterUsers(undefined)
  }
  
  async onFilterUsers(value) {
    const { dispatch, platformUsers: { data: { content: platformUsersData }}, installers: { data: { content: installersData }} } = this.props;
    const usersToFilter = [...platformUsersData, ...installersData ];
    const users = value?
    usersToFilter.filter(e=>(`${e.firstname} ${e.lastname} ${isUserWithoutEmail(e) ? '': ` - ${e.email}`}`).toLowerCase().includes(value.toLowerCase())):
    usersToFilter;
    const userOptions = _.map(users, user => ({ value: user, label: `${user.firstname} ${user.lastname} ${isUserWithoutEmail(user) ? '': ` - ${user.email}`}` }));
    const filteredUsersOptions = _.filter(userOptions, userOption => !userOption.value.companyNotificationsEnabled);
    this.setState({ usersOptions: filteredUsersOptions });
  }
  
  onSelectUser(user) {
    if (user && user.value)
      this.setState({ userSelected: user.value });
  }

  async onAddOrRemoveUser(user, isAdding) {
    const { dispatch } = this.props;
    const userOUT = structuredClone(user)
    userOUT.companyNotificationsEnabled = isAdding;
    this.setState({ loading: true });
    this.setState({ userSelected: null });
    await dispatch(change('HardwareStatusReportForm', 'userSelectedSearch', null));
    await this.onFilterUsers(undefined)
    await dispatch(GuestActions.updateGuest(userOUT, true));
    await dispatch(PlatformUserActions.fetchPlatformUsers(0,100));
    this.setState({ loading: false });
  }

  render() {
    const { form, formStyle, platformUsers: { data: { content: platformUsersData }}, user } = this.props;
    const { userSelected, usersOptions, loading } = this.state

    const gatewayOptions = [
      {
        label: <Entity entity="companyNotificationsGatewayOFF" />,
        value: COMPANY_NOTIFICATIONS_GATEWAY_STATES.OFF,
      },
      {
        label: <Entity entity="companyNotificationsGatewayON" />,
        value: COMPANY_NOTIFICATIONS_GATEWAY_STATES.ON,
      },
    ];
    const batteryOptions = [
      {
        label: <Entity entity="companyNotificationsBatteryOFF" />,
        value: COMPANY_NOTIFICATIONS_BATTERY_STATES.OFF,
      },
      {
        label: <Entity entity="companyNotificationsBatteryON" />,
        value: COMPANY_NOTIFICATIONS_BATTERY_STATES.ON,
      },
    ];
    const atlasOptions = [
      {
        label: <Entity entity="companyNotificationsAtlasOFF" />,
        value: COMPANY_NOTIFICATIONS_ATLAS_STATES.OFF,
      },
      {
        label: <Entity entity="companyNotificationsAtlasON" />,
        value: COMPANY_NOTIFICATIONS_ATLAS_STATES.ON,
      },
    ];
    const repeatOptions = [
      {
        label: <Entity entity="companyNotificationsRepeatOFF" />,
        value: COMPANY_NOTIFICATIONS_REPEAT_STATES.OFF,
      },
      {
        label: <Entity entity="companyNotificationsRepeatON" />,
        value: COMPANY_NOTIFICATIONS_REPEAT_STATES.ON,
      },
    ];
    
    const batteryLevelOptions = [
      {
        label: COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L75,
        value: COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L75,
      },
      {
        label: COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L50,
        value: COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L50,
      },
      {
        label: COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L25,
        value: COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L25,
      },
    ];

    const gatewayNotificationON = form && form.values && form.values.gatewayMode === COMPANY_NOTIFICATIONS_GATEWAY_STATES.ON;
    const batteryNotificationON = form && form.values && form.values.batteryMode === COMPANY_NOTIFICATIONS_BATTERY_STATES.ON;
    const repeatNotificationON = form && form.values && form.values.repeatMode === COMPANY_NOTIFICATIONS_REPEAT_STATES.ON;

    const usersEnabled = platformUsersData.filter(e=>e.companyNotificationsEnabled);

    return (
      <Form style={formStyle} autoComplete="off">
        <FormCardContainer
          title="companyNotifications"
          subtitle="companyNotificationsDescription"
          icon={<CompanyReportIconCustom style={{ color: '#3f3f3f', width: 20 }} />}
        >
          <div style={{ marginTop: 40 }}>
            <h4 style={{ fontSize: 20, marginBottom: 5, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="companyNotificationsGateway" /></h4>
            <h4 style={{ fontSize: 17, marginTop: 0, color: '#3f3f3f', fontWeight: 200, lineHeight: '1.2em' }}><Entity entity="companyNotificationsGatewayDescription" /></h4>
            <Field
              name="gatewayMode"
              title={<Entity entity="deviceUUIDMode" />}
              component={MDRadioButtonsGroupField}
              containerstyle={{ paddingLeft: 15, marginTop: 0 }}
              showErrorLabel
              options={gatewayOptions}
            />
            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft:0, opacity:gatewayNotificationON?1:0, pointerEvents:gatewayNotificationON?'unset':'none' }}>
              <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="companyNotificationsGatewayONdescription"/>}</h4>
              <Entity
                componentClass={Field}
                name="gatewayOfflineForMinutes"
                type="number"
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                componentAttribute="placeholder"
                showErrorLabel
                component={CustomField}
                containerStyle={{ width: 100 }}
                className="form-control-custom"
              />
              <h4 style={{ color: '#3f3f3f', marginLeft: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="minutes"/>}</h4>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <h4 style={{ fontSize: 20, marginBottom: 5, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="companyNotificationsBattery" /></h4>
            <h4 style={{ fontSize: 17, marginTop: 0, color: '#3f3f3f', fontWeight: 200, lineHeight: '1.2em' }}><Entity entity="companyNotificationsBatteryDescription" /></h4>
            <Field
              name="batteryMode"
              title={<Entity entity="deviceUUIDMode" />}
              component={MDRadioButtonsGroupField}
              containerstyle={{ paddingLeft: 15, marginTop: 0 }}
              showErrorLabel
              options={batteryOptions}
            />
            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft:0, opacity:batteryNotificationON?1:0, pointerEvents:batteryNotificationON?'unset':'none' }}>
              <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="companyNotificationsBatteryONdescription"/>}</h4>
              <Field
                id="smartLockBatteryLevelSelected"
                name="smartLockBatteryLevelSelected"
                title=""
                component={CustomField}
                className="form-control-select"
                componentClass="select"
              >
                {_.map(batteryLevelOptions, level =>
                  <option value={level.value} key={level.value}>
                    {level.value}
                  </option>
                )}
              </Field>
            </div>
          </div>
          {user && user.systemHasAtlas ? (
            <div style={{ marginTop: 20 }}>
              <h4 style={{ fontSize: 20, marginBottom: 5, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="companyNotificationsAtlas" /></h4>
              <h4 style={{ fontSize: 17, marginTop: 0, color: '#3f3f3f', fontWeight: 200, lineHeight: '1.2em' }}><Entity entity="companyNotificationsAtlasDescription" /></h4>
              <Field
                name="atlasMode"
                title={<Entity entity="deviceUUIDMode" />}
                component={MDRadioButtonsGroupField}
                containerstyle={{ paddingLeft: 15, marginTop: 0 }}
                showErrorLabel
                options={atlasOptions}
              />
            </div>
          ) : null}
          <Divider style={{marginTop:20}}/>
          <div style={{ marginTop: 40 }}>
            <h4 style={{ fontSize: 20, marginBottom: 5, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="companyNotificationsRepeat" /></h4>
            <h4 style={{ fontSize: 17, marginTop: 0, color: '#3f3f3f', fontWeight: 200, lineHeight: '1.2em' }}><Entity entity="companyNotificationsRepeatDescription" /></h4>
            <Field
              name="repeatMode"
              title={<Entity entity="deviceUUIDMode" />}
              component={MDRadioButtonsGroupField}
              containerstyle={{ paddingLeft: 15, marginTop: 0 }}
              showErrorLabel
              options={repeatOptions}
            />
            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft:0, opacity:repeatNotificationON?1:0, pointerEvents:repeatNotificationON?'unset':'none' }}>
              <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="companyNotificationsRepeatONdescription"/>}</h4>
              <Entity
                componentClass={Field}
                name="sameNotificationAfterHours"
                type="number"
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                componentAttribute="placeholder"
                showErrorLabel
                component={CustomField}
                containerStyle={{ width: 100 }}
                className="form-control-custom"
              />
              <h4 style={{ color: '#3f3f3f', marginLeft: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="hours"/>}</h4>
            </div>
          </div>
        </FormCardContainer>
        <FormCardContainer title="companyNotificationsUsers" subtitle="companyNotificationsUsersSubtitle">
          <div style={{opacity:loading?0.7:1, pointerEvents:loading?'none':'unset'}}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Field
                name="userSelectedSearch"
                component={SelectableField}
                noCache
                options={usersOptions}
                placeholder={<Entity entity="findGuestToAdd" />}
                containerstyle={{ minWidth: 480, marginRight: 10, zIndex:0 }}
                onInputChange={value => this.onFilterUsers(value)}
                onSelect={tagData => this.onSelectUser(tagData)}
              />
              <MDButton
                disabled={!userSelected}
                title={<Entity entity="addGuestToTag" />}
                style={{ borderRadius: 5 }}
                onClick={() => this.onAddOrRemoveUser(userSelected,true)}
              />
            </div>
            {!_.isEmpty(usersEnabled) ? (
              <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
                <div style={{ maxHeight: 400, padding: 5, overflowY: 'scroll' }}>
                  <InfiniteScroll
                    pageStart={0}
                    useWindow={false}
                    initialLoad={false}
                    loadMore={null}
                    hasMore={false}
                    loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
                  >
                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                      {_.map(usersEnabled, (item, index) => (
                        <div key={item.id}>
                          <div
                            style={{ backgroundColor: index % 2 === 0 ? 'white' : 'ghostwhite', borderBottom: '1px solid', borderTop: '1px solid', alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: 5, borderColor: '#f0f0f0' }}
                            key={item.id}
                          >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <h5 style={{ color: '#3f3f3f', marginLeft: 10 }}>{`${item.firstname} ${item.lastname} ${isUserWithoutEmail(item) ? '': ` - ${item.email}`}`}</h5>
                            </div>
                            <IconButton
                              style={{ color: RED }}
                              onClick={() => this.onAddOrRemoveUser(item,false)}
                            >
                              <DeleteIcon style={{ fontSize: 20 }} />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <GuestGroupIcon style={{ color: '#3f3f3f', marginTop: 3, fontSize: 35 }} />
                <h4 style={{ color: '#3f3f3f', textAlign: 'center' }}>
                  <Entity entity={'noGuestsInTag'} />
                </h4>
              </div>
            )}
          </div>
        </FormCardContainer>
      </Form>
    );
  }
} 

export default HardwareStatusReportForm;

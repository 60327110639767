import React from 'react';
import _ from 'lodash';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import MDRadioButtonView from './MDRadioButtonView';
import { Entity } from '@sketchpixy/rubix/lib/L20n';


class MDRadioButtonsGroupField extends React.Component {
  onHandleChange(value, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(value);
    }
    if (onHandleChange) {
      onHandleChange(value);
    }
  }

  render() {
    const {
      groupLabel,
      groupName,
      options,
      horizontal,
      input,
      radioLabelStyle,
      meta,
      showErrorLabel,
      containerStyle,
      disabled
    } = this.props;
    const hasError = meta.touched && meta.error;
    return (
      <FormControl component="fieldset" style={{ display: 'flex', ...containerStyle}}>
        <RadioGroup
          aria-label={groupLabel}
          name={groupName}
          value={input.value}
          style={{ flexDirection: (horizontal ? 'row' : 'column'), ...containerStyle }}
        >
          {_.map(options, (option, index) => (
            <MDRadioButtonView
              key={index}
              checked={input.value === option.value}
              handleChange={e => this.onHandleChange(option.value, input)}
              value={option.value}
              labelStyle={radioLabelStyle}
              label={option.label}
              disabled={disabled}
            />))}
        </RadioGroup>
        {showErrorLabel && hasError && <h6 style={{ color: 'red', marginTop: 4, marginLeft: 10 }}><Entity key={meta.error} entity={meta.error} /></h6>}
      </FormControl>
    );
  }
}

export default MDRadioButtonsGroupField;

import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import CloseIcon from '@material-ui/icons/Cancel';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import { Modal } from '@sketchpixy/rubix';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { LOCKER_RESERVATION_ERRORS, LOCKER_RESERVATION_STATUSES_PARAMETERS, RED, VIEW_MODES } from '../_config/consts';
import DeliveriesOperationalSection from '../components/ParcelManagement/DeliveriesOperationalSection.jsx';
import DeliveriesTableView from '../components/ParcelManagement/DeliveriesTableView.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import TranslatableOption from '../components/forms/Fields/TranslatableOption.jsx';
import * as LockerActions from '../redux/actions/locker.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { IconButton } from '@material-ui/core';
import DeliveryPinShareView from '../components/ParcelManagement/DeliveryPinShareView.jsx';

let filterTimeout;
@connect((state) => ({ lockers: state.lockers, lockerReservations: state.lockers.reservations, routing: state.router, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName, }))
class DeliveriesManagementView extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('lockersReservationViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
      showSharingSection: false,
      selectedPinToShare: undefined,
    };
  }
  async componentWillMount() {
    const { dispatch, routing, lockerReservations } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(LockerActions.selectLockerReservation({}));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(LockerActions.resetLockersFilters());
      await dispatch(LockerActions.resetLockerReservationsFilters());
      await dispatch(LockerActions.fetchAllLocks());
      await dispatch(LockerActions.fetchLockers(0,false,1000));
      await dispatch(LockerActions.fetchLockerReservations());
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    const lockerReservationId = parsed.lockerId;
    const newEntity = parsed.newEntity;
    if (lockerReservationId) {
      const newLockerReservation = ((lockerReservations && lockerReservations.data) || []).filter(e=>e.id===lockerReservationId).length>0?
        ((lockerReservations && lockerReservations.data) || []).filter(e=>e.id===lockerReservationId)[0]:undefined;
      if (newLockerReservation) {
        this.onSelectDelivery(newLockerReservation);
      }
    }
    if (newEntity) {
      this.onCreateNewDelivery();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(LockerActions.resetLockerReservationsFilters());
    dispatch(LockerActions.selectLockerReservation({}));
  }

  async fetchDeliveries(page = 0, append = false) {
    const { dispatch } = this.props;
    try {
      this.setState({ viewLoading: true });
      await dispatch(LockerActions.fetchLockerReservations(page, append));
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }

  async closeOperationalSection() {
    const { dispatch } = this.props;
    await dispatch(LockerActions.setOperationalModeReservations(false));
    await dispatch(LockerActions.selectLockerReservation({}));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(LockerActions.setOperationalModeReservations(true));
  }

  async onSetFilter(field, value, useTimeout) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2) || (_.isNumber(value) && value>0))) {
      await dispatch(LockerActions.setLockerReservationsFilter(field, value));
      if (useTimeout) {
        if (filterTimeout) clearTimeout(filterTimeout);
        filterTimeout = setTimeout(async () => {
          await this.fetchDeliveries()
        }, 500);
      }
      else {
        dispatch(UtilsActions.setSpinnerVisibile(true));
        await this.fetchDeliveries()
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    } else {
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockerActions.setLockerReservationsFilter(field, undefined));
      await this.fetchDeliveries()
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onResetFilters() {
    const { dispatch } = this.props;
    await dispatch(LockerActions.resetLockerReservationsFilters());
    dispatch(initialize('LockersReservationFilters', {}));
    await this.fetchDeliveries();
  }

  async onSelectDelivery(lockerReservation) {
    const { dispatch, lockerReservations } = this.props;
    const selectedLockerReservation = lockerReservations.selectedLockerReservation
    if (selectedLockerReservation&&selectedLockerReservation.id===lockerReservation.id)
      return;
    try {
      dispatch(LockerActions.selectLockerReservation(lockerReservation));
      dispatch(initialize('DeliveryForm', lockerReservation));
      this.openOperationalSection()
    } catch (error) {
      dispatch(LockerActions.selectLockerReservation({}));
    } finally {
      
    }
  }

  async onAppendDeliveries(page) {
    const { dispatch } = this.props;
    const append = true;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await this.fetchDeliveries(page, append);
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onFetchDeliveriesReservationsOnPage(page) {
    const { dispatch } = this.props;
    this.fetchDeliveries(page);
  }

  async onCreateNewDeliveryConfirmed(lockerId,lockerReservationId,lockerReservationData) {
    const { dispatch, lockers } = this.props;
    const data = lockerReservationData;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const lockerReservationResponse = await dispatch(LockerActions.editLockerReservation(lockerId,lockerReservationId,data));
      if (lockerReservationResponse.ERROR) {
        let errorMessage = `lockerReservationError${LOCKER_RESERVATION_ERRORS[lockerReservationResponse.ERROR.toString()] || ""}`;
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
          },
        }));
      }
      else {
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerReservationPropertiesAssigned' }} /></h6>),
          },
        }));
        this.closeOperationalSection()
        this.onRefreshDeliveries()
      }
    } catch (error) {
      let errorMessage = 'lockerReservationError';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onDeliveryCreateConfirmed(lockerId,lockerReservationData) {
    const { dispatch, lockers } = this.props;
    const data = lockerReservationData;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const lockerReservationResponse = await dispatch(LockerActions.createLockerReservation(lockerId,data));
      if (lockerReservationResponse.ERROR) {
        let errorMessage = `lockerReservationError${LOCKER_RESERVATION_ERRORS[lockerReservationResponse.ERROR.toString()] || ""}`;
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
          },
        }));
      }
      else {
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerReservationCreated' }} /></h6>),
          },
        }));
        this.closeOperationalSection()
      }
    } catch (error) {
      let errorMessage = 'lockerReservationError';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(LockerActions.resetLockerReservationsFilters());
      await this.fetchDeliveries();
    }
  }

  async onDeleteDeliveryRequest(lockerId, lockerReservationId, notes) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deleteLockerReservationDataMessage" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onDeleteDeliveryConfirm(lockerId, lockerReservationId, notes),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteDeliveryConfirm(lockerId, lockerReservationId, notes) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(LockerActions.deleteLockerReservationByManager(lockerId, lockerReservationId, notes));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(LockerActions.setOperationalModeReservations(false));
      dispatch(LockerActions.selectLockerReservation({}));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerReservationDeleted' }} /></h6>),
        },
      }));
      this.onRefreshDeliveries()
    } catch (error) {
      let errorMessage = 'lockerReservationDeleteError';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }
  
  async onRefreshDeliveries() {
    const { lockerReservations } = this.props;
    const page = lockerReservations && lockerReservations.data && lockerReservations.data.pagination && lockerReservations.data.pagination.number;
    this.onFetchDeliveriesReservationsOnPage(page);
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    this.fetchDeliveries()
  }

  async onCreateNewDelivery() {
    const { dispatch } = this.props;
    dispatch(LockerActions.selectLockerReservation({}));
    dispatch(initialize('DeliveryForm', {}));
    this.openOperationalSection()
  }

  onShowInfo() {
    /* TODO */
    /*
    const helpURL = localizeHelpCenterLink('luckey-floor-plans');
    window.open(helpURL);
    */
  }

  onSharePin(pinData) {
    this.setState({ showSharingSection: true , selectedPinToShare: pinData });
  }

  render() {
    const { lockers, lockerReservations, themeName } = this.props;
    const { showSharingSection, selectedPinToShare } = this.state;
    const lockersData = (lockers && lockers.data && lockers.data.content) || []
    const selectedLockerReservationId = lockers?.selectedLockerReservation?.id
    const selectedLockerReservationName = lockers?.selectedLockerReservation?.name;
    const canEditCreateLockerReservaitons = true; // TODO AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.LOCKER);
    return (
      <div style={{ backgroundColor: 'white'}}>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canEditCreateLockerReservaitons ? () => this.onCreateNewDelivery() : null}
          newEntityTitle="bookADelivery"
          newEntityIconName="icon-simple-line-icons-user-following"
          selectableFieldName={'lockerId'}
          selectableFieldTitle={undefined}
          onSelectOption={(value) => this.onSetFilter('lockerId', value==="__ANY__"||isNaN(parseInt(value))?undefined:parseInt(value), true)}
          selectableOptions={[
            (<Entity
              key={"lockerReservationAnyLocker"}
              componentClass={TranslatableOption}
              value={"__ANY__"}
              componentAttribute="text"
              entity={"lockerReservationAnyLocker"}
            />),
            ..._.map(_.sortBy(lockersData, (locker => locker.name.toLowerCase())), locker =>
              <option value={locker.id} key={locker.id}>
                {locker.name}
              </option>
            )
          ]}
          selectableFieldName2={'status'}
          selectableFieldTitle2={undefined}
          onSelectOption2={(value) => this.onSetFilter('status', value==="__ANY__"?undefined:value, true)}
          selectableOptions2={[
            (<Entity
              key={"lockerReservationAnyStatus"}
              componentClass={TranslatableOption}
              value={"__ANY__"}
              componentAttribute="text"
              entity={"lockerReservationAnyStatus"}
            />),
            ..._.map(LOCKER_RESERVATION_STATUSES_PARAMETERS, status =>
              <option value={status.value} key={status.value}>
                {L20NContext.getSync(status.string)}
              </option>
            )
          ]}
          onSearchReset={() => this.onResetFilters()}
          onFilterChange={value => this.onSetFilter('search', _.trim(value), true)}
          onSearchSubmit={() => this.fetchDeliveries()}
          //onInfo={() => this.onShowInfo()}
        />
        <DeliveriesTableView
          onSelectDelivery={locker => this.onSelectDelivery(locker)}
          onFetchDeliveriesReservationsOnPage={page => this.onFetchDeliveriesReservationsOnPage(page)}
          onRefreshDeliveries={() => this.onRefreshDeliveries()}
          onNewDelivery={canEditCreateLockerReservaitons ? () => this.onCreateNewDelivery() : null}
          onOrderChanged={() => this.onOrderChanged()}
          onSharePin={pinData => this.onSharePin(pinData)}
        />
        <OperationalView
          themeName={themeName}
          isVisible={lockers.isOperationalModeReservations}
          onClose={() => this.closeOperationalSection()}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity={selectedLockerReservationName?selectedLockerReservationName:"createLockerReservation"} data={{ name: 'lockersReservationOperational' }} key={selectedLockerReservationId} />}
        >
          <DeliveriesOperationalSection
            themeName={themeName}
            onCreateNewDeliveryConfirmed={(lockerId,lockerReservationId,lockerReservationData)=>this.onCreateNewDeliveryConfirmed(lockerId,lockerReservationId,lockerReservationData)}
            onDeliveryCreateConfirmed={(lockerId,lockerData)=>this.onDeliveryCreateConfirmed(lockerId,lockerData)}
            onRefreshDeliveries={()=>this.onRefreshDeliveries()}
            onDeleteDeliveryRequest={selectedLockerReservationId && canEditCreateLockerReservaitons ? 
              (lockerId,lockerReservationId,notes) => this.onDeleteDeliveryRequest(lockerId,lockerReservationId,notes) : null
            }
            onSharePin={pinData => this.onSharePin(pinData)}
          />
        </OperationalView>
        <Modal show={showSharingSection} onHide={() => this.setState({ showSharingSection: false })}>
          <div style={{ padding: 20, paddingTop: 10 }}>
            <div style={{ display: 'flex', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="shareDeliveryPinCode" /></h2>
              <IconButton onClick={() => this.setState({ showSharingSection: false })}>
                <CloseIcon style={{ color: RED, fontSize: 40 }} />
              </IconButton>
            </div>
            <DeliveryPinShareView pinCode={selectedPinToShare} />
          </div>
        </Modal>
      </div>
    );
  }
} 

export default DeliveriesManagementView;

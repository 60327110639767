import EventIcon from '@material-ui/icons/Event';
import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import MDRadioButtonView from '../../../Elements/MDRadioButtonsGroup/MDRadioButtonView';
import DateRangeView from './DateRangeView.jsx';

export default class DateRangeField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValue: props.checkedInterval,
      disabled: true,
    };
  }

  onDateCheckboxChange(value) {
    const { input } = this.props;
    let startDate = input && input.value && input.value.startDate ? moment(input.value.startDate) : null;
    let endDate = input && input.value && input.value.endDate ? moment(input.value.endDate) : null;
    if (value) {
      this.setState({ disabled: true });
      endDate = moment(startDate).add(value, 'years');
    } else { // Custom selection
      startDate = moment();
      endDate = moment().add(1, 'days');
      this.setState({ disabled: false });
    }
    input.onChange({ startDate, endDate });
    this.setState({ checkedValue: value });
  }

  componentWillReceiveProps(nextProps) {
    const { checkedInterval: nextInterval } = nextProps;
    const { checkedInterval: oldInterval } = this.props;
    if (oldInterval !== nextInterval) this.setState({ checkedValue: nextInterval });
  }

  render() {
    const { input, meta, title, options, optionContainerStyle } = this.props;
    const { checkedValue, disabled } = this.state;
    const startDate = input && input.value && input.value.startDate ? moment(input.value.startDate) : null;
    const endDate = input && input.value && input.value.endDate ? moment(input.value.endDate) : null;
    // Options
    const showOptions = options || !_.isEmpty(options);
    const customOptionValue = { value: 0, label: L20NContext.getSync('pesonalizedTimeRange') };
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
          <h4 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f' }}>{title}</h4>
        </div>
        <DateRangeView
          startDate={startDate}
          endDate={endDate}
          disabled={showOptions && disabled}
          {...input}
        />
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 30, ...optionContainerStyle }}>
          {showOptions &&
            <div style={{ marginBottom: 10 }}>
              {_.map([...options, customOptionValue], option => (
                <MDRadioButtonView
                  checked={checkedValue === option.value}
                  value={option.value}
                  label={option.label}
                  handleChange={value => this.onDateCheckboxChange(option.value)}
                />
              ),
              )}
            </div>
          }
        </div>
      </div>
    );
  }
}


import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { GREEN, RED, SUBSCRIPTION_TYPES } from '../../../_config/consts';
import { localizeHelpCenterLink } from '../../../_config/utils.js';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as UserActions from '../../../redux/actions/user.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import CheckOutlineIconCustom from '../../CustomIcons/CheckOutlineIconCustom.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import { IconButton } from '@material-ui/core';
import CopyIconCustom from '../../CustomIcons/CopyIconCustom.jsx';
import ResHarmonicsActivationForm from '../../forms/Integrations/ResHarmonicsActivationForm.jsx';
import { submit } from 'redux-form';
import { InfoOutlined } from '@material-ui/icons';
import FormFlatContainer from '../../forms/Elements/FormFlatContainer.jsx';


@connect(state => ({ language: state.settings.language, integrations: state.user.activeIntegration }))
class ResHarmonicsSettingsOperationalSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isActive: null,
      additionalInfo: null
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const isActive = dispatch(UserActions.userHasIntegrationActive(SUBSCRIPTION_TYPES.RES_HARMONICS_INTEGRATION));
    const additionalInfo = dispatch(UserActions.getIntegrationAdditionalInfo(SUBSCRIPTION_TYPES.RES_HARMONICS_INTEGRATION));
    this.setState({ isActive, additionalInfo: { unitsWebhookUrl: 'mimmoURL', roomStaysWebhookUrl: 'francoURL'} });
  }


  onDeactivateIntegrationRequest() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="disableIntegrationConfirm" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeactivateIntegration(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeactivateIntegration() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const deactivationDTO = dispatch(UserActions.getActiveIntegration(SUBSCRIPTION_TYPES.RES_HARMONICS_INTEGRATION));
      if (deactivationDTO && deactivationDTO.id) {
        await dispatch(UserActions.disableIntegration(deactivationDTO.id));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(UserActions.fetchCurrentActiveIntegrations());
        this.setState({ isActive: false });
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedSuccess" /></h6>),
          },
        }));
      } else {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
          },
        }));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
        },
      }));
    }
  }

  onOpenActivationInfo() {
    const helpURL = localizeHelpCenterLink('activate-resharmonics-integration');
    window.open(helpURL);
  }

  onGoBackToLogin() {
    this.setState({ resourceTypes: null, optixToken: null })
  }

  async onActivateIntegration(integrationData) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const reservationsSyncMode = []
      if (integrationData.pin) {
        reservationsSyncMode.push("PIN");
      }
      if (integrationData.credentialRule) {
        reservationsSyncMode.push("CREDENTIAL_RULE")
      }
      const activationDataParsed = {
        ...integrationData,
        reservationsSyncMode,
      }
      await dispatch(UserActions.activateResHarmonicsIntegration(activationDataParsed));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(UserActions.fetchCurrentActiveIntegrations());
      this.setState({ isActive: true });
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="integrationActivateSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
        },
      }));
    }
  }

  render() {
    const { isActive, additionalInfo } = this.state;
    const { dispatch } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <img alt="nexudus" src="/imgs/common/integrationLogos/resharmonics.png" style={{ height: 90, alignSelf: 'center' }} />
        {isActive ? (
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', paddingLeft: 25, paddingRight: 25, flexDirection: 'column'}}>
            <div style={{ width: '70%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckOutlineIconCustom style={{ color: GREEN, width: 30, marginRight: 10 }} />
              <h4 style={{ color: GREEN, fontWeight: 'bold', fontSize: 20 }}><Entity entity="integrationCorrectlyConfigured" /></h4>
            </div>
            {additionalInfo && (additionalInfo.unitsWebhookUrl || additionalInfo.roomStaysWebhookUrl) ?  (
              <FormFlatContainer
                title="integrationAdditionalInfo"
                subtitle="pastValueToResHarmonices"
                subtitleStyle={{ fontSize: 18, marginLeft: 0 }}
                titleStyle={{ marginLeft: 0 }}
                headerStyle={{ marginBottom: 20 }}
              >
                {additionalInfo.unitsWebhookUrl ? (
                  <div>
                    <h4 style={{ marginBottom: 0, fontWeight: 'bold' }}><Entity entity="unitsWebhookUrl" /></h4>
                    <h4 style={{ marginTop: 0 }}>{additionalInfo.unitsWebhookUrl} <CopyToClipboard text={additionalInfo.unitsWebhookUrl}>
                    <IconButton>
                      <CopyIconCustom style={{ width: 20, color: '#3f3f3f' }} />
                    </IconButton>
                    </CopyToClipboard></h4>
                  </div>
                ) : null}
                {additionalInfo.roomStaysWebhookUrl ? (
                  <div>
                    <h4 style={{ marginBottom: 0, fontWeight: 'bold' }}><Entity entity="roomStaysWebhookUrl" /></h4>
                    <h4 style={{ marginTop: 0, marginBottom: 0 }}>{additionalInfo.roomStaysWebhookUrl} <CopyToClipboard text={additionalInfo.roomStaysWebhookUrl}>
                    <IconButton>
                      <CopyIconCustom style={{ width: 20, color: '#3f3f3f' }} />
                    </IconButton>
                    </CopyToClipboard></h4>
                  </div>
                ): null}
              </FormFlatContainer>
            ): null}
            <MDButton
              title={<Entity key={isActive} entity="disableIntegrationTitle" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={RED}
              containerStyle={{ margin: 'auto', marginTop: 20, width: '80%' }}
              onClick={() => this.onDeactivateIntegrationRequest()}
            />
          </div>
        ) : (
            <div style={{ width: '70%', margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h4 style={{ margin: 0, marginTop: 20, marginBottom: 10 }}><Entity style={{ fontWeight: 'bold' }} entity="activateIntegrationTitle" data={{ integrationName: 'res:harmonics'}} /></h4>
            <h4 style={{ margin: 0, textAlign: 'center', marginBottom: 20 }}><Entity entity="activateTenupIntegrationDescription" data={{ integrationName: 'res:harmonics'}} /></h4>
            <ResHarmonicsActivationForm
              onSubmit={(integrationData) => this.onActivateIntegration(integrationData)}
            />
            <MDButton
              title={<Entity key={isActive} entity="activate" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={GREEN}
              containerStyle={{ margin: 0, marginTop: 20, width: '80%' }}
              onClick={() => dispatch(submit('ResHarmonicsActivationForm'))}
            />
            </div>
          )}
          <h5
            className="link-label"
            style={{ margin: 'auto', width: 'fit-content', marginTop: 20, fontSize: 16, textDecoration: 'underline' }}
            onClick={() => this.onOpenActivationInfo()}
          >
            <Entity entity="findOutMore" />
          </h5>
      </div>
    );
  }
}

export default ResHarmonicsSettingsOperationalSection;

import IconButton from '@material-ui/core/IconButton';
import { Warning } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import StarIcon from '@material-ui/icons/StarBorder';
import RoleIcon from '@material-ui/icons/VerifiedUser';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { CUSTOM_FIELDS_FORM_TYPE, LICENSE_TYPES, MOBILE_UDID_STATES, ORANGE, PERMISSION_ENTITIES, PERMISSIONS, RED, SUBSCRIPTION_TYPES } from '../../_config/consts';
import { hasFormWritePermission } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as UtilsActions from '../../redux/actions/utils.actions.js';
import * as UserActions from '../../redux/actions/user.actions.js';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import CustomField from './Fields/CustomField.jsx';
import MDSwitchField from './Fields/MDSwitch/MDSwitchField.jsx';
import TagsSelectorField from './Fields/TagsSelectorField.jsx';
import TranslatableOption from './Fields/TranslatableOption.jsx';
import TagIconCustom from '../CustomIcons/TagIconCustom.jsx';
import FormFlatContainer from './Elements/FormFlatContainer.jsx';
import OfficeModeIconCustom from '../CustomIcons/OfficeModeIconCustom.jsx';

const validate = values => {
  const errors = {};
  if (!values.firstname) {
    errors.firstname = 'required';
  }

  if (!values.lastname) {
    errors.lastname = 'required';
  }

  if (values.withoutEmail === false || !values.withoutEmail) {
    if (!values.email) {
      errors.email = 'required';
    } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
      errors.email = 'invalidEmailAddress';
    }
  }
  if (values.withoutEmail === true || values.withoutEmail) {
    if (!values.username) {
      errors.username = 'required';
    }
   }
  return errors;
};

const mobileUuidStateOptions = [
  {
    value: MOBILE_UDID_STATES.NEVER_SET,
    label: 'limitDeviceUUIDToOneForUser',
  },
  {
    value: MOBILE_UDID_STATES.ALWAYS_VALID,
    label: 'unlimitedDeviceUUIDForUser',
  }
];


@reduxForm({ form: 'GuestForm', validate })
@connect(state => ({ form: state.form.GuestForm, companyConfigurations: state.user.companyConfigurations, customFields: state.customFields.users }))
class GuestForm extends React.Component {
  

  onToggleAnonymousUserValue(value) {
    if (value === true) {
      const { dispatch } = this.props;
      dispatch(change('GuestForm', 'email', null));
    }
  }

  render() {
    const {
      onNewTag,
      onEditTag,
      onEditTagDates,
      onGuestInputChange,
      availableTags,
      form,
      guest,
      onShowUserTagsInfo,
      onDeleteGuest,
      formStyle,
      onResetPassword,
      companyConfigurations,
      customFields,
      onShowMobileUUIDInformation,
      dispatch
    } = this.props;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const isEditing = form && form.values && form.values.id;
    const isAnonymousUserSelected = form && form.values && form.values.withoutEmail;
    const isEditingStandardEmailUser = form && form.initial && form.initial.email && isEditing;
    const isCovivioIntegrationEnabled = companyConfigurations && companyConfigurations.covivioConnectorEnabled;
    const cannotEditExternalUser = guest.extId && isCovivioIntegrationEnabled;
    const canEditGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.USER) && !cannotEditExternalUser;
    const canDeleteGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.USER) && !cannotEditExternalUser;
    const canCreateUserTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER_TAG) && !cannotEditExternalUser;
    const canReadUserTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER_TAG);
    const customFieldsData = customFields.content;
    const isDeviceManagementActive = dispatch(UtilsActions.isDeviceManagementActive());
    const isArgoOnlySystem = dispatch(UtilsActions.isArgoOnlySystem());
    return (
      <Form style={formStyle} autoComplete="off">
        <input style={{ display: 'none' }} type="username" name="username" />
        {guest.isGuestManager ? (
        <FormCardContainer headerStyle={{ margin: 0 }} containerStyle={{ backgroundColor: RED, padding: 0, marginTop: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <RoleIcon style={{ color: 'orange', fontSize: 25, marginRight: 10 }} />
            <h5 style={{ color: 'white', fontWeight: 'bold' }}><Entity entity="guestIsAlsoManager" /></h5>
          </div>
        </FormCardContainer>
        ) : null}
        <FormCardContainer title="guestData">
          <Entity
            componentClass={Field}
            name="firstname"
            componentAttribute="placeholder"
            entity="insertFirstName"
            title={<Entity entity="firstName" />}
            component={CustomField}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing)|| cannotEditExternalUser}
            mandatory
          />
          <Entity
            componentClass={Field}
            name="lastname"
            componentAttribute="placeholder"
            entity="insertSurname"
            title={<Entity entity="surname" />}
            component={CustomField}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) || cannotEditExternalUser}
            mandatory
          />
          <Entity
            name="companyName"
            componentClass={Field}
            componentAttribute="placeholder"
            entity="insertCompanyName"
            title={<Entity entity="companyName" />}
            component={CustomField}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) || cannotEditExternalUser}
          />
          <Entity
            componentClass={Field}
            name="email"
            componentAttribute="placeholder"
            entity="insertEmail"
            title="E-mail"
            disabled={isEditingStandardEmailUser || (isEditing && isAnonymousUserSelected) || (!isEditing && isAnonymousUserSelected) || !hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) || cannotEditExternalUser}
            component={CustomField}
            mandatory={!isAnonymousUserSelected}
          />
          {!isEditingStandardEmailUser ? (
            <Field
              name="withoutEmail"
              label={<Entity entity="createUserNoEmail" />}
              titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
              containerstyle={{ marginTop: -10 }}
              component={MDSwitchField}
              activeText="createUserNoEmailWarning"
              activeTextIcon={<Warning style={{ color: ORANGE, marginRight: 10, marginTop: 0, fontSize: 20 }} />}
              onHandleChange={value => this.onToggleAnonymousUserValue(value)}
            />
          ) : null}
          {isEditing && canEditGuest && !isAnonymousUserSelected ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <h5
                onClick={() => onResetPassword()}
                className="link-label"
                style={{ margin: 0 }}
              >
                <Entity entity="resetPassword" />
              </h5>
            </div>
          ) : null}
          {isAnonymousUserSelected ? (
            <Entity
              componentClass={Field}
              name="username"
              componentAttribute="placeholder"
              entity="insertBaseGuestTag"
              title={<Entity entity="guestTag" />}
              component={CustomField}
              textFormattingFunction={text => text.toLowerCase()}
              disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) || cannotEditExternalUser || isEditing}
              mandatory
            />
          ) : null}
          {!isEditing && !isAnonymousUserSelected && isDeviceManagementActive ? (
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, fontSize: 16 }}>{<Entity entity="mobileUUIDModeTitle" />}</h4>
                <IconButton onClick={() => onShowMobileUUIDInformation()}>
                  <InfoIcon style={{ fontSize: 20, color: '#3f3f3f' }} />
                </IconButton>
              </div>
                <Field
                  id="mobileUuidState"
                  name="mobileUuidState"
                  component={CustomField}
                  disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing)}
                  className="form-control-select"
                  componentClass="select"
                >
                  {_.map(mobileUuidStateOptions, type =>
                    <Entity
                      key={type.value}
                      componentClass={TranslatableOption}
                      value={type.value}
                      componentAttribute="text"
                      entity={type.label}
                    />)
                  }
                </Field>
            </div>
          ) : null}
          {/* {!isAnonymousUserSelected && isInvitationAddonActive ? (
            <div style={{ marginTop: 25 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, fontSize: 16 }}>{<Entity entity="maxNumberInvitationPerSingleUser" />}</h4>
              </div>
                <h5 style={{ color: '#3f3f3f', margin: 0, fontSize: 14, marginBottom: 10, marginTop: 10 }}>{<Entity entity="maxNumberInvitationPerSingleUserDescription" />}</h5>
                <Entity
                  componentClass={Field}
                  name="maxNumberInvitationPerUser"
                  type="number"
                  componentAttribute="placeholder"
                  component={CustomField}
                  hideErrorLabel
                  containerStyle={{ width: 100 }}
                  className="form-control-custom"
                />
            </div>
          ) : null} */}
        </FormCardContainer>
        {customFieldsData && !_.isEmpty(customFieldsData) && !isLuckeyLite ? (
          <FormCardContainer
            title="customFields"
          >
            {_.map(customFieldsData, customField => (
              <Entity
                name={customField.keyValue}
                componentClass={Field}
                componentAttribute="placeholder"
                entity="insertDescription"
                type={customField.type && CUSTOM_FIELDS_FORM_TYPE[customField.type] ? CUSTOM_FIELDS_FORM_TYPE[customField.type] : 'text'}
                title={customField.name}
                component={CustomField}
                disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) || cannotEditExternalUser}
              />
            ))}
          </FormCardContainer>
          ) : null}
        <FormCardContainer
          title="additionalProperties"
          subtitle="additionalPropertiesDescription"
        >
          {!isEditing && canReadUserTags ? (
            <FormFlatContainer
              title="guestTags"
              subtitle="guestPersonalTagsDescription"
              iconButtonAction={() => onShowUserTagsInfo()}
              icon={<TagIconCustom style={{ width: 22, color: '#3f3f3f', marginTop: 2 }} />}
              iconButtonIcon={<InfoIcon style={{ fontSize: 20, color: '#3f3f3f' }} />}
            >
              <div>
                <Entity
                  componentClass={Field}
                  name="tags"
                  multi
                  componentAttribute="placeholder"
                  entity="insertGuestTags"
                  component={TagsSelectorField}
                  tagType="GUEST"
                  onNewTag={canCreateUserTags ? onNewTag : null}
                  onEditTag={onEditTag}
                  isDisabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) || cannotEditExternalUser}
                  onEditTagDates={onEditTagDates}
                  tags={availableTags}
                  onInputChange={onGuestInputChange}
                />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <StarIcon style={{ fontSize: 17, color: '#3f3f3f', marginRight: 5 }} />
                  <h5 style={{ color: '#3f3f3f', fontSize: 15, fontWeight: '200', margin: 0 }}><Entity entity="timedTagSuggestion" /></h5>
                </div>
              </div>
            </FormFlatContainer>
          ) : null}
          {isArgoOnlySystem ? (
            <FormFlatContainer
              title="toggleOfficeModeTitle"
              subtitle="toggleOfficeModeTitleDescription"
              icon={<OfficeModeIconCustom style={{ width: 22, color: '#3f3f3f', marginTop: 0 }} />}
            >
              <Field
                name="canTogglePassageMode"
                label={<Entity entity="enableToggleOfficeMode" />}
                titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
                component={MDSwitchField}
              />
            </FormFlatContainer>
          ) : null}
        </FormCardContainer>
        {onDeleteGuest && canDeleteGuest &&
          <MDButton
            title={<Entity entity="deleteUser" />}
            backgroundColor={RED}
            onClick={() => onDeleteGuest(guest)}
          />
        }
      </Form>
    );
  }
} 

export default GuestForm;

/* eslint-disable max-len */
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { change } from 'redux-form';
import * as XLSX from 'xlsx';
import { ACCESS_METHODS, BACKGROUND_TASK_TYPES, CARD_TYPES, CREDENTIALS_FILTERS_MAP, CREDENTIAL_CATEGORIES, CREDENTIAL_TYPES, MATCH_TAG_MODE, MOBILE_UDID_STATES, ROLES, SUBSCRIPTION_TYPES } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as ItemsAPI from '../../_config/itemsRegistryAPI';
import * as RestService from '../../_config/rest';
import * as GuestsAPI from '../../_config/usersAPI';
import { elaborateCustomFieldsEntityAttributes, saveDataToLocalStorage } from '../../_config/utils';
import {
  APPEND_GUESTS, APPEND_GUEST_CREDENTIALS,
  CACHE_GUEST_DETAILS, CACHE_REMOVE_GUEST_DETAILS, REMOVE_GUEST, RESET_GUESTS_DATA, RESET_GUESTS_FILTERS, RESET_GUESTS_PAGINATION_DATA,
  RESET_GUEST_CREDENTIALS_DATA,
  SAVE_GUEST, SAVE_GUESTS, SAVE_GUEST_ACCESS_METHODS, SAVE_GUEST_CREDENTIALS, SELECT_GUEST, SET_GUESTS_PAGINATION_DATA, SET_GUEST_CREDENTIALS_PAGINATION_DATA,
  SET_GUEST_FILTER, SET_GUEST_OPERATIONAL_MODE, SET_IS_LOADING_CREDENTIALS, UPDATE_GUEST, UPDATE_SELECTED_GUEST_TAG, SET_GUEST_ORDER
} from './actionTypes/guest';
import * as ModalActions from './modal.actions';
import * as UserAction from './user.actions';
import * as UtilsActions from './utils.actions';

export function saveGuests(guests) {
  return {
    type: SAVE_GUESTS,
    guests,
  };
}

export function resetGuestsData() {
  return { type: RESET_GUESTS_DATA };
}

export function appendGuests(guests) {
  return {
    type: APPEND_GUESTS,
    guests,
  };
}

export function saveGuest(guest) {
  return {
    type: SAVE_GUEST,
    guest,
  };
}

export function removeGuest(guestId) {
  return {
    type: REMOVE_GUEST,
    guestId,
  };
}

export function selectGuest(guest) {
  return {
    type: SELECT_GUEST,
    guest,
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_GUEST_OPERATIONAL_MODE,
    value,
  };
}
export function updateGuestOnState(guest) {
  return {
    type: UPDATE_GUEST,
    guest,
  };
}
export function setGuestsPaginationData(pagination) {
  return {
    type: SET_GUESTS_PAGINATION_DATA,
    pagination,
  };
}

export function resetGuestsPaginationData() {
  return {
    type: RESET_GUESTS_PAGINATION_DATA,
  };
}

export function appendGuestCredentials(credentialCategory, credentials) {
  return {
    type: APPEND_GUEST_CREDENTIALS,
    credentials,
    credentialCategory,
  };
}

export function saveGuestCredentials(credentialCategory, credentials) {
  return {
    type: SAVE_GUEST_CREDENTIALS,
    credentialCategory,
    credentials,
  };
}

export function resetGuestCredentialsData() {
  return { type: RESET_GUEST_CREDENTIALS_DATA };
}

export function saveGuestAccessMethods(accessMethods) {
  return {
    type: SAVE_GUEST_ACCESS_METHODS,
    accessMethods,
  };
}

export function setGuestCredentialsPaginationData(credentialCategory, currentPage, totalPages) {
  return {
    type: SET_GUEST_CREDENTIALS_PAGINATION_DATA,
    credentialCategory,
    currentPage,
    totalPages,
  };
}

export function setFilter(name, value) {
  return {
    type: SET_GUEST_FILTER,
    name,
    value,
  };
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_GUEST_ORDER,
    orderBy,
    orderDir,
  };
}

export function resetGuestsFilters() {
  return { type: RESET_GUESTS_FILTERS };
}

export function cacheGuest(guestId, guestDetails) {
  return {
    type: CACHE_GUEST_DETAILS,
    guestId,
    guestDetails,
  };
}

export function setIsLoadingCredentials(isLoading) {
  return {
    type: SET_IS_LOADING_CREDENTIALS,
    isLoading,
  };
}

export function updateSelectedGuestTag(tag, updateTagInForm = false) {
  return (dispatch, getState) => {
    if (updateTagInForm) {
      const guestForm = getState().form.GuestForm.values;
      const index = _.findIndex(guestForm.tags, { id: tag.id });
      const updatedTags = [...guestForm.tags];
      updatedTags[index] = tag;
      dispatch(change('GuestForm', 'tags', updatedTags));
      dispatch(change('GuestProfileViewForm', 'tags', updatedTags));
    }
    dispatch({
      type: UPDATE_SELECTED_GUEST_TAG,
      tag,
    });
  };
}

export function fetchGuests(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().guests.data.filters;
      const orderBy = getState().guests.data.sorting.orderBy;
      const orderDir = orderBy?getState().guests.data.sorting.orderDir:undefined;
      const params = {
        page,
        pageSize,
        roleIds: [5],
        ...filters,
        orderBy,
        orderDir,
      };
      const response = await RestService.fetchGuests(params);
      if (response.data && response.data.content) {
        const customFields = getState().customFields.users.content;
        const formattedGuests = _.map(response.data.content, guest => formatter.formatInputData(formatter.GUEST, { ...guest, customFields }));
        if (append) {
          dispatch(appendGuests(formattedGuests));
        } else {
          dispatch(saveGuests(formattedGuests));
        }
        dispatch(setGuestsPaginationData(_.omit(response.data, 'content')));
        return formattedGuests;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchGuestsByTags(tags, page = 0, pageSize = 20, append) {
  
  return async (dispatch, getState) => {
    try {
      const filters = getState().guests.data.filters;
      const params = {
        tagIds: _.map(tags, tagValue => tagValue.id || tagValue),
        append,
        ...filters,
        page,
        pageSize,
        userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      const response = await RestService.fetchUsersByTags(params);
      if (response.data && response.data.content) {
        const customFields = getState().customFields.users.content;
        const formattedGuests = _.map(response.data.content, guest => formatter.formatInputData(formatter.GUEST, { ...guest, customFields }));
        if (append) {
          dispatch(appendGuests(formattedGuests));
        } else {
          dispatch(saveGuests(formattedGuests));
        }
        dispatch(setGuestsPaginationData(_.omit(response.data, 'content')));
        return formattedGuests;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchGuestCredentials(guest, page = 0, credentialCategory = CREDENTIAL_CATEGORIES.MOBILE) {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingCredentials(true));
    try {
      let params = {
        page,
        guestId: guest.id,
        validFromDate: moment().valueOf(),
        pageSize: 200,
        ...CREDENTIALS_FILTERS_MAP[credentialCategory],
      };
      const response = await RestService.fetchCredentials(params);
      if (response.data && response.data.content) {
        const credentials = _.map(response.data.content, credential => formatter.formatInputData(formatter.CREDENTIAL_GUEST, credential));
        let pagination = _.omit(response.data, 'content');
        while (!pagination.last) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          let credentialResponse = await RestService.fetchCredentials(params);
          credentials.push(...credentialResponse.data.content);
          pagination = _.omit(credentialResponse.data, 'content');
        }
        dispatch(saveGuestCredentials(credentialCategory, credentials));
        dispatch(setGuestCredentialsPaginationData(credentialCategory, pagination.number, pagination.totalPages));
        dispatch(setIsLoadingCredentials(false));
        return response;
      }
      
      throw new Error();
    } catch (error) {
      dispatch(setIsLoadingCredentials(false));
      throw new Error(error);
    }
  };
}

export function fetchAndAppendGuestCredentials(guest, page = 0, credentialCategory = CREDENTIAL_CATEGORIES.MOBILE) {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingCredentials(true));
    try {
      const params = {
        page,
        guestId: guest.id,
        validFromDate: moment().valueOf(),
        pageSize: 20,
        ...CREDENTIALS_FILTERS_MAP[credentialCategory],
      };
      const response = await RestService.fetchCredentials(params);
      if (response.data && response.data.content) {
        const { number: currentPage, totalPages } = response.data;
        const formattedCredential = _.map(response.data.content, credential => formatter.formatInputData(formatter.CREDENTIAL_GUEST, credential));
        dispatch(appendGuestCredentials(credentialCategory, formattedCredential));
        dispatch(setGuestCredentialsPaginationData(credentialCategory, currentPage, totalPages));
        dispatch(setIsLoadingCredentials(false));
        return response;
      }
      
      throw new Error();
    } catch (error) {
      dispatch(setIsLoadingCredentials(false));
      throw new Error(error);
    }
  };
}


export function fetchGuestAccessMethods(guest) {
  return async (dispatch, getState) => {
    const accessMethods = [];
    try {
      const params = {
        page: 0,
        guestId: guest.id,
        validFromDate: moment().valueOf(),
        pageSize: 1,
        ...CREDENTIALS_FILTERS_MAP[CREDENTIAL_CATEGORIES.MOBILE],
      };
      const response = await RestService.fetchCredentials(params);
      if (response.data && response.data.content && !_.isEmpty(response.data.content)) {
        accessMethods.push({ type: ACCESS_METHODS.SMART });
      }
    } catch (error) {
      throw new Error(error);
    }
    try {
      const params = {
        page: 0,
        guestId: guest.id,
        validFromDate: moment().valueOf(),
        pageSize: 1,
        deviceTypes: CARD_TYPES.ISEO_PIN
      };
      const response = await RestService.fetchCredentials(params);
      if (response.data && response.data.content && !_.isEmpty(response.data.content)) {
        accessMethods.push({ type: ACCESS_METHODS.PIN });
      }
    } catch (error) {
      throw new Error(error);
    }

    try {
      const params = {
        page: 0,
        guestId: guest.id,
        validFromDate: moment().valueOf(),
        pageSize: 1,
        ...CREDENTIALS_FILTERS_MAP[CREDENTIAL_CATEGORIES.RFID],
      };
      const response = await RestService.fetchCredentials(params);
      if (response.data && response.data.content && !_.isEmpty(response.data.content)) {
        accessMethods.push({ type: ACCESS_METHODS.STANDARD });
      }
    } catch (error) {
      throw new Error(error);
    }
    
    const isF9000PluginActive = dispatch(UserAction.isF9000AddonActive());
    if (isF9000PluginActive) {
      try {
        const params = {
          page: 0,
          guestId: guest.id,
          validFromDate: moment().valueOf(),
          pageSize: 1,
          type: CREDENTIAL_TYPES.STANDARD,
          deviceType: 'F9000',
          // TODO HERE FILTER FOR TYPE: F9000
        };
        const response = await RestService.fetchCredentials(params);
        if (response.data && response.data.content && !_.isEmpty(response.data.content)) {
          accessMethods.push({ type: ACCESS_METHODS.F9000 });
        }
      } catch (error) {
        throw new Error(error);
      }
    }

    const isItemRegistryPluginActive = dispatch(UserAction.userHasSubscriptions(SUBSCRIPTION_TYPES.REGISTRY));
    if (isItemRegistryPluginActive) {
      try {
        const params = {
          page: 0,
          recipient: guest.id,
          pageSize: 1,
        };
        const response = await ItemsAPI.getRegistryItems(params);
        if (response.data && response.data.content && !_.isEmpty(response.data.content)) {
          accessMethods.push({ type: ACCESS_METHODS.ITEM });
        }
      } catch (error) {
        throw new Error(error);
      }
    }
    dispatch(saveGuestAccessMethods(accessMethods));
    return accessMethods;
  };
}


export function createGuest(values) {
  return async (dispatch, getState) => {
    try {
      const customFields = getState().customFields.users.content;
      const preformattedValues = {
        ...values,
        role: ROLES.GUEST,
        roleIds: [5],      
        customFields,
      };

      const formattedGuest = formatter.formatOutputData(formatter.GUEST, preformattedValues);
      const response = await RestService.createUser(formattedGuest);
      if (response && response.data) {
        const newGuest = formatter.formatInputData(formatter.GUEST, { ...response.data, customFields });
        dispatch(saveGuest(newGuest));
        if (!newGuest.withoutEmail) {
          dispatch(ModalActions.showModal({
            modalType: 'GUEST_CREATED_MODAL',
          }));
        }
        return newGuest;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function updateGuest(values, avoidModal = false) {
  return async (dispatch, getState) => {
    try {
      const customFields = getState().customFields.users.content;
      const preformattedValues = {
        ...values,
        customFields,
      };
      const formattedGuest = formatter.formatOutputData(formatter.GUEST, preformattedValues);
      const response = await RestService.editUser(formattedGuest.id, formattedGuest);
      if (response && response.data) {
        const newGuest = formatter.formatInputData(formatter.GUEST, { ...response.data, customFields });
        dispatch(updateGuestOnState(newGuest));
        if (!avoidModal) {
          dispatch(ModalActions.showModal({
            modalType: 'SUCCESS_ALERT',
            modalProps: {
              message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'guestUpdated' }} /></h6>),
            },
          }));
        }
        return newGuest;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function saveTagDates(values) {
  return (dispatch, getState) => {
    const activeForm = getState().form.GuestForm ? getState().form.GuestForm : getState().form.GuestProfileViewForm;
    const tags = [...activeForm.values.tags];
    const selectedTagIndex = _.findIndex(tags, { id: values.id });
    if (values.timedTagEnabled && values.dateFrom && values.dateTo && values.timeFrom && values.timeTo) {
      const dateFrom = moment(values.dateFrom);
      const dateTo = moment(values.dateTo);
      const timeFrom = moment(values.timeFrom);
      const timeTo = moment(values.timeTo);
      const timeFromInSeconds = (timeFrom.hours() * 3600) + (timeFrom.minutes() * 60);
      const timeToInSeconds = (timeTo.hours() * 3600) + (timeTo.minutes() * 60);

      const datetimeFrom = dateFrom.startOf('day').add(timeFromInSeconds, 'seconds');
      const datetimeTo = dateTo.startOf('day').add(timeToInSeconds, 'seconds');

      tags[selectedTagIndex] = {
        ...tags[selectedTagIndex],
        dateFrom: datetimeFrom.valueOf(),
        dateTo: datetimeTo.valueOf(),
        timeFrom: timeFrom.valueOf(),
        timeTo: timeTo.valueOf(),
      };
    } else {
      tags[selectedTagIndex] = _.omit(tags[selectedTagIndex], ['dateFrom', 'dateTo', 'timeFrom', 'timeTo']);
    }

    dispatch(change('GuestForm', 'tags', tags));
    dispatch(change('GuestProfileViewForm', 'tags', tags));
    return Promise.resolve();
  };
}

export function getGuestDetails(guestId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.getUserDetails(guestId);
      if (response && response.data) {
        const customFields = getState().customFields.users.content;
        const formattedGuest = formatter.formatInputData(formatter.GUEST, { ...response.data, customFields });
        dispatch(updateGuestOnState(formattedGuest));
        return formattedGuest;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function removeGuestMobileDevice(guest) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.removeMobileDevice(guest.id);
      if (response && response.data) {
        const customFields = getState().customFields.users.content;
        const formattedGuest = formatter.formatInputData(formatter.GUEST, { ...response.data, customFields });
        dispatch(updateGuestOnState({
          ...formattedGuest,
          tags: guest.tags,
          userTag: guest.userTag,
        }));
        return formattedGuest;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function disableGuest(guest) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.disableUser(guest.id);
      if (response && response.data) {
        const customFields = getState().customFields.users.content;
        const formattedGuest = formatter.formatInputData(formatter.GUEST, { ...response.data, customFields });
        dispatch(updateGuestOnState({
          ...formattedGuest,
          tags: guest.tags,
          userTag: guest.userTag,
        }));
        return formattedGuest;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function cacheGuestDetails(guestId) {
  return async (dispatch) => {
    try {
      const guestDetails = await RestService.fetchUserDetails(guestId);
      if (guestDetails && guestDetails.data) dispatch(cacheGuest(guestId, guestDetails.data));
      return guestDetails;
    } catch (error) {
      throw error;
    }
  };
}

export function removeGuestChached(guestId) {
  return {
    type: CACHE_REMOVE_GUEST_DETAILS,
    guestId,
  };
}

export function cacheGuestDetailsIfNeeded(guestId) {
  return async (dispatch, getState) => {
    try {
      if (guestId) {
        const cachedGuestsMap = getState().guests.cachedGuestsMap;
        if (!(guestId in cachedGuestsMap)) {
          const guestDetails = await dispatch(cacheGuestDetails(guestId));
          return guestDetails;
        }
        return null;
      }
    } catch (error) {
      return null;
    }
  };
}

export function cacheGuestsDetailsIfNeededFromAnalytics(analytics) {
  return async (dispatch, getState) => {
    try {
      const approvationCalls = _.map(analytics, analyticData => dispatch(cacheGuestDetailsIfNeeded(analyticData.userId)));
      await Promise.all(approvationCalls);
    } catch (error) {
      throw error;
    }
  };
}


export function fetchGuestsTagsByName(name) {
  return async (dispatch, getState) => {
    try {
      const params = {
        name,
        pageSize: 500,
        includeSpecialTags: true,
      };
      const tagsResponse = await RestService.fetchGuestTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.data) return tagsResponse.data.data;
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchGuestsByCompanyName(companyName) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 500,
        roleIds: [5],
        companyName,
      };
      const response = await RestService.fetchGuests(params);
      if (response.data && response.data.content) {
        const customFields = getState().customFields.users.content;
        const formattedGuests = _.map(response.data.content, guest => formatter.formatInputData(formatter.GUEST, { ...guest, customFields }));
        return formattedGuests;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchGuestsForSelector(searchText) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 30,
        roleIds: [5],
        search: searchText,
      };
      const response = await RestService.fetchGuests(params);
      if (response.data && response.data.content) {
        const customFields = getState().customFields.users.content;
        const formattedGuests = _.map(response.data.content, guest => formatter.formatInputData(formatter.GUEST, { ...guest, customFields }));
        return formattedGuests;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchUsersForSelector(searchText) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 30,
        roleIds: [1, 2, 5],
        search: searchText,
      };
      const response = await RestService.fetchGuests(params);
      if (response.data && response.data.content) {
        const customFields = getState().customFields.users.content;
        const formattedGuests = _.map(response.data.content, guest => formatter.formatInputData(formatter.GUEST, { ...guest, customFields }));
        return formattedGuests;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function exportGuestCsv() {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().guests.data.filters;
    try {
      const params = {
        ...filters,
        roleIds: [5], // guests
      };
      dispatch(UtilsActions.addBackgroundTaskSingleton({
        id: taskId,
        type: BACKGROUND_TASK_TYPES.DOWNLOAD_GUESTS_CSV,
        title: 'creatingGuestCSV',
        cancelCallback: () => {
          const cancelToken = GuestsAPI.getGuestCSVCancelToken();
          if (cancelToken) cancelToken.cancel();
        },
      }));
      const response = await GuestsAPI.exportGuestsCSV(params);
      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, 'Guests.csv');
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}


export function exportGuestsXLSX(fileName = 'Guests_Export') {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    try {
      dispatch(UtilsActions.addBackgroundTaskSingleton({
        id: taskId,
        type: BACKGROUND_TASK_TYPES.DOWNLOAD_SMARTLOCKS_EVENTS,
        title: 'creatingGuestCSV',
        cancelCallback: () => {
          const cancelToken = GuestsAPI.getGuestXLSXCancelToken();
          if (cancelToken) cancelToken.cancel();
        },
      }));
      const filters = getState().guests.data.filters;
      let params = {
        page: 0,
        pageSize: 1000,
        roleIds: [5],
        ...filters,
      };
      let guestsResponse = await GuestsAPI.exportGuestsXLSX(params);
      if (guestsResponse && guestsResponse.data.content && !_.isEmpty(guestsResponse.data.content)) {
        const customFields = getState().customFields.users.content;
        const formattedGuests = _.map(guestsResponse.data.content, guest => formatter.formatInputData(formatter.GUEST, { ...guest, customFields }));
        const guestsData = [...formattedGuests];
        while (!guestsResponse.data.last) {
          params = {
            ...params,
            page: guestsResponse.data.number + 1,
          };
          guestsResponse = await GuestsAPI.exportGuestsXLSX(params);
          const formattedGuestsData = _.map(guestsResponse.data.content, guest => formatter.formatInputData(formatter.GUEST, { ...guest, customFields }));
          guestsData.push(...formattedGuestsData);
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        const exportData = [];
        _.each(guestsData, (guest) => {
          const userCustomFields = elaborateCustomFieldsEntityAttributes(customFields, guest.customAttributes);
          exportData.push({
            Firstname: guest.firstname,
            Lastname: guest.lastname,
            Email: guest.email,
            Username: guest.username,
            'Company name': guest.companyName,
            Languange: guest.languageType,
            'Registration Date': moment(guest.registrationDate).format('LLL'),
            Enabled: guest.enabled,
            MobileId: guest.mobileUuidState,
            ...userCustomFields,
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { guests: ws }, SheetNames: ['guests'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        return guestsData;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function setGuestsViewMode(viewMode) {
  saveDataToLocalStorage('guestViewMode', viewMode);
}

export function importGuestsCSV(csvFile) {
  return async (dispatch, getState) => {
    try {
      const response = await GuestsAPI.importGuestsCSV(csvFile[0]);
      if (response && response.data) {
        const hasError = _.filter(response.data, data => data.errorCode === 10108);
        return hasError;
      }
      throw new Error();
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorImportingCSV" /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function generateCSVFromUsersListAndImport(users) {
  return async (dispatch, getState) => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const ws = XLSX.utils.json_to_sheet(users);
      const wb = { Sheets: { users: ws }, SheetNames: ['users'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'string' });
      const csvFile = new Blob([excelBuffer], { type: fileType });
      const response = await GuestsAPI.importGuestsCSV(csvFile);
      if (response && response.data) {
        const hasError = _.filter(response.data, data => data.errorCode === 10108);
        return hasError;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function deleteBatchUsers(guests, oblivionRequested = false) {
  return async (dispatch, getState) => {
    const errorGuests = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const guest of guests) {
      try {
        await dispatch(UserAction.deleteUser(guest.id, oblivionRequested));
      } catch (error) {
        errorGuests.push(guest);
      }
    }
    if (!_.isEmpty(errorGuests)) {
      throw errorGuests;
    }
  };
}

export function disableBatchUsers(guests) {
  return async (dispatch, getState) => {
    const errorGuests = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const guest of guests) {
      try {
        await RestService.disableUser(guest.id);
      } catch (error) {
        errorGuests.push(guest);
      }
    }
    if (!_.isEmpty(errorGuests)) {
      throw errorGuests;
    }
  };
}


export function enableBatchUsers(guests) {
  return async (dispatch, getState) => {
    const errorGuests = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const guest of guests) {
      try {
        const userData = await RestService.resetPassword(guest.id);
        if (userData && userData.data) return userData.data;
        throw new Error();
      } catch (error) {
        errorGuests.push(guest);
      }
    }
    if (!_.isEmpty(errorGuests)) {
      throw errorGuests;
    }
  };
}

export function addTagsBatchUsers(guests, tags) {
  return async (dispatch, getState) => {
    const errorGuests = [];
    for (const guest of guests) {
      try {
        const groupedTags = _.partition([...tags, ...guest.tags], tag => tag.timeFrom && tag.timeTo);
        const tagIds = groupedTags[1] ? _.map(groupedTags[1], val => val.id) : []
        const tagIdsWithTime = groupedTags[0] ? _.map(groupedTags[0], (tag) => {
          const timeFrom = moment(tag.timeFrom);
          const timeTo = moment(tag.timeTo);
          const tagIntervalTo = moment(tag.dateTo).hour(timeTo.hour()).minute(timeTo.minute()).second(0);
          const tagIntervalFrom = moment(tag.dateFrom).hour(timeFrom.hour()).minute(timeFrom.minute()).second(0);
          return {
            id: tag.id,
            interval: {
              from: tagIntervalFrom.valueOf(),
              to: tagIntervalTo.valueOf(),
            },
          };
        }) : []

        const data = {
          userTagIds: _.uniq(tagIds),
          userTagIdsWithTime: _.uniqBy(tagIdsWithTime, 'id')
        }
        const response = await RestService.editUser(guest.id, data);
        if (!(response && response.data))
          errorGuests.push(guest);
      } catch (error) {
        errorGuests.push(guest);
      }
    }
    if (!_.isEmpty(errorGuests)) {
      throw errorGuests;
    }
  };
}


export function removeTagsBatchUsers(guests, tags) {
  return async (dispatch, getState) => {
    const errorGuests = [];
    for (const guest of guests) {
      try {
        const groupedTags = _.partition(guest.tags, tag => tag.timeFrom && tag.timeTo);
        const groupedTagsToRemove = _.partition(tags, tag => tag.timeFrom && tag.timeTo);
        const tagsToRemove = groupedTagsToRemove[1]?groupedTagsToRemove[1]:[]
        const tagsWithTimeToRemove = groupedTagsToRemove[0]?groupedTagsToRemove[0]:[]
        const tagIds = groupedTags[1] ? _.map(_.filter(groupedTags[1],e=>!_.some(tagsToRemove,f=>e.id===f.id)), val => val.id) : []
        const tagIdsWithTime = groupedTags[0] ? _.map(_.filter(groupedTags[0],e=>!_.some(tagsWithTimeToRemove,f=>e.id===f.id)), (tag) => {
          const timeFrom = moment(tag.timeFrom);
          const timeTo = moment(tag.timeTo);
          const tagIntervalTo = moment(tag.dateTo).hour(timeTo.hour()).minute(timeTo.minute()).second(0);
          const tagIntervalFrom = moment(tag.dateFrom).hour(timeFrom.hour()).minute(timeFrom.minute()).second(0);
          return {
            id: tag.id,
            interval: {
              from: tagIntervalFrom.valueOf(),
              to: tagIntervalTo.valueOf(),
            },
          };
        }) : []

        const data = {
          userTagIds: _.uniq(tagIds),
          userTagIdsWithTime: _.uniqBy(tagIdsWithTime, 'id')
        }
        const response = await RestService.editUser(guest.id, data);
        if (!(response && response.data))
          errorGuests.push(guest);
      } catch (error) {
        errorGuests.push(guest);
      }
    }
    if (!_.isEmpty(errorGuests)) {
      throw errorGuests;
    }
  };
}


export function onUpdateGuestMobileUuidState(guest, mobileUuidState) {
  return async (dispatch, getState) => {
    try {
      const guestDTO = {
        ...guest,
        mobileUuidState,
      }
      const response = await RestService.editUser(guest.id, guestDTO);
      if (response && response.data) {
        const customFields = getState().customFields.users.content;
        const formattedGuest = formatter.formatInputData(formatter.GUEST, { ...response.data, customFields });
        dispatch(updateGuestOnState({
          ...formattedGuest,
          tags: guest.tags,
          userTag: guest.userTag,
        }));
        return formattedGuest;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function toggleOfficeModeBatchUsers(guests, isEnabled) {
  return async (dispatch, getState) => {
    const errorGuests = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const guest of guests) {
      try {
        const data = {
          ...guest,
          canTogglePassageMode: isEnabled,
        }
        const response = await RestService.editUser(guest.id, data);
        if (response && response.data) return response.data;
        throw new Error();
      } catch (error) {
        errorGuests.push(guest);
      }
    }
    if (!_.isEmpty(errorGuests)) {
      throw errorGuests;
    }
  };
}
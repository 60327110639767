import { InfoOutlined } from '@material-ui/icons';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormFlatContainer from '../Elements/FormFlatContainer.jsx';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';

const validate = values => {
  const errors = {};
  if (!values.clientId) {
    errors.clientId = 'required';
  }
  if (!values.clientSecret) {
    errors.clientSecret = 'required';
  }
  return errors;
};

@reduxForm({ form: 'ResHarmonicsActivationForm', validate })
export default class ResHarmonicsActivationForm extends React.Component {
  render() {
    return (
      <div style={{ marginTop: 10 }}>
        <Entity
          name="clientId"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertCobotToken"
          title="res:harmonics Client Id"
          component={CustomField}
        />
        <Entity
          name="clientSecret"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertCobotToken"
          title="res:harmonics Client Secret"
          component={CustomField}
        />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
          <InfoOutlined />
          <h5 style={{ color: '#3f3f3f', margin: 0, marginLeft: 5 }}><Entity entity="getIdAndSecretFromResHarmonics" /></h5>
        </div>
        <FormFlatContainer>
          <h4 style={{ color:'#3f3f3f', marginTop: 0 }}><Entity entity="selectColdStartUnitsResHarmonics" /></h4>
          <Field
            name="coldStartResources"
            label={<Entity entity="synchronizeAllUnits" style={{ color:'#3f3f3f' }} />}
            titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
            component={MDCheckBoxField}
          />
        </FormFlatContainer>
        <FormFlatContainer>
          <h4 style={{ marginBottom: 20, marginTop: 0, color:'#3f3f3f' }}><Entity entity="synchronizeReservationOption" /></h4>
          <Field
            name="pin"
            label={<Entity entity="synchronizeReservationsAsPinsTitle" style={{ color:'#3f3f3f' }} />}
            titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
            component={MDCheckBoxField}
          />
          <h5 style={{ marginBottom: 20, marginTop: -10, color:'#3f3f3f' }}><Entity entity="synchronizeReservationsAsPins" /></h5>
          <Field
            name="credentialRule"
            label={<Entity entity="synchronizeReservationsAsCredentialRulesTitle" style={{color:'#3f3f3f'}} />}
            titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
            component={MDCheckBoxField}
          />
          <h5 style={{ marginBottom: 10, marginTop: -10, color:'#3f3f3f' }}><Entity entity="synchronizeReservationsAsCredentialRules" /></h5>
        </FormFlatContainer>
      </div>
    );
  }
}

// @ts-nocheck
import { Card, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { BLUE, LOCKER_RESERVATION_STATUSES, MATCH_TAG_MODE, RED } from '../../_config/consts.js';
import * as RestService from '../../_config/rest';
import ArrowRightIconCustom from '../CustomIcons/ArrowRightIconCustom.jsx';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import FormCardContainer from '../forms/Elements/FormCardContainer.jsx';
import CustomField from '../forms/Fields/CustomField.jsx';
import DatePickerField from '../forms/Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import DatePickerFieldCustomBorder from '../forms/Fields/DatePickers/SingleDatePicker/DatePickerFieldCustomBorder.jsx';
import GuestSelector from '../forms/Fields/GuestSelector.jsx';
import SelectableField from '../forms/Fields/SelectableInput/SelectableField.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import Tag from '../Tag.jsx';
import PackageIconCustom from '../CustomIcons/PackageIconCustom.jsx';

const styles = theme => ({
  attachment: {
    backgroundColor: '#BBBBBB',
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: BLUE,
    }
  },
});

const validate = values => {
  const errors = {};

  if (!values.fromDate) {
    errors.fromDate = 'required';
  }

  if (!values.firstname || !values.newReservationuserId) {
    errors.firstname = 'required';
  }

  if (!values.locker) {
    errors.locker = 'required';
  }

  return errors;
};

const useAvailableDays = false // TODO sbloccare (e lavorarci su) dopo che facciamo la getAvailability

@reduxForm({ form: 'DeliveryForm', validate })
@connect(state => ({ lockers: state.lockers, form: state.form.DeliveryForm, language: state.settings.language, companyConfigurations: state.user.companyConfigurations }))
class DeliveryDetailsView extends React.Component {

  constructor(props) {
    super(props);
    const lockersData = (this.props.lockers && this.props.lockers.data && this.props.lockers.data.content) || []
    const lockersOptions =  _.map(lockersData, locker => ({ label: locker.name, value: locker.id, item:locker }));
    this.state = {
      lockersOptions: lockersOptions,
      availableDays: [1730246400000,1730332800000,1730419200000,1730678400000,1732924800000], // TODO DEBUG REMOVE
    }
  }

  async componentDidMount() {
    const { lockerReservation } = this.props;
    this.init(lockerReservation)
  }

  componentWillReceiveProps(nextProps) {
    const { lockerReservation: lockerReservationOld } = this.props;
    const { lockerReservation } = nextProps;
    if (lockerReservation.id!==lockerReservationOld.id)
      this.init(lockerReservation)
  }

  async init(lockerReservation) {
    const { dispatch, lockers } = this.props;
    const lockersData = (lockers && lockers.data && lockers.data.content) || []
    const foundLocker = (lockerReservation.lockerId && lockersData.find(e=>e.id===lockerReservation.lockerId)) || undefined
    const foundLockerParsed = foundLocker ? { label: foundLocker.name, value: foundLocker.id, item:foundLocker } : null
    dispatch(change('DeliveryForm', 'fromDate', lockerReservation.fromDate ? lockerReservation.fromDate:null));
    dispatch(change('DeliveryForm', 'toDate', lockerReservation.toDate ? (lockerReservation.toDate-1):null));
    dispatch(change('DeliveryForm', 'locker', foundLockerParsed));
    dispatch(change('DeliveryForm', 'newReservationuserId', lockerReservation && lockerReservation.user && lockerReservation.user.id || null));
    dispatch(change('DeliveryForm', 'firstname', lockerReservation&&lockerReservation.id?{}:null));
  }

  async onChangeGuest(userTag) {
    const { dispatch } = this.props;
    const userTagId = userTag ? userTag.id : undefined
    if (userTagId) {
      const userResponse = await RestService.fetchUsersByTags({ tagIds: [userTagId], userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG });
      if (userResponse.data.content && !_.isEmpty(userResponse.data.content) && userResponse.data.content.length && userResponse.data.content[0].id)
        dispatch(change('DeliveryForm', 'newReservationuserId', userResponse.data.content[0].id));
      else
        dispatch(change('DeliveryForm', 'newReservationuserId', null));
    }
    else
      dispatch(change('DeliveryForm', 'newReservationuserId', null));
  }

  async onChangeFromDate(value) {
    const { dispatch, form } = this.props;
    const { availableDays } = this.state;
    const fromDate_new = value ? moment(value).startOf('day').valueOf() : null
    if (availableDays.includes(fromDate_new) || !useAvailableDays) {
      dispatch(change('DeliveryForm', 'fromDate', fromDate_new));
      const locker_current = form && form.values && form.values.locker
      if (locker_current && locker_current.item) {
        const toDate_new = moment(fromDate_new).startOf('day').add(((locker_current.item.pickupDays-1) || 0), 'days').endOf('day').valueOf();
        dispatch(change('DeliveryForm', 'toDate', toDate_new));
      }
    }
    else {
      dispatch(change('DeliveryForm', 'fromDate', null));
      dispatch(change('DeliveryForm', 'toDate', null));
    }
  }

  async onChangeLocker(value) {
    const { dispatch } = this.props;
    dispatch(change('DeliveryForm', 'fromDate', null));
    dispatch(change('DeliveryForm', 'toDate', null));
  }

  onFilterLockers(value) {
    const { lockers } = this.props;
    const lockersData = (lockers && lockers.data && lockers.data.content) || []
    const filteredLockers = _.filter(lockersData, locker => locker.name.toLowerCase().includes(value.toLowerCase()));
    const lockersOptions =  _.map(filteredLockers, locker => ({ label: locker.name, value: locker.id, item:locker }));
    this.setState({ lockersOptions });
    return value;
  }

  render() {
    const { 
      classes, dispatch, lockers, form, formStyle, timeUnitsOptions, language,
      availableUserTags, onGuestInputChange, 
      lockerReservation, 
      onDeleteDeliveryRequest,
      onSharePin
    } = this.props;
    const { lockersOptions, availableDays } = this.state;

    const isEdit = lockerReservation && lockerReservation.id
    const lockerReservationEditable = lockerReservation && lockerReservation.status===LOCKER_RESERVATION_STATUSES.BOOKED
    const lockerReservationDeletedFromManager = lockerReservation && lockerReservation.status===LOCKER_RESERVATION_STATUSES.DELETED_FROM_MANAGER

    const fromDate_current = form && form.values && form.values.fromDate
    const locker_current = form && form.values && form.values.locker;
    const nameCurrent = form && form.values && form.values.name;
    const notes_current = form && form.values && form.values.notes
    const newReservationuserId_current = form && form.values && form.values.newReservationuserId

    const reservationLock = lockerReservation && lockerReservation.lock
    const reservationLockTag = reservationLock ? {
      color: '#ba68c8',
      name: reservationLock.name ? reservationLock.name.toLowerCase() : '',
      type: 'lock',
    } : undefined;

    const reservationPin = lockerReservation && lockerReservation.pinCode
    
    const userTag_edit = lockerReservation && lockerReservation.user && lockerReservation.user.tags && lockerReservation.user.tags.find(e=>e.type==='user')

    return (
      <Form style={formStyle} autoComplete="off" style={{padding:20,paddingBottom:120}}>
        <FormCardContainer
          title="deliveryData"
          subtitle={!isEdit && 'createDeliverySubscription'}
          icon={<PackageIconCustom style={{ width: 24, marginLeft: 10, color: '#3f3f3f' }} />}
          iconButtonAction={null}
        >
          <div style={{display: 'none', width: 0,height: 0, position: 'absolute' }}>
            <Entity
              componentClass={Field}
              name="newReservationUserId"
              componentAttribute="placeholder"
              entity="insertName"
              component={CustomField}
              containerStyle={{pointerEvents:'none'}}
            />
          </div>
          {isEdit ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>
                <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 5, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                  <Entity entity={"lockerReservationPin"} />
                </h4>
                <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 5, marginBottom: 25, color:'rgb(63,63,63)', fontSize:'17px' }}>
                  {reservationPin || ""}
                </h4>
              </div>
              <IconButton
              style={{ marginTop: 5 }}
              onClick={(e) => onSharePin(reservationPin)}>
                <ShareIconCustom style={{ width: 20, color: BLUE }} />
              </IconButton>
            </div>
          ) : null}
          {isEdit ? (
            <div>
              <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 0, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                <Entity entity="lockerReservationInsertGuest"/>
              </h4>
              <div style={{marginBottom:25, marginLeft:-5}}>
                {userTag_edit && 
                  <Tag
                    key={userTag_edit.id}
                    tag={userTag_edit}
                  />
                }
              </div>
            </div>
          ) : (
            <Field
              title={<Entity entity="lockerReservationInsertGuest" />}
              id="first"
              name="firstname"
              singleTag
              tags={availableUserTags.filter(e=>e.type==='user')}
              guests={undefined}
              placeholder={<Entity entity="lockerReservationInsertGuestDescription" />}
              component={GuestSelector}
              onInputChange={value => onGuestInputChange(value)}
              onHandleChange={value => {this.onChangeGuest(value)}}
              mandatory
            />
          )}
          {isEdit ? (
            <div>
              <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 5, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                <Entity entity={"lockerReservationInsertLocker"} />
              </h4>
              <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 5, marginBottom: 25, color:'rgb(63,63,63)', fontSize:'17px' }}>
                {locker_current && locker_current.item ? locker_current.item.name : ""}
              </h4>
            </div>
          ) : (
            <Field
              name="locker"
              title={<Entity entity={"lockerReservationInsertLocker"} />}
              component={SelectableField}
              options={lockersOptions}
              placeholder={<Entity entity="lockerReservationInsertLockerDescription" />}
              containerstyle={{ marginBottom: 15, marginTop: -10, pointerEvents:!isEdit?'unset':'none' }}
              helpText={undefined}
              onSelect={locker => {this.onChangeLocker(locker)}}
              onInputChange={name => this.onFilterLockers(name)}
              mandatory={!isEdit}
            />
          )}
          {reservationLock ? (
            <div>
              <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 0, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                <Entity entity="lockerReservationSlot"/>
              </h4>
              <div style={{marginBottom:25, marginLeft:-5}}>
                <Tag textStyle={{ fontSize: 12 }} style={{ paddingTop: 4, paddingBottom: 4 }} tag={reservationLockTag} />
              </div>
            </div>
          ) : null}
            <div style={{display:'flex', flexDirection:'row', alignItems:'end'}}>
              <Field
                title={<Entity entity="lockerReservationDateFrom" />}
                subtitle={<Entity entity="selectLockerToSelectDate" />}
                id="fromDate"
                name="fromDate"
                dateContainer={{ marginRight: 20 }}
                onHandleChange={value => {this.onChangeFromDate(value)}}
                component={DatePickerFieldCustomBorder}
                hideIcon={true}
                disabled={!locker_current}
                isOutsideRange={date => date.isBefore(moment().startOf('day'))}
                isDayBlocked={date => useAvailableDays && !availableDays.includes(moment(date).startOf('day').valueOf())}
                mandatory={(!isEdit || lockerReservationEditable)}
                hideErrorMessage={true}
                useErrorBorder={true}
                containerStyle={{ width: 300, marginLeft:-1,pointerEvents:(!isEdit || lockerReservationEditable)?'unset':'none'}}
              />
              <ArrowRightIconCustom style={{ width: 30, color: '#3f3f3f', marginRight:5, marginBottom:1 }} />
              <h4 style={{ paddingLeft: 0, margin: 0, marginRight:5, marginBottom:7, fontWeight:'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                <Entity entity="lockerReservationDateTo" />:
              </h4>
              <Field
                id="toDate"
                name="toDate"
                placeholder='---'
                dateContainer={{ marginLeft: 20, marginRight: 20 }}
                onHandleChange={() => {}}
                component={DatePickerField}
                disabled={!locker_current}
                hideIcon={true}
                containerStyle={{pointerEvents:'none', width: 320 }}
              />
            </div>
          {useAvailableDays && locker_current && (!isEdit || lockerReservationEditable) ? (
            <div>
              <h4 style={{ paddingLeft: 14, margin: 0, marginRight:5, marginTop: 15, marginBottom:5, fontWeight:'bold', color:'rgb(63,63,63)', fontSize:'15px' }}>
                <Entity entity="lockerReservationAvailableDates" />:
              </h4>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginLeft: 14 }}>
                {availableDays.map((day) => (
                  <Card
                    key={day}
                    onClick={() => this.onChangeFromDate(day)}
                    style={{
                      padding: '10px', cursor: 'pointer',
                      backgroundColor: fromDate_current === day ? 'lightblue' : 'white',border: fromDate_current === day ? `2px solid ${BLUE}` : '1px solid #DDD'
                    }}
                  >
                    {moment(day).format('LL')}
                  </Card>
                ))}
              </div>
            </div>
          ) : null}
          {isEdit ? (
            <div style={{ marginTop: 20 }}>
              <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', fontSize: 16, }}>{<Entity entity="itemNotes" />}</h5>
              <h5>{nameCurrent || '---'}</h5>
            </div>
          ) : (
            <Entity
              componentClass={Field}
              name="name"
              componentAttribute="placeholder"
              entity="insertDeliveryDescription"
              title={<Entity entity="itemNotes" />}
              component={CustomField}
              mandatory={false}
              type="textarea"
              containerStyle={{pointerEvents:!isEdit?'unset':'none', marginTop: 15 }}
            />
          )}
        </FormCardContainer>
        {(isEdit || lockerReservationDeletedFromManager) &&
          <FormCardContainer
            title={lockerReservationDeletedFromManager?"lockerReservationAlreadyDeletedFromManager":"lockerReservationDelete"}
            subtitle={lockerReservationDeletedFromManager?"":"lockerReservationDeleteWarning"}
          >
            <Entity
              componentClass={Field}
              name="notes"
              title={<Entity entity="lockerReservationDeletionNotes" />}
              componentAttribute="placeholder"
              entity="lockerReservationDeletionNotesSubtitle"
              component={CustomField}
              type="textarea"
              className="form-control-custom"
              mandatory={!lockerReservationDeletedFromManager}
              containerStyle={{pointerEvents:lockerReservationDeletedFromManager?'none':'unset'}}
            />
            {!lockerReservationDeletedFromManager &&
              <MDButton
                title={<Entity entity="lockerReservationDelete" />}
                backgroundColor={RED}
                style={{opacity:notes_current?1:0.7}}
                onClick={() => onDeleteDeliveryRequest(notes_current)}
                disabled={!notes_current}
              />
            }
          </FormCardContainer>
        }
      </Form>
    );
  }
} 

export default withStyles(styles)(DeliveryDetailsView);

import {
  Col,
  Row
} from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import WarningIcon from '@material-ui/icons/Warning';
import { Field, FieldArray } from 'redux-form';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/AddCircle';
import Divider from '@material-ui/core/Divider';
import { LANGUAGES, RED } from '../../../_config/consts.js';
import CustomField from '../Fields/CustomField.jsx';
import DateRangeField from '../Fields/DatePickers/DateRangePicker/DateRangeField.jsx';
import DaysOfTheWeekPicker from '../Fields/DaysOfTheWeekPicker.jsx';
import { Card } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AdditionalTimeIntervalForm from '../TimeProfiles/AdditionalTimeIntervalForm.jsx';
import moment from 'moment';

const onRenderAdditionalTimeInterval = (props) => {
  const { fields, formData, is12HoursFormat, onInitializeNewTimeInterval } = props;
  const numberOfTimeIntervals = _.size(fields);
  return (
    <div style={{paddingLeft:40, paddingRight:30}}>
      {fields && fields.map((timeInterval, index) => (
        <div key={`timeInterval${index + 1}`} elevation={2} style={{ marginTop: 10}}>
          <Divider />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, paddingRight: 10 }}>
            <AdditionalTimeIntervalForm
              timeIntervalName={`additionalTimeIntervals[${index}]`}
              is12HoursFormat={is12HoursFormat}
            />
            <IconButton
              style={{ marginLeft: 10, marginBottom: 5 }}
              onClick={(e) => {
                e.stopPropagation();
                if (!formData.isEditing) {
                  fields.remove(index);
                }
              }}
            >
              <DeleteIcon style={{ color: RED, fontSize: 20 }} />
            </IconButton>
          </div>
        </div>
      ))}
      <Card
        style={{ height: 60, marginBottom: 20, padding: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        className="card card-rounded"
        onClick={() => {
          fields.push({});
          onInitializeNewTimeInterval(numberOfTimeIntervals);
        }}
      >
        <AddIcon style={{ color: '#4DB6AC', marginRight: 15, fontSize: 25 }} />
        <h4 style={{ fontWeight: 'bold' }}><Entity entity="newResourceAdditionalTimeInterval" /></h4>
      </Card>
    </div>
  );
}

@connect(state => ({ form: state.form.ResourcesForm, language: state.settings.language }))
class ResourcesTimeSelector extends React.Component {

  onInitializeNewTimeInterval(timeIntervalId, fieldName) {
    const { dispatch, formName, form } = this.props;    
    dispatch(change(formName, `${fieldName}[${timeIntervalId}].timeIntervalFrom`, moment().startOf('day').valueOf()));
    dispatch(change(formName, `${fieldName}[${timeIntervalId}].timeIntervalTo`, moment().endOf('day').valueOf()));
  }

  render() {
    const { form, formData, formName, checkedInterval, language, timeIntervalsOK } = this.props;
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    const additionalTimeIntervals = formData && formData.additionalTimeIntervals
    return (
      <div style={{ marginTop: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <Field
            title={<Entity entity="resourceTimeFrame" />}
            name="credentialTimeframe"
            checkedInterval={checkedInterval}
            component={DateRangeField}
            optionContainerStyle={{ marginBottom: 0}}
            minimumNights={0}
            options={[
              { value: 1, label: L20NContext.getSync('singleYear', { year: 1 }) },
              { value: 5, label: L20NContext.getSync('multipleYears', { years: 5 }) },
              { value: 10, label: L20NContext.getSync('multipleYears', { years: 10 }) },
            ]}
          />
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', paddingLeft:40 }}>
              <Entity
                componentClass={Field}
                title={<Entity entity="resourceIntervalFrom" />}
                name="timeIntervalFrom"
                component={CustomField}
                type="antdTimePicker"
                is12HoursFormat={is12HoursFormat}
                placeholder="Select a time"
              />
              <div style={{paddingLeft:40}}>
                <Entity
                  componentClass={Field}
                  title={<Entity entity="resourceIntervalTo" />}
                  name="timeIntervalTo"
                  component={CustomField}
                  type="antdTimePicker"
                  is12HoursFormat={is12HoursFormat}
                  placeholder="Select a time"
                />
              </div>
            </div>
            <FieldArray
              name={`additionalTimeIntervals`}
              component={onRenderAdditionalTimeInterval}
              formData={formData}
              form={form}
              formName={formName}
              is12HoursFormat={is12HoursFormat}
              onInitializeNewTimeInterval={intervalId => this.onInitializeNewTimeInterval(intervalId, `additionalTimeIntervals`)}
            />
            {additionalTimeIntervals && additionalTimeIntervals.length && !timeIntervalsOK ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom:10, paddingLeft:40 }}>
                <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 24 }} />
                <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: 'bold', margin: 0, fontSize:16 }}>
                  <Entity
                    entity={"resourceAdditionalTimeIntervalsInvalid"}
                  />
                </h4>
              </div>
            ):null}
            <Entity
              componentClass={Field}
              title={<Entity entity="daysOfTheWeek" />}
              name="daysOfTheWeek"
              component={DaysOfTheWeekPicker}
              containerstyle={{ paddingLeft: 40, marginTop: 10 }}
              type="Selection"
            />
          </div>
        </div>
      </div>
    );
  }
} 

export default ResourcesTimeSelector;

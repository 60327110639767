// @ts-nocheck
import Warning from '@material-ui/icons/Warning.js';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { ORANGE, PERMISSIONS, PERMISSION_ENTITIES, RED } from '../../_config/consts.js';
import MDButton from '../MDButton/MDButton.jsx';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import Permission from '../Permission/Permission.jsx';
import InfoIcon from '@material-ui/icons/HelpOutline';
import CustomField from './Fields/CustomField.jsx';
import { MaximizeTwoTone } from '@material-ui/icons';
import * as ModalActions from '../../redux/actions/modal.actions';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';
import { IconButton } from '@material-ui/core';


const styles = theme => ({
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
    border: '1px solid red',
    borderRadius: 5,
  },
});

const validate = values => {
  const errors = {};
  if (values.occupancyLessOrEqualTo==="" || parseInt(values.occupancyLessOrEqualTo,10)<0) {
    errors.occupancyLessOrEqualTo = 'required';
  }
  if (values.checkIntervalMinutes==="" || parseInt(values.checkIntervalMinutes,10)<=1) {
    errors.checkIntervalMinutes = 'areaRuleTwoMinutesMinimum';
  }
  if (!values.lockId) {
    errors.lockId = 'required';
  }
  return errors;
};

@reduxForm({ form: 'AreaRuleForm', validate })
@connect(state => ({ form: state.form.AreaRuleForm }))
class AreaRuleForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      availableLocks: props.allLocksCompatibleWithRules ?
        _.map(props.allLocksCompatibleWithRules, lock => ({ label: `${lock.name} [${lock.serialNumber||""}]`, value: lock.id })) : [],
    }
  }
  
  onFilterAvailableLocks(value) {
    const { allLocksCompatibleWithRules } = this.props;
    const filteredLocksCompatibleWithRules = _.filter(allLocksCompatibleWithRules, lock => lock.name.toLowerCase().includes(value.toLowerCase()) || (lock.serialNumber || "").toLowerCase().includes(value.toLowerCase()));
    const availableLocks =  _.map(filteredLocksCompatibleWithRules, lock => ({ label: `${lock.name} [${lock.serialNumber||""}]`, value: lock.id }));
    this.setState({ availableLocks });
    return value;
  }

  onLockSelected(value) {
    const { dispatch } = this.props;
    dispatch(change('AreaRuleForm', 'lockId', value && value.value ? value.value : null));
  }

  render() {
    const { onValidateForm, form, classes, onDeleteAreaRuleRequest, allLocksCompatibleWithRules } = this.props;
    const { availableLocks } = this.state;

    const areaId = form?.values?.id

    return (
      <Form style={{ paddingLeft: 15, paddingRight: 15, marginTop: 0 }}>
        <input style={{ display: 'none' }} type="number" name="id" />
        <div style={{ width:400, display: 'flex', flexDirection: 'column', paddingLeft: 0, marginTop: 0 }}>
          <Field
            name="lockId"
            title={<Entity entity="lock" />}
            component={SelectableField}
            options={availableLocks}
            canCreate={false}
            placeholder={<Entity entity="areaRuleLockPlaceholder" />}
            containerstyle={{ minWidth:400 }}
            helpText={null}
            onSelect={value => {this.onLockSelected(value)}}
            onInputChange={name => this.onFilterAvailableLocks(name)}
          />
          <div style={{display:'flex',flexDirection:'row', marginTop:5}}>
            <InfoIcon style={{ fontSize: 20, color: '#3f3f3f', marginRight: 12 }} />
            <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 0, fontSize: 14, minWidth:350 }}>{<Entity entity="areaRuleLockDescription"/>}</h4>
          </div>
        </div>
        <div style={{fontSize:16,color:'rgb(63, 63, 63)',fontWeight:'bold',marginTop:20}}>
          {L20NContext.getSync('areaRuleOccupancy')}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row',paddingLeft:0 }}>
          <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="areaRuleOccupancyDescription"/>}</h4>
          <Entity
            componentClass={Field}
            name="occupancyLessOrEqualTo"
            type="number"
            textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
            componentAttribute="placeholder"
            component={CustomField}
            disabled={false}
            containerStyle={{ width: 100 }}
            className="form-control-custom"
          />
        </div>
        <div style={{fontSize:16,color:'rgb(63, 63, 63)',fontWeight:'bold',marginTop:20}}>
          {L20NContext.getSync('areaRuleCheckInterval')}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row',paddingLeft:0 }}>
          <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="areaRuleCheckIntervalDescription"/>}</h4>
          <Entity
            componentClass={Field}
            name="checkIntervalMinutes"
            type="number"
            textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
            componentAttribute="placeholder"
            component={CustomField}
            disabled={false}
            containerStyle={{ width: 100 }}
            className="form-control-custom"
          />
          <h4 style={{ color: '#3f3f3f', marginLeft: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="areaRuleCheckIntervalDescription2"/>}</h4>
        </div>
        <Permission do={[PERMISSIONS.ALL]} on={PERMISSION_ENTITIES.AREA} abilityHelper={AbilityProvider.getAbilityHelper()}>
          <MDButton
            title={<Entity entity="save" />}
            onClick={() => onValidateForm()}
          />
        </Permission>
        {areaId ? (
          <Permission do={[PERMISSIONS.ALL]} on={PERMISSION_ENTITIES.AREA} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MDButton
              title={<Entity entity="deleteAreaRule" />}
              backgroundColor={RED}
              onClick={() => onDeleteAreaRuleRequest(areaId)}
            />
          </Permission>
        ) : null}
      </Form>
    );
  }
} 

export default withStyles(styles)(AreaRuleForm);
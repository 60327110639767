import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as advancesModalsReducer } from '@bottega52/redux-modals';
import { resourceReducer, reservationReducer, resourceTypeReducer, eventReducer, analyticReducer, domainMediaReducer } from '@bottega52/bookey-redux-module';
import user from './user.reducers';
import cards from './card.reducers';
import modals from './modal.reducers';
import locks from './lock.reducers';
import tags from './tag.reducers';
import guests from './guest.reducers';
import credentials from './credential.reducers';
import settings from './setting.reducers';
import utils from './utils.reducers';
import gateways from './gateways.reducers';
import logEvents from './logEvents.reducers';
import agreementsReducers from './agreements.reducers';
import rolesReducers from './roles.reducers';
import platformUsersReducers from './platformUsers.reducers';
import metrics from './metrics.reducers';
import tagsManagementReducers from './tagsManagement.reducers';
import invitations from './invitations.reducers';
import itemRegistryReducers from './itemRegistry.reducers';
import installersReducers from './installers.reducers';
import communicationsReducers from './communications.reducers';
import cloudCreditsReducers from './cloudCredits.reducers';
import customFieldsReducers from './customFields.reducers';
import camerasReducers from './cameras.reducers';
import accessoriesReducers from './accessories.reducers';
import accessProfiles from './accessProfiles.reducers';
import visitors from './visitors.reducers';
import floorPlans from './floorPlans.reducers';
import tickets from './ticket.reducers';
import areas from './area.reducers';
import lockers from './locker.reducers';

export default (history) => combineReducers({
  user,
  modals,
  locks,
  advancedModals: advancesModalsReducer,
  accessProfiles,
  gateways,
  cards,
  guests,
  tags,
  credentials,
  visitors,
  settings,
  accessories: accessoriesReducers,
  logEvents,
  metrics,
  floorPlans,
  router: connectRouter(history),
  agreements: agreementsReducers,
  form: formReducer,
  roles: rolesReducers,
  itemRegistry: itemRegistryReducers,
  platformUsers: platformUsersReducers,
  installers: installersReducers,
  tagsManagement: tagsManagementReducers,
  communications: communicationsReducers,
  cameras: camerasReducers,
  cloudCredits: cloudCreditsReducers,
  customFields: customFieldsReducers,
  [reservationReducer.getStateKey()]: reservationReducer.reducerBinder(),
  [resourceReducer.getStateKey()]: resourceReducer.reducerBinder(),
  [resourceTypeReducer.getStateKey()]: resourceTypeReducer.reducerBinder(),
  [eventReducer.getStateKey()]: eventReducer.reducerBinder(),
  [analyticReducer.getStateKey()]: analyticReducer.reducerBinder(),
  [domainMediaReducer.getStateKey()]: domainMediaReducer.reducerBinder(),
  utils,
  invitations,
  tickets,
  areas,
  lockers,
});

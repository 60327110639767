import { reservationActions as ReservationActions, resourceActions as ResourceActions } from '@bottega52/bookey-redux-module';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import { change, initialize, reset, submit } from 'redux-form';
import CalendarViewIcon from '@material-ui/icons/Event';
import ListViewIcon from '@material-ui/icons/ViewList';
import ReservationSearchBar from '../../../components/forms/Bookey/ReservationSearchBar.jsx';
import OperationalView from '../../../components/OperationalView/OperationalView.jsx';
import * as CredentialActions from '../../../redux/actions/credential.actions';
import * as GuestActions from '../../../redux/actions/guest.actions';
import * as LocksActions from '../../../redux/actions/lock.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as MetricsActions from '../../../redux/actions/metrics.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as ReservationsActions from '../../../redux/actions/reservations.actions';
import * as formatter from '../../../_config/formatter';
import * as RestService from '../../../_config/rest';
import { MATCH_TAG_MODE, ORANGE, RESERVATION_CONFIRMATION_STATUSES, RESOURCE_CAPACITY_SLOTS, reservationSearchModeValues } from './../../../_config/consts';
import NewReservationView from './NewReservationView.jsx';
import ReservationDetailsView from './ReservationDetailsView.jsx';
import UnavailabilityDetailsView from './UnavailabilityDetailsView.jsx';
import TranslatableOption from '../../../components/forms/Fields/TranslatableOption.jsx';
import { Tab, Tabs } from '@material-ui/core';
import DateRangeView from '../../../components/forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import BookeyReservationsList from '../../../components/MetricCharts/BookeyReservationsList.jsx';
import { getColorFromThemeName } from '../../../_config/utils.js';
import ReservationTableView from '../../../components/forms/Bookey/ReservationTableView.jsx';
import SimpleExportMenu from '../../../components/ExportMenus/SimpleExportMenu.jsx';
import CapacitySearchBar from '../../../components/forms/Bookey/CapacitySearchBar.jsx';
import CapacityTableView from '../../../components/forms/Bookey/CapacityTableView.jsx';
import * as ResourceTypesEpic from '../../../epics/resourcesTypes.epics';
import NewReservationViewSlots from './NewReservationViewSlots.jsx';

const formatFormFilters = (form) => {
  if (!form || !form.values)
    return {};
  const { values } = form;
  return formatFilters(values);
};

const formatFilters = (filters) => ({
  reservationsDate: filters.reservationsDate?moment(filters.reservationsDate).startOf('day').valueOf():moment().startOf('day').valueOf(),
  typeId: filters.type&&filters.type!=="__ANY__"?filters.type:undefined,
});

function generateTimeIntervals(slotSizeInHours, startingHour=0, endingHour=24) {
  const intervals = [];
  const hourInMilliseconds = 60 * 60 * 1000;
  for (let i = startingHour; i <= (endingHour - slotSizeInHours); i += slotSizeInHours) {
    const fromDate = i * hourInMilliseconds;
    const toDate = (i + slotSizeInHours) * hourInMilliseconds;
    intervals.push({ fromDate: fromDate, toDate: toDate, index: Math.round(i/slotSizeInHours) });
  }
  return intervals;
}

function addTimeToIntervals(intervals, additionalTime) {
  return intervals.map(interval => ({
    fromDate: interval.fromDate + additionalTime,
    toDate: interval.toDate + additionalTime,
    index: interval.index,
  }));
}

@connect(state => ({ user: state.user, routing: state.router, filters: state.form.CapacitySearchBar, resources: state.resources, resourcesTypes: state.resourceTypes.data.content, reservations: state.reservations, metrics: state.metrics.bookeyAnalytics, themeName: state.settings.items.theme.data.themeName, guests: state.guests, form: state.form.ReservationForm }))
class BookeyAvailabilityAgenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOperativeSection: false,
      resourcesLoading: true,
      slotSize: RESOURCE_CAPACITY_SLOTS.MINUTES_30.value,
      deltaSlots: generateTimeIntervals(RESOURCE_CAPACITY_SLOTS.MINUTES_30.value / 60),
      resourcesOccupancies: [],
      startingSlotIndex: Math.ceil((moment().diff(moment().startOf('day'), 'minutes') / 60) / (RESOURCE_CAPACITY_SLOTS.MINUTES_30.value / 60)),
    };
  }

  async componentWillMount() {
    
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      await dispatch(change('CapacitySearchBar', 'reservationsDate', moment().startOf('day').valueOf()));
      await dispatch(initialize('CapacitySearchBar', { 
        slotSize: RESOURCE_CAPACITY_SLOTS.MINUTES_30.value,
      }));
      await dispatch(ResourceTypesEpic.fetchResourcesTypesByFilter(undefined, 50));
      await this.fetchResources()
      this.setState({ resourcesLoading: false })
    } catch (error) {
      console.log(error)
    }
  }

  async fetchResources(page = 0) {
    const { dispatch, filters: formFilters } = this.props;
    const formattedFilters = formatFormFilters(formFilters);
    const filters = {
      typeId: formattedFilters.typeId
    }
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const resources = await dispatch(ResourceActions.fetchResourcesWithMedia(page, 5, filters, false));
      await this.fetchCapacityTable()
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      console.log(error)
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async fetchCapacityTable() {
    const { dispatch, filters: formFilters, resources } = this.props;
    const { deltaSlots } = this.state;
    const resourcesOccupancies = []

    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const formattedFilters = formatFormFilters(formFilters);
      
      const slots = addTimeToIntervals(structuredClone(deltaSlots),formattedFilters.reservationsDate)

      const resourcesData = resources.data.content ? resources.data.content : []
      
      for (let i=0; i<resourcesData.length; i++) {
        const resource = resourcesData[i]
        const occupancy = await dispatch(ResourceActions.fetchResourceOccupancyMultislot(
          resource.id,{ slots: slots.map(e=>{return {fromDate:(e.fromDate+1),toDate:(e.toDate-1)}})}))
        const resourceOccupancy = {
          resourceId: resource.id,
          slots: occupancy&&occupancy.slots?occupancy.slots.map((item,i)=>{return {
            ...item,
            fromDate: item.fromDate-1,
            toDate: item.toDate+1,
            index:i
          }}):[]
        }
        resourcesOccupancies.push(resourceOccupancy)
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      console.log(error)
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }

    this.setState({resourcesOccupancies})
  }

  async onSelectResourceType(value) {
    const { dispatch } = this.props;
    await dispatch(change('CapacitySearchBar', 'type', value));
    await this.fetchResources()
  }

  async onSelectReservationsDate(value) {
    const { dispatch } = this.props;
    await dispatch(change('CapacitySearchBar', 'reservationsDate', value));
    await this.fetchCapacityTable()
  }

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(change('CapacitySearchBar', 'type', '__ANY__'));
    dispatch(change('CapacitySearchBar', 'reservationsDate', moment().startOf('day').valueOf()));
    setTimeout(() => this.fetchResources(), 500);
  }

  async onNewReservation() {
    const { dispatch, filters: formFilters } = this.props;
    const formattedFilters = formatFormFilters(formFilters);
    const currentDate = formattedFilters.reservationsDate
    await dispatch(ResourceActions.setReduxAvailableResources([]));
    await dispatch(reset('ReservationFormSlots'));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    this.setState({ showOperativeSection: false });
    setTimeout(async () => {
      await dispatch(initialize('ReservationFormSlots', { 
        dateFrom: currentDate,
        searchMode: reservationSearchModeValues.SELECT_RESOURCE,
        numberOfGuests: 0,
      }));
      this.setState({ showOperativeSection: true });
    }, 500);
  }

  async onCellClick(resource, slot) {
    const { dispatch, resources, filters: formFilters } = this.props;
    const formattedFilters = formatFormFilters(formFilters);
    const currentDate = formattedFilters.reservationsDate
    const resourceSelected = {
      value: resource.id,
      label: resource.name
    }
    await dispatch(ResourceActions.setReduxAvailableResources([]));
    await dispatch(reset('ReservationFormSlots'));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    this.setState({ showOperativeSection: false });
    setTimeout(async () => {
      await dispatch(initialize('ReservationFormSlots', { 
        dateFrom: currentDate,
        resourceSelected: resourceSelected,
        timeFrom: slot.fromDate,
        timeTo: slot.toDate,
        timeFromSearch: slot.fromDate,
        timeToSearch: slot.toDate,
        searchMode: reservationSearchModeValues.SELECT_RESOURCE,
        checkInNotNecessary: resource.automaticCheckIn,
        numberOfguests: 0,
      }));
      this.setState({ showOperativeSection: true });
    }, 500);
  }

  async saveReservation(values) {
    const { dispatch } = this.props;
    const { reservationUserId } = this.state;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const parsedValues = {
        dateFrom: moment(values.timeFrom).valueOf(),
        dateTo: moment(values.timeTo).valueOf(),
        checkInNotNecessary: values.checkInNotNecessary,
        resourceId: values.searchMode===reservationSearchModeValues.SELECT_RESOURCE ? values.resourceSelected.value : values.resourceId,
        title: values.title,
        description: values.description,
        numberOfPeople: parseInt(values.numberOfGuests || 0, 10) + 1,
        invitedEmails: values.invitedEmails ? values.invitedEmails.filter(e=>e.guest && e.guest.email).map(e => e.guest.email) : [],
      }
      const reservationData = formatter.formatOutputData(formatter.RESERVATION, { ...parsedValues, reservationUserId });
      await dispatch(ReservationActions.createReservation(reservationData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showOperativeSection: false })
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reservationCreatedSuccess" /></h6>),
        },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      await this.fetchResources();
    }
  }

  async onDateHasBeenChanged(dateInterval, userTagId = null, numberOfPeople=1) {
    const { dispatch } = this.props;
    const formattedDates = formatter.formatOutputData(formatter.RESERVATION_DATES, dateInterval);
    let { dateFrom, dateTo } = formattedDates;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await this.fetchAvailableResourcesForReservation(0, 20, { userTagId, dateFrom, dateTo, numberOfPeople });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async fetchAvailableResourcesForReservation(page = 0, pageSize = 20, availableResourcesData, append = false) {
    const { dispatch } = this.props;
    const userTagId = availableResourcesData.userTagId;
    const dateFrom = availableResourcesData.dateFrom;
    const dateTo = availableResourcesData.dateTo;
    const canFetchAvailableResources = dateFrom < dateTo;
    if (userTagId && canFetchAvailableResources) {
      const userResponse = await RestService.fetchUsersByTags({ tagIds: [userTagId], userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG });
      if (userResponse.data.content && !_.isEmpty(userResponse.data.content)) {
        this.setState({ reservationUserId: userResponse.data.content[0].id });
      }
      const filters = {
        fromDate: dateFrom,
        toDate: dateTo,
        userId: userResponse.data.content[0].id,
        numberOfPeople: availableResourcesData.numberOfPeople || 1,
      };
      await dispatch(ResourceActions.fetchAvailableResourcesWithMedia(page, pageSize, filters, append));
    } else {
      await dispatch(ResourceActions.setReduxAvailableResources([]));
    }
  }

  async onChangeReservationUser(userTag) {
    if (userTag && userTag.id) {
      const userResponse = await RestService.fetchUsersByTags({ tagIds: [userTag.id], userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG });
      if (userResponse.data.content && !_.isEmpty(userResponse.data.content))
        this.setState({ reservationUserId: userResponse.data.content[0].id });
      else 
        this.setState({ reservationUserId: undefined })
    }
    else
      this.setState({ reservationUserId: undefined })
  }

  async onChangeSlotSize(value) {
    const { slotSize, startingSlotIndex } = this.state
    this.setState({ resourcesOccupancies:[] })

    const slotSize_new = value
    const deltaSlots_new = generateTimeIntervals(value / 60)
    const startingSlotIndex_new = Math.ceil((moment().diff(moment().startOf('day'), 'minutes') / 60) / (value / 60))

    this.setState({ slotSize:slotSize_new, deltaSlots:deltaSlots_new, startingSlotIndex:startingSlotIndex_new })
    setTimeout(() => this.fetchCapacityTable(), 200);
  }

  render() {
    const { dispatch, themeName, 
      resources, resourcesTypes, filters: formFilters,
    } = this.props;
    const formattedFilters = formatFormFilters(formFilters);
    const currentDate = formattedFilters.reservationsDate
    const { resourcesLoading, resourcesOccupancies, startingSlotIndex, deltaSlots, slotSize, showOperativeSection } = this.state;

    return (
      <div style={{ marginTop: 0, height: 820 }}>
        <CapacitySearchBar
          themeName={themeName}
          disableComponents={resourcesLoading}
          onSearchReset={() => this.onSearchReset()}
          onSelectReservationsDate={value => this.onSelectReservationsDate(value)}
          onSelectResourceType={value => this.onSelectResourceType(value)}
          onNewReservation={()=>{this.onNewReservation()}}
          resourceTypeOptions={[
            (<Entity
              key={"anyResourceTypeFilter"}
              componentClass={TranslatableOption}
              value={"__ANY__"}
              componentAttribute="text"
              entity={"anyResourceTypeFilter"}
            />),
            ..._.map(_.sortBy(resourcesTypes, (type => type.name.toLowerCase())), type =>
              <option value={type.id} key={type.id}>
                {type.name}
              </option>
            )
          ]}
        />
        <CapacityTableView
          resources={resources.data}
          resourcesOccupancies={resourcesOccupancies}
          startingSlotIndex={startingSlotIndex}
          onChangeStartingSlotIndex={(newIndex) => this.setState({startingSlotIndex:newIndex})}
          slotSize={slotSize}
          deltaSlots={deltaSlots}
          currentDate={currentDate}
          viewLoading={resourcesLoading}
          onFetchResourcesOnPage={page => this.fetchResources(page)}
          onRefreshResources={() => this.fetchResources(0)}
          onSearchReset={() => this.onSearchReset()}
          onSelectResourceType={type => this.onSelectResourceType(type)}
          onSelectReservationsDate={value => this.onSelectReservationsDate(value)}
          onChangeSlotSize={(value)=>{this.onChangeSlotSize(value)}}
          onCellClick={(resource,slot)=>{this.onCellClick(resource,slot)}}
          resourceTypeOptions={[
            (<Entity
              key={"anyResourceTypeFilter"}
              componentClass={TranslatableOption}
              value={"__ANY__"}
              componentAttribute="text"
              entity={"anyResourceTypeFilter"}
            />),
            ..._.map(_.sortBy(resourcesTypes, (type => type.name.toLowerCase())), type =>
              <option value={type.id} key={type.id}>
                {type.name}
              </option>
            )
          ]}
        />
        <OperationalView
          themeName={themeName}
          isVisible={showOperativeSection}
          onClose={() => this.setState({ showOperativeSection: false })}
          title={<Entity entity="newReservation" />}
        >
          <NewReservationViewSlots
            onSubmitReservationForm={() => dispatch(submit('ReservationFormSlots'))}
            onSaveReservation={(values) => this.saveReservation(values)}
            themeName={themeName}
            dateHasBeenChanged={(dateInterval, userTagId, numberOfPeople=1) => this.onDateHasBeenChanged(dateInterval, userTagId, numberOfPeople)}
            onFetchMoreAvailableResources={(page, pageSize, availableResourcesData, append) => this.fetchAvailableResourcesForReservation(page, pageSize, availableResourcesData, append)}
            onChangeReservationUser={(userTag) => {this.onChangeReservationUser(userTag)}}
          />
        </OperationalView>
      </div>
    );
  }
}

export default BookeyAvailabilityAgenda;

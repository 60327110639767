module.exports = {
  SAVE_LOCKS: 'SAVE_LOCKS',
  APPEND_LOCKS: 'APPEND_LOCKS',
  SAVE_LOCK: 'SAVE_LOCK',
  SELECT_LOCK: 'SELECT_LOCK',
  UPDATE_SELECTED_LOCK_TAG: 'UPDATE_SELECTED_LOCK_TAG',
  SET_LOCK_OPERATIONAL_MODE: 'SET_LOCK_OPERATIONAL_MODE',
  SET_LOCKS_PAGINATION_DATA: 'SET_LOCKS_PAGINATION_DATA',
  SET_LOCK_FILTER: 'SET_LOCK_FILTER',
  CACHE_LOCK_DETAILS: 'CACHE_LOCK_DETAILS',
  RESET_LOCKS_FILTERS: 'RESET_LOCKS_FILTERS',
  RESET_LOCKS_DATA: 'RESET_LOCKS_DATA',
  RESET_LOCKS_PAGINATION_DATA: 'RESET_LOCKS_PAGINATION_DATA',
  UPDATE_LOCK: 'UPDATE_LOCK',
  SAVE_OR_UPDATE_LOCK: 'SAVE_OR_UPDATE_LOCK',
  SAVE_SHARED_SMART_LOCKS: 'SAVE_SHARED_SMART_LOCKS',
  APPEND_SHARED_SMART_LOCKS: 'APPEND_SHARED_SMART_LOCKS',
  SAVE_SHARED_SMART_LOCKS_PAGINATION_DATA: 'SAVE_SHARED_SMART_LOCKS_PAGINATION_DATA',
  RESET_SHARED_SMART_LOCKS_DATA: 'RESET_SHARED_SMART_LOCKS_DATA',
  SAVE_REMOTE_OPEN_LOCK: 'SAVE_REMOTE_OPEN_LOCK',
  SAVE_REMOTE_OPEN_LOCKS: 'SAVE_REMOTE_OPEN_LOCKS',
  SET_LOCK_ORDER: 'SET_LOCK_ORDER',
  SAVE_ALL_LOCKS: 'SAVE_ALL_LOCKS',
};

import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { CHAINELS_BASE_URL, GREEN, OCTORATE_AUTH_VARIABLES, RED, SUBSCRIPTION_TYPES } from '../../../_config/consts';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as UserActions from '../../../redux/actions/user.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import CheckOutlineIconCustom from '../../CustomIcons/CheckOutlineIconCustom.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import ChainelsLoginForm from '../../forms/Integrations/ChainelsLoginForm.jsx';
import { getChainelsBaeUrl, localizeHelpCenterLink } from '../../../_config/utils.js';


@connect(state => ({ language: state.settings.language }))
class OctorateSettingsOperationalSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isActive: null,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const isActive = dispatch(UserActions.userHasIntegrationActive(SUBSCRIPTION_TYPES.OCTORATE_INTEGRATION));
    this.setState({ isActive });
  }

  async onOctorateLogin() {
    const plantName = window && window.location && window.location.hostname && window.location.hostname.replace('.jago.cloud', '');
    const redirectUrl = `https://api-developers${_.includes(plantName, 'staging') || _.includes(plantName, 'localhost')?'.staging':''}.jago.cloud/api/v2/integrations/octorate/callback`;
    const stateUrl = `${window.location.protocol}//${window.location.host}/octorateActivation`
    const { client_id, base_url} = OCTORATE_AUTH_VARIABLES;
    window.location.replace(`${base_url}/octobook/identity/oauth.xhtml?client_id=${client_id}&redirect_uri=${encodeURIComponent(redirectUrl)}&state=${encodeURIComponent(stateUrl)}`);
  }

  onDeactivateIntegrationRequest() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="disableIntegrationConfirm" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeactivateIntegration(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeactivateIntegration() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const integrationDTO = dispatch(UserActions.getActiveIntegration(SUBSCRIPTION_TYPES.OCTORATE_INTEGRATION));
      if (integrationDTO && integrationDTO.id) {
        await dispatch(UserActions.disableIntegration(integrationDTO.id));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(UserActions.fetchCurrentActiveIntegrations());
        this.setState({ isActive: false});
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedSuccess" /></h6>),
          },
        }));
      } else {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
          },
        }));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
        },
      }));
    }
  }

  onOpenActivationInfo() {
    const helpURL = localizeHelpCenterLink('activate-chainels-integration');
    window.open(helpURL);
  }

  render() {
    const { isActive } = this.state;
    const { dispatch } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25, paddingTop: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <img alt="chainels" src="/imgs/common/integrationLogos/octorate-logo.png" style={{ height: 50, alignSelf: 'center' }} />
        {isActive ? (
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <div style={{ width: '70%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckOutlineIconCustom style={{ color: GREEN, width: 30, marginRight: 10 }} />
              <h4 style={{ color: GREEN, fontWeight: 'bold', fontSize: 20 }}><Entity entity="integrationCorrectlyConfigured" /></h4>
            </div>
            <MDButton
              title={<Entity key={isActive} entity="disableIntegrationTitle" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={RED}
              containerStyle={{ margin: 0, marginTop: 10, width: '80%' }}
              onClick={() => this.onDeactivateIntegrationRequest()}
            />
          </div>
        ) : (
          <div style={{ width: '70%', margin: 'auto' }}>
          <h4 style={{ color: '#3f3f3f', textAlign: 'center', marginTop: 10, marginBottom: 30 }}><Entity entity="connectYoutAccount" /></h4>
          <h4 style={{ color: '#3f3f3f', textAlign: 'center', marginTop: 10, marginBottom: 30  }}>{<Entity entity="contactUsBeforeActivateOctorate" />}</h4>          
            <MDButton
              title={<Entity entity="login"/>}
              titleStyle={{ marginTop: 0, fontSize: 13, color: 'white' }}
              backgroundColor="#1c8591"
              containerStyle={{ margin: 0, marginTop: 10, marginBottom: 10 }}
              onClick={() => this.onOctorateLogin()}
            />
          </div>
        )}
         {/* <h5
          className="link-label"
          style={{ margin: 'auto', width: 'fit-content', marginTop: 20, fontSize: 16, textDecoration: 'underline' }}
          onClick={() => this.onOpenActivationInfo()}
        >
          <Entity entity="findOutMore" />
        </h5> */}
      </div>
    );
  }
}

export default OctorateSettingsOperationalSection;

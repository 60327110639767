import _ from 'lodash';
import { Observable } from 'rxjs';
import { from } from 'rxjs/observable/from';
import { of } from 'rxjs/observable/of';
import { mergeMap, concatMap, switchMap, map, delay, takeUntil, catchError, finalize, endWith } from 'rxjs/operators';
import {
  FETCH_LOCKER_LOCKS_BY_TAGS,
  CANCEL_FETCH_LOCKER_LOCKS_BY_TAGS,
  FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS,
  CANCEL_FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS,
} from '../redux/actions/actionTypes/locker';

import * as RestService from '../_config/rest';
import {
  setLocksFilteredByTagsEpic,
  setEntranceLocksFilteredByTagsEpic,
} from '../redux/actions/locker.actions';
import { rxFetch } from './utils';
import moment from 'moment';

export const fetchLockerLocksByTagsEpic = (action$, store) =>
  action$.ofType(FETCH_LOCKER_LOCKS_BY_TAGS)
    .debounceTime(500)
    .switchMap((action) => {
      const { lockTagMatchingMode, page, append, index } = action;
      let tags = action.tags;
      if (!_.isArray(tags)) {
        tags = [tags];
      }

      if (tags && tags.length) {
        const params = {
          tagIds: _.map(tags, tag => tag.id || tag),
          page,
          lockTagMatchingMode,
        };

        return rxFetch(RestService.fetchLocksByTags(params))
          .map((response) => {
            if (response.data && response.data.content) {
              const locks = response.data.content;
              const pagination = _.omit(response.data, 'content');
              return setLocksFilteredByTagsEpic(locks, index, pagination, append);
            }
            return setLocksFilteredByTagsEpic([], index);
          })
          .takeUntil(action$.ofType(CANCEL_FETCH_LOCKER_LOCKS_BY_TAGS))
          .catch(() => Observable.empty());
      }
      return Observable.of(setLocksFilteredByTagsEpic([], index));
    });

export const fetchLockerEntranceLocksByTagsEpic = (action$, store) =>
  action$.ofType(FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS)
    .debounceTime(500)
    .switchMap((action) => {
      const { lockTagMatchingMode, page, append, index } = action;
      let tags = action.tags;
      if (!_.isArray(tags)) {
        tags = [tags];
      }

      if (tags && tags.length) {
        const params = {
          tagIds: _.map(tags, tag => tag.id || tag),
          page,
          lockTagMatchingMode,
        };

        return rxFetch(RestService.fetchLocksByTags(params))
          .map((response) => {
            if (response.data && response.data.content) {
              const locks = response.data.content;
              const pagination = _.omit(response.data, 'content');
              return setEntranceLocksFilteredByTagsEpic(locks, index, pagination, append);
            }
            return setEntranceLocksFilteredByTagsEpic([], index);
          })
          .takeUntil(action$.ofType(CANCEL_FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS))
          .catch(() => Observable.empty());
      }
      return Observable.of(setEntranceLocksFilteredByTagsEpic([], index));
    });
/* eslint-disable max-len */
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { change } from 'redux-form';
import * as XLSX from 'xlsx';
import { EXPORT_FORMATS, GREEN, ORANGE, RED } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import { saveDataToLocalStorage } from '../../_config/utils';
import * as ModalActions from './modal.actions';
import {
  SAVE_LOCKERS,
  APPEND_LOCKERS,
  SET_LOCKERS_PAGINATION_DATA,
  RESET_LOCKERS_PAGINATION_DATA,
  SELECT_LOCKER,
  RESET_LOCKERS_FILTERS,
  SET_LOCKER_FILTER,
  UPDATE_LOCKER,
  SET_LOCKER_OPERATIONAL_MODE,
  SAVE_LOCKERS_LOCKS,
  FETCH_LOCKER_LOCKS_BY_TAGS,
  CANCEL_FETCH_LOCKER_LOCKS_BY_TAGS,
  SET_LOCKER_LOCKS_FILTERED_BY_TAGS,
  APPEND_LOCKER_LOCKS_FILTERED_BY_TAGS,
  SET_LOCKER_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
  FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS,
  CANCEL_FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS,
  SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS,
  APPEND_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS,
  SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
  SET_LOCKER_ORDER,
  SAVE_LOCKER_RESERVATIONS,
  APPEND_LOCKER_RESERVATIONS,
  SET_LOCKER_RESERVATIONS_PAGINATION_DATA,
  RESET_LOCKER_RESERVATIONS_PAGINATION_DATA,
  SELECT_LOCKER_RESERVATION,
  RESET_LOCKER_RESERVATIONS_FILTERS,
  SET_LOCKER_RESERVATION_FILTER,
  UPDATE_LOCKER_RESERVATION,
  SET_LOCKER_RESERVATIONS_OPERATIONAL_MODE,
} from './actionTypes/locker';


export function saveLockers(lockers) {
  return {
    type: SAVE_LOCKERS,
    lockers
  };
}

export function appendLockers(lockers) {
  return {
    type: APPEND_LOCKERS,
    lockers
  };
}

export function setLockersPaginationData(pagination) {
  return {
    type: SET_LOCKERS_PAGINATION_DATA,
    pagination,
  };
}

export function resetLockersPaginationData() {
  return {
    type: RESET_LOCKERS_PAGINATION_DATA,
  };
}

export function selectLocker(locker) {
  return {
    type: SELECT_LOCKER,
    locker,
  };
}

export function resetLockersFilters() {
  return { type: RESET_LOCKERS_FILTERS };
}

export function setOperationalMode(value) {
  return {
    type: SET_LOCKER_OPERATIONAL_MODE,
    value,
  };
}

export function setOperationalModeReservations(value) {
  return {
    type: SET_LOCKER_RESERVATIONS_OPERATIONAL_MODE,
    value,
  };
}

export function setFilter(name, value) {
  return {
    type: SET_LOCKER_FILTER,
    name,
    value,
  };
}

export function updateLockerOnState(locker) {
  return {
    type: UPDATE_LOCKER,
    locker,
  };
}

export function saveLocks(locks) {
  return {
    type: SAVE_LOCKERS_LOCKS,
    locks
  };
}

export function cancelFetchLocksByTags() {
  return {
    type: CANCEL_FETCH_LOCKER_LOCKS_BY_TAGS,
  };
}

export function fetchLocksByTags(tags, lockTagMatchingMode, page = 0, append = false, index = 0) {
  return {
    type: FETCH_LOCKER_LOCKS_BY_TAGS,
    tags,
    index,
    lockTagMatchingMode,
    page,
    append,
  };
}

export function setLocksFilteredByTags(locks, index = 0) {
  return {
    type: SET_LOCKER_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}

export function appendLocksFilteredByTags(locks, index = 0) {
  return {
    type: APPEND_LOCKER_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}

export function setLocksFilteredByTagsPaginationData(pagination, index) {
  return {
    type: SET_LOCKER_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
    index: index || 0,
    pagination,
  };
}

export function cancelFetchEntranceLocksByTags() {
  return {
    type: CANCEL_FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS,
  };
}

export function fetchEntranceLocksByTags(tags, lockTagMatchingMode, page = 0, append = false, index = 0) {
  return {
    type: FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS,
    tags,
    index,
    lockTagMatchingMode,
    page,
    append,
  };
}

export function setEntranceLocksFilteredByTags(locks, index = 0) {
  return {
    type: SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}

export function appendEntranceLocksFilteredByTags(locks, index = 0) {
  return {
    type: APPEND_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}

export function setEntranceLocksFilteredByTagsPaginationData(pagination, index) {
  return {
    type: SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
    index: index || 0,
    pagination,
  };
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_LOCKER_ORDER,
    orderBy,
    orderDir,
  };
}

export function saveLockerReservations(reservations) {
  return {
    type: SAVE_LOCKER_RESERVATIONS,
    reservations
  };
}

export function appendLockerReservations(reservations) {
  return {
    type: APPEND_LOCKER_RESERVATIONS,
    reservations
  };
}

export function setLockerReservationsPaginationData(pagination) {
  return {
    type: SET_LOCKER_RESERVATIONS_PAGINATION_DATA,
    pagination,
  };
}

export function resetLockerReservationsPaginationData() {
  return {
    type: RESET_LOCKER_RESERVATIONS_PAGINATION_DATA,
  };
}

export function selectLockerReservation(reservation) {
  return {
    type: SELECT_LOCKER_RESERVATION,
    reservation,
  };
}

export function resetLockerReservationsFilters() {
  return { type: RESET_LOCKER_RESERVATIONS_FILTERS };
}

export function setLockerReservationsFilter(name, value) {
  return {
    type: SET_LOCKER_RESERVATION_FILTER,
    name,
    value,
  };
}

export function updateLockerReservationOnState(reservation) {
  return {
    type: UPDATE_LOCKER_RESERVATION,
    reservation,
  };
}









export function fetchLockers(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = structuredClone(getState().lockers.data.filters);
      const orderBy = getState().lockers.data.sorting.orderBy;
      const orderDir = orderBy?getState().lockers.data.sorting.orderDir:undefined;
      const params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      const response = await RestService.fetchLockers(params);
      if (response.data && response.data.content) {
        const formattedLockers = _.map(response.data.content, locker => formatter.formatInputData(formatter.LOCKER, { ...locker }));
        if (append) {
          dispatch(appendLockers(formattedLockers));
        } else {
          dispatch(saveLockers(formattedLockers));
        }
        dispatch(setLockersPaginationData(_.omit(response.data, 'content')));
        return formattedLockers;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function editLocker(lockerId,data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.LOCKER, data);
      const response = await RestService.editLocker(lockerId,formattedData);
      if (response && response.data) {
        const formattedLocker = formatter.formatInputData(formatter.LOCKER, { ...response.data });
        dispatch(updateLockerOnState({
          ...formattedLocker
        }));
        return formattedLocker;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createLocker(data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.LOCKER, data);
      const response = await RestService.createLocker(formattedData);
      if (response && response.data) {
        const formattedLocker = formatter.formatInputData(formatter.LOCKER, { ...response.data });
        return formattedLocker;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function deleteLocker(lockerId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deleteLocker(lockerId);
      if (response && response.data) {
        const formattedLocker = formatter.formatInputData(formatter.LOCKER, { ...response.data });
        dispatch(updateLockerOnState({
          ...formattedLocker
        }));
        return formattedLocker;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function addLockToLocker(lockerId,lockId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.addLockToLocker(lockerId,lockId);
      if (response && response.data) {
        const formattedLocker = formatter.formatInputData(formatter.LOCKER, { ...response.data });
        dispatch(updateLockerOnState({
          ...formattedLocker
        }));
        return formattedLocker;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function removeLockFromLocker(lockerId,lockId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.removeLockFromLocker(lockerId,lockId);
      if (response && response.data) {
        const formattedLocker = formatter.formatInputData(formatter.LOCKER, { ...response.data });
        dispatch(updateLockerOnState({
          ...formattedLocker
        }));
        return formattedLocker;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchAllLocks() {
  return async (dispatch, getState) => {
    try {
      let pageCounter = 0;
      const pageSize = 100;
      let formattedLocks = []
      while(pageCounter<10) {
        const params = {
          page: pageCounter,
          pageSize
        };
        const response = await RestService.fetchLocks(params);
        if (response.data && response.data.content) {
          const formattedLocksTemp = _.map(response.data.content, lock => formatter.formatInputData(formatter.LOCK, { ...lock }));
          formattedLocks = [...formattedLocks,...formattedLocksTemp]
          if (response.data.last)
            break;
          pageCounter++;
        }
        else 
          break;
      }
      dispatch(saveLocks(formattedLocks));
      return formattedLocks;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function setLocksFilteredByTagsEpic(locks, index, pagination, append = false) {
  return (dispatch, getState) => {
    if (append) {
      dispatch(appendLocksFilteredByTags(locks, index));
    } else {
      dispatch(setLocksFilteredByTags(locks, index));
    }
    dispatch(setLocksFilteredByTagsPaginationData(pagination, index));
  };
}

export function setEntranceLocksFilteredByTagsEpic(locks, index, pagination, append = false) {
  return (dispatch, getState) => {
    if (append) {
      dispatch(appendEntranceLocksFilteredByTags(locks, index));
    } else {
      dispatch(setEntranceLocksFilteredByTags(locks, index));
    }
    dispatch(setEntranceLocksFilteredByTagsPaginationData(pagination, index));
  };
}

export function fetchLockerReservations(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = structuredClone(getState().lockers.reservations.filters);
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await RestService.fetchLockerReservations(params);
      if (response.data && response.data.content) {
        const formattedLockerReservations = 
          _.map(response.data.content, reservation => formatter.formatInputData(formatter.LOCKER_RESERVATION, { ...reservation }));
        if (append) {
          dispatch(appendLockerReservations(formattedLockerReservations));
        } else {
          dispatch(saveLockerReservations(formattedLockerReservations));
        }
        dispatch(setLockerReservationsPaginationData(_.omit(response.data, 'content')));
        return formattedLockerReservations;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function editLockerReservation(lockerId,lockerReservationId,data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.LOCKER_RESERVATION, data);
      const response = await RestService.editLockerReservation(lockerId,lockerReservationId,formattedData);
      if (response && response.data) {
        if (response.data.code)
          return {"ERROR":response.data.code}
        const formattedLockerReservation = formatter.formatInputData(formatter.LOCKER_RESERVATION, { ...response.data });
        dispatch(updateLockerReservationOnState({
          ...formattedLockerReservation
        }));
        return formattedLockerReservation;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createLockerReservation(lockerId,data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.LOCKER_RESERVATION, data);
      const response = await RestService.createLockerReservation(lockerId,formattedData);
      if (response && response.data) {
        if (response.data.code)
          return {"ERROR":response.data.code}
        const formattedLockerReservation = formatter.formatInputData(formatter.LOCKER_RESERVATION, { ...response.data });
        return formattedLockerReservation;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function deleteLockerReservationByManager(lockerId,lockerReservationId,notes) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deleteLockerReservationByManager(lockerId,lockerReservationId,{notes});
      if (response && response.data) {
        const formattedLockerReservation = formatter.formatInputData(formatter.LOCKER_RESERVATION, { ...response.data });
        dispatch(updateLockerReservationOnState({
          ...formattedLockerReservation
        }));
        return formattedLockerReservation;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}
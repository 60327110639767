import Immutable from 'seamless-immutable';

import {
  SET_SETTING_OPERATIONAL_MODE,
  SELECT_SETTING,
  SAVE_SETTING,
  SAVE_LOGO,
  SAVE_LANGUAGE,
  SAVE_SAML_REQUEST_CONFIG,
  SAVE_COMPANY_SUBCOMPANIES,
  SAVE_WHATS_NEW_CONTENT,
  SAVE_TROUBLESHOOTING_CONTENT,
  SAVE_WHATS_NEW_HAS_UPDATE
} from '../actions/actionTypes/setting';

import { LANGUAGES } from '../../_config/consts';
import { detectBrowserLanguage } from '../../_config/utils';

const initialState = Immutable({
  items: {
    nexudus: {
      name: 'nexudus',
      iconOnly: true,
    },
    cobot: {
      name: 'cobot',
      iconOnly: true,
    },
    officeRnd: {
      name: 'officeRnd',
      iconOnly: true,
    },
    eagleEye: {
      name: 'eagleEye',
      iconOnly: true,
    },
    chainels: {
      name: 'chainels',
      iconOnly: true,
    },
    andcards: {
      name: 'andcards',
      iconOnly: true,
    },
    office365: {
      name: 'office365',
      iconOnly: true,
    },
    cloudbeds: {
      name: 'cloudbeds',
      iconOnly: true,
    },
    mews: {
      name: 'mews',
      iconOnly: true,
    },
    optix: {
      name: 'optix',
      iconOnly: true,
    },
    tenup: {
      name: 'tenup',
      iconOnly: true,
    },
    zapfloor: {
      name: 'zapfloor',
      iconOnly: true,
    },
    octorate: {
      name: 'octorate',
      iconOnly: true,
    },
    resharmonics: {
      name: 'resharmonics',
      iconOnly: true,
    },
    license: {
      name: 'license',
      data: {},
    },
    wallet: {
      name: 'wallet',
    },
    systemSettings: {
      name: 'systemSettings'
    },
    companyNotifications: {
      name: 'companyNotifications'
    },
    customFields: {
      name: 'customFields',
    },
    ticketCategories: {
      name: 'ticketCategories',
    },
    devicesDefaults: {
      name: 'devicesDefaults',
      data: {},
    },
    company: {
      name: 'company',
      data: {},
    },
    holidays: {
      name: 'holidaysSettings',
      data: {},
    },
    theme: {
      name: 'theme',
      data: {
        logo: '',
      },
    },
    privacyAndTerms: {
      name: 'privacy',
    },
    assistance: {
      name: 'assistance',
    },
  },
  language: detectBrowserLanguage(),
  isOperationalMode: false,
  selectedSetting: {},
  samlRequestConfig: {},
  subcompanies: [],
  whatsNew: {
    content: [],
    hasUpdate: false,
  },
  troubleshooting: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SETTING_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SELECT_SETTING:
      return state.set('selectedSetting', action.setting);
    case SAVE_SETTING:
      return state.setIn(['items', action.setting, 'data'], action.data);
    case SAVE_LOGO: 
      return state.setIn(['items', 'theme', 'data', 'logo'], action.logo);
    case SAVE_LANGUAGE:
      return state.set('language', action.language);
    case SAVE_SAML_REQUEST_CONFIG:
      return state.set('samlRequestConfig', action.samlRequestConfig);
    case SAVE_COMPANY_SUBCOMPANIES:
      return state.set('subcompanies', action.subcompanies);
    case SAVE_WHATS_NEW_CONTENT:
      return state.setIn(['whatsNew', 'content'], action.content);
    case SAVE_WHATS_NEW_HAS_UPDATE:
      return state.setIn(['whatsNew', 'hasUpdate'], action.hasUpdate);
    case SAVE_TROUBLESHOOTING_CONTENT:
      return state.set('troubleshooting', action.content);
    default:
      return state;
  }
}

import Immutable from 'seamless-immutable';
import _ from 'lodash';
import moment from 'moment';

import {
  SAVE_AREAS,
  APPEND_AREAS,
  SET_AREAS_PAGINATION_DATA,
  RESET_AREAS_PAGINATION_DATA,
  SELECT_AREA,
  RESET_AREAS_FILTERS,
  SET_AREA_FILTER,
  UPDATE_AREA,
  SET_AREA_OPERATIONAL_MODE,
  SAVE_LOCKS,
  SET_AREA_LOCKS_FILTERED_BY_TAGS,
  APPEND_AREA_LOCKS_FILTERED_BY_TAGS,
  SET_AREA_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
  SAVE_AREA_OCCUPANCY,
  RESET_AREAS_OCCUPANCY,
  SET_AREAS_OCCUPANCY_LOADING,
  SAVE_AREA_OCCUPANCY_HOURLY_DATA,
  SAVE_AREA_OCCUPANCY_HOURLY_AREA_SELECTED,
  SET_AREA_OCCUPANCY_HOURLY_FILTER,
  RESET_AREA_OCCUPANCY_HOURLY_FILTERS,
  SAVE_AREA_RULES,
  UPDATE_AREA_RULE,
  SET_AREA_ORDER,
} from '../actions/actionTypes/area';

const initialState = Immutable({
  data: {
    content: [],
    filters: {},
    pagination: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    },
    lastUpdate: 0,
  },
  locks: {
    content: [],
  },
  occupancy: {
    areas: [],
    filters: {},
    loading: false,
  },
  occupancyHourly: {
    areaSelected: {},
    hours: [],
    lastUpdate: 0,
    filters: {},
  },
  selectedArea: {},
  areaRules: [],
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_AREAS:
      return state.setIn(['data', 'content'], action.areas).setIn(['data', 'lastUpdate'], moment().valueOf());
    case APPEND_AREAS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.areas]).setIn(['data', 'lastUpdate'], moment().valueOf());
    case SET_AREAS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case RESET_AREAS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    case SELECT_AREA:
      return state.set('selectedArea', action.area);
    case RESET_AREAS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case SET_AREA_FILTER:
      return state.setIn(['data', 'filters', action.name], action.value);
    case UPDATE_AREA: {
      const index = _.findIndex(state.data.content, data => data.id === action.area.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.area);
      }
      return state;
    }
    case SET_AREA_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SAVE_LOCKS:
      return state.setIn(['locks', 'content'], action.locks);
    case SET_AREA_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedArea', 'locksFilteredByTags', action.index, 'data'], action.locks);
    case APPEND_AREA_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedArea', 'locksFilteredByTags', action.index, 'data'],
        [...state.selectedArea.locksFilteredByTags[action.index].data, ...action.locks]);
    case SET_AREA_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA:
      return state.setIn(['selectedArea', 'locksFilteredByTags', action.index, 'pagination'], action.pagination);
    case SAVE_AREA_OCCUPANCY:
      return state.setIn(['occupancy', 'areas'],[...state.occupancy.areas, action.area])
    case RESET_AREAS_OCCUPANCY:
      return state.setIn(['occupancy', 'areas'], []);
    case SET_AREAS_OCCUPANCY_LOADING:
      return state.setIn(['occupancy', 'loading'], action.loading);
    case SET_AREA_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case SAVE_AREA_OCCUPANCY_HOURLY_DATA:
      return state.setIn(['occupancyHourly', 'hours'], action.hours).setIn(['occupancyHourly', 'lastUpdate'], action.lastUpdate);
    case SAVE_AREA_OCCUPANCY_HOURLY_AREA_SELECTED:
      return state.setIn(['occupancyHourly', 'areaSelected'], action.area);
    case SET_AREA_OCCUPANCY_HOURLY_FILTER:
      return state.setIn(['occupancyHourly', 'filters', action.name], action.value);
    case SAVE_AREA_RULES:
      return state.setIn(['areaRules'], action.areaRules);
    case UPDATE_AREA_RULE: {
      const index = _.findIndex(state.areaRules, data => data.id === action.areaRule.id);
      if (index !== -1) {
        return state.setIn(['areaRules', index], action.areaRule);
      }
      return state;
    }
    case RESET_AREA_OCCUPANCY_HOURLY_FILTERS:
      return state.setIn(['occupancyHourly', 'filters'], {});
    default:
      return state;
  }
}

import React, { Component } from 'react';
import _ from 'lodash';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { IconButton } from '@material-ui/core';

const FormFlatContainer = ({ title, icon, iconButtonAction, iconButtonIcon, subtitle, children, iconButtonActionStyle, headerStyle, titleStyle, containerStyle, titleContainerStyle, subtitleStyle }) =>
  (
  <div style={{ marginTop: 20, marginLeft: 0, padding: 15, borderRadius: 10, backgroundColor: '#f6f6f6', ...containerStyle }}>
    <div style={{ marginBottom: 10, ...headerStyle }}>
      {title && _.isString(title) &&
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, ...titleContainerStyle }}>
        {icon}
        <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginLeft: 5, fontSize: 20, ...titleStyle }}>
          <Entity entity={title} />
        </h4>
        <div style={{ margin: -10, marginTop: -5, marginLeft: 0, ...iconButtonActionStyle }}>
          {iconButtonAction && iconButtonIcon ? (
            <IconButton onClick={() => iconButtonAction()} style={{ marginBottom: 4, ...iconButtonActionStyle }}>
              {iconButtonIcon}
            </IconButton>
          ) : null}
        </div>
      </div>
      }
      {subtitle && _.isString(subtitle) &&
      <h4 style={{ fontSize: 17, lineHeight: 1.4, marginTop: 0, marginLeft: 5, color: '#3f3f3f', ...subtitleStyle }}>
        <Entity entity={subtitle} />
      </h4>
      }
    </div>
    {children}
  </div>
    );

export default FormFlatContainer;

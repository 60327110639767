import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';

import Card from '@material-ui/core/Card';
import { getSettingIcon } from '../../_config/utils.js';

export default class SettingDetailsRow extends React.Component {
  render() {
    const { setting, isSelected, onClick } = this.props;
    return (
      <Card
        className={`card card-rounded ${isSelected && 'card-selected'}`}
        style={{ display: 'flex', justifyContent: 'center', height: 100, borderRadius: 10, padding: 20, width: 370 }}
        onClick={() => onClick()}
        elevation={2}
      >
        <div className="card-body" style={{ flexDirection: 'column', alignSelf: 'center' }}>
          {getSettingIcon(setting.name)}
          {!setting.iconOnly ? (
            <div className="card-inner-body" style={{ paddingLeft: 0 }}>
              <h4 className="card-title" style={{ margin: 0, marginTop: 8, paddingLeft: 0 }}>{<Entity entity="settingTitle" data={{ name: setting.name }} />}</h4>
            </div>
          ) : null}
        </div>
      </Card>
    );
  }
}

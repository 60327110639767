import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_LOCKS,
  APPEND_LOCKS,
  SAVE_LOCK,
  SELECT_LOCK,
  UPDATE_SELECTED_LOCK_TAG,
  SET_LOCKS_PAGINATION_DATA,
  SET_LOCK_OPERATIONAL_MODE,
  SET_LOCK_FILTER,
  CACHE_LOCK_DETAILS,
  RESET_LOCKS_FILTERS,
  RESET_LOCKS_DATA,
  RESET_LOCKS_PAGINATION_DATA,
  UPDATE_LOCK,
  SAVE_SHARED_SMART_LOCKS,
  APPEND_SHARED_SMART_LOCKS,
  SAVE_SHARED_SMART_LOCKS_PAGINATION_DATA,
  RESET_SHARED_SMART_LOCKS_DATA,
  SAVE_OR_UPDATE_LOCK,
  SAVE_REMOTE_OPEN_LOCK,
  SAVE_REMOTE_OPEN_LOCKS,
  SET_LOCK_ORDER,
  SAVE_ALL_LOCKS,
} from '../actions/actionTypes/lock';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    }
  },
  shared: {
    content: [],
    pagination: {},
    filters: {},
  },
  remoteOpenSmartLocks: [],
  selectedLock: {},
  isOperationalMode: false,
  cachedLocksMap: {},
  allLocks: {
    content: [],
  }
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_LOCKS:
      return state.setIn(['data', 'content'], action.locks);
    case APPEND_LOCKS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.locks]);
    case SAVE_LOCK:
      return state.setIn(['data', 'content'], [...state.data.content, action.lock]);
    case SET_LOCKS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case CACHE_LOCK_DETAILS:
      return state.setIn(['cachedLocksMap', action.lockId], action.lockDetails);
    case SELECT_LOCK:
      return state.set('selectedLock', action.lock);
    case UPDATE_SELECTED_LOCK_TAG: {
      const index = _.findIndex(state.selectedLock.tags, { id: action.tag.id });
      return state.setIn(['selectedLock', 'tags', index], action.tag);
    }
    case SAVE_SHARED_SMART_LOCKS: {
      return state.setIn(['shared', 'content'], action.locks);
    }
    case APPEND_SHARED_SMART_LOCKS: {
      return state.setIn(['shared', 'content'], [...state.shared.content, ...action.locks]);
    }
    case SAVE_SHARED_SMART_LOCKS_PAGINATION_DATA: {
      return state.setIn(['shared', 'pagination'], action.pagination);
    }
    case RESET_SHARED_SMART_LOCKS_DATA: {
      return state.set('shared', {});
    }
    case SAVE_REMOTE_OPEN_LOCK: {
      const indexEnabled = _.findIndex(state.remoteOpenSmartLocks, { serialNumber: action.lock.serialNumber });
      if (indexEnabled >= 0) {
        if (!state.remoteOpenSmartLocks[indexEnabled].enabled) {
          return state.setIn(['remoteOpenSmartLocks', indexEnabled], action.lock);
        }
        return state;
      }
      return state.set('remoteOpenSmartLocks', [...state.remoteOpenSmartLocks, action.lock]);
    }
    case SAVE_REMOTE_OPEN_LOCKS: {
      return state.set('remoteOpenSmartLocks', action.locks);
    }
    case SET_LOCK_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_LOCK_FILTER:
      return state.setIn(['data', 'filters', action.name], action.value);
    case UPDATE_LOCK: {
      const index = _.findIndex(state.data.content, data => data.id === action.lock.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.lock);
      }
      return state;
    }
    case SAVE_OR_UPDATE_LOCK: {
      const index = _.findIndex(state.data.content, data => data.id === action.lock.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.lock);
      } else {
        return state.setIn(['data', 'content'], [...state.data.content, action.lock]);
      }
    }
    case RESET_LOCKS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case SET_LOCK_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case RESET_LOCKS_DATA:
      return state.set('data', initialState.data);
    case RESET_LOCKS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    case SAVE_ALL_LOCKS:
      return state.setIn(['allLocks', 'content'], action.locks);
    default:
      return state;
  }
}

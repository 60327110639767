import { resourceActions as ResourceActions } from '@bottega52/bookey-redux-module';
import { IconButton } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import CloseIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import * as GuestActions from '../../../redux/actions/guest.actions';
import { BLUE, RESOURCE_CAPACITY_SLOTS } from '../../../_config/consts';
import MDButton from '../../MDButton/MDButton.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import { localizeHelpCenterLink } from '../../../_config/utils';
import CustomField from '../Fields/CustomField.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';

let filterTimeout;
@connect(state => ({}))
@reduxForm({ form: 'CapacitySearchBar' })
class CapacitySearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  onSearchReset() {
    const { dispatch, onSearchReset } = this.props;
    dispatch(reset('CapacitySearchBar'));
    onSearchReset();
  }

  openReservationHelpCenter() {
    const formattedURL = localizeHelpCenterLink('bookey-reservations-check-in-and-access-credentials');
    window.open(formattedURL);
  }

  render() {
    const { themeName, onSelectResourceType, resourceTypeOptions, disableComponents, onSelectReservationsDate, onChangeSlotSize, onNewReservation } = this.props;
    const themeClass = `list-view-header bg-${themeName}-theme`;
    const {  } = this.state;
    return (
      <div className={themeClass} style={{width:'100%', pointerEvents: disableComponents? 'none':'unset', opacity: disableComponents? 0.7:1, }}>
      <div style={{ minHeight: 70, padding: 0, display: 'flex', flexDirection: 'row', marginLeft: 0, alignItems: 'center' }}>
        <div style={{ marginTop: 15, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Field
            id="reservationsDate"
            name="reservationsDate"
            showClearDate
            placeholder={'--/--/----'}
            dateContainer={{ marginTop: -7, marginLeft: 10, width: 200 }}
            showDefaultInputIcon
            onHandleChange={value => onSelectReservationsDate(value)}
            component={DatePickerField}
          />
          <Field
            id="slotSize"
            name="slotSize"
            title=""
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            containerStyle={{marginBottom:-15, marginLeft: 20, width: 120 }}
            onHandleChange={(values) => onChangeSlotSize(values)}
          >
            {_.map(RESOURCE_CAPACITY_SLOTS, item =>
              <Entity
                key={item.value}
                componentClass={TranslatableOption}
                value={item.value}
                componentAttribute="text"
                entity={'resourceSlotDurations'}
                data={{ type: item.label }}
              />
            )}
          </Field>
          <Field
            id="selectResourceType"
            name={"type"}
            title={''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => onSelectResourceType(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12, marginTop: 3 }}
          >
            {resourceTypeOptions}
          </Field>
        </div>
        <MDButton
          style={{ marginTop: 5 }}
          containerStyle={{ margin: 0, marginLeft: 5, marginRight: 10 }}
          backgroundColor="#D32F2F"
          icon={<CloseIcon style={{ fontSize: 20 }} />}
          onClick={() => this.onSearchReset()}
        />
        <MDButton
          style={{ marginTop: 5 }}
          containerStyle={{ margin: 0, marginLeft: 15, marginRight: 10 }}
          backgroundColor={BLUE}
          icon={<CalendarIcon style={{ fontSize: 20, marginLeft: 15 }} />}
          title={<Entity entity="newReservation" />}
          onClick={() => onNewReservation()}
        />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <IconButton onClick={() => this.openReservationHelpCenter()} style={{ marginLeft: 10, marginTop: 3 }}>
            <InfoIcon style={{ fontSize: 30, color: 'white' }} />
          </IconButton>
        </div>
      </div>
      </div>
    );
  }
} 

export default CapacitySearchBar;

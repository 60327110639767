import React, { Component } from 'react';
import EventIcon from '@material-ui/icons/Event';
import { Icon, FormGroup, ControlLabel } from '@sketchpixy/rubix';
import moment from 'moment';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import DatePickerView from './DatePickerView.jsx';

export default class DatePickerField extends Component {
  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }
  render() {
    const { input, meta, hideIcon, dateContainer, containerStyle, isOutsideRange, title, titleStyle, subtitle, subtitleStyle, id, mandatory, hideErrorMessage } = this.props;

    let validationState;
    if (meta.touched) {
      if (meta.error) {
        validationState = 'error';
      } else {
        validationState = 'success';
      }
    }

    return (
      <div style={{ ...containerStyle }}>
        {title &&
          <div style={{ marginBottom: 11, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {hideIcon ? null : <EventIcon style={{ color: '#3f3f3f', marginRight: 5, fontSize: 22 }} />}
            <h4 style={{ margin: 0, color: '#3f3f3f', fontSize: 16, fontWeight: 'bold', ...titleStyle }}>{title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</h4>
          </div>
        }
        {subtitle ? <h4 style={{ margin: 0, color: '#3f3f3f', marginBottom: 11, fontSize: 14, ...subtitleStyle }}>{subtitle}</h4> : null}
        <div style={{ marginRight: 2, ...dateContainer }}>
          <DatePickerView
            isOutsideRange={isOutsideRange ? isOutsideRange : () => false}
            {...input}
            {...this.props}
            date={input.value ? moment(input.value) : null}
            onChange={value => this.onHandleChange(value)}
          />
          <ControlLabel> {meta.error && !hideErrorMessage && <h6 style={{ color: 'red', marginTop: 20 }}><Entity key={meta.error} entity={meta.error} /></h6>} </ControlLabel>
        </div>
      </div>
    );
  }
}


/* eslint-disable no-await-in-loop */
import React from 'react';
import _ from 'lodash';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as LocksActions from './lock.actions';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import {
  SAVE_ADMIN_EVENTS,
  APPEND_ADMIN_EVENTS,
  SAVE_ADMIN_EVENTS_PAGINATION,
  RESET_ADMIN_EVENTS,
  SET_ADMIN_EVENT_FILTERS,
  RESET_ADMIN_EVENT_FILTERS,
  SAVE_SMART_LOCKS_EVENTS,
  APPEND_SMART_LOCKS_EVENTS,
  SAVE_SMART_LOCKS_EVENTS_PAGINATION,
  RESET_SMART_LOCKS_EVENTS,
  SET_SMART_LOCKS_EVENT_FILTER,
  RESET_SMART_LOCKS_EVENT_FILTERS,
  CLEAR_SMART_LOCKS_EVENT_FILTER,
  CLEAR_ADMIN_EVENT_FILTER,
  SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS,
  RESET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTERS,
  SET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER,
  RESET_SUBCOMPANIES_SMART_LOCKS_EVENTS,
  CLEAR_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER,
  APPEND_SUBCOMPANIES_SMART_LOCKS_EVENTS,
} from './actionTypes/logEvents';
import * as UtilsActions from './utils.actions';
import * as ModalActions from './modal.actions';
import * as EventsAPI from '../../_config/eventsAPI';
import { BACKGROUND_TASK_TYPES, DOOR_LOCK_EVENTS, DOOR_LOCK_EVENTS_MAP, EXPORT_FORMATS, ADMIN_EVENTS_MAP } from '../../_config/consts';
import { getEventTitleOutcome, getEventDescriptionFromOutcome, getEventTitleFromOutcome, splitFieldsFromOutcome, getEventTranslatedOperationSuccessFromSplittedOutcome, getEventTranslatedFailureReasonFromSplittedOutcome, getEventTranslatedOperationFailedFromSplittedOutcome, isSuccessEventFromSplittedOutcome } from '../../_config/utils';
import { SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS_PAGINATION } from './actionTypes/logEvents';

export function saveAdminEvents(events) {
  return {
    type: SAVE_ADMIN_EVENTS,
    events,
  };
}

export function appendAdminEvents(events) {
  return {
    type: APPEND_ADMIN_EVENTS,
    events,
  };
}

export function saveAdminEventsPagination(pagination) {
  return {
    type: SAVE_ADMIN_EVENTS_PAGINATION,
    pagination,
  };
}

export function resetAdminEventsData() {
  return { type: RESET_ADMIN_EVENTS };
}

export function setAdminEventsFilter(field, value) {
  return {
    type: SET_ADMIN_EVENT_FILTERS,
    field,
    value,
  };
}

export function resetAdminEventsFilters() {
  return { type: RESET_ADMIN_EVENT_FILTERS };
}

export function saveSmartLocksEvents(events) {
  return {
    type: SAVE_SMART_LOCKS_EVENTS,
    events,
  };
}

export function appendSmartLocksEvents(events) {
  return {
    type: APPEND_SMART_LOCKS_EVENTS,
    events,
  };
}

export function clearSmartLockEventsFilter(field) {
  return {
    type: CLEAR_SMART_LOCKS_EVENT_FILTER,
    field,
  };
}

export function clearAdminEventsFilter(field) {
  return {
    type: CLEAR_ADMIN_EVENT_FILTER,
    field,
  };
}
export function saveSmartLocksEventsPagination(pagination) {
  return {
    type: SAVE_SMART_LOCKS_EVENTS_PAGINATION,
    pagination,
  };
}

export function resetSmartLocksEventsData() {
  return { type: RESET_SMART_LOCKS_EVENTS };
}

export function setSmartLocksEventsFilter(field, value) {
  return {
    type: SET_SMART_LOCKS_EVENT_FILTER,
    field,
    value,
  };
}

export function resetSmartLocksEventsFilters() {
  return { type: RESET_SMART_LOCKS_EVENT_FILTERS };
}

export function saveSubcompaniesSmartLocksEvents(events) {
  return {
    type: SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS,
    events,
  };
}

export function saveSubcompaniesSmartLocksEventsPagination(pagination) {
  return {
    type: SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS_PAGINATION,
    pagination,
  };
}

export function appendSubcompaniesSmartLocksEvents(events) {
  return {
    type: APPEND_SUBCOMPANIES_SMART_LOCKS_EVENTS,
    events,
  };
}

export function clearSubcompaniesSmartLockEventsFilter(field) {
  return {
    type: CLEAR_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER,
    field,
  };
}

export function resetSubcompaniesSmartLocksEventsData() {
  return { type: RESET_SUBCOMPANIES_SMART_LOCKS_EVENTS };
}

export function setSubcompaniesSmartLocksEventsFilter(field, value) {
  return {
    type: SET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER,
    field,
    value,
  };
}

export function resetSubcompaniesSmartLocksEventsFilters() {
  return { type: RESET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTERS };
}

export function fetchAdminEvents(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().logEvents.adminEvents.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await EventsAPI.fetchAdminEvents(params);
      if (response.data && response.data.content) {
        dispatch(saveAdminEvents(response.data.content));
        dispatch(saveAdminEventsPagination(_.omit(response.data, 'content')));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchAndAppendAdminEvents(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().logEvents.adminEvents.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await EventsAPI.fetchAdminEvents(params);
      if (response.data && response.data.content) {
        dispatch(appendAdminEvents(response.data.content));
        dispatch(saveAdminEventsPagination(_.omit(response.data, 'content')));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchSmartLocksEvents(page = 0, pageSize = 200, append = false) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().logEvents.smartLocksEvents.filters;
      const params = {
        page,
        pageSize,
        ...filters,
        //toDate: moment().endOf('month').valueOf(),
      };
      const response = await EventsAPI.fetchSmartLockEvents(params);
      if (response.data && response.data.content) {
        const events = [];
        for (const event of response.data.content) {
          let eventToSave = event;
          if (event && event.data && !event.data.lock && !event.data.actor) {
            eventToSave = {
              ..._.omit(event, 'data'),
            };
          }
          events.push(eventToSave);
        }
        if (append) {
          dispatch(appendSmartLocksEvents(response.data.content));
        } else {
          dispatch(saveSmartLocksEvents(events));
        }
        dispatch(saveSmartLocksEventsPagination(_.omit(response.data, 'content')));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSubcompaniesSmartLocksEvents(page = 0, pageSize = 50, append = false) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().logEvents.subcompaniesSmartLocksEvents.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await EventsAPI.fetchSubcompaniesSmartLockEvents(params);
      if (response.data && response.data.content) {
        const events = [];
        for (const event of response.data.content) {
          let eventToSave = event;
          if (event && event.data && !event.data.lock && !event.data.actor) {
            eventToSave = {
              ..._.omit(event, 'data'),
            };
          }
          events.push(eventToSave);
        }
        if (append) {
          dispatch(appendSubcompaniesSmartLocksEvents(response.data.content));
        } else {
          dispatch(saveSubcompaniesSmartLocksEvents(events));
        }
        dispatch(saveSubcompaniesSmartLocksEventsPagination(_.omit(response.data, 'content')));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendSmartLocksEvents(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().logEvents.smartLocksEvents.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await EventsAPI.fetchSmartLockEvents(params);
      if (response.data && response.data.content) {
        dispatch(appendSmartLocksEvents(response.data.content));
        dispatch(saveSmartLocksEventsPagination(_.omit(response.data, 'content')));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function exportAdminEvents(fileName = 'export', format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    try {
      dispatch(UtilsActions.addBackgroundTaskSingleton({
        id: taskId,
        type: BACKGROUND_TASK_TYPES.DOWNLOAD_SMARTLOCKS_EVENTS,
        title: 'exportingAdminEvents',
        cancelCallback: () => {
          const cancelToken = EventsAPI.getAdminEventsCancelToken();
          if (cancelToken) cancelToken.cancel();
        },
      }));
      const filters = getState().logEvents.adminEvents.filters;
      let params = {
        page: 0,
        pageSize: 100,
        ...filters,
      };
      let adminEventsResponse = await EventsAPI.fetchAdminEvents(params);
      if (adminEventsResponse && adminEventsResponse.data.content && !_.isEmpty(adminEventsResponse.data.content)) {
        const adminEvents = [...adminEventsResponse.data.content];
        while (!adminEventsResponse.data.last) {
          params = {
            ...params,
            page: adminEventsResponse.data.number + 1,
          };
          adminEventsResponse = await EventsAPI.fetchAdminEvents(params);
          adminEvents.push(...adminEventsResponse.data.content);
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        const exportData = [];
        _.each(adminEvents, (log) => {
          const eventInfo = ADMIN_EVENTS_MAP[log.eventType];
          if (eventInfo) {
            const type = L20NContext.getSync(eventInfo.title);
            const date = moment(log.timestamp).format('llll');
            
            let entity = log && log.data && log.data.entity ? `${log.data.entity.name || log.data.entity.deviceId || log.data.entity.entityId}` : '';
            entity = log && log.data && log.data.entity && log.data.entity.firstname ? `${log.data.entity.firstname} ${log.data.entity.lastname} - ${log.data.entity.email}` : entity;
            
            const actor = log && log.data && log.data.actor && log.actorId ? `${log.data.actor.firstname} ${log.data.actor.lastname} - ${log.data.actor.email}` : '---';

            const duration = actor && actor.impersonateDuration ? actor.impersonateDuration : 30;

            const deviceType = log && log.data && log.data.entity && log.data.entity.deviceType ? log.data.entity.deviceType : '';
            
            let description = L20NContext.getSync(eventInfo.body, { entity, actor, duration, deviceType });
            
            if (log && log.data && !log.data.entity && !log.data.actor) {
              description = L20NContext.getSync('adminEventRowAnonymizedDescription');
            }
            exportData.push({ Event: type, Actor: actor, Entity: entity, Description: description, Date: date });
          }
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = `.${format.toLowerCase()}`;
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { events: ws }, SheetNames: ['events'] };
        const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        return adminEvents;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function exportSmartLocksEvents(fileName = 'export', format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    try {
      dispatch(UtilsActions.addBackgroundTaskSingleton({
        id: taskId,
        type: BACKGROUND_TASK_TYPES.DOWNLOAD_SMARTLOCKS_EVENTS,
        title: 'exportingSmartLocksEvents',
        cancelCallback: () => {
          const cancelToken = EventsAPI.getSmartLocksEventsCancelToken();
          if (cancelToken) cancelToken.cancel();
        },
      }));
      const filters = getState().logEvents.smartLocksEvents.filters;
      let params = {
        page: 0,
        pageSize: 1000,
        ...filters,
      };
      let smartLockEventsResponse = await EventsAPI.fetchSmartLockEvents(params);
      if (smartLockEventsResponse && smartLockEventsResponse.data.content && !_.isEmpty(smartLockEventsResponse.data.content)) {
        const smartLockEvents = [...smartLockEventsResponse.data.content];
        while (!smartLockEventsResponse.data.last) {
          params = {
            ...params,
            page: smartLockEventsResponse.data.number + 1,
          };
          smartLockEventsResponse = await EventsAPI.fetchSmartLockEvents(params);
          smartLockEvents.push(...smartLockEventsResponse.data.content);
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        dispatch(exportEventsToFile(smartLockEvents, fileName, format));
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function exportSubCompanySmartLocksEvents(fileName = 'export', format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    try {
      dispatch(UtilsActions.addBackgroundTaskSingleton({
        id: taskId,
        type: BACKGROUND_TASK_TYPES.DOWNLOAD_SMARTLOCKS_EVENTS,
        title: 'exportingSmartLocksEvents',
        cancelCallback: () => {
          const cancelToken = EventsAPI.getSmartLocksEventsCancelToken();
          if (cancelToken) cancelToken.cancel();
        },
      }));
      const filters = getState().logEvents.subcompaniesSmartLocksEvents.filters;
      let params = {
        page: 0,
        pageSize: 1000,
        ...filters,
      };
      let smartLockEventsResponse = await EventsAPI.fetchSubcompaniesSmartLockEvents(params);
      if (smartLockEventsResponse && smartLockEventsResponse.data.content && !_.isEmpty(smartLockEventsResponse.data.content)) {
        const smartLockEvents = [...smartLockEventsResponse.data.content];
        while (!smartLockEventsResponse.data.last) {
          params = {
            ...params,
            page: smartLockEventsResponse.data.number + 1,
          };
          smartLockEventsResponse = await EventsAPI.fetchSubcompaniesSmartLockEvents(params);
          smartLockEvents.push(...smartLockEventsResponse.data.content);
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        dispatch(exportEventsToFile(smartLockEvents, fileName, format));
        return smartLockEvents;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function exportEventsToFile(eventsData, fileName = 'export', format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    try {
      const exportData = [];
      _.each(eventsData, (log) => {
        const date = moment(log.timestamp).toLocaleString();
        let lock = log && log.data && log.data.lock && log.lockId ? `${log.data.lock.name} (${log.data.lock.serialNumber})` : '';
        lock = log.eventType === 'JAGO_7' ? log.data.lock.name : lock; // handle groups
        const isInvitationEvent = log && !log.actorId && log.data && log.data.actor && log.data.actor.invitationCode;
        let actor = log && log.data && log.data.actor && log.actorId ? `${log.data.actor.firstname} ${log.data.actor.lastname} - ${log.data.actor.email}` : '';
        actor = isInvitationEvent ? L20NContext.getSync('invitationCodeLog', { code: log.data.actor.invitationCode }) : actor;
        const deviceId = log && log.deviceId ? log.deviceId : '';

        let type = '';
        let description = '';
        let isPinEvent = false;
        if (log.eventOutcome) {
          const splittedOutcome = splitFieldsFromOutcome(log.eventOutcome);
          type = L20NContext.getSync(getEventTitleFromOutcome(log.eventOutcome), { deviceType: L20NContext.getSync(splittedOutcome.deviceType)});
          let translatedAction = L20NContext.getSync(getEventTranslatedOperationFailedFromSplittedOutcome(splittedOutcome));
          if (isSuccessEventFromSplittedOutcome(splittedOutcome)) {
            translatedAction = L20NContext.getSync(getEventTranslatedOperationSuccessFromSplittedOutcome(splittedOutcome));
          }
          isPinEvent = splittedOutcome && splittedOutcome.deviceId && splittedOutcome.deviceId === 'PIN';
          description = L20NContext.getSync(getEventDescriptionFromOutcome(log.eventOutcome), {
            lock,
            actor: isInvitationEvent ? L20NContext.getSync('withInvitationCodeLog', { code: log.data.actor.invitationCode }) : (actor || ''),
            deviceType: L20NContext.getSync(splittedOutcome.deviceType),
            action: translatedAction,
            reason: L20NContext.getSync(getEventTranslatedFailureReasonFromSplittedOutcome(splittedOutcome)),
          });
        } else {
          const eventKey = DOOR_LOCK_EVENTS[log.eventType];
          const eventStringInfo = DOOR_LOCK_EVENTS_MAP[eventKey];
          type = L20NContext.getSync(eventStringInfo.title);
          description = L20NContext.getSync(eventStringInfo.body, { lock, actor });
        }

        let actorName = '';
        if (isInvitationEvent) {
          actorName = log.data.actor.invitationCode;
        } else if (log.data.actor) {
          actorName = log.data.actor.firstname || '';
        }

        exportData.push({
          Date: date,
          'Event Type': log.eventType,
          Event: type,
          'Actor Name': actorName,
          'Actor Surname': log.data.actor ? log.data.actor.lastname : '',
          'Actor Email': log.data.actor ? log.data.actor.email : '',
          'Lock Name': log.data.lock ? log.data.lock.name : '',
          'Lock Serial Number': log.data.lock ? log.data.lock.serialNumber : '',
          Outcome: log.eventOutcome,
          Description: description,
          Device: isPinEvent ? deviceId : log.description,
        });
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.${format.toLowerCase()}`;
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { events: ws }, SheetNames: ['events'] };
      const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      return eventsData;
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}
module.exports = {
  SAVE_LOCKERS: 'SAVE_LOCKERS',
  APPEND_LOCKERS: 'APPEND_LOCKERS',
  SET_LOCKERS_PAGINATION_DATA: 'SET_LOCKERS_PAGINATION_DATA',
  RESET_LOCKERS_PAGINATION_DATA: 'RESET_LOCKERS_PAGINATION_DATA',
  SELECT_LOCKER: 'SELECT_LOCKER',
  RESET_LOCKERS_FILTERS: 'RESET_LOCKERS_FILTERS',
  SET_LOCKER_FILTER: 'SET_LOCKER_FILTER',
  UPDATE_LOCKER: 'UPDATE_LOCKER',
  SET_LOCKER_OPERATIONAL_MODE: 'SET_LOCKER_OPERATIONAL_MODE',
  SAVE_LOCKERS_LOCKS: 'SAVE_LOCKERS_LOCKS',
  FETCH_LOCKER_LOCKS_BY_TAGS: 'FETCH_LOCKER_LOCKS_BY_TAGS',
  CANCEL_FETCH_LOCKER_LOCKS_BY_TAGS: 'CANCEL_FETCH_LOCKER_LOCKS_BY_TAGS',
  SET_LOCKER_LOCKS_FILTERED_BY_TAGS: 'SET_LOCKER_LOCKS_FILTERED_BY_TAGS',
  APPEND_LOCKER_LOCKS_FILTERED_BY_TAGS: 'APPEND_LOCKER_LOCKS_FILTERED_BY_TAGS',
  SET_LOCKER_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA: 'SET_LOCKER_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA',
  FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS: 'FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS',
  CANCEL_FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS: 'CANCEL_FETCH_LOCKER_ENTRANCE_LOCKS_BY_TAGS',
  SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS: 'SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS',
  APPEND_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS: 'APPEND_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS',
  SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA: 'SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA',
  SET_LOCKER_ORDER: 'SET_LOCKER_ORDER',
  SAVE_LOCKER_RESERVATIONS: 'SAVE_LOCKER_RESERVATIONS',
  APPEND_LOCKER_RESERVATIONS: 'APPEND_LOCKER_RESERVATIONS',
  SET_LOCKER_RESERVATIONS_PAGINATION_DATA: 'SET_LOCKER_RESERVATIONS_PAGINATION_DATA',
  RESET_LOCKER_RESERVATIONS_PAGINATION_DATA: 'RESET_LOCKER_RESERVATIONS_PAGINATION_DATA',
  SELECT_LOCKER_RESERVATION: 'SELECT_LOCKER_RESERVATION',
  RESET_LOCKER_RESERVATIONS_FILTERS: 'RESET_LOCKER_RESERVATIONS_FILTERS',
  SET_LOCKER_RESERVATION_FILTER: 'SET_LOCKER_RESERVATION_FILTER',
  UPDATE_LOCKER_RESERVATION: 'UPDATE_LOCKER_RESERVATION',
  SET_LOCKER_RESERVATIONS_OPERATIONAL_MODE: 'SET_LOCKER_RESERVATIONS_OPERATIONAL_MODE',
};

// @ts-nocheck

import Tab from '@material-ui/core/Tab';
import { Modal } from '@sketchpixy/rubix';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Cancel';
import CalendarViewIcon from '@material-ui/icons/Event';
import ListViewIcon from '@material-ui/icons/ViewList';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import { change, reset, submit } from 'redux-form';
import InvitationForm from '../components/forms/InvitationForm.jsx';
import InvitationTableView from '../components/Invitations/InvitationTableView.jsx';
import MDButton from '../components/MDButton/MDButton.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import * as InvitationsActions from '../redux/actions/invitation.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as TagActions from '../redux/actions/tag.actions';
import * as LocksActions from '../redux/actions/lock.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { BLUE, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, RED, VIEW_MODES } from '../_config/consts';
import * as formatter from '../_config/formatter';
import InvitationDetailView from '../components/Invitations/InvitationDetailView.jsx';
import InvitationExplanationView from '../components/Invitations/InvitationExplanationView.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import InvitationRedeemEventsView from '../components/Invitations/InvitationRedeemEventsView.jsx';
import { IconButton } from '@material-ui/core';
import ListLoadingView from '../components/ListLoadingView.jsx';
import UsersIconCustom from '../components/CustomIcons/UsersIconCustom.jsx';
import PlatformUserIconCustom from '../components/CustomIcons/PlatformUserIconCustom.jsx';
import { push } from 'react-router-redux';

let filterTimeout;

@connect(state => ({ themeName: state.settings.items.theme.data.themeName, lockTags: state.tags.lock.data, routing: state.router, invitations: state.invitations, invitationForm: state.form.InvitationForm, viewLoading: state.utils.viewLoading }))
class Invitations extends React.Component {
  constructor(props) {
    const cachedViewMode = localStorage.getItem('invitationsViewMode');
    super(props);
    this.state = {
      events: [],
      startingDate: moment().toDate(),
      showInvitationForm: false,
      showDetailsSection: false,
      showInvitationEventsModal: false,
      calendarEvents: [],
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CALENDAR ? 1 : 0,
      currentMonth: moment().month(),
    };
  }

  async componentWillMount() {
    const { routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    const currentDate = moment();
    await this.onFetchInvitationsByFilter(currentDate);

    const invitationId = parsed.invitationId;
    if (invitationId) {
      // todo Fetch invitation details and select
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { invitations: { data: { content: currentInvitations } } } = this.props;
    const { invitations: { data: { content: previousInvitations } } } = prevProps;
    if (currentInvitations && previousInvitations && currentInvitations !== previousInvitations) {
      const calendarEvents = _.map(currentInvitations, (invitation, key) => (
        {
          id: invitation.id,
          hexColor: !invitation.createdBy ? '#4DB6AC' : BLUE,
          title: (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 3 }}>
              {invitation.createdBy ? <UsersIconCustom style={{ width: 15 }} /> : <PlatformUserIconCustom style={{ width: 15 }}  />}
              <h4 style={{ margin: 0, marginLeft: 5, fontSize: 15}}>{`${invitation.name} - ${invitation.code}`}</h4>
            </div>
          ),
          start: moment(invitation.dateInterval.from).toDate(),
          end: moment(invitation.dateInterval.to).toDate(),
        }));
      this.setState({ calendarEvents });
    }
  }

  onSetCurrentMonth(date) {
    this.setState({
      currentMonth: moment(date).month(),
    });
  }

  async onFetchInvitationsWhenMonthChange(date) {
    const { dispatch } = this.props;
    const { currentMonth } = this.state;
    if (moment(date).month() !== currentMonth) {
      try {
        await this.onFetchInvitationsByFilter(date);
      } finally {
        this.onSetCurrentMonth(date);
      }
    }
  }

  async onFetchInvitationsByFilter(date) {
    const { dispatch } = this.props;
    const beginDate = moment(date).startOf('month').subtract(1, 'month').valueOf();
    const endDate = moment(date).endOf('month').add(1, 'month').valueOf();
    dispatch(InvitationsActions.setInvitationsFilter('dateIntervalFrom', beginDate));
    dispatch(InvitationsActions.setInvitationsFilter('dateIntervalTo', endDate));
    setTimeout(async () => {
      try {
        await dispatch(InvitationsActions.fetchInvitations(0, 100));
      } catch (error) {
      }
    }, 300);
  }

  async onInvitationViewModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CALENDAR;
    if (activeTab === this.state.activeTab) {
      return;
    }
    try {
      dispatch(UtilsActions.setViewLoading(true));
      if (selectedViewMode === VIEW_MODES.CALENDAR) { // FIXME: actually it is TABLE but they were mixed up in initialization
        dispatch(InvitationsActions.resetInvitationFilter());
        await dispatch(InvitationsActions.fetchInvitations(0, 20));
      } else {
        const currentDate = moment();
        await this.onFetchInvitationsByFilter(currentDate);
      }
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
    

    this.setState({ activeTab });
    InvitationsActions.setInvitationsViewMode(selectedViewMode);
  }

  async onSelectDateInterval(dateInterval) {
    const { dispatch, } = this.props;
    const formattedDates = formatter.formatOutputData(formatter.INVITATION_DATES, dateInterval);
    let { validityDateFrom, validityDateTo } = formattedDates;
    
    if (moment(validityDateFrom).diff(moment(), 'days') < 0) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'SELECTED_DAY_BEFORE_TODAY' },
      }));
      return;
    }

    if (moment(validityDateFrom).isSame(validityDateTo)) {
      validityDateTo = moment(validityDateFrom).add('days', 1).valueOf();
    }

    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      dispatch(change('InvitationForm', 'timeIntervalFrom', moment().set('hour', 0).set('minutes', 0)));
      dispatch(change('InvitationForm', 'timeIntervalTo', moment().set('hour', 23).set('minutes', 59)));
      dispatch(change('InvitationForm', 'daysOfTheWeek', [1, 2, 3, 4, 5, 6, 7]));
      dispatch(change('InvitationForm', 'lockTagMatchingMode', MATCH_TAG_MODE.AT_LEAST_ONE_TAG));
      dispatch(change('InvitationForm', 'validityDateFrom', validityDateFrom));
      dispatch(change('InvitationForm', 'validityDateTo', validityDateTo));
      dispatch(change('InvitationForm', 'validityTimeFrom', moment().set('hour', 9).set('minutes', 0)));
      dispatch(change('InvitationForm', 'validityTimeTo', moment().set('hour', 18).set('minutes', 0)));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showDetailsSection: false, showInvitationForm: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onFilterLockTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, includeSpecialTags));
  }

  async onFetchLocksByTags() {
    setTimeout(async () => {
      try {
        const { dispatch, invitationForm } = this.props;
        const tags = invitationForm && invitationForm.values && invitationForm.values.lockTags ? invitationForm.values.lockTags : [];
        const lockTagsMatchingMode = invitationForm && invitationForm.values && invitationForm.values.lockTagMatchingMode;
        const smartLocksData = await dispatch(LocksActions.fetchAllLocksByTags(tags, lockTagsMatchingMode, 0, 50));
        dispatch(change('InvitationForm', 'selectedLocks', { data: smartLocksData.locks, pagination: smartLocksData.pagination }));
      } catch (error) {
      }
    }, 500);
  }


  onSelectCalendarInterval(calendarInterval) {
    const { dispatch } = this.props;
    const dateFrom = moment(calendarInterval.start).valueOf();
    const dateTo = moment(calendarInterval.end).valueOf();
    const startTime = moment().valueOf();
    const endTime = moment().add(1, 'hours').valueOf();
    const canCreateItem = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.INVITATION);
    if (canCreateItem) {
      this.onSelectDateInterval({ dateFrom, dateTo, startTime, endTime });
    } else {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="noPermissionToCreateInvitation" /></h6>),
        },
      }));
    }
  }

  onFetchInvitationsOnPage(page) {
    const { dispatch } = this.props;
    dispatch(InvitationsActions.fetchInvitations(page));
  }

  onDeleteInvitationRequest(invitation) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteInvitationConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteInvitationConfirmed(invitation.id),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteInvitationConfirmed(invitationId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(InvitationsActions.deleteInvitation(invitationId));
      dispatch(InvitationsActions.fetchInvitations());
      dispatch(ModalActions.hideModal());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="deletingInvitationSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorDeletingInvitation" /></h6>),
        },
      }));
    } finally {
      this.setState({ showDetailsSection: false, showInvitationForm: false });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onNewInvitationButtonClicked() {
    const { dispatch } = this.props;
    dispatch(reset('InvitationForm'));
    const dateFrom = moment().valueOf();
    const dateTo = dateFrom;
    const startTime = dateFrom;
    const endTime = moment().add(1, 'hours').valueOf();
    this.onSelectDateInterval({ dateFrom, dateTo, startTime, endTime });
  }

  eventStyleGetter(event, start, end, isSelected) {
    const style = {
      backgroundColor: event.hexColor,
      borderRadius: '0px',
      opacity: 1,
      color: 'white',
      border: '0.5px solid white',
      display: 'block',
    };
    return {
      style,
    };
  }

  async onFilterInvitations(name) {
    const { dispatch } = this.props;
    dispatch(InvitationsActions.setInvitationsFilter('title', name));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      try {
        dispatch(UtilsActions.setViewLoading(true));
        await dispatch(InvitationsActions.fetchInvitations());
        dispatch(UtilsActions.setViewLoading(false));
      } catch (error) {
        dispatch(UtilsActions.setViewLoading(false));
      }
    }, 500);
  }

  async onSearchReset() {
    this.onFilterInvitations(null);
  }

  onSelectInvitationFromCalendar(invitationEvent) {
    const { invitations: { data: { content: invitationData }} } = this.props;
    const invitationToSelect = _.find(invitationData, invitation => invitation.id === invitationEvent.id);
    this.onSelectInvitation(invitationToSelect);
  }

  async onSelectInvitation(invitation) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const numberOfRedeems = await dispatch(InvitationsActions.fetchInvitationDevicesNumber(invitation.id));
      dispatch(InvitationsActions.setSelectedInvitation({ ...invitation, numberOfRedeems }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(InvitationsActions.setSelectedInvitation(invitation));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    this.setState({ showDetailsSection: true, showInvitationForm: false });
  }

  async onInvitationDataSubmit(invitationData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(InvitationsActions.createNewInvitation(invitationData));
      await dispatch(InvitationsActions.fetchInvitations());
      this.setState({ showInvitationForm: false });
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="invitationCreatedSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="invitationCreatedError" /></h6>),
        },
      }));
    }
  }

  onShowInfoModal() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="invitations" />,
        content: <InvitationExplanationView />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onOpenInvitationRedeemEvents(invitation) {
    const { dispatch } = this.props;
    dispatch(InvitationsActions.setSelectedInvitation(invitation));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(InvitationsActions.fetchInvitationRedeemEvents(invitation.id));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showInvitationEventsModal: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onAppendRedeemEvents(page) {
    const { dispatch, invitations: { selectedInvitation } } = this.props;
    dispatch(InvitationsActions.appendInvitationRedeemEvents(selectedInvitation.id, page));
  }

  onOpenInvitationsSecttings() {
    const { dispatch } = this.props;
    dispatch(push('/settings?showSystemSettings=true'))
  }

  render() {
    const { dispatch, themeName, invitations: { data: { content: invitationsData }, selectedInvitation, invitationRedeemEvents: { content: invitationRedeemEventsData, pagination: invitationRedeemEventsPagination } }, lockTags, viewLoading } = this.props;
    const { startingDate, showDetailsSection, showInvitationForm, activeTab, calendarEvents, showInvitationEventsModal } = this.state;
    const localizer = momentLocalizer(moment);
    const canCreateItem = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.INVITATION);
    return (
      <div style={{ marginLeft: 0, marginTop: 90, height: 820, padding: 0 }}>
        <PresentationalViewHeader
          themeName={themeName}
          searchPlaceholderEntityName="invitationName"
          newEntityTitle="newInvitation"
          onFilterChange={value => this.onFilterInvitations(value)}
          onSearchReset={() => this.onSearchReset()}
          onNewEntity={canCreateItem ? () => this.onNewInvitationButtonClicked() : null}
          onInfo={() => this.onShowInfoModal()}
          onSettings={() => this.onOpenInvitationsSecttings()}
        />
        {invitationsData && !_.isEmpty(invitationsData) ? (
          <div style={{ top: 145, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onInvitationViewModeChange(index)}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<CalendarViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 1 ? (
          <div>
            <InvitationTableView
              onDeleteInvitation={invitation => this.onDeleteInvitationRequest(invitation)}
              onNewInvitation={canCreateItem ? () => this.onNewInvitationButtonClicked() : null}
              onShowInfo={() => this.onShowInfoModal()}
              onShowInvitationEventsView={(invitation) => this.onOpenInvitationRedeemEvents(invitation)}
              onSelectInvitation={invitation => this.onSelectInvitation(invitation)}
              onFetchInvitationsOnPage={page => this.onFetchInvitationsOnPage(page)}
            />
          </div>
        ) :
        (viewLoading ? <ListLoadingView /> : (
          <div>
            <BigCalendar
              events={calendarEvents}
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              selectable
              showMultiDayTimes
              culture={moment.locale()}
              style={{ margin: 'auto', height: 850, width: '95%', padding: 15, paddingTop: 50 }}
              className="calendar-view"
              defaultDate={startingDate}
              onSelectEvent={event => this.onSelectInvitationFromCalendar(event)}
              onSelectSlot={dateInterval => this.onSelectCalendarInterval(dateInterval)}
              eventPropGetter={
                (event, start, end, isSelected) => this.eventStyleGetter(event, start, end, isSelected)
              }
              onNavigate={(date) => this.onFetchInvitationsWhenMonthChange(date)}
              messages={{
                today: <Entity entity="calendarLabels" data={{ type: 'today' }} />,
                previous: '<',
                next: '>',
                month: <Entity entity="calendarLabels" data={{ type: 'month' }} />,
                week: <Entity entity="calendarLabels" data={{ type: 'week' }} />,
                day: <Entity entity="calendarLabels" data={{ type: 'day' }} />,
                agenda: <Entity entity="calendarLabels" data={{ type: 'agenda' }} />,
                noEventsInRange: <Entity entity="noInvitations" />,
              }}
            />
          </div>
        ))}
        <OperationalView
          title={<Entity entity="invitation" />}
          isVisible={showInvitationForm}
          themeName={themeName}
          style={{ marginTop: 0 }}
          onClose={() => this.setState({ showInvitationForm: false })}
        >
          <InvitationForm
            availableLockTags={lockTags}
            dateHasBeenChanged={dateInterval => this.onSelectDateInterval(dateInterval)}
            onSubmit={eventData => this.onInvitationDataSubmit(eventData)}
            onLockInputChange={value => this.onFilterLockTags(value)}
            onFetchMoreLocks={page => this.onFetchLocksByTags(page, true)}
            onLockTagsChange={() => this.onFetchLocksByTags()}
            onLockFilterModeChange={() => this.onFetchLocksByTags()}
          />
        </OperationalView>
        <Modal
          show={showDetailsSection}
          className="alert-container full-height-modal modal-content-transparent"
          onHide={() => {
            this.setState({ showDetailsSection: false });
            dispatch(InvitationsActions.setSelectedInvitation({}));
          }}
        >
          <InvitationDetailView
            invitation={selectedInvitation}
            onDeleteInvitation={invitation => this.onDeleteInvitationRequest(invitation)}
            onShowInvitationEventsView={(invitation) => this.onOpenInvitationRedeemEvents(invitation)}
            onCloseModal={() => {
              this.setState({ showDetailsSection: false });
              dispatch(InvitationsActions.setSelectedInvitation({}));
            }}
          />
        </Modal>
        <Modal
          show={showInvitationEventsModal}
          onHide={() => {
            this.setState({ showInvitationEventsModal: false });
            dispatch(InvitationsActions.resetInvitationRedeemEvents());
          }}
        >
          <div style={{ padding: 20, paddingTop: 10 }}>
            <div style={{ display: 'flex', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="invitationRedeemEvents" /></h2>
              <IconButton
                onClick={() => {
                  this.setState({ showInvitationEventsModal: false });
                  dispatch(InvitationsActions.resetInvitationRedeemEvents());
                }}
              >
                <CloseIcon style={{ color: RED, fontSize: 40 }} />
              </IconButton>
            </div>
            <InvitationRedeemEventsView
              eventsData={invitationRedeemEventsData}
              pagination={invitationRedeemEventsPagination}
              onAppendRedeemEvents={page => this.onAppendRedeemEvents(page)}
            />
          </div>
        </Modal>
      </div>
    );
  }
} 

export default Invitations;

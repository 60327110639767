import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import * as FileSaver from 'file-saver';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import * as XLSX from 'xlsx';
import { analyticActions as AnalyticActions } from '@bottega52/bookey-redux-module';
import {
  SAVE_RESERVATIONS,
  APPEND_RESERVATIONS,
  RESET_RESERVATIONS_DATA,
  SET_RESERVATIONS_FILTER,
  RESET_RESERVATIONS_FILTER,
  SET_SELECTED_RESERVATION,
  SET_RESERVATIONS_PAGINATION_DATA,
  SET_RESERVATIONS_CURRENT_PAGE,
  SAVE_RESERVATION_TO_SHARE,
  UPDATE_RESERVATION,
  SAVE_RESERVATION_TO_SAVE_FIELD,
  RESET_RESERVATION_TO_SAVE,
  SET_AVAILABLE_RESOURCES,
  APPEND_AVAILABLE_RESOURCES,
  SAVE_AVAILABILITY_PAGINATION_DATA,
} from './actionTypes/reservations';
import * as UtilActions from '../actions/utils.actions';
import * as ModalActions from '../actions/modal.actions';
import * as BookeyAPI from '../../_config/bookeyAPI';
import * as RestService from '../../_config/rest';
import * as formatter from '../../_config/formatter';
import { BACKGROUND_TASK_TYPES, EXPORT_FORMATS } from '../../_config/consts';


export function saveReservations(reservations) {
  const formattedReservations = _.map(reservations, r =>
    formatter.formatInputData(formatter.RESERVATION, r));
  return {
    type: SAVE_RESERVATIONS,
    reservations: formattedReservations,
  };
}

export function appendReservations(reservations) {
  const formattedReservations = _.map(reservations, r =>
    formatter.formatInputData(formatter.RESERVATION, r));
  return {
    type: APPEND_RESERVATIONS,
    reservations: formattedReservations,
  };
}

export function resetReservations() {
  return { type: RESET_RESERVATIONS_DATA };
}

export function resetReservationsFilters() {
  return { type: RESET_RESERVATIONS_FILTER };
}

export function setSelectedReservation(reservation) {
  return {
    type: SET_SELECTED_RESERVATION,
    reservation,
  };
}

export function saveReservationsPaginationData(paginationData) {
  return {
    type: SET_RESERVATIONS_PAGINATION_DATA,
    paginationData,
  };
}

export function saveReservationsCurrentPage(page) {
  return {
    type: SET_RESERVATIONS_CURRENT_PAGE,
    page,
  };
}

export function saveReservationToShare(reservation) {
  return {
    type: SAVE_RESERVATION_TO_SHARE,
    reservation,
  };
}

export function updateReservation(reservation) {
  const formattedReservation = formatter.formatInputData(formatter.RESERVATION, reservation);

  return {
    type: UPDATE_RESERVATION,
    reservation: formattedReservation,
  };
}

export function setReservationNewField(field, value) {
  return {
    type: SAVE_RESERVATION_TO_SAVE_FIELD,
    field,
    value,
  };
}

export function setReservationFilter(field, value) {
  return {
    type: SET_RESERVATIONS_FILTER,
    field,
    value,
  };
}

export function resetReservationToCreate() {
  return { type: RESET_RESERVATION_TO_SAVE };
}

export function setAvailableResources(resources) {
  return {
    type: SET_AVAILABLE_RESOURCES,
    resources,
  };
}

export function appendAvailableResources(resources) {
  return {
    type: APPEND_AVAILABLE_RESOURCES,
    resources,
  };
}

export function saveAvailabilityPaginationData(paginationData) {
  return {
    type: SAVE_AVAILABILITY_PAGINATION_DATA,
    paginationData,
  };
}



export function fetchUserReservationsForCSV(formattedFilters) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    let stopFetching = false;
    dispatch(UtilActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_USERS_RESERVATIONS_CSV,
      title: 'creatingReservationCSV',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      const input = [];
      const analytics = await dispatch(AnalyticActions.fetchAllUserAnalytics(formattedFilters));
      for (let resourceAnalytic of analytics) {
        if (resourceAnalytic.userId) {
          try {
            const userInfo = await RestService.fetchUserDetails(resourceAnalytic.userId);
            resourceAnalytic = { ...resourceAnalytic, users: userInfo.data };
          } catch (error) {}
        }
        resourceAnalytic = {
          ...resourceAnalytic,
          effectiveUsageHours: resourceAnalytic.usageHours - resourceAnalytic.lostHours,
          successfulReservations: resourceAnalytic.totalReservations - resourceAnalytic.lostReservations,
        };
        input.push(resourceAnalytic);
        try {
          const detailedAnalytic = await dispatch(AnalyticActions.fetchAllDetailedAnalyticsByUser(resourceAnalytic.userId, formattedFilters));
          for (let resourceDetailedAnalytic of detailedAnalytic) {
            resourceDetailedAnalytic = { ...resourceDetailedAnalytic, effectiveUsageHours: resourceAnalytic.usageHours - resourceAnalytic.lostHours, resources: resourceDetailedAnalytic.resource.name, successfulReservations: resourceDetailedAnalytic.totalReservations - resourceDetailedAnalytic.lostReservations };
            input.push(resourceDetailedAnalytic);
          }
        } catch (error) {}
      }
      dispatch(UtilActions.removeBackgroundTask(taskId));
      return { input, stopFetching };
    } catch (error) {
      dispatch(UtilActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function exportResourceAnalytics(fileName = 'export', format = EXPORT_FORMATS.CSV, filters) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    let stopFetching = false;
    dispatch(UtilActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_RESOURCE_RESERVATIONS_CSV,
      title: 'creatingReservationReport',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    // This variable will populate the summary  of the usage of each resource
    const resourceSummaryAnalyticsData = [];
    // This variabile will populate the single resource XLSX Sheet, showing the usage for each user that has made a reservation
    let resourceDetailAnalyticsData = [];
    const sheetsName = ['Summary'];
    let sheetsContent = {};
    try {
      const analytics = await dispatch(AnalyticActions.fetchAllResourceAnalytics(filters));
      for (let resourceAnalytic of analytics) {
          resourceSummaryAnalyticsData.push(
            {
              [`${L20NContext.getSync('resource')}`]: resourceAnalytic.resource && resourceAnalytic.resource.name ? resourceAnalytic.resource.name : '',
              [`${L20NContext.getSync('type')}`]: resourceAnalytic.resource && resourceAnalytic.resource.type ? resourceAnalytic.resource.type.name : '',
              [`${L20NContext.getSync('totalReservations')}`]: resourceAnalytic.totalReservations,
              [`${L20NContext.getSync('lostReservations')}`]: resourceAnalytic.lostReservations,
              [`${L20NContext.getSync('successfulReservations')}`]: resourceAnalytic.totalReservations - resourceAnalytic.lostReservations,
              [`${L20NContext.getSync('penaltyHours')}`]: resourceAnalytic.deletedByUserWithPenaltyHours + resourceAnalytic.lostHours,
              [`${L20NContext.getSync('usageHours')}`]: resourceAnalytic.usageHours - resourceAnalytic.lostHours,
              [`${L20NContext.getSync('totalHours')}`]: resourceAnalytic.usageHours + resourceAnalytic.deletedByUserWithPenaltyHours,
            }
          );
          // Here for every resource, fetch the detailed reservations details and create a new sheet
          if (resourceAnalytic.resource && resourceAnalytic.resource.id) {
            try {
              const detailedAnalytic = await dispatch(AnalyticActions.fetchAllDetailedAnalyticsByResource(resourceAnalytic.resource.id, filters));
              for (let resourceDetailedAnalytic of detailedAnalytic) {
                let userInfo = {
                  firstname: '',
                  lastname: '',
                  username: '',
                  email: '',
                  companyName: '',
                };
                if (resourceDetailedAnalytic.userId) {
                  try {
                    const userResponse = await RestService.fetchUserDetails(resourceDetailedAnalytic.userId);
                    userInfo = userResponse.data;
                  } catch (error) {}
                }
                if (resourceAnalytic.resource && resourceAnalytic.resource.name) {
                  resourceDetailAnalyticsData.push(
                    {
                      [`${L20NContext.getSync('name')}`]: userInfo.firstname || '---',
                      [`${L20NContext.getSync('surname')}`]: userInfo.lastname || '---',
                      [`${L20NContext.getSync('username')}`]: userInfo.username || '---',
                      [`${L20NContext.getSync('email')}`]: userInfo.email || '---',
                      [`${L20NContext.getSync('companyName')}`]: userInfo.companyName || '---',
                      [`${L20NContext.getSync('totalReservations')}`]: resourceDetailedAnalytic.totalReservations,
                      [`${L20NContext.getSync('lostReservations')}`]: resourceDetailedAnalytic.lostReservations,
                      [`${L20NContext.getSync('successfulReservations')}`]: resourceDetailedAnalytic.totalReservations - resourceDetailedAnalytic.lostReservations,
                      [`${L20NContext.getSync('penaltyHours')}`]: resourceDetailedAnalytic.deletedByUserWithPenaltyHours + resourceDetailedAnalytic.lostHours,
                      [`${L20NContext.getSync('usageHours')}`]: resourceDetailedAnalytic.usageHours - resourceDetailedAnalytic.lostHours,
                      [`${L20NContext.getSync('totalHours')}`]: resourceDetailedAnalytic.usageHours + resourceDetailedAnalytic.deletedByUserWithPenaltyHours,
                    });
                    const resourceName = _.truncate(resourceAnalytic.resource.name, 30, { omission: '' });
                    sheetsName.push(resourceName);
                  }
              }
              // here there is for a single resource all the users details
              const ws = XLSX.utils.json_to_sheet(resourceDetailAnalyticsData);
              const resourceName = _.last(sheetsName);
              sheetsContent = { ...sheetsContent, [resourceName]: ws };
              resourceDetailAnalyticsData = [];
            } catch (error) {}
        }
      }
      const ws = XLSX.utils.json_to_sheet(resourceSummaryAnalyticsData);
      sheetsContent = { Summary: ws, ...sheetsContent };
      dispatch(UtilActions.removeBackgroundTask(taskId));
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.${format.toLowerCase()}`;
      const wb = { Sheets: { ...sheetsContent }, SheetNames: _.uniq(sheetsName) };
      const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      return resourceSummaryAnalyticsData;
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function exportUsersAnalytics(fileName = 'export', format = EXPORT_FORMATS.CSV, filters) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    let stopFetching = false;
    dispatch(UtilActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_USERS_RESERVATIONS_CSV,
      title: 'creatingReservationReport',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    // This variable will populate the summary  of the usage of each resource
    const userSummaryAnalyticsData = [];
    // This variabile will populate the single resource XLSX Sheet, showing the usage for each user that has made a reservation
    let userDetailAnalyticsData = [];
    const sheetsName = ['Summary'];
    let sheetsContent = {};
    try {
      let analytics = [];
      if (filters && filters.userIds && !_.isEmpty(filters.userIds)) {
        analytics = await dispatch(AnalyticActions.searchAllUserAnalytics(filters));
      } else {
        analytics = await dispatch(AnalyticActions.fetchAllUserAnalytics(filters));
      }
      for (let userAnalytic of analytics) {
        let userInfo  = {
          firstname: '',
          lastname: '',
          username: '',
          email: '',
          companyName: '',
        };
        if (userAnalytic.userId) {
          try {
            const userResponse = await RestService.fetchUserDetails(userAnalytic.userId);
            userInfo = userResponse.data;
          } catch (error) {}
        }
        userSummaryAnalyticsData.push(
          {
            [`${L20NContext.getSync('name')}`]: userInfo.firstname,
            [`${L20NContext.getSync('surname')}`]: userInfo.lastname,
            [`${L20NContext.getSync('username')}`]: userInfo.username,
            [`${L20NContext.getSync('email')}`]: userInfo.email,
            [`${L20NContext.getSync('companyName')}`]: userInfo.companyName,
            [`${L20NContext.getSync('totalReservations')}`]: userAnalytic.totalReservations,
            [`${L20NContext.getSync('lostReservations')}`]: userAnalytic.lostReservations,
            [`${L20NContext.getSync('successfulReservations')}`]: userAnalytic.totalReservations - userAnalytic.lostReservations,
            [`${L20NContext.getSync('penaltyHours')}`]: userAnalytic.deletedByUserWithPenaltyHours + userAnalytic.lostHours,
            [`${L20NContext.getSync('usageHours')}`]: userAnalytic.usageHours - userAnalytic.lostHours,
            [`${L20NContext.getSync('totalHours')}`]: userAnalytic.usageHours + userAnalytic.deletedByUserWithPenaltyHours,
          },
        );
        // Here for every user, fetch the detailed reservations details and create a new sheet
        if (userInfo.lastname && userInfo.lastname.length) {
          try {
            const detailedAnalytic = await dispatch(AnalyticActions.fetchAllDetailedAnalyticsByUser(userAnalytic.userId, _.omit(filters, 'userIds')));
            for (let userDetailedAnalytic of detailedAnalytic) {
              userDetailAnalyticsData.push(
                {
                  [`${L20NContext.getSync('resource')}`]: userDetailedAnalytic.resource.name || '---',
                  [`${L20NContext.getSync('totalReservations')}`]: userDetailedAnalytic.totalReservations,
                  [`${L20NContext.getSync('lostReservations')}`]: userDetailedAnalytic.lostReservations,
                  [`${L20NContext.getSync('successfulReservations')}`]: userDetailedAnalytic.totalReservations - userDetailedAnalytic.lostReservations,
                  [`${L20NContext.getSync('penaltyHours')}`]: userDetailedAnalytic.deletedByUserWithPenaltyHours + userDetailedAnalytic.lostHours,
                  [`${L20NContext.getSync('usageHours')}`]: userDetailedAnalytic.usageHours - userDetailedAnalytic.lostHours,
                  [`${L20NContext.getSync('totalHours')}`]: userDetailedAnalytic.usageHours + userDetailedAnalytic.deletedByUserWithPenaltyHours,
                  
                });
              const userName = _.truncate(userInfo.lastname, 30, { omission: '' });
              sheetsName.push(userName);
            }
            // here there is for a single user all the reversation details per resource
            const ws = XLSX.utils.json_to_sheet(userDetailAnalyticsData);
            const userName = _.last(sheetsName);
            sheetsContent = { ...sheetsContent, [userName]: ws };
            userDetailAnalyticsData = [];
          } catch (error) {}
        }
      }
      const ws = XLSX.utils.json_to_sheet(userSummaryAnalyticsData);
      sheetsContent = { Summary: ws, ...sheetsContent };
      dispatch(UtilActions.removeBackgroundTask(taskId));
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.${format.toLowerCase()}`;
      const wb = { Sheets: { ...sheetsContent }, SheetNames: _.uniq(sheetsName) };
      const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      return userSummaryAnalyticsData;
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function exportReservations(fileName = 'export', format = EXPORT_FORMATS.CSV, reservationsParsed) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    let stopFetching = false;
    dispatch(UtilActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_RESERVATIONS_CSV,
      title: 'creatingReservationReport',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    const reservationsData = [];
    const sheetsName = ['Reservations'];
    let sheetsContent = {};
    try {
      for (let reservation of reservationsParsed) {
        reservationsData.push(
          {
            [`${L20NContext.getSync('ID')}`]: reservation.id,
            [`${L20NContext.getSync('reservationDate')}`]: reservation.fromDate?moment(reservation.fromDate).format('YYYY-MM-DD'):"-",
            [`${L20NContext.getSync('reservationResource')}`]: reservation.resource?reservation.resource.name:"",
            [`${L20NContext.getSync('resourceType')}`]: reservation.resource&&reservation.resource.type?reservation.resource.type.name:"",
            [`${L20NContext.getSync('reservationUser')}`]: reservation.userId,
            [`${L20NContext.getSync('reservationStartTime')}`]: reservation.fromDate?moment(reservation.fromDate).format('HH:mm'):"-",
            [`${L20NContext.getSync('reservationEndTime')}`]: reservation.toDate?moment(reservation.toDate).format('HH:mm'):"-",
            [`${L20NContext.getSync('reservationState')}`]: reservation.state,
            [`${L20NContext.getSync('resourceWorkspace')}`]: reservation.resource?reservation.resource.workspace:"",
            [`${L20NContext.getSync('reservatonCheckInTime')}`]: reservation.checkInTime?moment(reservation.checkInTime).format('HH:mm'):"-",
          },
        );
      }
      const ws = XLSX.utils.json_to_sheet(reservationsData);
      sheetsContent = { Reservations: ws, ...sheetsContent };
      dispatch(UtilActions.removeBackgroundTask(taskId));
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.${format.toLowerCase()}`;
      const wb = { Sheets: { ...sheetsContent }, SheetNames: _.uniq(sheetsName) };
      const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      return reservationsData;
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilActions.removeBackgroundTask(taskId));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function fetchUserResources(userId, page = 0, pageSize = 2000) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        userId,
      };
      const response = await BookeyAPI.fetchResourcesWithInstance(params);
      if (response && response.content)
        return response.content;
      return []
    } catch (error) {
      throw new Error(error);
    }
  };
}
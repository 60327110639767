import React from 'react';
import moment from 'moment';
import TimeIcon from '@material-ui/icons/AccessTime';
import { TimePicker } from 'antd';
import { LANGUAGES } from '../../../../_config/consts';
import { connect } from 'react-redux';

@connect(state => ({ language: state.settings.language }))
class TimePickerField2 extends React.Component {
    
  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  render() {
    const { input, containerStyle, title, mandatory, language, iconStyle, titleStyle, hidePlaceholder, force24HoursFormat } = this.props;
    const value = input.value ? moment(input.value) : null;  // Controlla se `value` è null
    const is12HoursFormat = language === LANGUAGES.ENGLISH && !force24HoursFormat;
    
    return (
      <div style={{ ...containerStyle }}>
        {title &&
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, alignItems: 'center' }}>
          <TimeIcon style={{ marginRight: 10, color: '#3f3f3f', ...iconStyle }} />
          <h4 style={{ margin: 0, color: '#3f3f3f', ...titleStyle }}>{title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</h4>
        </div>}
        
        <TimePicker
          showSecond={false}
          value={value}  // Usa `value` che potrebbe essere `null`
          placeholder={hidePlaceholder ? '' : null}  // Nascondi il placeholder se richiesto
          className="time-picker"
          format={is12HoursFormat ? 'h:mm a' : 'HH:mm'}
          allowClear={false}
          onChange={(value) => this.onHandleChange(value)}
          use12Hours={is12HoursFormat}
          onClose={(value) => this.onHandleChange(value)}
        />
      </div>
    );
  }
} 

export default TimePickerField2;

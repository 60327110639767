import React from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  datePickerBorder: {
    '& .SingleDatePickerInput': {
      border: '2px solid red',
      borderRadius: '4px',
    },
    '& .SingleDatePickerInput__withBorder': {
      border: '2px solid red',
    },
    '& .DateInput': {
      background: 'transparent',
    },
    '& .DateInput_input': {
      border: 'none',
    }
  },
});

class DatePickerViewCustomBorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDateChange(date) {
    const { onChange } = this.props;
    if (date) {
      onChange(date.valueOf());
    } else onChange(date);
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }

  render() {
    const { focused } = this.state;
    const { date, id, classes, errorBorder } = this.props;
    return (
      <div className={errorBorder ? classes.datePickerBorder : ''}>
        <SingleDatePicker
          numberOfMonths={1}
          focused={focused}
          hideKeyboardShortcutsPanel
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          date={moment(date)}
          id={id}
          {...this.props}
          className={errorBorder?classes.datePickerBorder:""}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DatePickerViewCustomBorder);

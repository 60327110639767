import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { initialize, change } from 'redux-form';
import moment from 'moment';
import { VIEW_MODES } from '../_config/consts';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import LockerTableView from '../components/ParcelManagement/LockerTableView.jsx';
import LockersOperationalSection from '../components/ParcelManagement/LockersOperationalSection.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import * as LockerActions from '../redux/actions/locker.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UtilsActions from '../redux/actions/utils.actions';

let filterTimeout;
@connect((state) => ({ lockers: state.lockers, routing: state.router, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName, }))
class LockersManagement extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('lockersViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }
  async componentWillMount() {
    const { dispatch, routing, lockers } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(LockerActions.selectLocker({}));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      dispatch(LockerActions.resetLockersFilters());
      await dispatch(LockerActions.fetchAllLocks());
      await dispatch(LockerActions.fetchLockers());
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    const lockerId = parsed.lockerId;
    const newEntity = parsed.newEntity;
    if (lockerId) {
      const newLocker = ((lockers && lockers.data) || []).filter(e=>e.id===lockerReservationId).length>0?
        ((lockers && lockers.data) || []).filter(e=>e.id===lockerReservationId)[0]:undefined;
      if (newLocker) {
        this.selectLocker(newLocker);
      }
    }
    if (newEntity) {
      this.onCreateNewLocker();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(LockerActions.resetLockersFilters());
    dispatch(LockerActions.selectLocker({}));
  }

  async fetchLockers(page = 0, append = false) {
    const { dispatch } = this.props;
    try {
      this.setState({ viewLoading: true });
      await dispatch(LockerActions.fetchLockers());
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }

  async closeOperationalSection() {
    const { dispatch } = this.props;
    await dispatch(LockerActions.setOperationalMode(false));
    await dispatch(LockerActions.selectLocker({}));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(LockerActions.setOperationalMode(true));
  }

  async onSetFilter(field, value, useTimeout) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2) || (_.isNumber(value) && value>0))) {
      await dispatch(LockerActions.setFilter(field, value));
      if (useTimeout) {
        if (filterTimeout) clearTimeout(filterTimeout);
        filterTimeout = setTimeout(async () => {
          await this.fetchLockers()
        }, 500);
      }
      else {
        dispatch(UtilsActions.setSpinnerVisibile(true));
        await this.fetchLockers()
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    } else {
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockerActions.setFilter(field, undefined));
      await this.fetchLockers()
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onResetFilters() {
    const { dispatch } = this.props;
    await dispatch(LockerActions.resetLockersFilters());
    dispatch(initialize('LockersFilters', {}));
    await dispatch(LockerActions.fetchLockers());
  }

  async selectLocker(locker) {
    const { dispatch, lockers } = this.props;
    const selectedLocker = lockers.selectedLocker
    if (selectedLocker&&selectedLocker.id===locker.id)
      return;
    try {
      dispatch(LockerActions.selectLocker(locker));
      dispatch(initialize('LockerDetailsViewForm', locker));
      this.openOperationalSection()
    } catch (error) {
      dispatch(LockerActions.selectLocker({}));
    } finally {
      
    }
  }

  async onAppendLockers(page) {
    const { dispatch } = this.props;
    const append = true;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockerActions.fetchLockers(page, append));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onFetchLockerOnPage(page) {
    const { dispatch } = this.props;
    dispatch(LockerActions.fetchLockers(page));
  }

  async onLockerEditConfirmed(lockerId,lockerData) {
    const { dispatch, lockers } = this.props;
    const data = lockerData;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const lockerResponse = await dispatch(LockerActions.editLocker(lockerId,data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerPropertiesAssigned' }} /></h6>),
        },
      }));
      this.closeOperationalSection()
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onLockerCreateConfirmed(lockerData) {
    const { dispatch, lockers } = this.props;
    const data = lockerData;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const createdLocker = await dispatch(LockerActions.createLocker(data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="lockerCreated"  /></h6>),
        },
      }));
      if (lockers.isOperationalMode) {
        dispatch(LockerActions.selectLocker(createdLocker));
        dispatch(initialize('LockerDetailsViewForm', createdLocker));
        const intervalSecondsFrom = createdLocker.intervalSecondsFrom || 0;
        const intervalSecondsTo = createdLocker.intervalSecondsTo || 86340;
        const fromHours = Math.floor(intervalSecondsFrom / 3600);
        const fromMinutes = Math.floor((intervalSecondsFrom % 3600) / 60);
        const toHours = Math.floor(intervalSecondsTo / 3600);
        const toMinutes = Math.ceil((intervalSecondsTo % 3600) / 60);
        dispatch(change('LockerDetailsViewForm', 'timeIntervalFrom',moment().startOf('day').set({ hour: fromHours, minute: fromMinutes }).valueOf()));
        dispatch(change('LockerDetailsViewForm', 'timeIntervalTo',moment().startOf('day').set({ hour: toHours, minute: toMinutes }).valueOf()));
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(LockerActions.resetLockersFilters());
      await dispatch(LockerActions.fetchLockers());
    }
  }

  async onDeleteLockerRequest(selectedLockerId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deleteLockerDataMessage" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onDeleteLockerConfirm(selectedLockerId),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteLockerConfirm(selectedLockerId) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(LockerActions.deleteLocker(selectedLockerId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(LockerActions.setOperationalMode(false));
      dispatch(LockerActions.selectLocker({}));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerDeleted' }} /></h6>),
        },
      }));
      this.onRefreshLockers()
    } catch (error) {
      let errorMessage = 'lockerDeleteError';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }
  
  async onRefreshLockers() {
    const { lockers } = this.props;
    const page = lockers && lockers.data && lockers.data.pagination && lockers.data.pagination.number;
    this.onFetchLockerOnPage(page);
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(LockerActions.fetchLockers())
  }

  async onCreateNewLocker() {
    const { dispatch } = this.props;
    dispatch(LockerActions.selectLocker({}));
    dispatch(initialize('LockerDetailsViewForm', {}));
    this.openOperationalSection()
  }

  onShowInfo() {
    /* TODO */
    /*
    const helpURL = localizeHelpCenterLink('luckey-floor-plans');
    window.open(helpURL);
    */
  }

  render() {
    const { lockers, themeName } = this.props;
    const selectedLockerId = lockers?.selectedLocker?.id
    const selectedLockerName = lockers?.selectedLocker?.name;
    const canEditCreateLockers = true; // TODO AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.LOCKER);
    return (
      <div style={{ backgroundColor: 'white'}}>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canEditCreateLockers ? () => this.onCreateNewLocker() : null}
          newEntityTitle="addLocker"
          newEntityIconName="icon-simple-line-icons-user-following"
          onSearchReset={() => this.onResetFilters()}
          onFilterChange={value => this.onSetFilter('search', _.trim(value), true)}
          onSearchSubmit={() => this.fetchLockers()}
          //onInfo={() => this.onShowInfo()}
        />
        <LockerTableView
          onSelectLocker={locker => this.selectLocker(locker)}
          onFetchLockerOnPage={page => this.onFetchLockerOnPage(page)}
          onRefreshLockers={() => this.onRefreshLockers()}
          onNewLocker={canEditCreateLockers ? () => this.onCreateNewLocker() : null}
          onDeleteLocker={(lockerId) => {this.onDeleteLockerRequest(lockerId)}}
          onOrderChanged={() => this.onOrderChanged()}
        />
        <OperationalView
          themeName={themeName}
          isVisible={lockers.isOperationalMode}
          onClose={() => this.closeOperationalSection()}
          onDelete={selectedLockerName && canEditCreateLockers ? () => this.onDeleteLockerRequest(selectedLockerId) : null}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity={selectedLockerName?selectedLockerName:"createLocker"} data={{ name: 'lockersOperational' }} key={selectedLockerName} />}
        >
          <LockersOperationalSection
            themeName={themeName}
            onLockerEditConfirmed={(lockerId,LockerData)=>this.onLockerEditConfirmed(lockerId,LockerData)}
            onLockerCreateConfirmed={(LockerData)=>this.onLockerCreateConfirmed(LockerData)}
            onRefreshLockers={()=>this.onRefreshLockers()}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default LockersManagement;

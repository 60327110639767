// @ts-nocheck
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  Form,
  OverlayTrigger
} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import Tooltip from '@sketchpixy/rubix/lib/Tooltip';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { GREEN, LICENSE_TYPES, PERMISSIONS, PERMISSION_ENTITIES, RED, SOFIA_SUPPORT_MANAGERS_EMAILS, TWO_FACTOR_TYPES } from '../../_config/consts.js';
import { hasFormWritePermission } from '../../_config/utils.js';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as GuestActions from '../../redux/actions/guest.actions';
import * as RolesActions from '../../redux/actions/roles.actions';
import CompanyReportIconCustom from '../CustomIcons/CompanyReportIconCustom.jsx';
import SmartPhoneKeyIconCustom from '../CustomIcons/SmartPhoneKeyIconCustom.jsx';
import TwoFactorIconCustom from '../CustomIcons/TwoFactorIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import EffectActionSection from './Elements/EffectActionSection.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import CustomField from './Fields/CustomField.jsx';
import MDSwitchField from './Fields/MDSwitch/MDSwitchField.jsx';
import MDSwitchView from './Fields/MDSwitch/MDSwitchView.jsx';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';
import ReactTooltip from 'react-tooltip';

const validate = values => {
  const errors = {};
  if (!values.firstname) {
    errors.firstname = 'required';
  }

  if (!values.lastname) {
    errors.lastname = 'required';
  }

  if (!values.username) {
    errors.username = 'required';
  }
  if (!values.userRole || (values.userRole && _.isEmpty(values.userRole))) {
    errors.userRole = 'required';
  }

  if (!values.email) {
    errors.email = 'required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'invalidEmailAddress';
  }
  return errors;
};


const PlatformUserInfoSection = ({ title, body, bodyColor, actionText, onClick, toolTipText }) =>
  (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{title}</h5>
        {toolTipText &&
        <p style={{ margin: 0, marginTop: 5, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync(toolTipText)}>
          <InfoIcon style={{ fontSize: 18, marginLeft: 10, cursor: 'pointer', color: '#3f3f3f' }} />
        </p>
        }
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <div>
          <h5 style={{color: bodyColor }}>{body}</h5>
        </div>
        <h5 onClick={() => onClick()} className="link-label">{actionText}</h5>
      </div>
      <ReactTooltip delayShow={200} effect="float" />
    </div>
  );
@reduxForm({ form: 'PlatformUserForm', validate })
@connect(state => ({ form: state.form.PlatformUserForm, user: state.user, companyConfigurations: state.user.companyConfigurations }))
class PlatformUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rolesOptions: null,
      guestsOptions: [],
      isGuestSelected: false,
      isPromoteGuestActive: false,
    };
  }

  async componentDidMount() {
    const { dispatch, isDisabledRoleSection } = this.props;
    if (!isDisabledRoleSection) {
      const roles = await dispatch(RolesActions.fetchRolesOptions({}));
      const filteredRoles = _.filter(roles, role => role.id !== 5); // remove guest option
      const rolesFormatted = _.map(filteredRoles, (role) => {
        const formattedRole = RolesActions.formatRole(role);
        return { value: String(role.id), label: formattedRole.label };
      });
      this.setState({ rolesOptions: rolesFormatted });
    }
  }

  onRoleSelected(role) {
    const { dispatch } = this.props;
    dispatch(change('PlatformUserForm', 'roleIds', [role.value]));
  }

  async fetchRolesOptions(value) {
    const { dispatch } = this.props;
    const roles = await dispatch(RolesActions.fetchRolesOptions({ name: value }));
    const filteredRoles = _.filter(roles, role => role.id !== 5); // remove guest option
    const rolesFormatted = _.map(filteredRoles, (role) => {
      const formattedRole = RolesActions.formatRole(role);
      return { value: String(role.id), label: formattedRole.label };
    });
    this.setState({ rolesOptions: rolesFormatted });
    return rolesFormatted;
  }

  
  async onFilterGuests(value) {
    const { dispatch } = this.props;
    const guests = await dispatch(GuestActions.fetchGuestsForSelector(value));
    const filterSingleRoleGuests = _.filter(guests, guest => _.size(guest.roles) === 1);
    const options = _.map(filterSingleRoleGuests, guest => ({ value: guest, label: guest.email }));
    this.setState({ guestsOptions: options });
    return options;
  }
  
  onSelectGuest(guest) {
    const { onGuestSelected } = this.props;
    if (guest) {
      if (onGuestSelected) onGuestSelected(guest.value);
      this.setState({ isGuestSelected: true });
    } else {
      if (onGuestSelected) onGuestSelected(null);
      this.setState({ isGuestSelected: false });
    }
  }

  onToggleTwoFactor(isSelected) {
    const { dispatch } = this.props;
    if (isSelected) {
      dispatch(change('PlatformUserForm', 'twoFactorType', TWO_FACTOR_TYPES.MAIL_OTP));
    } else {
      dispatch(change('PlatformUserForm', 'twoFactorType', null));
    }
  }

  onTogglePromoteGuest(selected) {
    this.setState({ isPromoteGuestActive: selected });
  }

  render() {
    const { user, companyConfigurations, platformUser, onNewRole, onDeleteUser, onConfirmResetPassword, form, onDisableUser, onEnableUser, isDisabledRoleSection } = this.props;
    const { rolesOptions, isGuestSelected, isPromoteGuestActive, guestsOptions } = this.state;
    const isEditing = form && form.values && form.values.id;
    const canPromoteGuests = form && form.values && form.values.canPromoteGuests;
    const isKeepManagerAsGuestSelected = form && form.values && form.values.keepManagerAsGuest;
    const isGuestManager = platformUser && _.find(platformUser.roles, role => role.id === 5);
    const canDeleteGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.USER);
    const canUpdateGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.USER);
    const isSelfEditing = form && form.values && form.values.id && user && user.data && user.data.id && (form.values.id === user.data.id);
    const isSupportManager = platformUser && platformUser.email && _.findIndex(SOFIA_SUPPORT_MANAGERS_EMAILS, email => email === platformUser.email) !== -1;
    const isLuckeyRFID = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.RFID]);
    const isAdmin = form && form.values && form.values.roleIds && form.values.roleIds.filter(e=>e===1).length
    return (
      <Form>
        <input style={{ display: 'none' }} type="username" name="username" />
        <FormCardContainer
          title={isEditing ? 'userData' : 'createNewManager'}
          subtitle={isEditing && !isGuestSelected ? '' : 'createNewManagerSubtitle'}
          headerStyle={{ marginBottom: 15 }}
        >
          {canPromoteGuests ? (
            <div>
              <MDSwitchView
                label={<Entity key={isPromoteGuestActive} entity="selectGuestForPlatformUser" />}
                checked={isPromoteGuestActive}
                titleStyle={{ fontWeight: isPromoteGuestActive ? 'bold' : 'normal', color: isPromoteGuestActive ? GREEN : '#3f3f3f' }}
                onSelect={selected => this.onTogglePromoteGuest(selected)}
              />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <InfoIcon style={{ fontSize: 20, color: '#3f3f3f' }} />
                <h5 style={{ margin: 0, marginLeft: 10, color: '#3f3f3f', lineHeight: '1.5em' }}>{<Entity entity="promoteGuestSubtitle" />}</h5>
              </div>
              {isPromoteGuestActive ? (
                <Field
                  name="guestTagSelectedSearch"
                  component={SelectableField}
                  noCache
                  options={guestsOptions}
                  placeholder={<Entity entity="findGuestToPromote" />}
                  style={{ marginTop: 15 }}
                  onInputChange={value => this.onFilterGuests(value)}
                  onSelect={tagData => this.onSelectGuest(tagData)}
                />
              ) : null}
              
              {<Divider style={{ marginTop: 20, marginBottom: 15, height: 2 }} />}
            </div>
            ) : null }
          <Entity
            componentClass={Field}
            name="firstname"
            componentAttribute="placeholder"
            entity="insertFirstName"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) || isGuestSelected || isSupportManager}
            title={<Entity entity="firstName" />}
            component={CustomField}
            mandatory
          />
          <Entity
            componentClass={Field}
            name="lastname"
            componentAttribute="placeholder"
            entity="insertSurname"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) || isGuestSelected || isSupportManager}
            title={<Entity entity="surname" />}
            component={CustomField}
            mandatory
          />
          <Entity
            componentClass={Field}
            name="email"
            componentAttribute="placeholder"
            entity="insertEmail"
            title="E-mail"
            disabled={isEditing || isGuestSelected || isSupportManager}
            component={CustomField}
            mandatory
          />
          {platformUser && platformUser.id && canUpdateGuest && !isSupportManager && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <h5
                onClick={() => onConfirmResetPassword()}
                className="link-label"
                style={{ margin: 0 }}
              >
                <Entity entity="resetPassword" />
              </h5>
            </div>
          )}
          {!isDisabledRoleSection && !isSupportManager ? (
            <Field
              name="userRole"
              title={<Entity entity="userRole" />}
              component={SelectableField}
              options={rolesOptions}
              canCreateAsync
              disabled={isSelfEditing}
              mandatory
              placeholder={<Entity entity="insertRole" />}
              onNewOption={async (role) => onNewRole(role.value)}
              containerstyle={{ marginBottom: 15, marginTop: -10 }}
              onSelect={role => this.onRoleSelected(role)}
              defaultOptions={rolesOptions}
              onInputChange={async(name) => await this.fetchRolesOptions(name)}
              loadOptions={async(name) => await this.fetchRolesOptions(name)}
            />
          ): null}
          {platformUser && platformUser.id && canUpdateGuest && !isSelfEditing && !isSupportManager && (
            <PlatformUserInfoSection
              title={<Entity entity="userState" />}
              toolTipText={!platformUser.enabled && 'guestInactiveStatusExplanation'}
              body={platformUser.enabled ? <Entity key="userEnabled" entity="userEnabled" /> : <Entity key="userDisabled" entity="userDisabled" />}
              bodyColor={platformUser.enabled ? 'green' : 'red'}
              actionText={platformUser.enabled ? <Entity key="deactivateUser" entity="deactivateUser" /> : <Entity key="activateUser" entity="activateUser" />}
              onClick={platformUser.enabled ? () => onDisableUser() : () => onEnableUser()}
            />
          )}
        </FormCardContainer>
        {!isSupportManager ? (
          <FormCardContainer
            title="twoFactorAuthenticationTitle"
            subtitle="twoFactorAuthenticationSubTitle"
            titleContainerStyle={{ justifyContent: 'flex-start' }}
            icon={<TwoFactorIconCustom style={{ width: 25, marginLeft: 10, marginBottom: 7, color: '#3f3f3f' }} />}
          >
            <Field
              name="using2FA"
              label={<Entity entity="enableTwoFactorAuthentication" />}
              titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
              component={MDSwitchField}
              onHandleChange={value => this.onToggleTwoFactor(value)}
            />
          </FormCardContainer>
        ) : null}
        {!isSupportManager && !isLuckeyRFID ? (
          <FormCardContainer
            title="guestManagerTitle"
            subtitle="guestManagerSubTitle"
            titleContainerStyle={{ justifyContent: 'flex-start' }}
            icon={<SmartPhoneKeyIconCustom style={{ width: 23, marginLeft: 10, marginBottom: 7, color: '#3f3f3f' }} />}
          >
            <Field
              name="isGuestManager"
              label={<Entity entity="enableGuestManager" />}
              titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
              component={MDSwitchField}
              disabled={isGuestSelected}
            />
          </FormCardContainer>
        ) : null}
        <FormCardContainer
          title="platformUserNotifications"
          subtitle="platformUserNotificationsSubtitle"
          titleContainerStyle={{ justifyContent: 'flex-start' }}
          icon={<CompanyReportIconCustom style={{ width: 23, marginLeft: 10, marginBottom: 7, color: '#3f3f3f' }} />}
        >
          <Field
            name="companyNotificationsEnabled"
            label={<Entity entity="platformUserNotificationsEnable" />}
            titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
            component={MDSwitchField}
            disabled={false}
          />
        </FormCardContainer>
        {onDeleteUser && platformUser.id && canDeleteGuest && !isSelfEditing && !isSupportManager && (
          <FormCardContainer
            title="deletePlatformUser"
          >
            <div>
              {isGuestManager && (
                <Field
                  name="keepManagerAsGuest"
                  label={<Entity entity="keepManagerAsGuest" />}
                  subtitle={<Entity entity="keepManagerAsGuestDescription" />}
                  titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
                  subtitleStyle={{ fontSize: 16, color: '#3f3f3f' }}
                  component={MDSwitchField}
                  disabled={isGuestSelected || isSupportManager}
                />
              )}
              <EffectActionSection
                key={isKeepManagerAsGuestSelected}
                headerStyle={{ marginTop: 20 }}
                effectDescription={isKeepManagerAsGuestSelected ? 'keepManagerAsGuestEffect' : 'deleteManagerEffect'}
              />
              <MDButton
                title={<Entity key={isKeepManagerAsGuestSelected} entity={isKeepManagerAsGuestSelected ? 'removeManager' : 'deleteUserManagerFromSystem'} />}
                backgroundColor={RED}
                onClick={() => onDeleteUser()}
              />
            </div>
          </FormCardContainer>
        )}
      </Form>
    );
  }
} 

export default PlatformUserForm;

// @ts-nocheck
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { createTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloudIcon from '@material-ui/icons/ImportExport';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import RoleIcon from '@material-ui/icons/VerifiedUser';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import { BLUE, GREEN, LOCKER_LOCK_STATUS, LOCKER_RESERVATION_STATUSES_PARAMETERS, MOBILE_UDID_STATES, ORANGE, PERMISSIONS, PERMISSION_ENTITIES, RED } from '../../_config/consts';
import { getColorFromThemeName, getLockImage } from '../../_config/utils';
import CustomFieldsDisplayView from '../CustomFields/CustomFieldsDisplayView.jsx';
import DeleteOutlineIconCustom from '../CustomIcons/DeleteOutlineIconCustom.jsx';
import DeviceRemoveIconCustom from '../CustomIcons/DeviceRemoveIconCustom.jsx';
import SelectMultipleCheckBoxIconCustom from '../CustomIcons/SelectMultipleCheckBoxIconCustom.jsx';
import UserCheckIconCustom from '../CustomIcons/UserCheckIconCustom.jsx';
import UserDisableIconCustom from '../CustomIcons/UserDisableIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';
import RemoveEmailIconCustom from '../CustomIcons/RemoveEmailIconCustom.jsx';
import AccessProfilesIconCustom from '../CustomIcons/AccessProfilesIconCustom.jsx';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import UnsortIconCustom from '../CustomIcons/UnsortIconCustom.jsx';
import SortIconCustom from '../CustomIcons/SortIconCustom.jsx';

import * as LockerActions from '../../redux/actions/locker.actions';
import SortAscendingIconCustom from '../CustomIcons/SortAscendingIconCustom.jsx';
import SortDescendingIconCustom from '../CustomIcons/SortDescendingIconCustom.jsx';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import LockersIconCustom from '../CustomIcons/LockersIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  tableHeader: {
    padding: 5,
    paddingLeft: 25,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: '#E53935',
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lockerRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lockerLocksListContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: -5,
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 45,
    height: 45,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  lockContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid grey',
    padding: 3,
    paddingRight: 6,
    borderRadius: 10,
    backgroundColor: 'white',
    marginRight: 5,
    marginLeft: 5,
    marginTop: 3,
    marginBottom: 3,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect((state) => ({ lockers: state.lockers, lockerReservations: state.lockers.reservations,
  viewLoading: state.utils.viewLoading, companyConfigurations: state.user.companyConfigurations, themeName: state.settings.items.theme.data.themeName }))

class DeliveriesTableView extends React.Component {
  
  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { lockers: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(LockerActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(LockerActions.setOrder(undefined, undefined));
    onOrderChanged()
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(LockerActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      lockers: { reservations: { content: lockerReservationsData, pagination: { number: currentPage, size, totalPages, totalElements }, filters, sorting: {orderBy, orderDir} } },
      lockers: { data: { content: lockerData } },
      lockers: { selectedLockerReservation },
      lockers: { locks: { content: locks }},
      onFetchDeliveriesReservationsOnPage,
      onSelectDelivery,
      onRefreshDeliveries,
      onNewDelivery,
      viewLoading,
      themeName,
      onSharePin
    } = this.props;
    const headerColor = getColorFromThemeName(themeName);
    const canEditCreateLockers = true // TODO AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.LOCKER);
    return (
      <div className="list-view details-section" style={{ top: 150, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(lockerReservationsData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell className={classes.tableHeader} colSpan={2}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="deliveries" /></h3>
                        <IconButton onClick={() => onRefreshDeliveries()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                        {/* {orderBy && 
                          <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                            <UnsortIconCustom style={{ color: 'white', width: 25 }} />
                          </IconButton>
                        } */}
                      </div>
                    </TableCell>
                    <TableCell />
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="lockerReservationsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchDeliveriesReservationsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchDeliveriesReservationsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="pinCodeUnlock" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="lockerReservationUser" /></h4></TableCell>
                    <TableCell  style={{ minWidth: 200 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="lockerReservationLocker" /></h4></TableCell>
                    <TableCell  style={{ minWidth: 300 }}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="itemNotes" /></h4>
                    </TableCell>
                    <TableCell style={{ minWidth: 300 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="lockerReservationStatus" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(lockerReservationsData, (lockerReservation) => {
                    const lock = lockerReservation && lockerReservation.lock
                    const locker = lockerReservation && lockerReservation.lockerId && lockerData && lockerData.find(e=>e.id===lockerReservation.lockerId)
                    const status = lockerReservation && lockerReservation.status
                    const fromDate = lockerReservation && lockerReservation.fromDate
                    const toDate = lockerReservation && lockerReservation.toDate

                    const userTag = lockerReservation && lockerReservation.user && lockerReservation.user.tags && lockerReservation.user.tags.find(e=>e.type==='user')

                    return (
                      <TableRow
                        key={lockerReservation.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={() => onSelectDelivery(lockerReservation)}
                        selected={selectedLockerReservation && selectedLockerReservation.id === lockerReservation.id}
                      >
                        <TableCell>
                          <div className={classes.lockerRowContainer} style={{ minWidth: 150, margin: 15 }}>
                            <h5 style={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}>
                              {lockerReservation && lockerReservation.pinCode}
                            </h5>
                            <IconButton onClick={(e) => {
                              e.stopPropagation();
                              onSharePin(lockerReservation.pinCode);
                            }}>
                              <ShareIconCustom style={{ width: 20, color: BLUE }} />
                            </IconButton>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.lockerRowContainer}>
                            {userTag && 
                              <Tag
                                key={userTag.id}
                                tag={userTag}
                              />
                            }
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.lockerRowContainer} >
                            <div style={{display:'flex', flexDirection: 'row', alignItems:'center' }}>
                              <LockersIconCustom style={{ width: 22, color: '#3f3f3f', marginRight: 7 }} />
                              <h5 style={{ fontSize: 16, margin: 0, lineBreak:'anywhere' }}>
                                {`${(locker && locker.name) || ""}`}
                              </h5>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5, fontSize: 14, maxWidth: 220, overflowWrap: 'break-word', }}>
                              {lockerReservation.name ? `#${lockerReservation.id} - ${lockerReservation.name}` : '---'}
                            </h5>
                        </TableCell>
                        <TableCell>
                          <div className={classes.lockerRowContainer} >
                            <div style={{ paddingBottom: 6, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              {status ? (
                                <h5 style={{ fontWeight: 'bold', marginTop: 4, marginBottom: 6 }}>
                                  <Entity
                                    key={`status_${status}`}
                                    style={{ textTransform: 'uppercase', color: LOCKER_RESERVATION_STATUSES_PARAMETERS[status].color }}
                                    entity={LOCKER_RESERVATION_STATUSES_PARAMETERS[status].string}
                                  />
                                </h5>
                              ) : null}
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                                  {fromDate && toDate ? `${moment(fromDate).format('LLL')} - ${moment(toDate-1).format('LLL')}`: ""}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <ReactTooltip delayShow={200} effect="float" />
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="lockerReservationsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchDeliveriesReservationsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchDeliveriesReservationsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title={filters && !filters.search ? 'noLockerReservations' : 'noLockerReservationsFound'}
                    subtitle={filters && !filters.search ? 'noLockerReservationsDescription' : 'noLockerReservationsFoundSearchDescription'}
                    onNewEntity={filters && !filters.search && onNewDelivery ? () => onNewDelivery() : null}
                    newEntityTitle="addLockerReservation"
                  /> ) : (
                    <ListLoadingView />
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DeliveriesTableView);

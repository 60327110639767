import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import ReservationForm from '../../../components/forms/Bookey/ReservationForm.jsx';
import MDButton from '../../../components/MDButton/MDButton.jsx';
import * as CredentialActions from '../../../redux/actions/credential.actions';
import * as TagActions from '../../../redux/actions/tag.actions';

@connect(state => ({ userTags: state.tags.user.data, user: state.user, availabileResources: state.resources.availableResources, resources: state.resources.data.content }))
class NewReservationView extends React.Component {

  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    const tagType = 'USER'
    dispatch(TagActions.fetchGuestTags(includeSpecialTags, tagType));
  }

  fetchGuestsByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    dispatch(CredentialActions.fetchGuestsByTagsSmartCredentialsForm(page, append));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    const tagType = 'USER';
    dispatch(TagActions.fetchGuestTagsByFilter(value, 5, includeSpecialTags, tagType));
  }

  render() {
    const { userTags, onSubmitReservationForm, availabileResources, dateHasBeenChanged, resources, onSaveReservation, onFetchMoreAvailableResources } = this.props;
    
    const availableResourcesFiltered = {
      ...availabileResources,
      content: availabileResources&&availabileResources.content?availabileResources.content.filter(e=>e.capacity<=1):[]
    }
    return (
      <div style={{ marginLeft: 0 }}>
        <ReservationForm
          availabileResources={availableResourcesFiltered}
          availableUserTags={userTags}
          dateHasBeenChanged={(dateInterval, userTagId) => dateHasBeenChanged(dateInterval, userTagId)}
          onSubmit={(values) => onSaveReservation(values)}
          onFetchMoreGuests={page => this.fetchGuestsByTags(page)}
          onGuestTagsChange={() => this.fetchGuestsByTags()}
          onGuestInputChange={value => this.filterGuestTags(value)}
          onFetchMoreAvailableResources={(page, pageSize, availableResourcesData, append) => onFetchMoreAvailableResources(page, pageSize, availableResourcesData, append)}
        />
        <MDButton
          title={<Entity entity="save" />}
          containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
          style={{ height: 45, borderRadius: 0 }}
          onClick={() => onSubmitReservationForm()}
        />
      </div>
    );
  }
} 

export default NewReservationView;

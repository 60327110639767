import { IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { CheckCircleOutline, Domain, RemoveCircleOutline } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BLUE, GREEN, RESOURCE_IMAGE_MAP } from '../../_config/consts';
import { getHeaderColorFromThemeName } from '../../_config/utils';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    fontSize: 10,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    marginRight: 10,
    width: 'max-content',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lockRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  rowBatteryLevel: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    justifycontent: 'center',
    alignItems: 'center',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class ResourcesTableView extends React.Component {

  render() {
    const { classes, themeName, onShowResourceOnMap,  resources: { content: resourcesData, pagination: { number: currentPage, size, totalPages, totalElements } }, onNewEntity, selectedResource, onRefreshResources, onFetchResourcesOnPage, onSelectResource } = this.props;
    const headerColor = getHeaderColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(resourcesData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={1}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="resources" /></h3>
                        <IconButton onClick={() => onRefreshResources()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="resourcesTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchResourcesOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          lastPage={totalPages - 1}
                          iconColor="white"
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchResourcesOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="resourceName" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="type" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="resourceAvailability" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="resourceReservationParameters" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="automaticCheckIn" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(resourcesData, (resource) => {
                    let imageSource = resource.imageUrl && RESOURCE_IMAGE_MAP[resource.imageUrl] ? RESOURCE_IMAGE_MAP[resource.imageUrl] : null;
                    imageSource = resource.media ? `data:image/jpeg;base64,${resource.media}` : imageSource;
                    const timeIntervalFrom = moment().startOf('day').add(resource.timeInterval.from, 'seconds').valueOf();
                    const timeIntervalTo = moment().startOf('day').add(resource.timeInterval.to, 'seconds').valueOf();
                    const additionalTimeIntervals = []
                    if (resource && resource.additionalTimeIntervals) {
                      resource.additionalTimeIntervals.forEach((item) => {
                        const additionalTimeIntervalFrom = moment().startOf('day').add(item.from, 'seconds').valueOf();
                        const additionalTimeIntervalTo = moment().startOf('day').add(item.to, 'seconds').valueOf();
                        additionalTimeIntervals.push({
                          from: additionalTimeIntervalFrom,
                          to: additionalTimeIntervalTo,
                        })
                      });
                    }
                    const timeIntervals = [{from: timeIntervalFrom, to: timeIntervalTo}, ...additionalTimeIntervals].sort((a, b) => a.from - b.from);
                    const timeIntervalsStrings = timeIntervals.map(e=>`${moment(e.from).format('LT')} - ${moment(e.to).format('LT')}`)
                    const isRowLarge = resource.description && resource.description.length && resource.description.length > 2;
                    const days = resource && resource.daysOfTheWeek && _.orderBy(resource.daysOfTheWeek);
                    const daysOptions = _.map(moment.weekdays(true), weekday => ({
                      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
                      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
                    }));
                    return (
                      <TableRow
                        key={resource.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={event => onSelectResource(resource)}
                        selected={selectedResource && selectedResource.id === resource.id}
                      >
                        <TableCell>
                          <div className={classes.lockRowContainer} style={{ margin: isRowLarge ? 0 : 15, marginLeft: 0  }}>
                            {imageSource ? <img alt="resource" className="lock-icon" style={{ width: 45, height: 45, marginRight: 15 }} src={imageSource} /> : <div className="lock-icon" style={{ width: 45, height: 45, marginRight: 15, backgroundColor: 'white' }} />}
                            <div style={{ marginRight: 10 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'max-content' }}>
                                <h5 style={{ fontWeight: 'bold', fontSize: 19 }}>{resource.name}</h5>
                                <div style={{ marginTop: 12.5, marginBottom: 12.5, marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {resource.shared || resource.sharedFromHostname ? (
                                  <p style={{ margin: 0, marginTop: 2, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('resourceIsSharedWithDomain')}><ShareIconCustom style={{ width: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginTop: -2, marginBottom: 0, marginRight: 15 }} /></p>
                                ) : null}
                                {resource.workspace && resource.workspace.length ? (
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
                                    <Domain className="resourceInfoRow-info-col-icon" style={{ fontSize: 18 }} />
                                    <h6 style={{ color: '#3f3f3f', margin: 0, marginLeft: 5, textAlign: 'center' }}>{resource.workspace}</h6>
                                  </div>
                                    ): null}
                                </div>
                              </div>
                              {isRowLarge ? <h5 style={{ fontSize: 13, marginTop: 0 }}>{resource.description}</h5> : null}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.lockRowContainer} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div className="resourceInfoRow-typeTag-flat">
                              <h5 style={{ color: 'white', margin: 0 }}>{resource.type.name}</h5>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.lockRowContainer} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                                {`${moment(resource.dateInterval.from).format('DD MMMM YYYY')} - ${moment(resource.dateInterval.to).format('DD MMMM YYYY')}`}
                              </h5>
                            </div>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 5 }}>{timeIntervalsStrings.join(" | ")}</h5>
                            <div style={{ marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>
                              {_.map(daysOptions, option => (
                                <h6 key={option.label} style={{ margin: 0, marginRight: 7, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                                  {option.label.toUpperCase()}
                                </h6>
                                ))}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div style={{display:'flex', flexDirection:'column'}}>
                            {resource.capacity>1&&<h5 style={{ color: '#3f3f3f', margin: 0, marginBottom: 5, fontWeight: 'bold' }}>{`${resource.capacity} ${L20NContext.getSync('resourceCapacityTableText')}`}</h5>}
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                              {resource.minimumReservationTimeMinutes ? `
                                ${L20NContext.getSync('resourceReservationParametersMinTime')}: 
                                ${(resource.minimumReservationTimeMinutes>=60)?(resource.minimumReservationTimeMinutes/60):(resource.minimumReservationTimeMinutes)}
                                ${(resource.minimumReservationTimeMinutes>=60)?(L20NContext.getSync('hours')):(L20NContext.getSync('minutes'))}
                              ` : `${L20NContext.getSync('resourceReservationParametersMinTime')}: ${L20NContext.getSync('resourceReservationParametersNoLimit')}`}
                            </h5>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                              {resource.maximumReservationTimeMinutes ? `
                                ${L20NContext.getSync('resourceReservationParametersMaxTime')}: 
                                ${(resource.maximumReservationTimeMinutes>=60)?(resource.maximumReservationTimeMinutes/60):(resource.maximumReservationTimeMinutes)}
                                ${(resource.maximumReservationTimeMinutes>=60)?(L20NContext.getSync('hours')):(L20NContext.getSync('minutes'))}
                              ` : `${L20NContext.getSync('resourceReservationParametersMaxTime')}: ${L20NContext.getSync('resourceReservationParametersNoLimit')}`}
                            </h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.lockRowContainer}>
                            <h5 style={{ color: resource.automaticCheckIn ? GREEN : 'grey', marginRight: 10 }}> <Entity key={resource.automaticCheckIn} entity={resource.automaticCheckIn ? 'areaUserActive' : 'areaUserExpired'} /></h5>
                            {resource.automaticCheckIn ? (
                              <CheckCircleOutline style={{ fontSize: 20, color: GREEN }} />
                            ) : <RemoveCircleOutline style={{ fontSize: 20, color: 'grey' }} />}
                          </div>
                        </TableCell>
                        <ReactTooltip delayShow={200} effect="float" />
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ background: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="resourcesTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchResourcesOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          iconColor="white"
                          onChangePage={(event, page) => onFetchResourcesOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <EmptyView
                title="noResources"
                newEntityTitle="createResource"
                subtitle="startCreatingResource"
                onNewEntity={onNewEntity ? () => onNewEntity() : null}
              />
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ResourcesTableView);

module.exports = {
  SAVE_AREAS: 'SAVE_AREAS',
  APPEND_AREAS: 'APPEND_AREAS',
  SET_AREAS_PAGINATION_DATA: 'SET_AREAS_PAGINATION_DATA',
  RESET_AREAS_PAGINATION_DATA: 'RESET_AREAS_PAGINATION_DATA',
  SELECT_AREA: 'SELECT_AREA',
  RESET_AREAS_FILTERS: 'RESET_AREAS_FILTERS',
  SET_AREA_FILTER: 'SET_AREA_FILTER',
  UPDATE_AREA: 'UPDATE_AREA',
  SET_AREA_OPERATIONAL_MODE: 'SET_AREA_OPERATIONAL_MODE',
  SAVE_LOCKS: 'SAVE_LOCKS',
  FETCH_AREA_LOCKS_BY_TAGS: 'FETCH_AREA_LOCKS_BY_TAGS',
  CANCEL_FETCH_AREA_LOCKS_BY_TAGS: 'CANCEL_FETCH_AREA_LOCKS_BY_TAGS',
  SET_AREA_LOCKS_FILTERED_BY_TAGS: 'SET_AREA_LOCKS_FILTERED_BY_TAGS',
  APPEND_AREA_LOCKS_FILTERED_BY_TAGS: 'APPEND_AREA_LOCKS_FILTERED_BY_TAGS',
  SET_AREA_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA: 'SET_AREA_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA',
  FETCH_AREAS_OCCUPANCY: 'FETCH_AREAS_OCCUPANCY',
  CANCEL_FETCH_AREAS_OCCUPANCY: 'CANCEL_FETCH_AREAS_OCCUPANCY',
  SAVE_AREA_OCCUPANCY: 'SAVE_AREA_OCCUPANCY',
  RESET_AREAS_OCCUPANCY: 'RESET_AREAS_OCCUPANCY',
  SET_AREAS_OCCUPANCY_LOADING: 'SET_AREAS_OCCUPANCY_LOADING',
  SAVE_AREA_OCCUPANCY_HOURLY_DATA: 'SAVE_AREA_OCCUPANCY_HOURLY_DATA',
  SAVE_AREA_OCCUPANCY_HOURLY_AREA_SELECTED: 'SAVE_AREA_OCCUPANCY_HOURLY_AREA_SELECTED',
  SET_AREA_OCCUPANCY_HOURLY_FILTER: 'SET_AREA_OCCUPANCY_HOURLY_FILTER',
  RESET_AREA_OCCUPANCY_HOURLY_FILTERS: 'RESET_AREA_OCCUPANCY_HOURLY_FILTERS',
  SAVE_AREA_RULES: 'SAVE_AREA_RULES',
  UPDATE_AREA_RULE: 'UPDATE_AREA_RULE',
  SET_AREA_ORDER: 'SET_AREA_ORDER',
};

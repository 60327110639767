// @ts-nocheck
import jwt from 'jsonwebtoken';
import Geohash from 'latlon-geohash';
import * as XLSX from 'xlsx';
// @ts-nocheck
import PrivacyIcon from '@material-ui/icons/AccountBalance';
import PinIcon from '@material-ui/icons/Apps';
import LicenseIcon from '@material-ui/icons/BookmarkBorder';
import ThemeIcon from '@material-ui/icons/Brush';
import BuildIcon from '@material-ui/icons/Build';
import CompanyIcon from '@material-ui/icons/Business';
import HyperGateIcon from '@material-ui/icons/Cast';
import CardIcon from '@material-ui/icons/CreditCard';
import AssistanceIcon from '@material-ui/icons/FlagOutlined.js';
import RegistryIcon from '@material-ui/icons/HdrWeak';
import InvitationsIcon from '@material-ui/icons/InsertLink';
import UsersIcon from '@material-ui/icons/PeopleOutline';
import SmartphoneIcon from '@material-ui/icons/PhoneIphone';
import PermissionIcon from '@material-ui/icons/Security';
import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import { SHA256 } from 'crypto-js';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import AgendaIconCustom from '../components/CustomIcons/AgendaIconCustom.jsx';
import BuildingIconCustom from '../components/CustomIcons/BuildingIconCustom.jsx';
import BuildingSettingIconCustom from '../components/CustomIcons/BuildingSettingIconCustom.jsx';
import ClockCloudIconCustom from '../components/CustomIcons/ClockCloudIconCustom.jsx';
import CommunicationIconCustom from '../components/CustomIcons/CommunicationIconCustom.jsx';
import CompanyReportIconCustom from '../components/CustomIcons/CompanyReportIconCustom.jsx';
import CustomFieldsIconCustom from '../components/CustomIcons/CustomFieldsIconCustom.jsx';
import KeyOutlinedIconCustom from '../components/CustomIcons/KeyOutlinedIconCustom.jsx';
import MessageAlertIconCustom from '../components/CustomIcons/MessageAlertIconCustom.jsx';
import TagCheckIconCustom from '../components/CustomIcons/TagCheckIconCustom.jsx';
import TwoFactorIconCustom from '../components/CustomIcons/TwoFactorIconCustom.jsx';
import WalletIconCustom from '../components/CustomIcons/WalletIconCustom.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import { CARD_TYPES, CHAINELS_BASE_URL, CUSTOM_FIELDS_TYPES, DEVELOPERS_URL, EVENT_OUTCOME, GATEWAYS_NOTIFICATION_STATUSES, HYPERGATE_DEVICE_TYPES, ISEO_ZENDESK_API_KEY, ISEO_ZENDESK_EMAIL, ISEO_ZENDESK_GROUP_ID, ISEO_ZENDESK_URL, LANGUAGE_DETAILS, LANGUAGE_LOCALES, LANGUAGES, LICENSE_TYPES, LOGS, OFFICE_MODE_SMARTLOCK_MODELS_ENABLED, OFFLINE_TIME_UNIT, PERMISSIONS, SMARTLOCK_DOOR_STATUS, VEGA_OPERATION_CODES, VEGA_RESULTS_CODES, VENDORS_LIST } from "./consts";
import CalendarEditIconCustom from '../components/CustomIcons/CalendarEditIconCustom.jsx';
import CalendarStarIconCustom from '../components/CustomIcons/CalendarStarIconCustom.jsx';

const csv = require('csvtojson');

export function getContrastYIQ(hexcolor) {
  if (hexcolor) {
    const formattedHex = hexcolor.replace('#', '');
    const r = parseInt(formattedHex.substr(0, 2), 16);
    const g = parseInt(formattedHex.substr(2, 2), 16);
    const b = parseInt(formattedHex.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 158) ? 'black' : 'white';
  }
  return 'white';
}

export function isMobileBrowser() {
  let check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function elaborateCredentialDaysArray(credential) {
  const days = [];
  if (credential.mon) days.push(moment().isoWeekday(1).isoWeekday());
  if (credential.tue) days.push(moment().isoWeekday(2).isoWeekday());
  if (credential.wed) days.push(moment().isoWeekday(3).isoWeekday());
  if (credential.thu) days.push(moment().isoWeekday(4).isoWeekday());
  if (credential.fri) days.push(moment().isoWeekday(5).isoWeekday());
  if (credential.sat) days.push(moment().isoWeekday(6).isoWeekday());
  if (credential.sun) days.push(moment().isoWeekday(7).isoWeekday());
  return days;
}

export function elaborateForbiddenDaysString(daysNumberArray) {
  if (_.size(daysNumberArray) === 7) return null;
  let days = '';
  const daysArray = [1, 2, 3, 4, 5, 6, 7];
  const notAllowedDays = _.difference(daysArray, daysNumberArray);
  _.forEach(notAllowedDays, (dayNumber) => days += ` ${_.startCase(moment.weekdays(dayNumber))}`);
  return days;
}

export function saveDataToLocalStorage(fieldName, data) {
  localStorage[fieldName] = data;
}

export function getLockImage(lockModel) {
  switch (lockModel) {
    case 'IMArgoLib.Stylos':
    case 'STYLOS':
      return '/imgs/common/locks/stylos.png';
    case 'STYLOS_DISPLAY':
      return '/imgs/common/locks/stylos_display.png';
    case 'IMArgoLib.Libra':
    case 'LIBRA':
      return '/imgs/common/locks/libra.jpg';
    case 'IMArgoLib.X1revo':
    case 'X1REVO':
      return '/imgs/common/locks/x1R.jpg';
    case 'IMArgoLib.Aires':
    case 'ARIES':
      return '/imgs/common/locks/aires.jpg';
    case 'LOCKER':
      return '/imgs/common/locks/Smart_Locker.png';
    case 'SMART_RELAY':
      return '/imgs/common/locks/Smart_Relay.jpg';
    case 'F9000':
      return '/imgs/common/locks/f9000.jpg';
    case 'MAYA':
      return '/imgs/common/locks/Maya.png';
    case 'MULLION':
      return '/imgs/common/locks/1nca.jpg';
    case 'MULLION_KB':
      return '/imgs/common/locks/1nca_kb.jpg';
    case 'MULLION_RELAY':
      return '/imgs/common/locks/1nca.jpg';
    case 'MULLION_RELAY_KB':
      return '/imgs/common/locks/1nca_kb.jpg';
    case 'SMART_READER':
      return '/imgs/common/locks/Smart_Reader.png';
    case 'SMART_READER_KB':
      return '/imgs/common/locks/Smart_Reader_Kp.png';
    case 'SMART_READER_KB_FP':
      return '/imgs/common/locks/Smart_Reader_Fp.png';
    case 'EMOTION_SMART':
      return '/imgs/common/locks/Emotion_Smart.png';
    default:
      return '/imgs/common/locks/aires.jpg';
  }
}

export function getHyperGateImage(deviceType) {
  switch (deviceType) {
    case HYPERGATE_DEVICE_TYPES.HYPERGATE_ISEO:
      return '/imgs/common/hypergates/hypergate_iseo.png';
    case HYPERGATE_DEVICE_TYPES.HYPERGATE_PRO:
      return '/imgs/common/hypergates/hypergate_pro.png';
    default:
      return '/imgs/common/hypergates/hypergate_pro.png';
  }
}

export function userHasSubscriptions(allowedSubscriptions, tokenSubscriptions) {
  if (!allowedSubscriptions) return true;
  return tokenSubscriptions && !_.difference(allowedSubscriptions, tokenSubscriptions).length;
}

export const getLogColor = (logEvent) => {
  switch (logEvent) {
    case LOGS.CREDENTIAL_RULE_DELETION:
    case LOGS.ACS_OPEN_NOT_AUTHORIZED:
      return 'red';
    case LOGS.ACS_OPEN_OK:
    case LOGS.CREDENTIAL_RULE_CREATION:
    case LOGS.USER_CREATION:
      return 'green';
    case LOGS.USER_UPDATE:
      return 'orange';
    default:
      return '#3f3f3f';
  }
};


export function isURLValid(url) {
  if (!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(url)) {
    return false;
  } else {
    return true;
  }
}

export function minutesOfDay(date) {
  let momentDate = date;
  if (!moment.isMoment(date)) {
    momentDate = moment(date);
  }
  return (momentDate.hours() * 60) + momentDate.minutes();
}


export function elaborateLogDescriptionData(log) {
  
  switch (log.type) {
    case LOGS.ACS_OPEN_OK:
    case LOGS.ACS_OPEN_NOT_AUTHORIZED : {
      const { payload } = log;
      const userData = !_.isEmpty(payload) && payload.email && payload.firstName && payload.lastName ? `${payload.firstName} ${payload.lastName} (${payload.email})` : log.secondSourceSearch;
      return { secondSourceSearch: userData, secondDestinationSearch: log.secondDestinationSearch };
    }
    case LOGS.USER_CREATION:
    case LOGS.USER_UPDATE: {
      const { payload } = log;
      
      const userData = !_.isEmpty(payload) && payload.email && payload.firstname && payload.lastname ? `${payload.firstname} ${payload.lastname} (${payload.email})` : log.secondDestinationSearch;
      return { secondSourceSearch: log.secondSourceSearch, secondDestinationSearch: userData };
    }
    case LOGS.CREDENTIAL_RULE_CREATION: {
      const { payload } = log;
      const credentialData = !_.isEmpty(payload) && payload.name ? ` ${payload.name}` : `${log.secondDestinationSearch || ''}`;
      return { secondSourceSearch: log.secondSourceSearch || '', secondDestinationSearch: credentialData };
    }
    case LOGS.CREDENTIAL_RULE_DELETION: {
      const { payload } = log;
      const credentialData = !_.isEmpty(payload) && payload.name ? ` ${payload.name}` : `${log.firstDestinationSearch || ''}`;
      return { secondSourceSearch: log.secondSourceSearch || '', secondDestinationSearch: credentialData };
    }

    default: {
      return {
        secondSourceSearch: log.secondSourceSearch || '',
        secondDestinationSearch: log.secondDestinationSearch || log.firstDestinationSearch || '',
      };
    }
  }
}

export function elaborateNotificationProgressPercentage(status) {
  switch (status) {
    case GATEWAYS_NOTIFICATION_STATUSES.WAITING: return 0;
    case GATEWAYS_NOTIFICATION_STATUSES.IN_TRANSIT: return 34;
    case GATEWAYS_NOTIFICATION_STATUSES.RECEIVED: return 67;
    case GATEWAYS_NOTIFICATION_STATUSES.APPLIED: return 100;
    case GATEWAYS_NOTIFICATION_STATUSES.FAILED: return 100;
    default: return 0;
  }
}

export function getSettingIcon(settingName) {
  switch (settingName) {
    case 'assistance': return <AssistanceIcon style={{ color: '#3f3f3f', fontSize: 32 }} />;
    case 'company': return <CompanyIcon style={{ color: '#3f3f3f', fontSize: 30 }} />;
    case 'permissions': return <PermissionIcon style={{ color: '#3f3f3f', fontSize: 30 }} />;
    case 'privacy': return <PrivacyIcon style={{ color: '#3f3f3f', fontSize: 30 }} />;
    case 'theme': return <ThemeIcon style={{ color: '#3f3f3f', fontSize: 30 }} />;
    case 'license': return <LicenseIcon style={{ color: '#3f3f3f', fontSize: 30 }} />;
    case 'wallet': return <WalletIconCustom style={{ color: '#3f3f3f', width: 30 }} />;
    case 'systemSettings': return <BuildingSettingIconCustom style={{ color: '#3f3f3f', width: 28 }} />;
    case 'companyNotifications': return <CompanyReportIconCustom style={{ color: '#3f3f3f', width: 30 }} />;
    case 'customFields': return <CustomFieldsIconCustom style={{ color: '#3f3f3f', width: 30 }} />;
    case 'ticketCategories': return <MessageAlertIconCustom style={{ color: '#3f3f3f', width: 30 }} />;
    case 'devicesDefaults': return <ClockCloudIconCustom style={{ color: '#3f3f3f', width: 30 }} />;
    case 'holidaysSettings': return <CalendarStarIconCustom style={{ color: '#3f3f3f', width: 30 }} />;
    case 'nexudus': return <img src="/imgs/common/integrationLogos/nexudus.png" style={{ height: 35 }} />;
    case 'eagleEye': return <img src="/imgs/common/integrationLogos/eagle_eye.png" style={{ height: 50 }} />;
    case 'cobot': return <img src="/imgs/common/integrationLogos/cobot.png" style={{ height: 37 }} />;
    case 'officeRnd': return <img src="/imgs/common/integrationLogos/officeRnd.png" style={{ height: 37 }} />;
    case 'chainels': return <img src="/imgs/common/integrationLogos/chainels-black.png" style={{ height: 37 }} />;
    case 'andcards': return <img src="/imgs/common/integrationLogos/spacebring-black.png" style={{ height: 37 }} />;
    case 'office365': return <img src="/imgs/common/integrationLogos/microsoft-logo-lettering.png" style={{ height: 50 }} />;
    case 'cloudbeds': return <img src="/imgs/common/integrationLogos/cloudbeds.png" style={{ height: 40 }} />;
    case 'mews': return <img src="/imgs/common/integrationLogos/mews-logo.png" style={{ height: 30, margin: 10 }} />;
    case 'optix': return <img src="/imgs/common/integrationLogos/optix-logo.png" style={{ height: 60 }} />;
    case 'tenup': return <img src="/imgs/common/integrationLogos/tenup-logo.png" style={{ height: 40 }} />;
    case 'zapfloor': return <img src="/imgs/common/integrationLogos/zapfloor-logo.png" style={{ height: 60 }} />;
    case 'octorate': return <img src="/imgs/common/integrationLogos/octorate-logo.png" style={{ height: 50 }} />;
    case 'resharmonics': return <img src="/imgs/common/integrationLogos/resharmonics.png" style={{ height: 60 }} />;
    case 'devicesDefaults': return <ClockCloudIconCustom style={{ color: '#3f3f3f', width: 30 }} />;
    default: return <BuildIcon style={{ color: '#3f3f3f', fontSize: 30 }} />;
  }
}

export function elaborateReservationCheckedInterval(startDate, endDate) {
  const end = moment(endDate).startOf('year');
  const start = moment(startDate).startOf('year');
  const difference = end.diff(start, 'years');
  switch (difference) {
    case 1: return 1;
    case 5: return 5;
    case 10: return 10;
    default: return 0;
  }
}

export function executeRequestOrDefault(entity, permissions, requestData) {
  if (!AbilityProvider.getAbilityHelper().hasPermission(permissions, entity)) {
    return Promise.resolve(generateDefaultResponse(requestData));
  }
  return requestData.instance.request(_.omit(requestData, 'instance'));
}

function generateDefaultResponse(requestData) {
  if (requestData.method !== 'get') {
    return {};
  }

  if (_.includes(requestData.url, 'v1')) {
    return {
      data: {
        data: [],
        page: 0,
        total: 0,
      },
    };
  }
  return {
    data: {
      content: [],
      first: true,
      last: true,
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
      totalPages: 0,
    },
  };
}

export function hasFormWritePermission(entity, isEditing) {
  const canCreateEntity = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], entity);
  const canUpdateEntity = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], entity);
  const hasAllPermission = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], entity);
  return (!isEditing && canCreateEntity) || (isEditing && canUpdateEntity || hasAllPermission);
}

export function getHeaderColorFromThemeName(themeName) {
  switch (themeName) {
    case 'default': return '#157495';
    case 'green': return '#009688';
    case 'blue': return '#3F51B5';
    case 'red': return '#e95841';
    case 'orange': return '#FF9800';
    case 'purple': return '#673AB7';
    case 'dark': return '#1E1E1E';
    case 'gray': return '#8e8e8e';
    case 'dark-brown': return '#403034';
    case 'dark-gray': return '#383f49'; 
    default: return '#157495';
  }
}

export function getColorFromThemeName(themeName) {
  switch (themeName) {
    case 'default': return '#12637f';
    case 'green': return '#00796B';
    case 'blue': return '#303F9F';
    case 'red': return '#eb6753';
    case 'orange': return '#F57C00';
    case 'purple': return '#512DA8';
    case 'dark': return '#3f3f3f';
    case 'gray': return '#a4a4a4';
    case 'dark-gray': return '#383f49';
    case 'dark-brown': return '#403034';
    default: return '#12637f';
  }
}

export function elaborateVideoIndex() {
  return Math.floor(Math.random() * 4) + 1;
}

export function getPlantName() {
  const plantName = window && window.location && window.location.hostname && window.location.hostname.replace('.jago.cloud', '');
  return _.upperFirst(plantName) || '';
}

export function createHostNameFromPlantName(plantName) {
  return `https://${plantName}.jago.cloud`;
}

export function formatDomainHostNameToFriendlyName(hostName) {
  let hostNameRemovePrefix = _.replace(hostName.toLowerCase(), '.jago.cloud', '');
  hostNameRemovePrefix = _.replace(hostNameRemovePrefix, '.staging', '');
  const hostNameRemoveSuffix = _.replace(_.replace(hostNameRemovePrefix, 'api.', ''), 'api-', '');
  return _.upperFirst(hostNameRemoveSuffix);
}

export function formatDomainHostForAgreements(hostName) {
  const hostNameRemovePrefix = _.replace(hostName.toLowerCase(), '.jago.cloud', '');
  const hostNameRemoveSuffix = _.replace(_.replace(hostNameRemovePrefix, 'api.', ''), 'api-', '');
  return hostNameRemoveSuffix;
}

export function formatDomainHostNameToDomainName(hostName) {
  const hostNameRemovePrefix = _.replace(hostName.toLowerCase(), '.jago.cloud', '');
  const hostNameRemoveSuffix = _.replace(_.replace(hostNameRemovePrefix, 'api.', ''), 'api-', '');
  return hostNameRemoveSuffix;
}

export function isDemoSystem() {
  const plantName = window && window.location && window.location.hostname && window.location.hostname.replace('.jago.cloud', '');
  return _.includes(plantName, 'demo') || _.includes(plantName, 'staging') || _.includes(plantName, 'localhost');
}

export function getLuckeyLogoSource(colorScheme) {
  const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
  const isLuckeyRFID = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.RFID]);
  const isLuckeyEngine = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);
  if (isLuckeyLite) {
    if (colorScheme === 'white') return '/imgs/common/luckey_lite_logowhite.png';
    return '/imgs/common/luckey_lite_logodark.png';
  }
  if (isLuckeyRFID) {
    if (colorScheme === 'white') return '/imgs/common/luckey_Essential_logowhite.png';
    return '/imgs/common/luckey_Essential_logodark.png';
  }
  if (isLuckeyEngine) {
    if (colorScheme === 'white') return '/imgs/common/luckey_Engine_logowhite.png';
    return '/imgs/common/luckey_Engine_logodark.png';
  }
  if (colorScheme === 'white') return '/imgs/common/luckey_Enterprise_logowhite.png';
  return '/imgs/common/luckey_Enterprise_logodark.png';
}

export function formatBrowserLanguage(browserLanguage) {
  try {
    let language = _.first(_.split(browserLanguage, '-'));
    if (browserLanguage.includes('en')) language = 'en-US';
    if (_.indexOf(LANGUAGE_LOCALES, language) === -1) return 'en-US';
    return language;
  } catch (error) {
    return 'en-US';
  }
}

export function getStandardDeviceIcon(standardDeviceType) {
  switch (standardDeviceType) {
    case CARD_TYPES.ISEO_PIN: return <PinIcon style={{ fontSize: 30, marginRight: 25, color: '#3f3f3f' }} />;
    case 'F9000': return <KeyOutlinedIconCustom style={{ width: 30, marginRight: 25, color: '#3f3f3f' }} />;
    default: return <CardIcon style={{ color: '#3f3f3f', fontSize: 30, marginRight: 25 }} />;
  }
}

export function getEventOutcomeCategory(outcome) {
  return outcome.substring(0, outcome.lastIndexOf('_'));
}

export function getEventTitleFromOutcome(outcome) {
  if (isSuccessEventFromSplittedOutcome(splitFieldsFromOutcome(outcome))) return 'deviceOpenRequestGrantedTitle';
  return 'openRequestGrantedButFailedTitle';
}

export function getEventDescriptionFromOutcome(outcome) {
  if (outcome === EVENT_OUTCOME.OPEN_SUCCESS) return 'deviceOpenRequestGrantedDescription';
  const baseDescription = 'smartphoneOpenRequestFailedDescription';
  let additionalDescription = '';
  switch (outcome) {
    case EVENT_OUTCOME.CONNECTION_ERROR: {
      additionalDescription = 'ConnectionError';
      break;
    }
    case EVENT_OUTCOME.NOT_CONNECTED: {
      additionalDescription = 'NotConnected';
      break;
    }
    case EVENT_OUTCOME.CMD_UNSUPPORTED: {
      additionalDescription = 'UnsupportedCommand';
      break;
    }
    case EVENT_OUTCOME.CMD_INVALID_REQUEST: {
      additionalDescription = 'InvalidRequest';
      break;
    }
    case EVENT_OUTCOME.CMD_INVALID_RESPONSE: {
      additionalDescription = 'InvalidResponse';
      break;
    }
    case EVENT_OUTCOME.CMD_TIMEOUT: {
      additionalDescription = 'Timeout';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_ALREADY_OPEN: {
      additionalDescription = 'AlreadyOpened';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_ALREADY_OPEN_PASSAGE_MODE: {
      additionalDescription = 'AlreadyOpenedPassageMode';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_DENIED:
    case EVENT_OUTCOME.CMD_EC_DENIED_INVITATION_CODE_NOT_VALID:
    case EVENT_OUTCOME.CMD_EC_DENIED_INVITATION_TIMEPROFILE_NOT_FULFILLED:
    case EVENT_OUTCOME.CMD_EC_DENIED_INVITATION_VALIDITY_END_EXCEEDED:
    case EVENT_OUTCOME.CMD_EC_DENIED_INVITATION_VALIDITY_START_NOT_REACHED:
    case EVENT_OUTCOME.CMD_EC_DENIED_INVITATION_PERMISSIONS_INSUFFICIENT:
    case EVENT_OUTCOME.CMD_EC_DENIED_PASSAGE_MODE_DISABLED:
    case EVENT_OUTCOME.CMD_EC_DENIED_PIN_MISMATCH:
    case EVENT_OUTCOME.CMD_EC_DENIED_PIN_NOT_CHANGEABLE:
    case EVENT_OUTCOME.CMD_EC_DENIED_PIN_NOT_DISABLEABLE:
    case EVENT_OUTCOME.CMD_EC_DENIED_PIN_REQUIRED:
    case EVENT_OUTCOME.CMD_EC_DENIED_PRIVACY:
    case EVENT_OUTCOME.CMD_EC_DENIED_VIP:
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_TOGGLE_PM_NOT_ENABLED:
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_ID_NOT_FOUND:
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_VIP_PLUS_NOT_ENABLED:
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_PASSWORD_MISMATCH:
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_OPEN_INHIBITED:
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_AUTH_MISMATCH: {
      additionalDescription = 'PermissionDenied';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_TIMEPROFILE_NOT_FULFILLED: {
      additionalDescription = 'TimeProfileNotFulfilled';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_VALIDITY_END_EXCEEDED: {
      additionalDescription = 'TimeProfileExpired';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_DENIED_USER_VALIDITY_START_NOT_REACHED: {
      additionalDescription = 'TimeProfileNotActiveYet';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_OPEN_FAULT_EXTRA_LOW_BATTERY: {
      additionalDescription = 'ExtraLowBattery';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_OPEN_FAULT_PRECONDITION: {
      additionalDescription = 'FailedPrecondition';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_PASSWORD_AUTHENTICATION: {
      additionalDescription = 'WrongPassword';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_USER_LIST_IO: {
      additionalDescription = 'UserListIo';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_USER_LIST_FULL: {
      additionalDescription = 'UserListFull';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_SYSTEM_BLOCKED: {
      additionalDescription = 'SystemBlocked';
      break;
    }
    case EVENT_OUTCOME.OFFLINE_OPEN_ERROR: {
      additionalDescription = 'OfflineError';
      break;
    }
    case EVENT_OUTCOME.CMD_EC_USER_FP_TEMPLATE_NOT_FOUND:
    case EVENT_OUTCOME.CMD_EC_USER_FP_TEMPLATE_READ_FAILED:
    case EVENT_OUTCOME.CMD_EC_USER_FP_ID_ALREADY_REGISTERED:
    case EVENT_OUTCOME.CMD_EC_USER_FP_TEMPLATE_SIMILAR:
    case EVENT_OUTCOME.CMD_EC_CFG_GENERIC:
    case EVENT_OUTCOME.CMD_EC_CFG_PARAM_KEY_NOT_FOUND:
    case EVENT_OUTCOME.CMD_EC_CFG_PARAM_VALUE_INVALID:
    case EVENT_OUTCOME.CMD_EC_CFG_PARAM_TYPE_MISMATCH:
    case EVENT_OUTCOME.CMD_EC_CFG_PARAM_WRITE_NOT_PERMITTED:
    case EVENT_OUTCOME.CMD_EC_LNS_NO_FREE_SLOTS:
    case EVENT_OUTCOME.CMD_EC_LNS_DENIED:
    case EVENT_OUTCOME.INTERNAL_ERROR:
    case EVENT_OUTCOME.MASTER_MODE_REQUIRED:
    case EVENT_OUTCOME.IO_GENERIC:
    case EVENT_OUTCOME.IO_INTERRUPTED:
    case EVENT_OUTCOME.FIRMWARE_UPD_GENERIC:
    case EVENT_OUTCOME.FIRMWARE_UPD_DENIED_LOW_BATTERY:
    case EVENT_OUTCOME.FIRMWARE_UPD_DENIED_UNSUPPORTED:
    case EVENT_OUTCOME.FIRMWARE_UPD_INIT_ERROR:
    case EVENT_OUTCOME.FIRMWARE_UPD_IO_ERROR:
    case EVENT_OUTCOME.CMD_EC_GENERIC:
    case EVENT_OUTCOME.CMD_EC_INDEX: {
      additionalDescription = 'GenericError';
      break;
    }
    default:
      break;
  }

  return baseDescription + additionalDescription;
}


export function createChartDataFromLogs(events) {
  const orderedSmartLocksEvents = _.groupBy(events, event => moment(event.timestamp).hours());
  const data = _.reduce(_.keys(orderedSmartLocksEvents), (acc, curr) => {
    acc = {
      ...acc,
      [curr]: _.size(orderedSmartLocksEvents[curr]),
    };
    return acc;
    
  }, {});
  const chartData = {
    labels: _.times(24, n => moment().hours(n).minutes(0).format('LT')),
    datasets: [
      {
        label: L20NContext.getSync('todayAccesses'),
        backgroundColor: 'rgba(27,151,194,0.2)',
        borderColor: 'rgba(27,151,194,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(27,151,194,0.4)',
        hoverBorderColor: 'rgba(27,151,194,1)',
        data: _.times(24, n => data[n] || 0),
      },
    ],
  };
  return chartData;

}

export function getBatteryLevelFromPercentage(batteryPercentage) {
  switch (batteryPercentage) {
    case '0%': {
      return 0;
    }
    case '25%': {
      return 25;
    }
    case '50%' : {
      return 50;
    }
    case '75%' : {
      return 75;
    }
    case '100%': {
      return 100;
    }
    default: return 100;
  }
}

export function convertValueFromUnitToMinutes(value, unitFrom) {
  switch (unitFrom) {
    case OFFLINE_TIME_UNIT.minutes:
      return value;
    case OFFLINE_TIME_UNIT.hours:
      return value * 60;
    case OFFLINE_TIME_UNIT.days:
      return value * 1440;
    default:
      return value;
  }
}

export function convertValueFromUnitToHours(value, unitFrom) {
  switch (unitFrom) {
    case OFFLINE_TIME_UNIT.minutes:
      return value / 60;
    case OFFLINE_TIME_UNIT.hours:
      return value;
    case OFFLINE_TIME_UNIT.days:
      return value * 24;
    default:
      return value;
  }
}

export function convertValueFromUnitToDays(value, unitFrom) {
  switch (unitFrom) {
    case OFFLINE_TIME_UNIT.minutes:
      return value / 1440;
    case OFFLINE_TIME_UNIT.hours:
      return value / 24;
    case OFFLINE_TIME_UNIT.days:
      return value;
    default:
      return value;
  }
}

export function convertValueToNewTimeUnit(value, oldUnit, newUnit) {
  switch (newUnit) {
    case OFFLINE_TIME_UNIT.minutes:
      return convertValueFromUnitToMinutes(value, oldUnit);
    case OFFLINE_TIME_UNIT.hours:
      return convertValueFromUnitToHours(value, oldUnit);
    case OFFLINE_TIME_UNIT.days:
      return convertValueFromUnitToDays(value, oldUnit);
    default: return value;
  }
}

export function userCanDoCheckinOnResource(reservation) {
  const checkInUpperLimitDate = moment(reservation.fromDate).add(reservation.resource.checkInWindowMinutes, 'minutes').valueOf();
  const checkInLowerLimitDate = moment(reservation.fromDate).subtract(reservation.resource.checkInWindowBeforeMinutes, 'minutes').valueOf();
  return (moment().isSameOrBefore(checkInUpperLimitDate) && moment().isSameOrAfter(checkInLowerLimitDate));
}

export function getResidualElementFromLicense(license, resourceType) {
  const usersSubscription = _.find(license.subscriptions, subscription => subscription.resourceType === resourceType);
  if (!usersSubscription) return undefined;
  return (usersSubscription.quantity - usersSubscription.used);
}


export function elaborateWarningThresholds(total) {
  if (total > 30) {
    return {
      warningThreshold: 3,
      errorThreshold: 2,
    };
  }
  return {
    warningThreshold: 1,
    errorThreshold: 0,
  };
}

export function getNotificationTypeStatus(storeKey) {
  let notificationAreDisabled;
  try {
    notificationAreDisabled = JSON.parse(localStorage.getItem(storeKey));
  } catch (error) {
    notificationAreDisabled = false;
  }
  return notificationAreDisabled;
}

export function getStatusColorFromBatteryLevel(batteryLevel) {
  if (batteryLevel !== -1 && batteryLevel > 50 && batteryLevel <= 75) {
    return '#D4C721';
  }
  if (batteryLevel !== -1 && batteryLevel > 25 && batteryLevel <= 50) {
    return '#f4b350';
  }
  if (batteryLevel !== -1 && batteryLevel <= 25) {
    return 'red';
  }
  return 'limegreen';
}

export function splitFieldsFromOutcome(outcome) {
  const fields = _.split(outcome, '_');
  return {
    source: fields.length > 0 ? fields[0] : null,
    deviceType: fields.length > 1 ? fields[1] : null,
    operation: fields.length > 2 ? fields[2] : null,
    result: fields.length > 3 ? fields[3] : null,
  };
}


export function getEventTranslatedOperationSuccessFromSplittedOutcome(splittedOutcome) {
  if (splittedOutcome.source === 'JAGO') {
    switch (splittedOutcome.operation) {
      case '0':
        return 'vegaOperationSuccessDoorOpen';
      default:
        return '-';
    }
  } else if (splittedOutcome.source === 'VEGA') {
    switch (splittedOutcome.operation) {
      // TODO: translate all the possibile V364 operations and return the translation key
      case VEGA_OPERATION_CODES.NO_OPERATION:
        return 'vegaOperationSuccessNoOperation';
      case VEGA_OPERATION_CODES.DOOR_OPEN:
        return 'vegaOperationSuccessDoorOpen';
      case VEGA_OPERATION_CODES.OFFICE_MODE_ENABLED:
        return 'vegaOperationSuccessOfficeModeEnabled';
      case VEGA_OPERATION_CODES.OFFICE_MODE_DISABLED:
        return 'vegaOperationSuccessOfficeModeDisabled';
      case VEGA_OPERATION_CODES.EMERGENCY_OPEN_STATUS_ENABLED:
        return 'vegaOperationSuccessEmergencyOpenEnabled';
      case VEGA_OPERATION_CODES.EMERGENCY_OPEN_STATUS_DISABLED:
        return 'vegaOperationSuccessEmergencyOpenDisabled';
      case VEGA_OPERATION_CODES.EMERGENCY_OPEN_STATUS_ENABLED_DATE_INVALID:
        return 'vegaOperationSuccessEmergencyOpenEnabledDateInvalid';
      case VEGA_OPERATION_CODES.DOOR_OPEN_WITH_EMERGENCY_KEY:
        return 'vegaOperationSuccessDoorOpenWithEmergencyKey';
      case VEGA_OPERATION_CODES.DOOR_OPEN_WITH_EMERGENCY_KEY_DATE_INVALID:
        return 'vegaOperationSuccessDoorOpenWithEmergencyKeyDateInvalid';
      case VEGA_OPERATION_CODES.DOOR_OPEN_WITH_FIRE_RESCUE:
        return 'vegaOperationSuccessDoorOpenWithFireRescue';
      case VEGA_OPERATION_CODES.LOCKOUT_ENABLED:
        return 'vegaOperationSuccessLockoutEnabled';
      case VEGA_OPERATION_CODES.LOCKOUT_REMOVED:
        return 'vegaOperationSuccessLockoutRemoved';
      case VEGA_OPERATION_CODES.KEY_INSERTED_IN_BLACK_LIST:
        return 'vegaOperationSuccessKeyInsertedInBlackList';
      case VEGA_OPERATION_CODES.KEY_ACTIVATED_BY_PIN_CODE:
        return 'vegaOperationSuccessKeyActivatedByPinCode';
      case VEGA_OPERATION_CODES.KEY_EXTRACTED_FROM_CYLINDER:
        return 'vegaOperationSuccessKeyExtractedFromCylinder';
      case VEGA_OPERATION_CODES.OFFICE_MODE_ENABLED_USING_TIME_PROFILE:
        return 'vegaOperationSuccessOfficeModeEnabledUsingTimeProfile';
      case VEGA_OPERATION_CODES.OFFICE_MODE_DISABLED_USING_TIME_PROFILE:
        return 'vegaOperationSuccessOfficeModeDisabledUsingTimeProfile';
      case VEGA_OPERATION_CODES.PRIVACY_MODE_ENABLED:
        return 'vegaOperationSuccessPrivacyModeEnabled';
      case VEGA_OPERATION_CODES.PRIVACY_MODE_DISABLED:
        return 'vegaOperationSuccessPrivacyModeDisabled';
      case VEGA_OPERATION_CODES.OWNERSHIP_STATUS_CLEARED:
        return 'vegaOperationSuccessOwnershipStatusCleared';
      case VEGA_OPERATION_CODES.VALIDATION_INHIBIT_MODE_ENABLED:
        return 'vegaOperationSuccessValidationInhibitModeEnabled';
      case VEGA_OPERATION_CODES.VALIDATION_INHIBIT_MODE_DISABLED:
        return 'vegaOperationSuccessValidationInhibitModeDisbled';
      case VEGA_OPERATION_CODES.VALIDATION_INHIBIT_MODE_DISABLED_TIMEOUT_EXPIRED:
        return 'vegaOperationSuccessValidationInhibitModeDisbledTimeoutExpired';
      case VEGA_OPERATION_CODES.MAINTENANCE_CARD:
        return 'vegaOperationSuccessMaintenanceCard';
      case VEGA_OPERATION_CODES.SETUP_MODE_ENABLED:
        return 'vegaOperationSuccessSetupModeEnabled';
      case VEGA_OPERATION_CODES.MANUAL_OFFICE_MODE_DISABLED_WITH_TIME_PROFILE:
        return 'vegaOperationSuccessManualOfficeModeDisabledWithTimeProfile';
      case VEGA_OPERATION_CODES.MAINTENANCE_CARD_INVALID_DATE:
        return 'vegaOperationSuccessMaintenanceCardInvalidDate';
      case VEGA_OPERATION_CODES.DOOR_OPEN_WITH_MECHANICAL_KEY:
        return 'vegaOperationSuccessDoorOpenWithMechanicalKey';
      case VEGA_OPERATION_CODES.KEY_REMOVED_FROM_CYLINDER:
        return 'vegaOperationSuccessKeyRemovedFromCylinder';
      case VEGA_OPERATION_CODES.REMOTE_OPEN:
        return 'vegaOperationSuccessRemoteOpen';
      case VEGA_OPERATION_CODES.DOOR_INIT:
        return 'vegaOperationSuccessDoorInit';
      case VEGA_OPERATION_CODES.DOOR_FACTORY_RESET:
        return 'vegaOperationSuccessDoorFactoryReset';
      case VEGA_OPERATION_CODES.DOOR_UPDATED:
        return 'vegaOperationSuccessDoorUpdated';
      case VEGA_OPERATION_CODES.DOOR_POSITION_REPORTING:
        return 'vegaOperationSuccessDoorPositionReporting';
      case VEGA_OPERATION_CODES.CSF_SERVICE_KEY:
        return 'vegaOperationSuccessCSFServiceKey';
      case VEGA_OPERATION_CODES.KEY_UNKNOWN_BY_THE_SYSTEM:
        return 'vegaOperationSuccessKeyUnknownByTheSystem';

      default:
        return '-';
    }
  }

  return '-';
}


export function getLogIconFromDeviceType(deviceType) {
  switch (deviceType) {
    case 'MOBILE':
      return <SmartphoneIcon style={{ fontSize: 35, color: '#3f3f3f' }} />;
    case 'F9000':
      return <KeyOutlinedIconCustom alt="f9000" style={{ width: 35, transform: 'rotate(90deg)', color: '#3f3f3f' }} />;
    case 'RFID':
      return <CardIcon style={{ fontSize: 35, color: '#3f3f3f' }} />;
    case 'GATEWAY':
      return <HyperGateIcon style={{ fontSize: 35, color: '#3f3f3f' }} />;
    case 'PIN':
      return <PinIcon style={{ fontSize: 35, color: '#3f3f3f' }} />;
    default:
      return <SmartphoneIcon style={{ fontSize: 35, color: '#3f3f3f' }} />;
  }
}

export function getEventTranslatedOperationFailedFromSplittedOutcome(splittedOutcome) {
  if (splittedOutcome.source === 'JAGO') {
    switch (splittedOutcome.operation) {
      case '1':
        return 'vegaOperationErrorDoorOpen';
      default:
        return '-';
    }
  } else if (splittedOutcome.source === 'VEGA') {
    switch (splittedOutcome.operation) {
      case VEGA_OPERATION_CODES.NO_OPERATION:
        return 'vegaOperationErrorNoOperation';
      case VEGA_OPERATION_CODES.DOOR_OPEN:
        return 'vegaOperationErrorDoorOpen';
      case VEGA_OPERATION_CODES.OFFICE_MODE_ENABLED:
        return 'vegaOperationErrorOfficeModeEnabled';
      case VEGA_OPERATION_CODES.OFFICE_MODE_DISABLED:
        return 'vegaOperationErrorOfficeModeDisabled';
      case VEGA_OPERATION_CODES.EMERGENCY_OPEN_STATUS_ENABLED:
        return 'vegaOperationErrorEmergencyOpenEnabled';
      case VEGA_OPERATION_CODES.EMERGENCY_OPEN_STATUS_DISABLED:
        return 'vegaOperationErrorEmergencyOpenDisabled';
      case VEGA_OPERATION_CODES.EMERGENCY_OPEN_STATUS_ENABLED_DATE_INVALID:
        return 'vegaOperationErrorEmergencyOpenEnabledDateInvalid';
      case VEGA_OPERATION_CODES.DOOR_OPEN_WITH_EMERGENCY_KEY:
        return 'vegaOperationErrorDoorOpenWithEmergencyKey';
      case VEGA_OPERATION_CODES.DOOR_OPEN_WITH_EMERGENCY_KEY_DATE_INVALID:
        return 'vegaOperationErrorDoorOpenWithEmergencyKeyDateInvalid';
      case VEGA_OPERATION_CODES.DOOR_OPEN_WITH_FIRE_RESCUE:
        return 'vegaOperationErrorDoorOpenWithFireRescue';
      case VEGA_OPERATION_CODES.LOCKOUT_ENABLED:
        return 'vegaOperationErrorLockoutEnabled';
      case VEGA_OPERATION_CODES.LOCKOUT_REMOVED:
        return 'vegaOperationErrorLockoutRemoved';
      case VEGA_OPERATION_CODES.KEY_INSERTED_IN_BLACK_LIST:
        return 'vegaOperationErrorKeyInsertedInBlackList';
      case VEGA_OPERATION_CODES.KEY_ACTIVATED_BY_PIN_CODE:
        return 'vegaOperationErrorKeyActivatedByPinCode';
      case VEGA_OPERATION_CODES.KEY_EXTRACTED_FROM_CYLINDER:
        return 'vegaOperationErrorKeyExtractedFromCylinder';
      case VEGA_OPERATION_CODES.OFFICE_MODE_ENABLED_USING_TIME_PROFILE:
        return 'vegaOperationErrorOfficeModeEnabledUsingTimeProfile';
      case VEGA_OPERATION_CODES.OFFICE_MODE_DISABLED_USING_TIME_PROFILE:
        return 'vegaOperationErrorOfficeModeDisabledUsingTimeProfile';
      case VEGA_OPERATION_CODES.PRIVACY_MODE_ENABLED:
        return 'vegaOperationErrorPrivacyModeEnabled';
      case VEGA_OPERATION_CODES.PRIVACY_MODE_DISABLED:
        return 'vegaOperationErrorPrivacyModeDisabled';
      case VEGA_OPERATION_CODES.OWNERSHIP_STATUS_CLEARED:
        return 'vegaOperationErrorOwnershipStatusCleared';
      case VEGA_OPERATION_CODES.VALIDATION_INHIBIT_MODE_ENABLED:
        return 'vegaOperationErrorValidationInhibitModeEnabled';
      case VEGA_OPERATION_CODES.VALIDATION_INHIBIT_MODE_DISABLED:
        return 'vegaOperationErrorValidationInhibitModeDisbled';
      case VEGA_OPERATION_CODES.VALIDATION_INHIBIT_MODE_DISABLED_TIMEOUT_EXPIRED:
        return 'vegaOperationErrorValidationInhibitModeDisbledTimeoutExpired';
      case VEGA_OPERATION_CODES.MAINTENANCE_CARD:
        return 'vegaOperationErrorMaintenanceCard';
      case VEGA_OPERATION_CODES.SETUP_MODE_ENABLED:
        return 'vegaOperationErrorSetupModeEnabled';
      case VEGA_OPERATION_CODES.MANUAL_OFFICE_MODE_DISABLED_WITH_TIME_PROFILE:
        return 'vegaOperationErrorManualOfficeModeDisabledWithTimeProfile';
      case VEGA_OPERATION_CODES.MAINTENANCE_CARD_INVALID_DATE:
        return 'vegaOperationErrorMaintenanceCardInvalidDate';
      case VEGA_OPERATION_CODES.DOOR_OPEN_WITH_MECHANICAL_KEY:
        return 'vegaOperationErrorDoorOpenWithMechanicalKey';
      case VEGA_OPERATION_CODES.KEY_REMOVED_FROM_CYLINDER:
        return 'vegaOperationErrorKeyRemovedFromCylinder';
      case VEGA_OPERATION_CODES.REMOTE_OPEN:
        return 'vegaOperationErrorRemoteOpen';
      case VEGA_OPERATION_CODES.DOOR_INIT:
        return 'vegaOperationErrorDoorInit';
      case VEGA_OPERATION_CODES.DOOR_FACTORY_RESET:
        return 'vegaOperationErrorDoorFactoryReset';
      case VEGA_OPERATION_CODES.DOOR_UPDATED:
        return 'vegaOperationErrorDoorUpdated';
      case VEGA_OPERATION_CODES.DOOR_POSITION_REPORTING:
        return 'vegaOperationErrorDoorPositionReporting';
      case VEGA_OPERATION_CODES.CSF_SERVICE_KEY:
        return 'vegaOperationErrorCSFServiceKey';
      case VEGA_OPERATION_CODES.KEY_UNKNOWN_BY_THE_SYSTEM:
        return 'vegaOperationErrorKeyUnknownByTheSystem';
      default:
        return '-';
    }
  }

  return '-';
}

export function getEventTranslatedFailureReasonFromSplittedOutcome(splittedOutcome) {
  if (splittedOutcome.source === 'JAGO') {
    switch (splittedOutcome.result) {
      case '1':
        return '';
      default:
        return '';
    }
  } else if (splittedOutcome.source === 'VEGA') {
    switch (splittedOutcome.result) {
      case VEGA_RESULTS_CODES.OPERATION_COMPLETED_SUCCESSFULLY:
        return 'vegaOperationReasonCompletedSuccessfully';
      case VEGA_RESULTS_CODES.WRONG_CUSTOMER_CODE:
        return 'vegaOperationReasonWrongCustomerCode';
      case VEGA_RESULTS_CODES.WRONG_SITE_CODE:
        return 'vegaOperationReasonWrongSiteCode';
      case VEGA_RESULTS_CODES.CREDENTIAL_NOT_ENABLED_FOR_GATE:
        return 'vegaOperationReasonNotEnabledForGate';
      case VEGA_RESULTS_CODES.PIN_CODE_NOT_VALID_FOR_OPERATION:
        return 'vegaOperationReasonPinCodeNotValidForOperation';
      case VEGA_RESULTS_CODES.CREDENTIAL_OUT_OF_VALIDITY_PERIOD:
        return 'vegaOperationReasonCredentialOutOfValidityPeriod';
      case VEGA_RESULTS_CODES.VALIDATION_PERIOD_EXPIRED:
        return 'vegaOperationReasonValidationPeriodExpired';
      case VEGA_RESULTS_CODES.VALIDATION_PERIOD_SUSPENDED:
        return 'vegaOperationReasonValidationPeriodSuspended';
      case VEGA_RESULTS_CODES.VALIDATION_MODE_NOT_SUPPORTED:
        return 'vegaOperationReasonValidationModeNotSupported';
      case VEGA_RESULTS_CODES.CREDENTIAL_INVALID_TIME_RANGE:
        return 'vegaOperationReasonCredentialInvalidTimeRange';
      case VEGA_RESULTS_CODES.PRIVACY_STATUS_IS_ON:
        return 'vegaOperationReasonPrivacyStatusIsOn';
      case VEGA_RESULTS_CODES.CREDENTIAL_OUT_OF_VALIDITY_PERIOD_PROFILE_ENABLED:
        return 'vegaOperationReasonCredentialOutOfValidiyPeriodProfileEnabled';
      case VEGA_RESULTS_CODES.KEY_IS_LOCKED_OUT_MODE:
        return 'vegaOperationReasonKeyIsInLockedOutMode';
      case VEGA_RESULTS_CODES.FUNCTION_NOT_SUPPORTED_FOR_THIS_GATE:
        return 'vegaOperationReasonFunctionNotSupportedForGate';
      case VEGA_RESULTS_CODES.FUNCTION_IS_INHIBITED_ON_THIS_GATE:
        return 'vegaOperationReasonFunctioInhibitedForGate';
      case VEGA_RESULTS_CODES.FUNCTION_IS_USELESS_FOR_THIS_GATE:
        return 'vegaOperationReasonFunctionIsUselessForGate';
      case VEGA_RESULTS_CODES.KEY_IS_IN_BLACK_LIST:
        return 'vegaOperationReasonKeyIsInBlacklist';
      case VEGA_RESULTS_CODES.BLACKLIST_IS_FULL:
        return 'vegaOperationReasonBlacklistIsFull';
      case VEGA_RESULTS_CODES.KEY_NO_MORE_OWNERSHIP_OF_THE_GATE:
        return 'vegaOperationReasonKeyNoMoreOwnershipOfGate';
      case VEGA_RESULTS_CODES.KEY_ARE_NOT_ACTIVATED:
        return 'vegaOperationReasonKeyAreNotActivated';
      case VEGA_RESULTS_CODES.CARD_INVALIDATED_DURING_WRITE_OPERATION:
        return 'vegaOperationReasonCardInvalidatedDuringWriteOperation';
      case VEGA_RESULTS_CODES.ERROR_WRITING_LOG:
        return 'vegaOperationReasonErrorWritingLog';
      case VEGA_RESULTS_CODES.KEY_LOGS_ARE_FULL:
        return 'vegaOperationReasonKeyLogsAreFull';
      case VEGA_RESULTS_CODES.INVALID_KEY_CREDENTIAL_ID_NULL:
        return 'vegaOperationReasonInvalidCredentialIdNull';
      case VEGA_RESULTS_CODES.CREDENTIAL_NOT_FOUND_IN_ATLAS:
        return 'vegaOperationReasonCredentialNotFoundInAtlas';
      case VEGA_RESULTS_CODES.UNSUPPORTED_FUNCTION:
        return 'vegaOperationReasonUnsupportedFunction';
      case VEGA_RESULTS_CODES.GENERIC_ERROR:
        return 'vegaOperationReasonGenericError';
      default:
        return '-';
    }
  }

  return '-';
}

export function isSuccessEventFromSplittedOutcome(splittedOutcome) {
  if (splittedOutcome.source === 'JAGO') {
    return splittedOutcome.operation === '0';
  }
  return splittedOutcome.result === '0';
}

export async function convertCSVToJSON(csvOrigin) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(csvOrigin);
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      const binaryStr = reader.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' }); // parse the array buffer
      const workSheet = workbook.Sheets[workbook.SheetNames[0]]; // get the first worksheet
      const userJson = XLSX.utils.sheet_to_json(workSheet);
      resolve(userJson);
      return userJson;
    };
  });
}


export function generateTemplateCSV() {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const templateData = [{
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    companyName: '',
    password: '',
    tags: 'tag1,tag2',
  }];
  const ws = XLSX.utils.json_to_sheet(templateData);
  const wb = { Sheets: { users: ws }, SheetNames: ['users'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, 'Luckey_Users_Template.xlsx');
}

export function getIconFromSubscriptionResource(resource) {
  switch (resource) {
    case 'DOMAIN':
      return <BuildingIconCustom style={{ width: 30, color: '#3f3f3f', alignSelf: 'flex-start' }} />;
    case 'BOOKEY':
      return <AgendaIconCustom style={{ width: 30, color: '#3f3f3f' }} />;
    case 'COMMUNICATIONS':
      return <CommunicationIconCustom style={{ width: 30, color: '#3f3f3f' }} />;
    case 'BATCH_OPERATIONS':
      return <UsersIcon style={{ fontSize: 30, alignSelf: 'center', color: '#3f3f3f' }} />;
    case 'ACL':
      return <TwoFactorIconCustom style={{ width: 30, alignSelf: 'center', color: '#3f3f3f' }} />;
    case 'REGISTRY':
      return <RegistryIcon style={{ fontSize: 30, alignSelf: 'center', color: '#3f3f3f' }} />;
    case 'INVITATIONS':
      return <InvitationsIcon style={{ fontSize: 30, alignSelf: 'center', color: '#3f3f3f' }} />;
    default:
      return <TagCheckIconCustom style={{ width: 30, color: '#3f3f3f' }} />;
  }
}

export function elaborateConsumptionChartData(consumptionsData) {
  const orderedConsumptionData = _.orderBy(consumptionsData, 'createdAt', 'asc');
  const groupedConsumptionData = _.groupBy(orderedConsumptionData, event => event.createdAt);
  const chartData = _.map(_.keys(groupedConsumptionData), (curr) => _.sumBy(groupedConsumptionData[curr], event => event.quantity));
  const creditsData = _.map(_.keys(groupedConsumptionData), (curr) => {
    const lastEvent = _.first(groupedConsumptionData[curr]);
    return lastEvent.totalAvailable;
  });
  const labels = _.map(_.keys(groupedConsumptionData), (eventDate) => {
    return moment(eventDate, 'x').format('LL');
  });
  return {
    labels,
    datasets: [
      {
        type: 'bar',
        label: L20NContext.getSync('consumptionRowTitle'),
        data: chartData,
        backgroundColor: 'rgba(244, 179, 80, 0.2)',
        borderColor: 'rgba(244, 179, 80, 1)',
        hoverBackgroundColor: 'rgba(244, 179, 80, 0.4)',
        hoverBorderColor: 'rgba(244, 179, 80, 1)',
        borderWidth: 1,
        tension: 0,
      },
      {
        type: 'line',
        label: L20NContext.getSync('totalCredits'),
        data: creditsData,
        backgroundColor: 'rgba(74, 143, 150, 1)',
        borderColor: 'rgba(74, 143, 150, 1)',
        borderWidth: 2,
        tension: 0.5,
        fill: false,
      },
    ],
  };
}

export function elaborateRechargeChartData(rechargeData) {
  const orderedData = _.orderBy(rechargeData, 'createdAt', 'asc');
  const groupedRechargeData = _.groupBy(orderedData, event => moment(event.createdAt).date());
  const chartData = _.map(groupedRechargeData, (curr) => _.sumBy(curr, event => event.quantity));
  const labels = _.map(groupedRechargeData, (eventDate) => {
    if (_.isArray(eventDate) && _.first(eventDate).createdAt) {
      const event = _.first(eventDate);
      return moment(event.createdAt, 'x').format('LL');
    }
  });
  return {
    labels,
    datasets: [{
      label: L20NContext.getSync('rechargeChartTooltip'),
      data: chartData,
      backgroundColor: 'rgba(74,143,150,0.2)',
      borderColor: 'rgba(74,143,150, 1)',
      hoverBackgroundColor: 'rgba(74,143,150,0.4)',
      hoverBorderColor: 'rgba(74,143,150, 1)',
      borderWidth: 1,
      tension: 0,
    }],
  };
}


export function calculateNextRechargeDate(totalCredits, burnRate) {
  if (burnRate === 0) {
    return moment('2999-12-31').valueOf();
  }
  const days = totalCredits / burnRate;
  return moment().add(days, 'days').valueOf();
}

export function getDisplayCredits(credits) {
  return credits / 100;
}

export function getMonthlyBurnRate(burnRate) {
  return _.ceil(((burnRate / 100) * 365) / 12);
}

export function getHelpCenterLanguagePrefix() {
  let decodedJWT;
  let languagePrexif = 'en';
  if (localStorage.user) {
    decodedJWT = jwt.decode(localStorage.user);
    if (decodedJWT && decodedJWT.languageType && LANGUAGE_DETAILS[decodedJWT.languageType]) {
      languagePrexif = LANGUAGE_DETAILS[decodedJWT.languageType].helpCenterLanguage;
    }
  }
  return languagePrexif;
}

export function getStoreLanguagePrefix() {
  let decodedJWT;
  let languagePrexif = 'en';
  if (localStorage.user) {
    decodedJWT = jwt.decode(localStorage.user);
    if (decodedJWT && decodedJWT.languageType && LANGUAGE_DETAILS[decodedJWT.languageType]) {
      languagePrexif = LANGUAGE_DETAILS[decodedJWT.languageType].storeLanguagePrefix;
    }
  }
  return languagePrexif;
}


export function localizeHelpCenterLink(url) {
  const languagePrexif = getHelpCenterLanguagePrefix();
  return `https://help-center.sofialocks.com/${languagePrexif}/${url}`;
}


export function formatOutputCustomFieldValue(value, customFieldType) {
  switch (customFieldType) {
    case CUSTOM_FIELDS_TYPES.DATE: {
      if (value) {
        return moment(value).startOf('day').valueOf();
      }
      return '';
    }
    case CUSTOM_FIELDS_TYPES.NUMBER:
      return _.toNumber(value);
    case CUSTOM_FIELDS_TYPES.TEXT:
      return _.toString(value);
    default:
      return _.toString(value);
  }
}

export function formatInputCustomFieldValue(value, customFieldType) {
  switch (customFieldType) {
    case CUSTOM_FIELDS_TYPES.DATE:
      return moment(value).startOf('day').format('LL');
    case CUSTOM_FIELDS_TYPES.NUMBER:
      return _.toNumber(value);
    case CUSTOM_FIELDS_TYPES.TEXT:
      return _.toString(value);
    default:
      return _.toString(value);
  }
}

export function elaborateCustomFieldsEntityAttributes(customFieldsData, entityCustomAttributes) {
  let customFields = {};
  if (customFieldsData && entityCustomAttributes) {
    customFields = _.reduce(_.keys(entityCustomAttributes), (acc, curr) => {
      const field = _.find(customFieldsData, customField => customField.keyValue === curr);
      if (field && field.name) {
        const value = formatInputCustomFieldValue(entityCustomAttributes[curr], field.type);
        acc = {
          ...acc,
          [field.name]: value,
        };
      }
      return acc;
    }, {});
  }
  return customFields;
}

export function canConfigureOfficeMode(lock) {
  return lock.connectionType !== 'SMART_CLASSIC_OFFLINE' && lock.model && _.indexOf(OFFICE_MODE_SMARTLOCK_MODELS_ENABLED, lock.model) !== -1;
}


export function isLockSupportingPIN(smartLock) {
  const isStylosDisplay = smartLock.model === 'STYLOS_DISPLAY';
  const isX1R = smartLock.model === 'X1REVO' || smartLock.model === 'IMArgoLib.X1revo';
  const isMullion = smartLock.model === 'MULLION_KB' || smartLock.model === 'MULLION_RELAY_KB';
  const hasPINCapabilities = smartLock && smartLock.capabilities &&  _.indexOf(smartLock.capabilities, 'PIN') !== -1;
  return isStylosDisplay || isX1R || isMullion || hasPINCapabilities;
}

export function isLockSupportingRFID(smartLock) {
  const isSmartRelay = smartLock.model === 'SMART_RELAY' && smartLock.connectionType === 'SMART';
  const hasRFIDCapabilities = smartLock && smartLock.capabilities &&  _.indexOf(smartLock.capabilities, 'RFID') !== -1;
  return !isSmartRelay || hasRFIDCapabilities;
}

export function isLockSupportingLockerCompartmentFunctionality(smartLock) {
  return smartLock.configuration && smartLock.configuration.remoteOpenEnabled && smartLock.connectionType==='SMART_CLASSIC_OFFLINE';
}

export function formatAccessHistoryValueToLabel(data) {
  switch (data) {
    case 2:
      return L20NContext.getSync('doorStatusOpened');
    case 1:
      return L20NContext.getSync('doorStatusClosed');
    case 0:
      return L20NContext.getSync('doorStatusUnknown');
    default:
      return L20NContext.getSync('doorStatusUnknown');
  }
}

export function formatAccessoryStatusToValue(doorStatus) {
  switch (doorStatus) {
    case SMARTLOCK_DOOR_STATUS.CLOSED:
      return 1;
    case SMARTLOCK_DOOR_STATUS.OPENED:
      return 2;
    case SMARTLOCK_DOOR_STATUS.UNKNOWN:
      return 0;
    default:
      return 0;
  }
}


export function elaborateAccessoryHistoryChartData(accessoryHistoryData) {
  const orderedHistoryData = _.orderBy(accessoryHistoryData, 'timestamp', 'asc');
  const groupedHistoryData = _.groupBy(orderedHistoryData, event => event.timestamp);
  const creditsData = _.map(_.keys(groupedHistoryData), (curr) => {
    const lastEvent = _.first(groupedHistoryData[curr]);
    return formatAccessoryStatusToValue(lastEvent.value);
  });
  const labels = _.map(_.keys(groupedHistoryData), (eventDate) => {
    return moment(eventDate, 'x').format('MMMM Do - HH:mm');
  });
  return {
    labels,
    datasets: [
      {
        type: 'line',
        label: L20NContext.getSync('sensorStatus'),
        data: creditsData,
        backgroundColor: 'rgba(74, 143, 150, 1)',
        borderColor: 'rgba(74, 143, 150, 1)',
        fill: false,
        steppedLine: true,
      },
    ],
  };
}

export function isLicenseBasic(subscriptions) {
  const domainSubscription = _.find(subscriptions, subscription => subscription && subscription.resource === 'DOMAIN');
  return domainSubscription && _.includes(domainSubscription.sku, '018S');
}

export function isLicenseRFID(subscriptions) {
  const domainSubscription = _.find(subscriptions, subscription => subscription && subscription.resource === 'DOMAIN');
  return domainSubscription && _.includes(domainSubscription.sku, '025S');
}

export function isLicenseEngine(subscriptions) {
  const domainSubscription = _.find(subscriptions, subscription => subscription && subscription.resource === 'DOMAIN');
  return domainSubscription && _.includes(domainSubscription.sku, '024S');
}

export function elaborateTimeProfileForExport(timeProfile, index) {
  return {
    [`Time Profile #${index +1} Smart Locks Tags`]: _.map(timeProfile.lockTags, tag => tag.name).join(', '),
    [`Time Profile #${index +1} Valid From Date`]: moment(timeProfile.dateInterval.from).format('DD MMMM YYYY'),
    [`Time Profile #${index +1} Valid From Time`]: moment(timeProfile.timeIntervalFrom).format('LT'),
    [`Time Profile #${index +1} Valid To Date`]: moment(timeProfile.dateInterval.to).format('DD MMMM YYYY'),
    [`Time Profile #${index +1} Valid To Time`]: moment(timeProfile.timeIntervalTo).format('LT'),
    [`Time Profile #${index +1} Days`]: _.map(timeProfile.daysOfTheWeek, weekday => moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday())).join(', '),
    ...elaborateTimeRangeForExport(timeProfile.additionalTimeRange),
  }
}

export function elaborateTimeRangeForExport(timeRange) {
  if (timeRange) {
    return {
      'Time Range 1: Valid From Time': moment(timeRange.timeIntervalFrom).format('LT'),
      'Time Range 1: Valid To Time': moment(timeRange.timeIntervalTo).format('LT'),
      'Time Range 1: Days': _.map(timeRange.daysOfTheWeek, weekday => moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday())).join(', '),
    }
  }
}

export function isUserWithoutEmail(user) {
  if (!user) return true;
  return user.withoutEmail || (user.email && user.email.toLowerCase().includes('anonymoususer'));
}

export function decodeUnicode(input) {
   // Regular expression to find characters in the form '&#xxxx'
   const regex = /&#(\d+);/g;

   // Use the replace method with a callback function
   return input.replace(regex, (match, charCode) => {
     // Convert the matched character code to the actual character
     return String.fromCharCode(parseInt(charCode, 10));
   });
}

export function areSmartLocksSelectedOverlapping(locksFilteredByTags) {
  const smartLocksSelected = _.reduce(locksFilteredByTags, (acc, curr, index) => {
    acc = [
      ...acc,
      ...curr.data
    ];
    return acc;
  }, []);
  const intersection = _.uniqBy(smartLocksSelected, 'id');
  return intersection.length !== smartLocksSelected.length;
}

export function formatGatewayVersion(firmwareVersion) {
  const version =  firmwareVersion ? _.split(firmwareVersion, 'SW: ')[1] : '---';
  return version || firmwareVersion;
}

export function removeAccessTokenFromURL(url) {
  const urlObject = new URL(url);
  urlObject.searchParams.delete('access_token');
  return urlObject.toString();
}

export function getChainelsBaeUrl() {
  const plantName = window && window.location && window.location.hostname && window.location.hostname.replace('.jago.cloud', '');
  const isStagingSystem = _.includes(plantName, 'staging') || _.includes(plantName, 'localhost');
  let chainelsURL = CHAINELS_BASE_URL.staging;
  if (!isStagingSystem) {
    chainelsURL = CHAINELS_BASE_URL.production;
  }
  return chainelsURL
}

export function sanitizeUserImportTagsSections(tagsString) {
  // Replace spaces after commas with nothing
  return tagsString.replace(/,\s+/g, ',');
}

export function formatSubscriptionsToReadableEntity(subscription) {
  let name = subscription.name.replace('LKYENT SAAS', 'Luckey Enterprise').replace('LUCKEY BASIC', 'Luckey Basic');
  name =  _.includes(name, 'Basic')  ? name.substring(0, 16) : name.substring(0, 21);
  name = _.includes(name, 'P') ? `${name}L` : name; //sorry
  return {
    ...subscription,
    friendlyName: subscription.name,
  }
}

function extractSubstring(inputString) {
  // Define a regular expression to match the desired substring pattern
  const regex = /^(.*?)\s+T\d+/;

  // Use the match method to extract the substring based on the regex
  const match = inputString.match(regex);

  // Check if a match is found
  if (match && match.length > 1) {
      // Return the first captured group which corresponds to the desired substring
      return match[1];
  } else {
      // Return null if no match is found
      return inputString;
  }
}


export function formatWalletSubscriptionsToReadableEntity(subscription) {
  let name = subscription.name.replace('BKYENT', 'Bookey Enterprise').replace('SAAS', '').replace('-', ' ');
  name = extractSubstring(name);
  return {
    ...subscription,
    friendlyName: _.capitalize(_.trim(name)),
  }
}

export function isLocalhost() {
  return (
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );
}

export function isValidLuckeyDomain() {
  const isLocalhostDomain = isLocalhost();
  const isJagoCloudDomain = _.endsWith(window.location.hostname, '.jago.cloud');
  return isLocalhostDomain || isJagoCloudDomain;
}

export function elaborateSmartLockTroubleShootingId(smartlock) {
  let articleId = smartlock.model;
  const isOnline = (smartlock && smartlock.configuration && smartlock.configuration.remoteOpenEnabled) || (smartlock && smartlock.gatewayId);
  articleId += isOnline ? '_ONLINE_' : '_OFFLINE_';
  const isDoL = smartlock && smartlock.vendor && smartlock.connectionType && smartlock.vendor === VENDORS_LIST.ISEO_ARGO && smartlock.connectionType === 'SMART';
  articleId += isDoL ? 'DOL' : 'DOC';
  return articleId;
}

export function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const type = 'image/jpeg';
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

export function generateRandomNumber(numDigits) {
  // Calculate the minimum and maximum values for the specified number of digits
  const min = Math.pow(10, numDigits - 1);
  const max = Math.pow(10, numDigits) - 1;
  
  // Generate a random integer within the specified range
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  
  return randomNumber;
}


export function getIseoZendeskParameters() {
  let params = {
    url: ISEO_ZENDESK_URL.production,
    apiKey: ISEO_ZENDESK_API_KEY.production, 
    email: ISEO_ZENDESK_EMAIL.production,
    groupId: ISEO_ZENDESK_GROUP_ID.production,
  }
  return params;
}

export function getInvitationBaseUrl() {
  const plantName = window && window.location && window.location.hostname && window.location.hostname.replace('.jago.cloud', '');
  const isStagingSystem = _.includes(plantName, 'staging') || isLocalhost();
  if (isStagingSystem) {
    return DEVELOPERS_URL.staging;
  }
  return DEVELOPERS_URL.production;
}

export function getWeekdayListFromCredendial(credential) {
  let credentialDays = [];
  if (credential.mon) credentialDays.push(0);
  if (credential.tue) credentialDays.push(1);
  if (credential.wed) credentialDays.push(2);
  if (credential.thu) credentialDays.push(3);
  if (credential.fri) credentialDays.push(4);
  if (credential.sat) credentialDays.push(5);
  if (credential.sun) credentialDays.push(6);
  return credentialDays;
}

export async function currentPositionMatchesGeoHash(currentPosition, lockGeoHashSha256, geoHashPrecision) {
  if (!currentPosition) {
    return false;
  }
  const currenPositionGeoHash = Geohash.encode(currentPosition.latitude, currentPosition.longitude, geoHashPrecision);
  const currenPositionGeoHashSha256 = await SHA256(currenPositionGeoHash).toString();
  return currenPositionGeoHashSha256 === lockGeoHashSha256;
}


export function formatClusterForZenDeskPortal(cluster) {
  if (cluster === 'France') return 'france.';
  return _.snakeCase(_.lowerCase(cluster));
}

export function calculateCompaniesToAddAndRemove(newSelectedIds, oldSelectedIds) {
    // Convert arrays to sets for easy comparison
    const newSet = new Set(newSelectedIds);
    const oldSet = new Set(oldSelectedIds);

    // Find ids that are new in the newSelectedIds
    const addedIds = newSelectedIds.filter(id => !oldSet.has(id));

    // Find ids that are removed in the oldSelectedIds
    const removedIds = oldSelectedIds.filter(id => !newSet.has(id));

    return { addedIds, removedIds };
}

export function isValidEmail(email) {
  // Regular expression for basic email validation
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}

export function detectBrowserLanguage() {
  try {
    const browserLanguage = window.navigator.language;
    if (_.includes(browserLanguage,'en')) return LANGUAGES.ENGLISH;
    if (_.includes(browserLanguage, 'it')) return LANGUAGES.ITALIAN;
    if (_.includes(browserLanguage, 'fr')) return LANGUAGES.FRENCH;
    if (_.includes(browserLanguage, 'de')) return LANGUAGES.GERMAN;
    if (_.includes(browserLanguage, 'es')) return LANGUAGES.SPANISH;
    if (_.includes(browserLanguage, 'nl')) return LANGUAGES.DUTCH;
    if (_.includes(browserLanguage, 'pl')) return LANGUAGES.POLISH;
    return ENGLISH;
  } catch (error) {
    return ENGLISH;
  }
}

export function isActiveVisitor(visitor, fromDate, toDate) {
  return moment(visitor.checkInDate).isSameOrAfter(fromDate) && moment(visitor.checkInDate).isSameOrBefore(toDate) && !visitor.checkOutDate;
}
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Cancel';
import LockIcon from '@material-ui/icons/LockOpen';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { BLUE, MATCH_TAG_MODE, RED, VISIBLE_TAGS_NUMBER } from '../../../_config/consts';
import { getLockImage } from '../../../_config/utils.js';
import * as ModalActions from '../../../redux/actions/modal.actions.js';
import ListLoadingView from '../../ListLoadingView.jsx';
import LockDetailsRow from '../../Locks/LockDetailsRow.jsx';
import Tag from '../../Tag.jsx';
import TagsSelectorField from '../Fields/TagsSelectorField.jsx';
import MDRadioButtonsGroupField from './MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import LockAddIconCustom from '../../CustomIcons/LockAddIconCustom.jsx';


const ItemRow = ({ item, toggleLock, hasError, removeLock, isEven, isSelected, onShowItemDetails, showLockTagsMode }) => {
  const fakeUserTag = {
    color: '#ba68c8',
    name: item && item.username ? item.username.toLowerCase() : '',
    type: 'user',
  };
  let backgroundColor = !isEven ? '#FAFAFA' : 'white';
  const showMoreTagsSectionVisible = item && item.tags && _.size(item.tags) > VISIBLE_TAGS_NUMBER;
  return (
    <div
      className="card-noHover tag-element-selected"
      style={{ display: 'flex', zIndex: 0, flexDirection: 'row', alignItems: 'center', margin: 5, marginBottom: 0, marginTop: 0,  backgroundColor: hasError ? '#ff7f7f' : isSelected ? `${BLUE}40` : backgroundColor }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {toggleLock && !isSelected && 
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 5, opacity:hasError?0:1, pointerEvents:hasError?'none':'unset' }}>
            <IconButton style={{ marginRight: 10, padding: 8 }} onClick={toggleLock? toggleLock : null}>
              <LockAddIconCustom style={{ width: 20, color: 'gray' }}/>
            </IconButton>
          </div>
        }
        {removeLock && (isSelected || !toggleLock) && 
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 0, cursor: 'pointer' }}>
            <IconButton style={{ marginRight: 10, padding: 8 }} onClick={removeLock? removeLock : null}>
              <CloseIcon
                glyph="icon-fontello-ok-circled"
                style={{ fontSize: 20, color: RED }}
              />
            </IconButton>
          </div>
        }
      </div>
      {showLockTagsMode ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h5 style={{ margin: 0, color: '#3f3f3f', width: 'max-content' }}>
            {item.name || `${item.firstname} ${item.lastname}`}
            {item.email ? <h6 style={{ margin: 0, marginTop: 2, color: '#3f3f3f' }}>{item.email}</h6> : null}
          </h5>
        </div>
      ) : (
        <div style={{position: 'relative',display: 'flex',flexDirection: 'row',alignItems: 'center',
          padding: 3,paddingRight: 6,borderRadius: 10,
          marginRight: 5,marginLeft: 5,marginTop: 3,marginBottom: 3,}}>
          <img alt="lock" className="lock-icon" style={{ width: 30, height: 30, marginRight: 10 }} src={getLockImage(item.model)} />
          <div style={{ marginRight: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'max-content' }}>
              <h5 style={{ fontWeight: 'normal', fontSize: 14, margin:0 }}>{item.name}</h5>
            </div>
          </div>
        </div>
      )}
      <span style={{flexGrow:1}}></span>
      {showLockTagsMode ? (
        <div className="tags-container" style={{ margin: 0, marginLeft: 55 }}>
          {item && item.username &&
            <Tag textStyle={{ fontSize: 12 }} style={{ paddingTop: 4, paddingBottom: 4 }} tag={fakeUserTag} />
          }
          {_.map(_.slice(item.tags, 0, VISIBLE_TAGS_NUMBER), tag =>
            <Tag
              textStyle={{ fontSize: 12 }}
              key={tag.id}
              tag={tag}
            />)}
          {showMoreTagsSectionVisible ? (
            <h5
              onClick={(e) => {
                e.stopPropagation();
                onShowItemDetails(item);
              }}
              className="link-label"
              style={{ color: BLUE, marginLeft: 10, marginTop: 18 }}>
                ...<Entity entity="showXMore" data={{ number: _.size(item.tags) - VISIBLE_TAGS_NUMBER }} />
              </h5>
          ): null}
        </div>
      ) : null}
    </div>
  );
};

@connect((state) => ({  }))
class LockerLocksSelectorField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsSelected: props.initialValues || [],
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
  }

  onToggleLock(item) {
    const { onToggleLockId } = this.props;
    const { itemsSelected } = this.state;
    if (item) {
      if (_.find(itemsSelected, i => i.id === item.id)) { // if already selected
        const finalItems = _.filter(itemsSelected, i => i.id !== item.id);
        this.setState({ itemsSelected: finalItems }, () => onToggleLockId(finalItems));
      } 
      else {
        const finalItems = [...itemsSelected, item];
        this.setState({ itemsSelected: finalItems }, () => onToggleLockId(finalItems));
      }
    }
  }

  onRemovelock(item) {
    const { onToggleLockId } = this.props;
    const { itemsSelected } = this.state;
    if (_.find(itemsSelected, i => i.id === item.id)) {
      const finalItems = _.filter(itemsSelected, i => i.id !== item.id);
      this.setState({ itemsSelected: finalItems }, () => onToggleLockId(finalItems));
    }
  }


  componentWillReceiveProps(nextProps) {
    const { initialValues: oldInitialValues } = this.props;
    const { initialValues } = nextProps;
    if (initialValues && oldInitialValues !== initialValues)
      this.setState({ itemsSelected: initialValues });
  }

  onShowLockDetails(item) {
    const { dispatch } = this.props;
    const lockTag = {
      color: '#ba68c8',
      name: item && item.name ? item.name : '',
      type: 'lock',
    };
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="lockSpecialTag" />,
        content: <LockDetailsRow lock={{ ...item, lockTag }} />,
        onConfirmText: <Entity entity="gotcha" />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
        onConfirm: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  render() {
    const { name, selectorFieldName, tags, placeholder, isOptional, isSingleChoice, listTitle, title, 
      selectedTags, onTagsChange, onInputChange, onFilterModeChange, onFetchMoreItems, 
      selectionSectionTitle, selectionSectionSubtitle, selectionSectionEmptySubtitle, disabled,
      onAddLockIdRequest, onRemoveLockIdRequest,
      lockHasErrorCondition
    } = this.props;
    const { itemsSelected } = this.state;
    const hasElements = selectedTags && selectedTags.data && !_.isEmpty(selectedTags.data);
    const tagsPagination = selectedTags && selectedTags.pagination;
    const tagsSelectorFieldName = selectorFieldName || 'lockTagMatchingMode';
    const sortedItemsSelected = _.sortBy(itemsSelected, item => item.name.toLowerCase());
    return (
      <div>
        <div>
          <Entity
            name={name}
            multi={!isSingleChoice}
            componentClass={Field}
            componentAttribute="placeholder"
            entity={placeholder || 'insertItemsTags'}
            title={title || <Entity entity="genericTags" />}
            tags={tags}
            isDisabled={disabled}
            component={TagsSelectorField}
            tagType={'LOCK'}
            mandatory={!isOptional}
            onInputChange={value => onInputChange(value)}
            onHandleChange={onTagsChange ? values => onTagsChange(values) : null}
          />
          {onFilterModeChange &&
            <div style={{ marginLeft: 5 }}>
              <Field
                name={tagsSelectorFieldName}
                title={<Entity entity="daysOfTheWeek" />}
                component={MDRadioButtonsGroupField}
                onHandleChange={values => onFilterModeChange(values)}
                containerstyle={{ paddingLeft: 15, marginTop: 10 }}
                horizontal
                options={[
                  {
                    label: <Entity key={'LOCK'} entity={'matchOneTagSearch'} />,
                    value: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
                  },
                  {
                    label: <Entity key={'LOCK'} entity={'matchEveryTagSearch'} />,
                    value: MATCH_TAG_MODE.EVERY_TAG,
                  },
                ]}
                disabled={disabled}
              />
            </div>
          }
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
              <h5 style={{ fontSize: 15 }}><Entity entity={listTitle} /></h5>
              <h5 style={{ marginLeft: 5, marginTop: 14 }}>
                {hasElements?`(${tagsPagination && tagsPagination.totalElements ? tagsPagination.totalElements : 0})`:""}
              </h5>
            </div>
          </div>
        </div>
        <div>
        {hasElements ? (
          <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
            <div style={{ minHeight:220, maxHeight: 220, overflowY: 'scroll' }}>
              <InfiniteScroll
                pageStart={0}
                useWindow={false}
                initialLoad={false}
                loadMore={() => onFetchMoreItems(tagsPagination.number + 1)}
                hasMore={tagsPagination && tagsPagination.number + 1 < tagsPagination.totalPages}
                loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
              >
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  {_.map(selectedTags.data, (item, index) =>
                    <ItemRow
                      key={item.id}
                      item={item}
                      isEven={index % 2 === 0}
                      isSelected={(itemsSelected && _.find(itemsSelected, i => i.id === item.id))}
                      toggleLock={onAddLockIdRequest ? (() => onAddLockIdRequest(item)) : (() => this.onToggleLock(item))}
                      removeLock={onRemoveLockIdRequest ? (() => onRemoveLockIdRequest(item)) : (() => this.onRemovelock(item))}
                      onShowItemDetails={() => this.onShowLockDetails(item)}
                      showLockTagsMode={true}
                      hasError={lockHasErrorCondition ? lockHasErrorCondition(item) : false}
                    />)}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        ):(
          <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, minHeight:220, maxHeight: 220, 
            display:'flex',alignItems:'center',justifyContent:'center',fontStyle:'italic',fontSize:'1.1em'}}>
            <Entity key={hasElements} entity={hasElements?listTitle:'areaLocksNoSearch'} />
          </div>
        )}
        </div>
        <div style={{display:'flex',flexDirection:'row'}}>
          <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', width:'100%' }}>
            <div style={{display:'flex',flexDirection:'row'}}>
              <LockIcon
                glyph="icon-fontello-ok-circled"
                style={{ fontSize: 20, marginRight: 15, color: BLUE, marginLeft: 2 }}
              />
              <h4 style={{ marginTop: 0, color: '#3f3f3f', marginBottom: 10, fontWeight: 'bold' }}><Entity entity={selectionSectionTitle} /></h4>
              {/*<h4 style={{ marginTop: 0, marginBottom: 10, fontWeight: 'bold', color:'rgb(215, 31, 75)' }}>*</h4>*/}
            </div>
            <h5 style={{ marginTop: 0, color: '#3f3f3f', fontWeight: 200, marginLeft: 5 }}>
              <Entity key={JSON.stringify(itemsSelected)} entity={itemsSelected && !_.isEmpty(itemsSelected) ? selectionSectionSubtitle : selectionSectionEmptySubtitle} 
                style={{color: itemsSelected && !_.isEmpty(itemsSelected)?'':'rgb(215, 31, 75)', fontWeight:itemsSelected && !_.isEmpty(itemsSelected)?'normal':'bold'}}/>
            </h5>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: 220, maxHeight: 220, overflowY: 'scroll' }}>
              {sortedItemsSelected.length>0?_.map(sortedItemsSelected, (item, index) => 
                <ItemRow key={index} item={item} removeLock={onRemoveLockIdRequest ? (() => onRemoveLockIdRequest(item)) : (() => this.onRemovelock(item))} showLockTagsMode={false} hasError={lockHasErrorCondition ? lockHasErrorCondition(item) : false}/>
              ):(<div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, minHeight:220, maxHeight: 220, borderTopRightRadius:0, borderBottomRightRadius:0 }}></div>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
} 

export default LockerLocksSelectorField;

/* eslint-disable react/prop-types */
// @ts-nocheck
import IconButton from '@material-ui/core/IconButton';
import { SettingsOutlined } from '@material-ui/icons';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import InfoIcon from '@material-ui/icons/HelpOutline';
import Refresh from '@material-ui/icons/Refresh';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React, { Component } from 'react';
import { GREEN } from '../../_config/consts.js';
import CloudUploadIconCustom from '../CustomIcons/CloudUploadIconCustom.jsx';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import SearchBarForm from '../forms/SearchBarForm.jsx';


export default class PresentationalViewHeader extends Component {

  render() {
    const {
      themeName,
      onFilterChange,
      onSearchSubmit,
      onNewEntity,
      newEntityTitle,
      onSelectGuest,
      onSelectGuestTag,
      onRefresh,
      searchPlaceholderEntityName,
      onSelectLockTag,
      onSearchReset,
      onSelectOption,
      selectableFieldName,
      selectableOptions,
      selectableFieldTitle,
      onSelectOption2,
      selectableFieldName2,
      selectableOptions2,
      selectableFieldTitle2,
      onExportCSV,
      isGuestTagsMultiSelectionActive,
      isLockTagsMultiSelectionActive,
      onGuestCompanyNameChange,
      onInfo,
      searchNameContainerStyle,
      searchGuestContainerStyle,
      entitiesNumberSection,
      onExportElements,
      numberOfElements,
      isCardMappingEnabled,
      cardIdsMap,
      onSelectOriginalCard,
      onImportFromCSV,
      customFields,
      onCustomFieldSearch,
      onSelectLockHasGateway,
      onSelectProfile,
      onSelectPriority,
      priorityOptions,
      onSelectCategory,
      categoryOptions,
      onSelectAssignee,
      assigneeOptions,
      onSelectResourceType,
      resourceTypeOptions,
      onDatesChange,
      startDate,
      endDate,
      datesHelpersOptions,
      mainFilterTextFormattingFunction,
      onSettings,
    } = this.props;
    const themeClass = `list-view-header bg-${themeName}-theme`;
    return (
      <div className={themeClass} style={{ width: '100%' }}>
        <div style={{ minHeight: 70, padding: 0, display: 'flex', marginLeft: 0, alignItems: 'center' }}>
          {entitiesNumberSection}
          <SearchBarForm
            customFields={customFields}
            isGuestTagsMultiSelectionActive={isGuestTagsMultiSelectionActive}
            isLockTagsMultiSelectionActive={isLockTagsMultiSelectionActive}
            searchPlaceholderEntityName={searchPlaceholderEntityName}
            onSubmit={onSearchSubmit ? data => onSearchSubmit(data) : null}
            onFilterChange={onFilterChange ? value => onFilterChange(value) : null}
            onGuestCompanyNameChange={onGuestCompanyNameChange ? value => onGuestCompanyNameChange(value) : null}
            onSelectLockTag={onSelectLockTag ? tag => onSelectLockTag(tag) : null}
            onSelectGuestTag={onSelectGuestTag ? tag => onSelectGuestTag(tag) : null}
            onSelectLockHasGateway={onSelectLockHasGateway ? value => onSelectLockHasGateway(value) : null}
            onSelectGuest={onSelectGuest ? guest => onSelectGuest(guest) : null}
            onSearchReset={onSearchReset ? () => onSearchReset() : null}
            onSelectProfile={onSelectProfile ? (profile) => onSelectProfile(profile) : null}
            onCustomFieldSearch={onCustomFieldSearch ? (value, key, type) => onCustomFieldSearch(value, key, type) : null}
            onSelectOption={onSelectOption ? value => onSelectOption(value) : null}
            onSelectOption2={onSelectOption2 ? value => onSelectOption2(value) : null}
            onSelectPriority={onSelectPriority ? value => onSelectPriority(value) : null}
            priorityOptions={priorityOptions?priorityOptions:null}
            onSelectCategory={onSelectCategory ? value => onSelectCategory(value) : null}
            categoryOptions={categoryOptions?categoryOptions:null}
            onSelectAssignee={onSelectAssignee ? value => onSelectAssignee(value) : null}
            assigneeOptions={assigneeOptions?assigneeOptions:null}
            onSelectResourceType={onSelectResourceType ? value => onSelectResourceType(value) : null}
            resourceTypeOptions={resourceTypeOptions?resourceTypeOptions:null}
            onDatesChange={onDatesChange? (startDate, endDate) => onDatesChange(startDate, endDate) : null}
            startDate={startDate}
            endDate={endDate}
            datesHelpersOptions={datesHelpersOptions}
            selectableFieldName={selectableFieldName}
            selectableOptions={selectableOptions}
            selectableFieldTitle={selectableFieldTitle}
            selectableFieldName2={selectableFieldName2}
            selectableOptions2={selectableOptions2}
            selectableFieldTitle2={selectableFieldTitle2}
            searchNameContainerStyle={searchNameContainerStyle}
            searchGuestContainerStyle={searchGuestContainerStyle}
            mainFilterTextFormattingFunction={mainFilterTextFormattingFunction ? value => mainFilterTextFormattingFunction(value) : null}
            cardIdsMap={cardIdsMap}
            onSelectOriginalCard={isCardMappingEnabled && onSelectOriginalCard ? selectedOriginalCard => onSelectOriginalCard(selectedOriginalCard) : null}
          />
          {onNewEntity &&
            <MDButton
              backgroundColor={GREEN}
              containerStyle={{ margin: 0, marginLeft: 20, marginTop: 4 }}
              title={newEntityTitle && <Entity entity={newEntityTitle} />}
              onClick={() => onNewEntity()}
            />
          }
          {onImportFromCSV &&
            <MDButton
              backgroundColor="#0288D1"
              containerStyle={{ margin: 0, marginLeft: 20, marginTop: 4 }}
              icon={<CloudUploadIconCustom style={{ color: 'white', marginLeft: 10, marginTop: -2, width: 20 }} />}
              title={<Entity entity="importFromCSV" />}
              onClick={() => onImportFromCSV()}
            />
          }
          {onExportCSV &&
            <MDButton
              backgroundColor="#0288D1"
              containerStyle={{ margin: 0, marginLeft: 20, marginTop: 4 }}
              icon={<DownloadIcon style={{ color: 'white', marginLeft: 10, fontSize: 18 }} />}
              title="CSV"
              onClick={() => onExportCSV()}
            />
          }
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {onRefresh &&
            <IconButton onClick={() => onRefresh()} style={{ marginLeft: 10, marginTop: 3 }}>
              <Refresh style={{ fontSize: 30, color: 'white' }} />
            </IconButton>
          }
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {onInfo &&
            <IconButton onClick={() => onInfo()} style={{ marginLeft: 10, marginTop: 3 }}>
              <InfoIcon style={{ fontSize: 30, color: 'white' }} />
            </IconButton>
          }
        </div>
        {onSettings ? (
          <div>
            <IconButton onClick={() => onSettings()} style={{ marginLeft: 10, marginTop: 3 }}>
              <SettingsOutlined style={{ fontSize: 30, color: 'white' }} />
            </IconButton>
          </div>
        ) : null}
        {onExportElements ? (
          <div style={{ marginLeft: 25, marginTop: 4 }}>
            <SimpleExportMenu
              numberOfElements={numberOfElements}
              onExportLogs={format => onExportElements(format)}
            />
          </div>
        ) : null}
      </div>
    );
  }
};
